import React from 'react';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import FarmTypeLabel from 'components/FarmTypeLabel/FarmTypeLabel';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import ProgressPercent from 'components/ProgressBar/ProgressPercent';
import WaterUsageProgress from 'components/ProgressBar/WaterUsageProgress';
import { GroupStatusBadge } from 'components/StatusBadge/StatusBadge';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge';
import Button from 'components/Button';
import VerticalAligner from 'components/VerticalAligner';
import TruncateHint from 'components/TruncateHint';
import FormattedWeight from 'components/FormattedWeight';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatMortality, formatTableValue } from 'utils';
import moment from 'moment';
import download from 'downloadjs';

export default function useGroupsColumnRenderers(options) {
  const { openDropdown } = useDropdownActions();
  const { isAdmin = false, handleTurnOnEditMode } = options;

  const handleDownloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const handleOpenDropdown = (e, id, name, external_source_id, isSmallScreenView) => {
    const csvPath = `/csv/barnsheets/pig_groups/${id}`;

    const generalOptions = [
      {
        label: <FormattedMessage id="general.downloadBarnSheet" />,
        onClick: handleDownloadCSV(csvPath, `barnsheets-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadMortalityData" />,
        onClick: handleDownloadCSV(`${csvPath}/mortalities`, `mortality-report-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadTreatmentData" />,
        onClick: handleDownloadCSV(`${csvPath}/treatment_products`, `treatments-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadSymptomData" />,
        onClick: handleDownloadCSV(`${csvPath}/symptoms`, `symptoms-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadDiagnosesData" />,
        onClick: handleDownloadCSV(`${csvPath}/diagnoses`, `diagnoses-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadMovementData" />,
        onClick: handleDownloadCSV(`${csvPath}/pig_migrations`, `pig-migrations-${name}.csv`)
      },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${id}`, hide: !isAdmin },
      {
        label: <FormattedMessage id="general.viewSource" />, url: `/sources/${external_source_id}`,
        hide: !isAdmin || !external_source_id
      },
    ];

    const additionalOptions = [
      { label: <FormattedMessage id="general.button.view" />, url: `/barnsheets/groups/${id}` },
      { label: <FormattedMessage id="general.editData" />, onClick: () => handleTurnOnEditMode?.(id) },
    ];

    const options = isSmallScreenView ? generalOptions : additionalOptions.concat(generalOptions);
    openDropdown(e, options);
  };

  const renderGroupNameColumn = (rowData, { rowIndex }) => {
    const { id, name, external_source_id } = rowData;
    return (
      <CustomColumn>
        <div className="group-id-column">
          <VerticalAligner>
            <span className="hide-for-large semibold">
              <FormattedMessage id="component.dataTable.headers.groupId" />&nbsp;•&nbsp;
            </span>
            <Link to={`/barnsheets/groups/${id}`} className="color-primary">
              {name}
            </Link>
            <PigGroupMarketingBadge
              className="ml-5"
              size="small"
              pigGroup={rowData}
              isMinimized
              isHintAttachedToBody
            />
          </VerticalAligner>
          <i
            data-name={`${name}-${id}-${rowIndex}`}
            className="fa fa-dots-three-horizontal hide-for-large"
            onClick={(e) => handleOpenDropdown(e, id, name, external_source_id, true)}
          />
        </div>
      </CustomColumn>
    );
  };

  const renderFarmNameColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
      <Link className="primary-link" to={`/barnsheets/farms/${rowData.farm_id}/groups`}>
        <TruncateHint name={rowData.farm_name} />
      </Link>
    </CustomColumn>
  );

  const renderFarmTypeColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      <FarmTypeLabel farmType={rowData.farm_type} />
    </CustomColumn>
  );

  const renderTreatedColumn = (rowData) => {
    const { avg_treated_24h } = rowData;
    return (
      <CustomColumn textRight label={<FormattedMessage id="general.treatedPeriod" values={{ period: '24h' }} />}>
        {formatTableValue(avg_treated_24h, Number(avg_treated_24h).toFixed(2) + '%', '-')}
      </CustomColumn>
    );
  };

  const renderInventoryColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.inventory" />}>
      {formatTableValue(rowData.pigs)}
    </CustomColumn>
  );

  const renderEstimatedWeightColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="general.estAvgWt" />}>
      <FormattedWeight value={rowData.estimated_weight} />
    </CustomColumn>
  );

  const renderMortalityRateColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.mortRate" />}>
      {formatMortality(rowData.mortality_rate)}
    </CustomColumn>
  );

  const renderTotalMortalityColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.totalMortality" />}>
      {rowData.total_deaths !== null ? rowData.total_deaths : 'N/A'}
    </CustomColumn>
  );

  const renderProgressColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.compliance" />}>
      {formatTableValue(rowData.progress, <ProgressPercent progress={rowData.progress} />)}
    </CustomColumn>
  );

  const renderWaterUsageDiffColumn = (rowData) => {
    const { water_usage_diff_percentage } = rowData;
    return (
      <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.waterUsage" />}>
        <WaterUsageProgress progress={water_usage_diff_percentage} />
      </CustomColumn>
    );
  };

  const renderPigsInColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="general.pigsIn" />}>
      {formatTableValue(rowData.total_pigs_in)}
    </CustomColumn>
  );

  const renderStartDateColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.startDate" />}>
      {formatTableValue(rowData.started_on, moment(rowData.started_on).format('MM/DD/YYYY'))}
    </CustomColumn>
  );

  const renderDaysOnColumn = (rowData) => (
    <CustomColumn textRight label={<FormattedMessage id="general.daysOnFeed" />}>
      {formatTableValue(rowData.days_on_feed)}
    </CustomColumn>
  );

  const renderStatusColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.status" />} noBottomBorder>
      <GroupStatusBadge
        status={rowData.status}
        textValues={{
          date: rowData.closed_on ? moment(rowData.closed_on).format('MM/DD/YY') : null,
        }}
      />
    </CustomColumn>
  );

  const renderButtonsColumn = (rowData, { rowIndex }) => {
    const { name, id, external_source_id } = rowData;
    return (
      <div className="collapsible-value button-column">
        <i
          data-name={`${name}-${id}-${rowIndex}`}
          className="fa fa-dots-three-horizontal show-for-large"
          onClick={(e) => handleOpenDropdown(e, id, name, external_source_id)}
        />
        <Link to={`/barnsheets/groups/${id}`} className="button light hide-for-large">
          <FormattedMessage id="general.button.view" />
        </Link>
        <Button darkGrey onClick={() => handleTurnOnEditMode(id)} className="hide-for-large">
          <FormattedMessage id="general.editData" />
        </Button>
      </div>
    );
  };

  const renderExpandable = (rowData) => {
    const { id, name } = rowData;
    const csvPath = `/csv/barnsheets/pig_groups/${id}`;
    return (
      <div>
        {isAdmin && (
          <Link to={`/groups/${id}`} className="button small light">
            <i className="fa fa-gear mr-5" />
            <FormattedMessage id="general.manage" />
          </Link>
        )}
        <Button small light onClick={() => handleTurnOnEditMode(id)}>
          <i className="fa fa-pencil mr-5" />
          <FormattedMessage id="general.button.edit" />
        </Button>
        <Link to={`/barnsheets/groups/${id}`} className="button small light">
          <i className="fa fa-eye mr-5" />
          <FormattedMessage id="general.viewBarnSheet" />
        </Link>
        <Button small light onClick={handleDownloadCSV(csvPath, `barnsheets-${name}.csv`)}>
          <i className="fa fa-download-btn mr-5" />
          <FormattedMessage id="general.pageTitle.barnsheets" />
        </Button>
        <Button small light onClick={handleDownloadCSV(`${csvPath}/mortalities`, `mortality-report-${name}.csv`)}>
          <i className="fa fa-download-btn mr-5" />
          <FormattedMessage id="general.pageTitle.mortalities" />
        </Button>
        <Button small light onClick={handleDownloadCSV(`${csvPath}/treatment_products`, `treatments-${name}.csv`)}>
          <i className="fa fa-download-btn mr-5" />
          <FormattedMessage id="general.treatments" />
        </Button>
        <Button small light onClick={handleDownloadCSV(`${csvPath}/symptoms`, `symptoms-${name}.csv`)}>
          <i className="fa fa-download-btn mr-5" />
          <FormattedMessage id="component.analyticsPanel.title.symptoms" />
        </Button>
        <Button small light onClick={handleDownloadCSV(`${csvPath}/pig_migrations`, `pig-migrations-${name}.csv`)}>
          <i className="fa fa-download-btn mr-5" />
          <FormattedMessage id="general.pigMigrations" />
        </Button>
      </div>
    );
  };

  return {
    renderButtonsColumn,
    renderDaysOnColumn,
    renderGroupNameColumn,
    renderEstimatedWeightColumn,
    renderExpandable,
    renderWaterUsageDiffColumn,
    renderFarmNameColumn,
    renderFarmTypeColumn,
    renderInventoryColumn,
    renderMortalityRateColumn,
    renderTotalMortalityColumn,
    renderPigsInColumn,
    renderProgressColumn,
    renderTreatedColumn,
    renderStartDateColumn,
    renderStatusColumn,
  };
}
