import { validateToken, logout, logoutAction } from 'reducers/auth';
import { getAuthData } from './authData';

export const handleRedirect = (store) => (nextState, replace) => {
  const { user, authenticated } = store.getState().auth;
  const incompleteRegistration = user?.registration_state === 'passworded';
  const isAdmin = user?.roles_map?.admin;
  const userId = user?.id;

  if (authenticated && !isAdmin) {
    // allow logout
    if (/logout/.test(nextState.location.pathname)) return;

    // replace on incorrect parameters
    if (/complete-registration/.test(nextState.location.pathname)) {
      if (userId !== parseInt(nextState.params.id, 10)) {
        return replace(`/invitations/${userId}/complete-registration`); // eslint-disable-line consistent-return
      }
    }

    // incomplete registration
    if (incompleteRegistration) {
      const redirectUrl = `/invitations/${userId}/set-language`;
      const theSameUrl = nextState.location.pathname === redirectUrl;
      if (!/complete-registration/.test(nextState.location.pathname) && !theSameUrl) {
        return replace(redirectUrl); // eslint-disable-line consistent-return
      }
    }
  }
};

export const requireAuth = (store) => (nextState, replace) => {
  const { authenticated, user } = store.getState().auth;
  const isValidCurrentCompany = user && user.root_companies.some((c) => c.id === user.current_company?.id);

  if (!authenticated || !isValidCurrentCompany) {
    sessionStorage.setItem('redirect_location', JSON.stringify(nextState.location));
    replace('/login');
  } else {
    handleRedirect(store)(nextState, replace);
  }
};

export const preloadUser = (store) => (nextState, replace, proceed) => {
  const { authenticated } = store.getState().auth;
  const authData = getAuthData();
  const isInvitationPages = nextState.location.pathname.startsWith('/invitations');

  if (!authenticated && !isInvitationPages) {
    if (authData.uid && navigator.onLine) {
      store.dispatch(validateToken())
        .catch(() => {
          store.dispatch(logoutAction());
        })
        .finally(() => {
          proceed();
        });
      return;
    }
  }
  proceed();
};

export const logoutUser = (store) => (nextState, replace) => {
  store.dispatch(logout);
  replace('/login');
};

export const permissions = (store) => (page) => (nextState, replace) => {
  const user = store.getState().auth?.user;
  const hasPermission = {
    'admin': user?.roles_map.admin,
    'super-admin': user?.roles_map.super_admin,
    'svr': user?.current_company.admin_svrs,
    'water-usage': user?.current_company.track_water_usage,
    'logistics': user?.current_company.logistics,
    'virtual_visits': user?.current_company.virtual_visits,
    'treatment_protocols': user?.current_company.treatment_protocols,
  }[page];
  if (!hasPermission) {
    replace('/farmfeed');
  }
};

export default (store) => ({
  permissions: permissions(store),
  preloadUser: preloadUser(store),
  requireAuth: requireAuth(store),
  handleRedirect: handleRedirect(store),
  logoutUser: logoutUser(store),
});
