import { handleActions, createAction, combineActions } from 'redux-actions';
import {
  createFarmSVR,
  fetchSVRFarm as getSVRFarm,
  getFarmSVR,
  updateFarmSVR,
  createSVRComment,
  updateSVRComment,
  deleteFarmDraftSVR,
  deleteSVRComment,
  attachSVRAssets,
  detachSVRAsset,
} from 'endpoints/svr';
import { parseSVRResponse } from 'utils/SVRHelper';

// ------------------------------------
// Helpers
// ------------------------------------

const getActionSet = (actionType) => [`${actionType}_PENDING`, `${actionType}_REJECTED`, `${actionType}_FULFILLED`];

// ------------------------------------
// Constants
// ------------------------------------

const initialState = {
  farm: undefined,
  report: undefined,
  isLoading: false,
};

// ------------------------------------
// Action Types
// ------------------------------------
const FETCH_SVR_FARM = 'siteVisitReport/FETCH_SVR_FARM';
const [FETCH_SVR_FARM_PENDING, FETCH_SVR_FARM_FULFILLED, FETCH_SVR_FARM_REJECTED] = getActionSet(FETCH_SVR_FARM);

const SAVE_SVR = 'siteVisitReport/SAVE_SVR';
const [SAVE_SVR_PENDING, SAVE_SVR_REJECTED] = getActionSet(SAVE_SVR);

const CREATE_SVR = 'siteVisitReport/CREATE_SVR';
const [CREATE_SVR_PENDING, CREATE_SVR_REJECTED, CREATE_SVR_FULFILLED] = getActionSet(CREATE_SVR);

const DELETE_SVR = 'siteVisitReport/DELETE_SVR';
const [DELETE_SVR_PENDING, DELETE_SVR_REJECTED] = getActionSet(DELETE_SVR);

const PUBLISH_SVR = 'siteVisitReport/PUBLISH_SVR';
const [PUBLISH_SVR_PENDING, PUBLISH_SVR_REJECTED] = getActionSet(PUBLISH_SVR);

const FETCH_SVR = 'siteVisitReport/FETCH_SVR';
const [FETCH_SVR_PENDING, FETCH_SVR_REJECTED, FETCH_SVR_FULFILLED] = getActionSet(FETCH_SVR);

const ANSWER_QUESTION = 'siteVisitReport/ANSWER_QUESTION';
const [ANSWER_QUESTION_PENDING, ANSWER_QUESTION_REJECTED, ANSWER_QUESTION_FULFILLED] = getActionSet(ANSWER_QUESTION);

const ANSWER_ADDITIONAL_QUESTION = 'siteVisitReport/ANSWER_ADDITIONAL_QUESTION';
const [
  ANSWER_ADDITIONAL_QUESTION_PENDING,
  ANSWER_ADDITIONAL_QUESTION_REJECTED,
  ANSWER_ADDITIONAL_QUESTION_FULFILLED,
] = getActionSet(ANSWER_ADDITIONAL_QUESTION);

const POST_COMMENT = 'siteVisitReport/POST_COMMENT';
const [POST_COMMENT_PENDING, POST_COMMENT_REJECTED, POST_COMMENT_FULFILLED] = getActionSet(POST_COMMENT);

const DELETE_COMMENT = 'siteVisitReport/DELETE_COMMENT';
const [DELETE_COMMENT_PENDING, DELETE_COMMENT_REJECTED, DELETE_COMMENT_FULFILLED] = getActionSet(DELETE_COMMENT);

const UPDATE_COMMENT = 'siteVisitReport/UPDATE_COMMENT';
const [UPDATE_COMMENT_PENDING, UPDATE_COMMENT_REJECTED, UPDATE_COMMENT_FULFILLED] = getActionSet(UPDATE_COMMENT);

const UPLOAD_ASSET = 'siteVisitReport/UPLOAD_ASSET';
const [UPLOAD_ASSET_PENDING, UPLOAD_ASSET_REJECTED, UPLOAD_ASSET_FULFILLED] = getActionSet(UPLOAD_ASSET);

const DELETE_ASSET = 'siteVisitReport/DELETE_ASSET';
const [DELETE_ASSET_PENDING, DELETE_ASSET_REJECTED, DELETE_ASSET_FULFILLED] = getActionSet(DELETE_ASSET);

const RESET_DATA = 'siteVisitReport/RESET_DATA';

// ------------------------------------
// Actions
// ------------------------------------

const processAction = async (callback, dispatch, actionType) => {
  try {
    dispatch(createAction(`${actionType}_PENDING`)());
    return callback();
  } catch (error) {
    dispatch(createAction(`${actionType}_REJECTED`)());
    throw error;
  }
};

export const setAnswer = (categoryIndex, questionIndex, answer) => async (dispatch, getState) => {
  return processAction(async () => {
    const { report: { categories, id: svrId }, farm: { id: farmId } } = getState().siteVisitReport;
    const category = categories[categoryIndex];
    const question = category.svr_questions[questionIndex];
    const updatedAnswer = { ...(question.answer || {}), ...answer };
    const updatedQuestion = { ...question, answer: updatedAnswer };
    const requestData = { questions: [updatedQuestion] };
    const response = await updateFarmSVR(farmId, svrId, requestData);
    const data = parseSVRResponse(response);
    dispatch(createAction(ANSWER_QUESTION_FULFILLED)(data));
  }, dispatch, ANSWER_QUESTION);
};

export const setAdditionalAnswer = (updatedQuestionIndex, answer) => async (dispatch, getState) => {
  return processAction(async () => {
    const { farm, report: { id: svrId, additionalCategory: { svr_questions } } } = getState().siteVisitReport;
    const questionForUpdate = svr_questions[updatedQuestionIndex];
    questionForUpdate.answer = { ...questionForUpdate.answer, ...answer };
    const response = await updateFarmSVR(farm.id, svrId, { questions: [questionForUpdate] });
    const data = parseSVRResponse(response);
    dispatch(createAction(ANSWER_ADDITIONAL_QUESTION_FULFILLED)(data));
  }, dispatch, ANSWER_ADDITIONAL_QUESTION);
};

export const postComment = (categoryIndex, questionIndex, commentData) => async (dispatch, getState) => {
  return processAction(async () => {
    const { categories } = getState().siteVisitReport.report;
    const question = categories[categoryIndex].svr_questions[questionIndex];
    const createdComment = await createSVRComment(question.answer.id, commentData);
    const comments = [createdComment, ...(question.answer.comments || [])];
    const payload = { categoryIndex, questionIndex, answer: { comments } };
    dispatch(createAction(POST_COMMENT_FULFILLED)(payload));
  }, dispatch, POST_COMMENT);
};

export const updateComment = (categoryIndex, questionIndex, commentData) => async (dispatch, getState) => {
  return processAction(async () => {
    const { categories } = getState().siteVisitReport.report;
    const category = categories[categoryIndex];
    const { answer } = category.svr_questions[questionIndex];
    const updatedComment = await updateSVRComment(answer.id, commentData.id, commentData);
    const comments = answer.comments.map((comment) => (comment.id === updatedComment.id ? updatedComment : comment));
    const payload = { categoryIndex, questionIndex, answer: { comments }  };
    dispatch(createAction(UPDATE_COMMENT_FULFILLED)(payload));
  }, dispatch, UPDATE_COMMENT);
};

export const deleteComment = (categoryIndex, questionIndex, commentId) => async (dispatch, getState) => {
  return processAction(async () => {
    const { categories } = getState().siteVisitReport.report;
    const category = categories[categoryIndex];
    const { answer } = category.svr_questions[questionIndex];
    await deleteSVRComment(answer.id, commentId);
    const comments = answer.comments.filter(({ id }) => id !== commentId);
    const payload = { categoryIndex, questionIndex, answer: { comments } };
    dispatch(createAction(DELETE_COMMENT_FULFILLED)(payload));
  }, dispatch, DELETE_COMMENT);
};

export const fetchSVRFarm = (farmId) => async (dispatch) => {
  return processAction(async () => {
    const farm = await getSVRFarm(farmId);
    dispatch(createAction(FETCH_SVR_FARM_FULFILLED)(farm));
  }, dispatch, FETCH_SVR_FARM);
};

export const fetchSVR = (farmId, svrId) => async (dispatch) => {
  return processAction(async () => {
    const response = await getFarmSVR(farmId, svrId);
    const data = parseSVRResponse(response);
    dispatch(createAction(FETCH_SVR_FULFILLED)(data));
  }, dispatch, FETCH_SVR);
};

export const publishSVR = () => async (dispatch, getState) => {
  return processAction(async () => {
    const { farm: { id: farmId }, report: { id: svrId } } = getState().siteVisitReport;
    await updateFarmSVR(farmId, svrId, { is_draft: false, questions: [] });
  }, dispatch, PUBLISH_SVR);
};

export const deleteDraftSVR = () => async (dispatch, getState) => {
  return processAction(async () => {
    const { report: { id: svrId }, farm: { id: farmId } } = getState().siteVisitReport;
    return deleteFarmDraftSVR(farmId, svrId);
  }, dispatch, DELETE_SVR);
};

export const createDraftSVR = (farmId) => async (dispatch) => {
  return processAction(async () => {
    const createdSVR = await createFarmSVR(farmId, { is_draft: true, questions: [] });
    const data = parseSVRResponse(createdSVR);
    dispatch(createAction(CREATE_SVR_FULFILLED)(data));
    return createdSVR.id;
  }, dispatch, CREATE_SVR);
};

export const uploadAssets = (questionIndex, assetsForUpload) => async (dispatch, getState) => {
  return processAction(async () => {
    const { report: { additionalCategory: { svr_questions } } } = getState().siteVisitReport;
    const { answer } = svr_questions[questionIndex];
    const assets = await attachSVRAssets(answer.id, assetsForUpload);
    const value = [...(answer.value || []), ...assets];
    const payload = { questionIndex, answer: { value } };
    dispatch(createAction(UPLOAD_ASSET_FULFILLED)(payload));
  }, dispatch, UPLOAD_ASSET);
};

export const deleteAsset = (questionIndex, assetForDelete) => async (dispatch, getState) => {
  return processAction(async () => {
    const { report: { additionalCategory: { svr_questions } } } = getState().siteVisitReport;
    const { answer } = svr_questions[questionIndex];
    await detachSVRAsset(answer.id, assetForDelete);
    const value = answer.value?.filter(({ id }) => id !== assetForDelete.id) || [];
    const payload = { questionIndex, answer: { value } };
    dispatch(createAction(UPLOAD_ASSET_FULFILLED)(payload));
  }, dispatch, DELETE_ASSET);
};

export const resetData = createAction(RESET_DATA);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  // PENDING ACTIONS
  [combineActions(
    FETCH_SVR_FARM_PENDING,
    FETCH_SVR_PENDING,
    SAVE_SVR_PENDING,
    CREATE_SVR_PENDING,
    DELETE_SVR_PENDING,
    PUBLISH_SVR_PENDING,
    ANSWER_QUESTION_PENDING,
    UPDATE_COMMENT_PENDING,
    UPLOAD_ASSET_PENDING,
    DELETE_ASSET_PENDING,
    DELETE_COMMENT_PENDING,
    ANSWER_ADDITIONAL_QUESTION_PENDING,
    POST_COMMENT_PENDING,
    PUBLISH_SVR_PENDING,
  )]: (state) => ({
    ...state,
    isLoading: true,
  }),
  // REJECTED ACTIONS
  [combineActions(
    FETCH_SVR_FARM_REJECTED,
    FETCH_SVR_REJECTED,
    SAVE_SVR_REJECTED,
    CREATE_SVR_REJECTED,
    DELETE_SVR_REJECTED,
    PUBLISH_SVR_REJECTED,
    ANSWER_QUESTION_REJECTED,
    UPDATE_COMMENT_REJECTED,
    UPLOAD_ASSET_REJECTED,
    DELETE_ASSET_REJECTED,
    DELETE_COMMENT_REJECTED,
    ANSWER_ADDITIONAL_QUESTION_REJECTED,
    POST_COMMENT_REJECTED,
    PUBLISH_SVR_REJECTED,
  )]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  // FULFILLED ACTIONS
  [combineActions(
    FETCH_SVR_FULFILLED,
    CREATE_SVR_FULFILLED,
    ANSWER_QUESTION_FULFILLED,
    ANSWER_ADDITIONAL_QUESTION_FULFILLED
  )]: (state, action) => ({
    ...state,
    report: action.payload,
    isLoading: false,
  }),
  // COMMENTS
  [combineActions(
    POST_COMMENT_FULFILLED,
    DELETE_COMMENT_FULFILLED,
    UPDATE_COMMENT_FULFILLED,
  )]: (state, { payload: { categoryIndex, questionIndex, answer } }) => {
    const { report } = state;
    const { categories } = report;
    const category = report.categories[categoryIndex];
    const { svr_questions: categoryQuestions } = category;
    const updatedQuestion = category.svr_questions[questionIndex];

    return {
      ...state,
      report: {
        ...report,
        categories: [
          ...categories.slice(0, categoryIndex),
          {
            ...category,
            svr_questions: [
              ...categoryQuestions.slice(0, questionIndex),
              {
                ...updatedQuestion,
                answer: {
                  ...(updatedQuestion.answer || {}),
                  ...answer
                }
              },
              ...categoryQuestions.slice(questionIndex + 1),
            ],
          },
          ...categories.slice(categoryIndex + 1),
        ],
      },
      isLoading: false,
    };
  },
  // ASSETS
  [combineActions(
    DELETE_ASSET_FULFILLED,
    UPLOAD_ASSET_FULFILLED,
  )]: (state, { payload: { questionIndex, answer } }) => {
    const { report } = state;
    const { additionalCategory } = report;
    const { svr_questions: questions } = additionalCategory;
    const question = questions[questionIndex];

    return {
      ...state,
      report: {
        ...report,
        additionalCategory: {
          ...additionalCategory,
          svr_questions: [
            ...questions.slice(0, questionIndex),
            {
              ...question,
              answer: {
                ...(question.answer || {}),
                ...answer,
              }
            },
            ...questions.slice(questionIndex + 1),
          ]
        }
      },
      isLoading: false,
    };
  },
  [FETCH_SVR_FARM_FULFILLED]: (state, action) => ({
    ...state,
    farm: action.payload,
    isLoading: false,
  }),
  // RESETING
  [RESET_DATA]: () => ({
    ...initialState,
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
