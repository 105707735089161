import React from 'react';
import T from 'prop-types';
// components
import DateConditionSelector from './DateConditionSelector';
import SimpleConditionSelector from './SimpleConditionSelector';
import FixedListConditionSelector from './FixedListConditionSelector';
import TextConditionSelector from './TextConditionSelector';
import MultiItemsConditionSelector from './MultiItemsConditionSelector';
import NameAndTextConditionSelector from './NameAndTextConditionSelector';
import NumberConditionSelector from './NumberConditionSelector';
// utils, constants
import { getSectionInputLabel } from 'utils/userFiltersHelper';
import {
  conditionsBySectionType,
  sectionInputPlaceholderIds,
  numberConditions,
  userFilterSectionTypes,
} from 'utils/constants/userFilters';

const SwitchConditionSelector = ({ selectedSection, ...otherProps }) => {
  switch (selectedSection.type) {
    case userFilterSectionTypes.arriveAt:
    case userFilterSectionTypes.date:
    case userFilterSectionTypes.pigGroupStartDate:
    case userFilterSectionTypes.startDate:
    case userFilterSectionTypes.closeDate:
    case userFilterSectionTypes.pigGroupCloseDate:
    case userFilterSectionTypes.latestSvrCreatedAt:
      return (
        <DateConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={conditionsBySectionType[selectedSection.type]}
        />
      );
    case userFilterSectionTypes.aiImagePrediction:
    case userFilterSectionTypes.aiTreatmentPrediction:
    case userFilterSectionTypes.aiMortalityPrediction:
    case userFilterSectionTypes.dcAsset:
    case userFilterSectionTypes.flagged:
    case userFilterSectionTypes.svr:
    case userFilterSectionTypes.pigGroupStatus:
    case userFilterSectionTypes.pigMovements:
      return (
        <SimpleConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={conditionsBySectionType[selectedSection.type]}
        />
      );
    case userFilterSectionTypes.farmExternalLinkId:
    case userFilterSectionTypes.farmFederalPremiseId:
    case userFilterSectionTypes.farmLocation:
      return (
        <TextConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={conditionsBySectionType[selectedSection.type]}
          inputPlaceholderId={sectionInputPlaceholderIds[selectedSection.type]}
        />
      );
    case userFilterSectionTypes.farm:
    case userFilterSectionTypes.pigGroup:
    case userFilterSectionTypes.user:
    case userFilterSectionTypes.company:
    case userFilterSectionTypes.load:
    case userFilterSectionTypes.managementCompany:
    case userFilterSectionTypes.latestSvrCreatedBy:
      return (
        <NameAndTextConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={conditionsBySectionType[selectedSection.type]}
          inputPlaceholderId={sectionInputPlaceholderIds[selectedSection.type]}
        />
      );
    case userFilterSectionTypes.avgMortalityRate:
    case userFilterSectionTypes.avgTreated:
    case userFilterSectionTypes.compliance:
    case userFilterSectionTypes.dcMortalityRate:
    case userFilterSectionTypes.mortalityRate:
      return (
        <NumberConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={numberConditions}
          inputLabel="%"
          inputStep={0.01}
          min={0}
          max={100}
        />
      );
    case userFilterSectionTypes.dcInventory:
    case userFilterSectionTypes.openPigGroupsCount:
    case userFilterSectionTypes.pigsCount:
    case userFilterSectionTypes.totalDeaths:
    case userFilterSectionTypes.weight: {
      return (
        <NumberConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={numberConditions}
          inputLabel={getSectionInputLabel(selectedSection.type)}
          inputStep={1}
          min={0}
          max={999999}
          valueKey="count"
          valueEndKey="count_to"
          valueStartKey="count_from"
        />
      );
    }
    case userFilterSectionTypes.currentWaterUsage:
    case userFilterSectionTypes.dcEstimatedWeight:
      return (
        <NumberConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={numberConditions}
          inputLabel={getSectionInputLabel(selectedSection.type)}
          inputStep={0.01}
          min={0}
          max={999999}
        />
      );
    case userFilterSectionTypes.farmType:
    case userFilterSectionTypes.loadType:
    case userFilterSectionTypes.commitmentStatus:
    case userFilterSectionTypes.loadingStatus:
      return (
        <FixedListConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={conditionsBySectionType[selectedSection.type]}
        />
      );
    case userFilterSectionTypes.dcDiagnoses:
      return (
        <MultiItemsConditionSelector
          {...otherProps}
          selectedSection={selectedSection}
          conditions={conditionsBySectionType[selectedSection.type]}
          inputPlaceholderId={sectionInputPlaceholderIds[selectedSection.type]}
        />
      );
    default: {
      console.warn('User filter section type didn\'t recognized', { type: selectedSection.type });
      return null;
    }
  }
};

SwitchConditionSelector.propTypes = {
  selectedSection: T.object.isRequired,
  title: T.string.isRequired,
  onBack: T.func.isRequired,
  onSave: T.func.isRequired,
};

export default SwitchConditionSelector;
