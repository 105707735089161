import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { fetchFarmProfile } from 'reducers/profiles';
// components
import { FormattedMessage } from 'react-intl';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import Subnavigation from 'components/Subnavigation';
import ProgressPercent from 'components/ProgressBar/ProgressPercent';
// api
import { downloadBarnSheetsGroupsCSV } from 'endpoints/barnsheets/groups';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatTableValue } from 'utils';
import download from 'downloadjs';

class BarnSheetsFarmProfile extends Component {

  constructor(props) {
    super(props);
    const { params: { farmId, companyId }, location: { query }, isSVREnabled } = props;
    const path = this.getPagePath(farmId, companyId);
    this.navLinks = [
      { link: { pathname: `${path}/groups`, query }, label: <FormattedMessage id="general.pageTitle.groups" /> },
      { link: { pathname: `${path}/treatments`, query }, label: <FormattedMessage id="general.treatments" /> },
      { link: { pathname: `${path}/diagnoses`, query }, label: <FormattedMessage id="general.diagnosisHistory" /> },
      { link: { pathname: `${path}/pig-movements`, query }, label: <FormattedMessage id="general.pigMovements" /> },
      { link: { pathname: `${path}/media`, query }, label: <FormattedMessage id="general.media" /> },
      {
        link: { pathname: `${path}/site-visit-reports`, query }, label: <FormattedMessage id="general.svr" />,
        isHidden: !isSVREnabled
      },
    ];
  }

  componentDidMount() {
    this.getFarmData();
  }

  componentWillUnmount() {
    this.props.setBreadcrumbs();
  }

  getPagePath = (farmId, companyId) => {
    if (companyId) return `/barnsheets/companies/${companyId}/farms/${farmId}`;
    return `/barnsheets/farms/${farmId}`;
  };

  downloadFarmGroupsCSV = () => {
    const { params: { farmId } } = this.props;
    const filename = `farm-groups-${farmId}.csv`;

    return downloadBarnSheetsGroupsCSV({ farm_id: farmId })
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch(toastResponseErrors);
  };

  getFarmData = () => {
    const { params: { farmId, companyId }, setBreadcrumbs, fetchFarmProfile } = this.props;

    fetchFarmProfile(`/barnsheets/farms/${farmId}`)
      .then((response) => {
        const { value: { resource } } = response;
        const label = <FormattedMessage id="general.pageTitle.barnsheets" />;

        const breadcrumbsData = companyId
          ? [
            { label, path: '/barnsheets/companies' },
            { label: resource.manager.name, path: `/barnsheets/companies/${companyId}/farms` },
            { label: resource.name, path: `/barnsheets/companies/${companyId}/farms/${farmId}/groups`,
              useLabelAsMobileTitle: true, },
          ]
          : [
            { label, path: '/barnsheets/farms' },
            { label: resource.name, path: `/barnsheets/farms/${farmId}/groups`, useLabelAsMobileTitle: true, },
          ];

        setBreadcrumbs(breadcrumbsData);
      })
      .catch((resp) => {
        setBreadcrumbs([
          {
            label: <FormattedMessage id="general.pageTitle.barnsheets" />,
            path: '/barnsheets/farms',
            useLabelAsMobileTitle: true,
          },
        ]);
        return toastResponseErrors(resp);
      });
  };

  getFarmStats = ({ pigs_count, avg_treated_24h, progress }) => {
    return [
      { label: <FormattedMessage id="general.puc" />, value: formatTableValue(pigs_count) },
      { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24H' }} />,
        value: formatTableValue(avg_treated_24h, Number(avg_treated_24h).toFixed(2) + '%') },
      { label: <FormattedMessage id="component.analyticsPanel.title.farmCompliance" />,
        value: formatTableValue(progress, <ProgressPercent progress={progress} />) },
    ];
  };

  render() {
    const { params: { farmId }, children, currentUser: { roles_map }, farm, isLoading } = this.props;
    const farmStats = this.getFarmStats(farm);
    const dropdownOptions = [
      {
        label: <FormattedMessage id="general.manageFarm" />,
        url: `/admin/farms/${farmId}/edit`,
        hide: !roles_map.admin
      },
      {
        label: <FormattedMessage id="general.button.downloadCSV" />,
        onClick: this.downloadFarmGroupsCSV,
        hide: !roles_map.admin
      },
    ];

    return (
      <div>
        <FarmProfileHeader
          actualFarmId={Number(farmId)}
          isLoading={isLoading}
          farm={farm}
          stats={farmStats}
          dropdownOptions={dropdownOptions}
        />

        <Subnavigation className="small-12 column" links={this.navLinks} />

        <section className="small-12 column">
          {children}
        </section>
      </div>
    );
  }
}

BarnSheetsFarmProfile.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsFarmProfile.propTypes = {
  location: T.object.isRequired,
  params: T.object.isRequired,
  currentUser: T.object.isRequired,
  children: T.node.isRequired,
  setBreadcrumbs: T.func.isRequired,
  fetchFarmProfile: T.func.isRequired,
  farm: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isSVREnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    currentUser: state.auth.user,
    farm: state.profiles.farm.data,
    isLoading: state.profiles.farm.isLoading,
    isSVREnabled: state.auth.user.current_company.svrs && state.auth.user.current_company.admin_svrs,
  }), {
    setBreadcrumbs,
    fetchFarmProfile,
  }
)(BarnSheetsFarmProfile);
