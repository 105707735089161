import React, { useRef } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import Dropzone from 'react-dropzone';
import Button from 'components/Button';
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
// redux
import { openViewer } from 'reducers/mediaViewer';
import { connect } from 'react-redux';
import classnames  from 'classnames/bind';
import { uploadAssets, deleteAsset } from 'reducers/siteVisitReport';
// utils
import { formatAsset } from './helper';
import { isVideoAsset } from 'utils';
// styles
import styles from './SVRMediaUploader.module.scss';

const cn = classnames.bind(styles);

const SVRMediaUploader  = ({  attachedAssets, questionIndex, openViewer, uploadAssets, deleteAsset }) => {
  const dropzone = useRef(null);

  const attachAssets = (assets) => uploadAssets(questionIndex, assets.map(formatAsset));

  const detachAsset = (detachedIndex) => () => {
    const assetForRemove = attachedAssets.find((_, assetIndex) => assetIndex === detachedIndex);
    deleteAsset(questionIndex, assetForRemove);
  };

  const renderAssetsPreviews = () => {
    const assets = attachedAssets
      .map((file, fileIndex) => ({
        ...file,
        fileIndex
      }))
      .filter((({ action }) => action !== 'delete'));

    return assets.map((file, index) => (
      <div className={cn('preview-row')} key={file.fileIndex}>
        <div className={cn('file-wrapper')}>
          <div
            onClick={() => openViewer(assets, index, {})}
            className={cn('preview-image')}
            style={{ backgroundImage: `url(${file?.url?.thumb || URL.createObjectURL(file.asset)})` }}
          />
          {isVideoAsset(file) && <VideoPreviewOverlay size="25" />}
        </div>
        <div className={cn('file-info')}>
          <span className={cn('file-name')}>{file.name || file.asset.name}</span>
          <div className={cn('desc')}>
            {file.media_type || file.asset.type} | {(file.media_size || file.asset.size) / 1000}Kb
          </div>
        </div>
        <i onClick={detachAsset(file.fileIndex)} className={cn('fa fa-trash-o', 'icon')} />
      </div>
    ));
  };

  const openDialog = (e) => {
    e.stopPropagation();

    if (dropzone.current) {
      dropzone.current.open();
    }
  };

  const uploadedMediaLength = attachedAssets.filter(({ action }) => action !== 'delete').length;

  return (
    <div className={cn('svr-media-uploader')}>
      <div className={cn('header-section')}>
        <FormattedMessage id="general.media" />
        <DotSeparator />
        {uploadedMediaLength}
      </div>
      <Dropzone
        ref={dropzone}
        onDrop={attachAssets}
        accept=".jpg, .jpeg, .png, .avi, .mp4, .mov"
        multiple
        className={cn('dropzone')}
        activeClassName={cn('dropzone-active')}
        disableClick
      >
        <div className={cn('body-section')}>
          <div className={cn('upload-placeholder')}>
            <i className={cn('fa fa-cloud-upload-ios', 'upload-icon')} />
            <div onClick={openDialog} className="trigger">
              <FormattedMessage
                id="component.mediaUploader.dropFilesOrBrowse"
                values={{
                  textLink: (
                    <span className={cn('browse-btn')} onClick={openDialog}>
                      <FormattedMessage id="component.mediaUploader.browse" />
                    </span>
                  ),
                }}
              />
            </div>
          </div>
          <div className={cn('previews-section')}>
            {renderAssetsPreviews()}
          </div>
        </div>
        <Button wide light onClick={openDialog} className={cn('browse-mobile-btn')}>
          <FormattedMessage id="general.button.browse" />
        </Button>
      </Dropzone>
    </div>
  );
};

SVRMediaUploader.propTypes = {
  questionIndex: T.number.isRequired,
  attachedAssets: T.array,
  openViewer: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  uploadAssets: T.func.isRequired,
};

export default connect(null, {
  openViewer,
  uploadAssets,
  deleteAsset,
})(SVRMediaUploader);
