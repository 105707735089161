import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import moment from 'moment';
import startCase from 'lodash.startcase';
import { currentUtcOffsetTime } from './timeHelper';

export function getMigrationTypeLabel(migrationType) {
  switch (migrationType) {
    case 'placement': return <FormattedMessage id="general.headPlaced" />;
    case 'transfer': return <FormattedMessage id="general.headTransferred" />;
    case 'sale': return <FormattedMessage id="general.headSold" />;
    case 'count_add': return <FormattedMessage id="general.headAdded" />;
    case 'count_remove': return <FormattedMessage id="general.headRemoved" />;
    default: return '';
  }
}

export function isMigrationTypeNegative(migrationType) {
  return ['transfer', 'sale', 'count_remove'].includes(migrationType);
}

export function formatCheckupDate(date, farmUtcOffset) {
  const momentDate = moment.tz(date, 'UTC');
  const dayOfWeek = startCase(momentDate.format('ddd'));
  const month = startCase(momentDate.format('MMM'));
  const checkupDate = `${dayOfWeek}., ${month}. ${momentDate.format('D, YYYY')}`;
  const currentFarmTime = currentUtcOffsetTime(farmUtcOffset);

  if (momentDate.isSame(currentFarmTime, 'day')) {
    return <FormattedMessage id="general.time.todayAt" values={{ date: checkupDate }} />;
  }

  if (momentDate.isSame(currentFarmTime.subtract(1, 'days'), 'day')) {
    return <FormattedMessage id="general.time.yesterdayAt" values={{ date: checkupDate }} />;
  }

  return checkupDate;
}

export function isSomeSectionReporting(checkupState) {
  const { isReportingMovements, isReportingDeaths, isReportingTreatments, isReportingSymptoms, isReportingSurvey,
    isReportingTemps, isReportingWater, isReportingSVRQuestionAnswer } = checkupState;
  return isReportingMovements
    || isReportingDeaths
    || isReportingTreatments
    || isReportingSymptoms
    || isReportingSVRQuestionAnswer
    || isReportingSurvey
    || isReportingTemps
    || isReportingWater;
}

export function getCheckupSegmentHeaderData({
  // edge
  edge_water_usage,
  edge_temperature,
  // maximus
  maximus_temperature,
  maximus_water_usage,
  // dicam
  dicam_temperature,
  dicam_water_usage,
  // barntools
  barn_tools_temperature,
  barn_tools_water_usage,
}) {
  if (edge_temperature || edge_water_usage) {
    return { text: <FormattedMessage id="general.edgeController" /> };
  }
  if (dicam_temperature || dicam_water_usage) {
    return { text: <FormattedMessage id="general.dicamController" /> };
  }
  if (maximus_temperature || maximus_water_usage) {
    return { text: <FormattedMessage id="general.maximusController" /> };
  }
  if (barn_tools_temperature || barn_tools_water_usage) {
    return { text: <FormattedMessage id="general.barnToolController" /> };
  }
  return {};
}

export function getCheckupBackUrls(checkup, location) {
  const { query } = location;
  const groupId = checkup?.pig_group_id;
  const farmId = checkup?.pig_group?.farm_id;
  const isEditMode = (query.editMode === 'true') && checkup?.pig_group?.in_edit_mode;
  const checkupUrl = isEditMode
    ? `/barnsheets/daily-checkup/${checkup.id}?editMode=true`
    : `/daily-checkup/${checkup.id}`;
  let prevPageUrl = checkupUrl;
  if (query.redirect === 'group-treatments' && groupId) {
    prevPageUrl = `/barnsheets/groups/${groupId}/treatments`;
  }
  if (query.redirect === 'farm-treatments' && farmId) {
    prevPageUrl = `/barnsheets/farms/${farmId}/treatments`;
  }
  return {
    checkupUrl,
    prevPageUrl,
  };
}

export const thirdPartyTemperatureDevices = [
  'edge_temperature',
  'maximus_temperature',
  'dicam_temperature',
  'barn_tools_temperature',
];

export const thirdPartyWaterUsageDevices = [
  'edge_water_usage',
  'maximus_water_usage',
  'dicam_water_usage',
  'barn_tools_water_usage',
];
