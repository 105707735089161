import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Button from 'components/Button';
import NameWithAddressForm from './NameWithAddrressForm';
// redux
import { connect } from 'react-redux';

const PackingCompanyForm = ({
  hasLocationDefining = false,
  name,
  addressObj,
  isEditForm,
  onSubmit,
  initialValues,
}) => {
  const allowSubmit = !!name && !!addressObj && !!addressObj.city;
  return (
    <NameWithAddressForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      hasLocationDefining={hasLocationDefining}
      nameLabelKey="general.packingCompanyName"
    >
      {({ handleSubmit, isSubmitting }) => (
        <Fragment>
          {/* BUTTON */}
          <div className="button-submit">
            <Button
              onClick={handleSubmit}
              primary
              type="button"
              disabled={!allowSubmit || isSubmitting}
            >
              {isEditForm
                ? <FormattedMessage id="general.button.saveChanges" />
                : <FormattedMessage id="general.button.createPackingCompany" />
              }
            </Button>
            {!isEditForm && (
              <Link to="/admin/packing-companies" className="button btn-link margin-left-1">
                <FormattedMessage id="general.button.cancel" />
              </Link>
            )}
          </div>
        </Fragment>
      )}
    </NameWithAddressForm>
  );
};

PackingCompanyForm.propTypes = {
  onSubmit: T.func.isRequired,
  initialValues: T.object,
  isEditForm: T.bool,
  name: T.string,
  addressObj: T.object,
  hasLocationDefining: T.bool,
};

const selector = formValueSelector('address-form');

export default connect(
  (state) => ({
    name: selector(state, 'name'),
    addressObj: selector(state, 'addressObj'),
  })
)(PackingCompanyForm);
