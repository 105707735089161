/*
 * Various utility functions
 */
import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
// utils
import getValue from 'lodash.get';
import isNumber from 'lodash.isnumber';
import flow from 'lodash.flow';
import take from 'lodash.take';
import round from 'lodash.round';
import groupBy from 'lodash.groupby';
import sumBy from 'lodash.sumby';
import { isChrome, isSafari, isFirefox, isEdge, isIE } from 'react-device-detect';
import moment from 'moment';
import memoize from 'lodash.memoize';
import { toastr } from 'react-redux-toastr';
import { currentUtcOffsetTime, changeUtcOffsetTime } from 'utils/timeHelper';
// icons
import okIcon from '../../public/images/ok-hand-icon.png';
import medalIcon from '../../public/images/medal-icon.png';
import trophyIcon from '../../public/images/trophy-icon.png';
import diamondIcon from '../../public/images/diamond-icon.png';
import starIcon from '../../public/images/shooting-star-icon.png';
import cameraIcon from '../../public/images/camera-icon.png';
// constants
import { METERS_IN_MILE } from '../constants';

export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/*
 * Password complexity rules:
 * none: form is invalid, less than 6 characters
 * weak: at least 6 characters
 * medium: at least (one lc letter AND one uc letter) OR (one lc letter AND one digit) OR (one uc letter AND one digit), at least 8 characters total
 * strong: at least (one lc letter AND one uc letter) OR (one lc letter AND one digit) OR (one uc letter AND one digit), at least 10 characters total
 * excellent: at least one lc letter AND one uc letter AND digit AND special character, at least 10 characters total
 */
export const passwordRules = {
  excellent: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*()\|\]\[\{\}_=+-])(?=.{10,})/,
  strong: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{10,})/,
  medium: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/,
  weak: /^(?=.{6,})/,
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(parseInt(bytes, 10)) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
};

export const isImageAsset = ({ media_type }) => {
  return media_type.split('/')[0] === 'image';
};

export const isVideoAsset = ({ media_type }) => {
  return ['mp4', 'mov', 'avi', 'quicktime'].includes(media_type.split('/')[1].toLowerCase());
};

export const isAudioAsset = ({ media_type }) => {
  return media_type.split('/')[0] === 'audio';
};

export const isPDFAsset = ({ media_type }) => {
  return media_type === 'application/pdf';
};

export const getUserNickname = (user) => {
  return user.nickname || user.full_name.toLowerCase().replace(/ /g, '');
};

export const getFarmNickname = (farm) => {
  return farm.name.toLowerCase().replace(/ /g, '_');
};

export const getInitials = (name) => {
  return flow(
    (s) => s.split(' '),
    (s) => ((s.length > 1) ? s.map(take) : s),
    (s) => s.join(''),
    (s) => s.slice(0, 2),
    (s) => s.toUpperCase(),
  )(name);
};

export const isProduction = (
  process.env.NODE_ENV === 'production'
);

export const getBrowserName = () => {
  if (isSafari) return 'Safari';
  if (isChrome) return 'Chrome';
  if (isFirefox) return 'Firefox';
  if (isEdge) return 'Edge';
  if (isIE) return 'IE';

  return '';
};

export const formatSignInCount = (value) => (value > 1000 ? round(value / 1000, 1) + 'K' : value);

export const isAudioRecordSupported = () => {
  return isFirefox || isChrome;
};

export function getLetterInitials(name) {
  const words = name.toUpperCase().split(' ');
  return words.length > 1
    ? words[0][0] + words[1][0]
    : name[0] + name[1];
}

export function renderMentionsText(comment) {
  const mentionRegex = /@\s?\[(.*?)\]/g;
  const partitions = comment.split(mentionRegex);

  return partitions.map((part, index) => {
    const uniqueKey = index + (Math.random() * Math.random());
    const userRegex = /^@(.+)\(([0-9]+)\)$/g;
    if (part.match(userRegex)) {
      // the part is - '@user(1)'
      const indStart = part.indexOf('@');
      const indEnd = part.indexOf('(');
      const nickName = part.substring(indStart + 1, indEnd);
      return <span key={uniqueKey} className="mention">@{nickName.trim()}</span>;
    }
    return (<span key={uniqueKey}>{part}</span>);
  });
}

export function disableDoubleTapZoom() {
  let lastTouch = 0;

  function resetPreventZoom() {
    lastTouch = 0;
  }

  function preventZoom(e) {
    const t2 = e.timeStamp;
    const t1 = lastTouch || t2;
    const dt = t2 - t1;
    const fingers = e.touches.length;
    lastTouch = t2;

    if (!dt || dt >= 300 || fingers > 1) {
      return;
    }
    resetPreventZoom();
    // e.preventDefault();
    if (e.target && e.target.click) {
      e.target.click();
    }
  }

  document.addEventListener('touchstart', preventZoom, { passive: true });
  document.addEventListener('touchmove', resetPreventZoom, { passive: true });
}

export function formatNumber(number) {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function prepareTreatmentsList(resources) {
  const commonGroup = [];
  const injectableGroup = [];
  const oralGroup = [];
  const topicalGroup = [];
  resources.forEach((medication) => {
    const { common, route } = medication;
    if (common) {
      commonGroup.push(medication);
      return;
    }
    if (route.includes('oral')) {
      oralGroup.push(medication);
      return;
    }
    if (route.includes('topical')) {
      topicalGroup.push(medication);
      return;
    }
    injectableGroup.push(medication);
  });
  if (commonGroup[0] && injectableGroup[0]) injectableGroup[0].divider = true;
  if (injectableGroup[0] && oralGroup[0]) oralGroup[0].divider = true;
  if (oralGroup[0] && topicalGroup[0]) topicalGroup[0].divider = true;

  return [
    ...commonGroup,
    ...injectableGroup,
    ...oralGroup,
    ...topicalGroup,
  ];
}

export function isEqualKeys(keys, obj1, obj2) {
  return !keys.some((key) => obj1[key] !== obj2[key]);
}

export function formatMortality(mortality) {
  return isNumber(mortality)
    ? `${Number(mortality).toFixed(2)}%`
    : 'N/A';
}

export function formatEstWeight(estWeight, weightItem) {
  return isNumber(estWeight)
    ? round(Number(estWeight)) + (weightItem ? ' ' + weightItem : '')
    : 'N/A';
}

export function formatDaysToWeeks(days) {
  return Number.isInteger(days) ? (days / 7).toFixed(1) : 'N/A';
}

export function formatSetpointValue(value) {
  return value ? value + 'º' : '--';
}

export function calcSetPointValue(value, toSystem = 'fahrenheit', currSystem = 'fahrenheit') {
  // imperial - Fahrenheit, metric - Celsius, f = (c * 1.8) + 32
  const formattedValue = Number.isInteger(value) ? value : round(value, 1);
  if ((currSystem === 'fahrenheit') && value) {
    return toSystem === 'fahrenheit'
      ? formattedValue
      : round(((value - 32) / 1.8), 1);
  }
  if ((currSystem === 'celsius') && value) {
    return toSystem === 'celsius'
      ? formattedValue
      : round(((value * 1.8) + 32), 1);
  }
  return null;
}

export function calcGrowthRate(current, prev) {
  if (!current && !prev) return 0;
  if (!current) return -100;
  if (!prev) return 100;
  return ((current - prev) * 100) / prev;
}

export function calcAverageData(data, key = 'value') {
  if (!data.length) return 0;
  return sumBy(data, (item) => (item[key])) / data.length;
}

export function getArrowClass(value) {
  if (value > 0) return 'fa fa-arrow-up-btm';
  if (value < 0) return 'fa fa-arrow-down';
  return 'fa fa-arrow-no-change';
}

export function getRateClass(value) {
  if (value < 0) return 'negative';
  if (value > 0) return 'positive';
  return '';
}

export function groupDataByWeek(data, type) {
  const first = data.length && moment(data[0].date).week();
  const groupedData = groupBy(data, (item) => (moment(item.date).week()));
  let keys = Object.keys(groupedData);
  if (first && keys[0] && (first !== Number(keys[0]))) {
    // needed this for right week order cause groupby sort numbers
    const index = keys.indexOf(String(first));
    const head = keys.splice(index);
    keys = [...head, ...keys];
  }
  const sumData = (dataKey) => {
    const sum = sumBy(groupedData[dataKey], (item) => (item[type]));
    return (type === 'avg_mortality_rate') ? sum / groupedData[dataKey].length : sum;
  };
  return keys.map((key) => ({
    week_index: key,
    [type]: sumData(key),
  }));
}

export function groupDataByMonth(data, type) {
  const groupedData = groupBy(data, (item) => (moment(item.date).format('YYYY MM')));
  const keys = Object.keys(groupedData);

  const sumData = (dataKey) => {
    const sum = sumBy(groupedData[dataKey], (item) => (item[type]));
    return (type === 'avg_mortality_rate') ? sum / groupedData[dataKey].length : sum;
  };
  return keys.map((key) => ({
    date: key,
    [type]: sumData(key),
  }));
}

export const renderEncourageIcon = (count, diagnoseType) => {
  if (count < 50) {
    return <img src={diagnoseType ? okIcon : cameraIcon} className="encourage-icon" alt="ok-icon" loading="lazy" />;
  }
  if (count >= 50 && count < 100) {
    return <img src={medalIcon} className="encourage-icon" alt="medal-icon" loading="lazy" />;
  }
  if (count >= 100 && count < 500) {
    return <img src={trophyIcon} className="encourage-icon" alt="trophy-icon" loading="lazy" />;
  }
  if (count >= 500 && count < 1000) {
    return <img src={diamondIcon} className="encourage-icon" alt="diamond-icon" loading="lazy" />;
  }
  if (count === 1000) return <img src={starIcon} className="encourage-icon" alt="star-icon" loading="lazy" />;

  return null;
};

export function formatFarmOptionLabel(farm) {
  return (farm.name + ` (${farm.external_link_id || 'N/A'})`);
}

export function formatGroupStatus(status) {
  switch (status) {
    case 'opened':
      return 'open';
    case 'awaiting_delivery':
      return 'pending';
    case 'scheduled_delivery':
      return 'pending';
    case 'request_close':
      return 'request close';
    case 'pending_close':
      return 'action required';
    default: return status;
  }
}

export const checkDisabledFields = (allFields, key) => (options) => {
  return options.map((option) => ({
    ...option,
    disabled: Boolean(allFields.find((field) => getValue(field, key) === option.value)),
  }));
};

export const checkVariety = (logs, value) => {
  const result = logs.filter((obj) => {
    return obj.variety ? obj.variety.includes(value) : null;
  });

  return result.length > 0;
};

export const getDeathsCount = ({ chronic_death_count, acute_death_count, euthanasia_death_count }) => {
  return (
    parseInt(chronic_death_count || 0, 10) +
    parseInt(acute_death_count || 0, 10) +
    parseInt(euthanasia_death_count || 0, 10)
  );
};

export function formatDateAt(date) {
  const time = moment(date).format('hh:mm a');
  const dateLabel = moment().diff(date, 'days')
    ? moment(date).format('MMM D, YYYY')
    : <FormattedMessage id="general.time.today" />;

  return <FormattedMessage id="general.timeAt" values={{ date: dateLabel, time }} />;
}

export function formatCheckupLogDate(date, farmUtcOffset) {
  const logTime = changeUtcOffsetTime(moment(date), farmUtcOffset);
  const currentFarmTime = currentUtcOffsetTime(farmUtcOffset);
  const dateLabel = moment(logTime).isSame(currentFarmTime, 'd')
    ? <FormattedMessage id="general.time.today" />
    :  moment(logTime).format('MMM D, YYYY');
  return <FormattedMessage id="general.timeAt" values={{ date: dateLabel, time: logTime.format('hh:mm a') }} />;
}

export function formatTableValue(value, formattedValue, placeholder = 'N/A') {
  if (value === null || value === undefined) return placeholder;
  if (!formattedValue) return value;
  return formattedValue;
}

export function isUserCanDiagnose(user, farmId, farmManagerId) {
  if (!user || !farmId || !farmManagerId) return false;

  const { roles_map: { admin, vet } } = user;
  const hasCompanyVetRole = vet.company_ids.includes(farmManagerId);
  const hasFarmVetRole = vet.farm_ids.includes(farmId);
  return admin || hasCompanyVetRole || hasFarmVetRole;
}

export function isUserCanShare(user, farmId, farmManagerId) {
  if (!user || !farmId || !farmManagerId) return false;

  const { roles_map: { admin, tenant_owner, manager, vet } } = user;
  const hasCompanyVetRole = vet.company_ids.includes(farmManagerId);
  const hasCompanyManagerRole = Boolean(manager && manager.company_ids.includes(farmManagerId));
  const hasFarmVetRole = vet.farm_ids.includes(farmId);
  const hasFarmManagerRole = Boolean(manager && manager.farm_ids.includes(farmId));
  return admin || tenant_owner || hasCompanyVetRole || hasCompanyManagerRole || hasFarmVetRole || hasFarmManagerRole;
}

export function formatWater(value, toSystem = 'imperial', currSystem = 'imperial', placeholder = 'N/A') {
  if ((!value && value !== 0) || !['imperial', 'metric'].includes(toSystem)) return placeholder;
  if (toSystem === 'imperial') {
    return currSystem === 'imperial'
      ? round(value, 1)
      : value / 3.78541178;
  }
  return currSystem === 'imperial'
    ? round(value * 3.78541178, 1)
    : round(value, 0);
}

export const renderMedicationLine = (item, formatMessage) => {
  const { gallons_value, quantity, treatment_product: { dosage_type, name, dosage_measure, route } } = item;

  if (route.includes('topical')) return name;

  let message = `${name} • ${quantity} ${formatMessage({ id: `general.short.${dosage_type}` })}`;
  if (route.includes('oral') && dosage_type !== 'doses') {
    message += `/${dosage_measure === 'gallon'
      ? `${gallons_value}`
      : ''}${formatMessage({ id: `general.short.${dosage_measure}` })}`;
  }

  return message;
};

export const getMedType = (medication) => {
  const route = medication?.route || [];
  if (route.includes('oral')) return 'water-soluble';
  if (route.includes('topical')) return 'topical';

  return 'injectable';
};

export const filterMedsByType = (medications = []) => {
  const injectable = medications.filter((obj) => !getValue(obj, 'treatment_product.route', []).includes('oral')
    && !getValue(obj, 'treatment_product.route', []).includes('topical'));
  const oral = medications.filter((obj) => getValue(obj, 'treatment_product.route', []).includes('oral'));
  const topical = medications.filter((obj) => getValue(obj, 'treatment_product.route', []).includes('topical'));

  return { injectable, oral, topical };
};

export const calculateMgPerHead = (waterUsageValue, initialPigs = 0) => (
  initialPigs ? round(waterUsageValue / initialPigs, 2) : 0
);

export const getDiffDaysWithoutTime = (dateStart, dateEnd) => {
  return moment(dateEnd).startOf('day').diff(moment(dateStart).startOf('day'), 'days');
};

export const withdrawalParams = memoize((treatment) => {
  const { withdrawal_value_us, withdrawal_period_us, withdrawal_value_export, withdrawal_period_export } = treatment;

  const usEndDate = moment().add(withdrawal_value_us, withdrawal_period_us);
  const exportEndDate = moment().add(withdrawal_value_export, withdrawal_period_export);

  if (usEndDate >= exportEndDate) return { value: withdrawal_value_us, period: withdrawal_period_us };
  return { value: withdrawal_value_export, period: withdrawal_period_export };
});

export function formatAssetLink(assetLink = '') {
  const url = assetLink.toString().toLowerCase();
  const isUrlWithProtocol = url.startsWith('http://') || url.startsWith('https://');
  return isUrlWithProtocol ? assetLink : `http://${assetLink}`;
}

export const getFullAddress = ({ address, city, countryCode, state, zipcode }) => {
  let fullAddress = '';

  if (address) fullAddress += `${address}, `;
  if (city) fullAddress += `${city}, `;
  if (state) fullAddress += `${state}, `;
  if (countryCode) fullAddress += `${countryCode}, `;
  if (zipcode) fullAddress += `${zipcode}, `;
  const trimmedAddress = fullAddress.trim();

  return trimmedAddress[trimmedAddress.length - 1] === ','
    ? trimmedAddress.substring(0, trimmedAddress.length - 1)
    : trimmedAddress;
};

export const parseURL = (url) => {
  const parser = document.createElement('a');
  parser.href = url;
  const { protocol, hostname, port, pathname, hash, search, origin } = parser;

  return { protocol, hostname, port, pathname, hash, search, origin };
};

export const formatUnviewedAssetsCount = (count) => {
  // TODO: sometimes unviewed assets count (websocket - ws) comes later then execute decreasing action
  // 1) 4 assets was created;
  // 2) 3 assets comes as unviewed from ws;
  // 3) 4 assets mark as seen (api);
  // 4) 1 asset comes after (ws).
  // 3 - 4 = -1 (seen as 0 now), then -1 + 1 = 0. It removes bug with count (-1).
  return count < 0 ? 0 : count;
};

export const showToastrMessage = (messageKey, type = 'success', iconClass = 'fa fa-thumbs-up', options = {}) => {
  if (!messageKey) return null;
  return toastr[type]('', '', {
    icon: <i className={iconClass} />,
    component: (
      <FormattedMessage id={messageKey}>
        {(text) => (<div className="rrt-text">{text}</div>)}
      </FormattedMessage>
    ),
    ...options,
  });
};

export const getMigrationLabel = (migration) => {
  if (migration.amount > 0) return <FormattedMessage id="general.placedPigs" />;
  if (migration.external_destinations.length) {
    return <FormattedMessage id="general.transferredPigsToAnExternalSource" />;
  }
  if (migration.internal_destinations.length) {
    return <FormattedMessage id="general.transferredPigsToAnInternalSource" />;
  }
  return <FormattedMessage id="general.transferredPigs" />;
};

export const formatGroupDeliveryDate = (startDate, endDate, formats = {}) => {
  const { month = 'MMM', year = 'YYYY', day = 'DD' } = formats;
  if (!startDate || !endDate) return '';
  const isSameMonth = moment(startDate).isSame(endDate, 'month');
  const isSameYear = moment(startDate).isSame(endDate, 'year');
  const formatStartDate = isSameYear
    ? `${month} ${day}`
    : `${month} ${day}, ${year}`;
  const formatEndDate = isSameYear && isSameMonth
    ? `${day}, ${year}`
    : `${month} ${day}, ${year}`;
  return moment(startDate).format(formatStartDate) + '-' + moment(endDate).format(formatEndDate);
};

export const isCheckupPigGroupsLocation = (path = '') => {
  return path.includes('daily-checkup/farms');
};

export const isCheckupFarmsLocation = (path = '') => {
  return ['/daily-checkup/', '/daily-checkup'].includes(path);
};

export const isFarmSVRsLocation = (path = '') => {
  return path.match(/svr-farms\/[0-9]*\/svrs$/);
};

export const isFakeSVRLocation = (path = '') => {
  return path.match(/svr-farms\/[0-9]*\/svrs\/fake-/);
};

export function convertSecondsToHours(seconds = 0) {
  const minutes = round(seconds / 60);
  if (minutes < 60) return minutes + 'm';
  const hours = Math.floor(minutes / 60);
  const minWithoutHours = minutes % 60;
  return `${hours}h ${minWithoutHours}m`;
}

export function formatGrams(value) {
  return value
    ? value.toLocaleString('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    : 'N/A';
}

export function formatTravelTime(meters = 0, seconds = 0) {
  if (!meters || !seconds) return '';
  const miles = Number(round(meters / METERS_IN_MILE, 1));
  const time = convertSecondsToHours(seconds);
  return time + ` (${miles} miles)`;
}

export function formatLocation(city = '', state = '') {
  if (!city) return 'N/A';
  if (!state) return city;
  return `${city}, ${state}`;
}

export function getQueryString(params) {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
}

// Metric - ml, US/Standart - cc (cubic centimeters)
export const formatDosageType = (dosage_type, measurement_system) => {
  if (measurement_system === 'metric') {
    return dosage_type === 'cc' ? 'ml' : dosage_type;
  }

  return dosage_type;
};

export function inRange(value, start, end) {
  return value >= start && value <= end;
}

export function querifyArrayParams(key, values) {
  return values.map((item) => `${key}[]=${item}`).join('&');
}

export function getCachedMentionsKey(entityType, entityId, rolesList = []) {
  const defaultString = `mentions-${entityType}-${entityId}`;
  if (!rolesList.length) {
    return defaultString;
  }
  return defaultString + `-${rolesList.join('-')}`;
}
