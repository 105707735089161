import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
import { setTableParams } from 'reducers/tableParams';
import { openPortalRight } from 'reducers/rightToLeftPortal';
// components
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
import SiteVisitReportsFarmRow from './components/SiteVisitReportsFarmRow';
import { FormattedMessage, useIntl } from 'react-intl';
import UserFilter from 'components/UserFilter/UserFilter';
import OfflineContainer from 'components/OfflineScreen/OfflineContainer';
import UserFilterMobileHeader from 'components/UserFilterMobile/UserFilterMobileHeader';
import UserFilterMobile from 'components/UserFilterMobile/UserFilterMobile';
// api
import { applySvrFarmsFilter, getSVRsFarms } from 'endpoints/svr';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import useDidMountEffect from 'hooks/useDidMountEffect';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { useMediaQuery } from 'react-responsive';
import { userFilterGroupNames, userFilterSectionTypes, allOrAnyFarmsOptions } from 'utils/constants/userFilters';
import { tableNames } from 'utils/constants';
import cn from 'classnames';
// styles
import './SiteVisitReportFarms.scss';

const SiteVisitReportFarms = ({
  isOnline,
  isSVREnabled,
  tableParams,
  setBreadcrumbs,
  setPageOptions,
  router,
  setTableParams,
  openPortalRight,
}) => {
  const { formatMessage } = useIntl();
  const sections = [
    { value: userFilterSectionTypes.farm, label: formatMessage({ id: 'general.farmName' }) },
    { value: userFilterSectionTypes.managementCompany, label: formatMessage({ id: 'general.role.managementCo' }) },
    { value: userFilterSectionTypes.latestSvrCreatedAt, label: formatMessage({ id: 'general.latestReportDate' }) },
    { value: userFilterSectionTypes.latestSvrCreatedBy, label: formatMessage({ id: 'general.latestReportAuthor' }) },
  ];
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
    onUserFilterSectionsChange,
    onUserFilterSegmentSave,
  } = useDataTable(getSVRsFarms, {
    setTableParams: (params) => setTableParams(tableNames.siteVisitReportsFarms, params),
    tableParams,
    filterFetcher: applySvrFarmsFilter,
    userFilterGroupName: userFilterGroupNames.svrsFarms,
  });

  const handleOpenMobileFilters = () => {
    openPortalRight(
      <UserFilterMobile
        allOrAnyConditionLabel={<FormattedMessage id="component.advancedSearchItem.allFarmsFilters" />}
        appliedFilter={tableParams.filters?.[0] || {}}
        filterSections={sections}
        filterGroupName={userFilterGroupNames.svrsFarms}
        setFilter={(filter) => fetchData({ ...tableParams, filters: filter ? [filter] : undefined })}
      />,
    );
  };

  const handleSetPageOptions = () => {
    setPageOptions({
      rightButton: !isDesktop && isOnline ? {
        icon: 'fa fa-filter',
        onClick: handleOpenMobileFilters,
      } : '',
    });
  };

  useEffect(() => {
    if (!isSVREnabled) {
      router.goBack();
    } else {
      fetchData({ ...tableParams, page: 1 }).catch(toastResponseErrors);
    }
  }, [isOnline]);

  useEffect(() => {
    setPageOptions({ backLink: '' });
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.siteVisitReports" />, useLabelAsMobileTitle: true },
    ]);
    handleSetPageOptions();

    return () => {
      setPageOptions({ rightButton: '' });
    };
  }, []);


  useDidMountEffect(handleSetPageOptions, [tableParams, isDesktop, isOnline]);

  const userFilter = tableParams.filters?.[0];
  const filterSectionsCount = userFilter?.sections?.length;
  const { page, per_page, search } = tableParams;
  const { total } = meta;
  const hasMobileFilterHeader = !isDesktop && filterSectionsCount > 0;

  return (
    <div className={cn('SiteVisitReportFarms row centered', { 'has-mobile-filters': hasMobileFilterHeader })}>
      <OfflineContainer>
        {!isDesktop && filterSectionsCount > 0 && (
          <UserFilterMobileHeader
            onClick={handleOpenMobileFilters}
            onClose={() => fetchData({ ...tableParams, filters: undefined })}
            sectionsCount={filterSectionsCount}
            segmentName={userFilter?.name}
          />
        )}

        {isDesktop && (
          <UserFilter
            className="filters"
            isVisible
            filterGroupName={userFilterGroupNames.svrsFarms}
            availFilterSections={sections}
            listOptions={allOrAnyFarmsOptions}
            onSectionsChange={onUserFilterSectionsChange}
            initialFilter={tableParams.filters?.[0]}
            onSave={onUserFilterSegmentSave}
          />
        )}
        <Panel className="farms-panel">
          <Panel.Heading
            className="farms-panel-title"
            title={<span className="lighter"><FormattedMessage id="general.farms" /></span>}
          >
            <SearchBox onChange={onSearchChange} initialValue={search} disableFocus />
          </Panel.Heading>
          <Panel.Body className="farms-panel-body">
            <Preloader isActive={isLoading} />

            {resources.map((farm) => (
              <SiteVisitReportsFarmRow
                key={farm.id}
                onSelect={() => router.push(`/svr-farms/${farm.id}/svrs`)}
                farm={farm}
              />
            ))}

            <NothingBox
              itemsName="farms"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
            >
              <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsToDisplay" />
            </NothingBox>

            <PaginationWrapper
              currentPage={page}
              perPage={per_page}
              onPageChange={onPageChange}
              onPerPageChange={onPerPageChange}
              totalItems={total}
              onlyBottom
            />
          </Panel.Body>
        </Panel>
      </OfflineContainer>
    </div>
  );
};

SiteVisitReportFarms.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  tableParams: T.object.isRequired,
  isSVREnabled: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  router: T.object.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.siteVisitReportsFarms),
    isSVREnabled: state.auth.user.current_company.svrs && state.auth.user.current_company.admin_svrs,
    isOnline: state.network.isOnline,
  }), {
    setPageOptions,
    setBreadcrumbs,
    setTableParams,
    openPortalRight,
  }
)(SiteVisitReportFarms);
