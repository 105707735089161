import { handleActions, createAction } from 'redux-actions';
import unionBy from 'lodash.unionby';
import pickBy from 'lodash.pickby';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
// endpoints
import {
  createActivityComment,
  deleteActivityComment,
  deleteDirectPost,
  fetchActivities as getActivities,
  fetchFilteredActivities as getFilteredActivities,
  flagActivity,
  markActivityAsSeen,
  unFlagActivity,
  updateActivityComment,
} from 'endpoints/farmfeed';
// ------------------------------------
// Constants
// ------------------------------------
const defaultParams = {
  page: 1,
  per_page: 12,
  search: '',
  filters: {},
  date_start: null,
  date_end: null,
};

const initialState = {
  resources: [],
  newActivities: {
    resources: [],
    padding: 0,
  },
  height: null,
  meta: {},
  isLoading: false,
  isLoaded: false,
  params: defaultParams,
  filter: {},
};

const FETCH_ACTIVITIES = 'farmfeed/FETCH_ACTIVITIES';
const [FETCH_ACTIVITIES_PENDING, FETCH_ACTIVITIES_FULFILLED, FETCH_ACTIVITIES_REJECTED] = [
  `${FETCH_ACTIVITIES}_PENDING`,
  `${FETCH_ACTIVITIES}_FULFILLED`,
  `${FETCH_ACTIVITIES}_REJECTED`,
];

const SET_PAGE_HEIGHT = 'farmfeed/SET_PAGE_HEIGHT';

const ADD_NEW_ACTIVITY = 'farmfeed/ADD_NEW_ACTIVITY';
const LOAD_MORE_ACTIVITIES = 'farmfeed/LOAD_MORE_ACTIVITIES';
const CLEAR_NEW_ACTIVITIES = 'farmfeed/CLEAR_NEW_ACTIVITIES';

const POST_MESSAGE = 'farmfeed/POST_MESSAGE';

const DELETE_DIRECT_POSTED_ACTIVITY = 'farmfeed/DELETE_DIRECT_POSTED_ACTIVITY';

export const SET_FLAGGED = 'farmfeed/SET_FLAGGED';
export const MARK_AS_SEEN = 'farmfeed/MARK_AS_SEEN';
export const MERGE_NEW_ACTIVITIES = 'farmfeed/MERGE_NEW_ACTIVITIES';
export const POST_COMMENT = 'farmfeed/POST_COMMENT';
export const DELETE_COMMENT = 'farmfeed/DELETE_COMMENT';
export const UPDATE_COMMENT = 'farmfeed/UPDATE_COMMENT';
export const ADD_NEW_POSTED_ACTIVITY = 'farmfeed/ADD_NEW_POSTED_ACTIVITY';
export const UPDATE_ACTIVITY = 'farmfeed/UPDATE_ACTIVITY';
export const SET_DEFAULT_PARAMS = 'farmfeed/SET_DEFAULT_PARAMS';
export const SET_ACTIVITIES_FILTER = 'farmfeed/SET_ACTIVITIES_FILTER';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchActivities = (
  params = defaultParams,
  loadMore,
) => (dispatch, getState) => {
  const { filter } = getState().farmfeed;
  const isSearchExist = params.search || params.date_end || params.date_start || !isEmpty(params.filters);
  const getActivitiesData = isSearchExist || isEmpty(filter) ? getActivities : getFilteredActivities;

  dispatch(createAction(FETCH_ACTIVITIES_PENDING)());
  return getActivitiesData(pickBy(params), filter)
    .then((response) => {
      const action = loadMore
        ? LOAD_MORE_ACTIVITIES
        : FETCH_ACTIVITIES_FULFILLED;
      dispatch(createAction(action)({ ...response, params }));
      return response;
    })
    .catch((response) => {
      dispatch(createAction(FETCH_ACTIVITIES_REJECTED)(response));
      throw response;
    });
};

export const setDefaultParams = createAction(SET_DEFAULT_PARAMS);

export const setPageHeight = createAction(SET_PAGE_HEIGHT);

export const addNewActivity = createAction(ADD_NEW_ACTIVITY);

export const setActivitiesFilter = createAction(SET_ACTIVITIES_FILTER);

export const updateActivity = (data) => createAction(UPDATE_ACTIVITY)(data);

export const mergeNewActivities = (newActivities) => (dispatch, getState) => {
  const { resources } = getState().farmfeed;
  const newResources = unionBy(newActivities, resources, 'id');
  const wereUnviewed = resources.filter((resource) => !resource.is_viewed).length;
  const becomeUnviewed = newResources.filter((resource) => !resource.is_viewed).length;
  // unViewedDiff - for sidebar actions
  return dispatch(createAction(MERGE_NEW_ACTIVITIES)({
    resources: newResources,
    unViewedDiff: becomeUnviewed - wereUnviewed,
  }));
};

export const clearNewActivities = createAction(CLEAR_NEW_ACTIVITIES);

export const addNewPostedActivity = (newActivity) => (dispatch, getState) => {
  const { resources } = getState().farmfeed;
  const newResources = [newActivity, ...resources];
  // unViewedDiff - for sidebar actions
  return dispatch(createAction(ADD_NEW_POSTED_ACTIVITY)({ resources: newResources, unViewedDiff: 1 }));
};

export const deleteDirectPostedActivity = (activityId) => (dispatch) => {
  return deleteDirectPost(activityId)
    .then(() => {
      dispatch(createAction(DELETE_DIRECT_POSTED_ACTIVITY)(activityId));
    });
};

export const postComment = (id, resource) => (dispatch) => {
  return createActivityComment(id, resource)
    .then((response) => {
      return dispatch(createAction(POST_COMMENT)(response));
    });
};

export const deleteComment = (activityId, commentId) => (dispatch) => {
  dispatch(createAction(DELETE_COMMENT)({ commentId, activityId }));
  return deleteActivityComment(activityId, commentId);
};

export const updateComment = ({
  id,
  activityId,
  comment,
  comment_mentions_attributes,
}) => (dispatch) => {
  return updateActivityComment(activityId, id, { comment, comment_mentions_attributes })
    .then((response) => {
      return dispatch(createAction(UPDATE_COMMENT)({ ...response, activityId }));
    });
};

export const setActivityFlag = (value, activityId) => (dispatch) => {
  dispatch(createAction(SET_FLAGGED)({ value, activityId })); // here we do optimistic update
  return (value ? flagActivity : unFlagActivity)(activityId)
    .catch((error) => {
      dispatch(createAction(SET_FLAGGED)({ value: !value, activityId }));
      throw error;
    }); // revert optimistic update if api error
};

// Todo: markAsSeen logic call many extra maping activities
export const markAsSeen = (id) => (dispatch) => {
  return markActivityAsSeen(id).then(() => dispatch(createAction(MARK_AS_SEEN)(id)));
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [FETCH_ACTIVITIES_PENDING]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [FETCH_ACTIVITIES_FULFILLED]: (state, action) => ({
    ...state,
    ...action.payload,
    params: {
      ...defaultParams,
      ...action.payload.params,
    },
    isLoading: false,
    isLoaded: true,
  }),
  [FETCH_ACTIVITIES_REJECTED]: (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  }),
  [UPDATE_ACTIVITY]: (state, { payload }) => {
    const activity = state.resources.find(({ id }) => id === payload.id);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        { ...payload },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },
  [LOAD_MORE_ACTIVITIES]: (state, action) => ({
    ...state,
    ...action.payload,
    resources: [
      ...state.resources,
      ...action.payload.resources,
    ],
    isLoading: false,
  }),
  [ADD_NEW_ACTIVITY]: (state, action) => {
    const activity = action.payload;
    const alreadyFetchedActivity = state.resources.find(({ id }) => id === activity.id) ||
      state.newActivities.resources.find(({ id }) => id === activity.id);

    return {
      ...state,
      newActivities: {
        ...state.newActivities,
        resources: unionBy([activity], state.newActivities.resources, 'id'),
        padding: state.newActivities.padding + !alreadyFetchedActivity,
      },
    };
  },

  [ADD_NEW_POSTED_ACTIVITY]: (state, { payload: { resources } }) => ({
    ...state,
    resources,
  }),
  [DELETE_DIRECT_POSTED_ACTIVITY]: (state, action) => {
    const activityId = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    const activityIndex = state.resources.indexOf(activity);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [MERGE_NEW_ACTIVITIES]: (state, { payload: { resources } }) => ({
    ...state,
    resources,
    newActivities: {
      ...state.newActivities,
      resources: [],
    },
    params: {
      ...state.params,
      page: 1,
    },
    meta: {
      ...state.meta,
      total: state.meta.total + state.newActivities.padding,
    },
  }),
  [CLEAR_NEW_ACTIVITIES]: (state) => ({
    ...state,
    newActivities: { ...initialState.newActivities },
  }),

  [POST_COMMENT]: (state, action) => {
    const newComment = action.payload.resource;
    const activity = state.resources.find(({ id }) => id === newComment.commentable_id);

    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        {
          ...activity,
          updated_at: newComment.updated_at,
          comments: [
            newComment,
            ...activity.comments,
          ],
        },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [DELETE_COMMENT]: (state, action) => {
    const { activityId, commentId } = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);
    const comment = activity.comments.find(({ id }) => id === commentId);
    const commentIndex = activity.comments.indexOf(comment);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        {
          ...activity,
          updated_at: moment().toISOString(),
          comments: [
            ...activity.comments.slice(0, commentIndex),
            ...activity.comments.slice(commentIndex + 1),
          ],
        },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [UPDATE_COMMENT]: (state, action) => {
    const { id: commentId, updated_at } = action.payload.resource;
    const { activityId } = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);
    const comment = activity.comments.find(({ id }) => id === commentId);
    const commentIndex = activity.comments.indexOf(comment);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        {
          ...activity,
          updated_at,
          comments: [
            ...activity.comments.slice(0, commentIndex),
            action.payload.resource,
            ...activity.comments.slice(commentIndex + 1),
          ],
        },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [POST_MESSAGE]: (state, action) => {
    const newPost = action.payload.resource;
    return {
      ...state,
      resources: [
        newPost,
        ...state.resources,
      ],
    };
  },

  [SET_FLAGGED]: (state, action) => {
    const { value, activityId } = action.payload;
    const activity = state.resources.find(({ id }) => id === activityId);
    const activityIndex = state.resources.indexOf(activity);
    const updatedActivity = {
      ...activity,
      flagged: value,
    };

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        updatedActivity,
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [MARK_AS_SEEN]: (state, action) => {
    const activity = state.resources.find(({ id }) => id === action.payload);
    if (!activity) return state;

    const activityIndex = state.resources.indexOf(activity);

    return {
      ...state,
      resources: [
        ...state.resources.slice(0, activityIndex),
        { ...activity, is_viewed: true },
        ...state.resources.slice(activityIndex + 1),
      ],
    };
  },

  [SET_PAGE_HEIGHT]: (state, action) => ({
    ...state,
    height: action.payload,
  }),

  [SET_ACTIVITIES_FILTER]: (state, action) => ({
    ...state,
    filter: action.payload,
  }),
  [SET_DEFAULT_PARAMS]: (state) => ({
    ...state,
    params: defaultParams,
  }),

  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
