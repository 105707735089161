import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatTreatmentProtocolPayload } from './helper';
// endpoints
import { createPigGroupTreatmentProtocol, getTreatmentProtocolsFarmPigGroup } from 'endpoints/treatmentProtocols';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from './components/TreatmentProtocolForm';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';

const initialTreatment = { product: '', steps: [{}], };
const defaultInitialValues = { treatments: [initialTreatment] };

const CreateGroupTreatmentProtocol = ({
  setBreadcrumbs,
  setPageOptions,
  router: { params: { farmId, groupId }, push },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState(undefined);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setGroup(await getTreatmentProtocolsFarmPigGroup(farmId, groupId));
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      push(`/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols`);
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocol-farms/${farmId}/groups/${groupId}treatment-protocols` });
  }, []);

  useEffect(() => {
    if (group) {
      setBreadcrumbs([
        {
          label: <FormattedMessage id="general.treatmentProtocolFarms" />,
          path: '/treatment-protocol-farms',
        },
        {
          label: group.name,
          path: `/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols`,
        },
        {
          label: <FormattedMessage id="general.createTreatmentProtocol" />,
          path: `/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols/create`,
          useLabelAsMobileTitle: true,
        },
      ]);
    }
  }, [group]);

  const onSubmit = async (newProtocolData) => {
    try {
      setIsLoading(true);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(newProtocolData);
      await createPigGroupTreatmentProtocol(groupId, treatmentProtocolPayload);
      push(`/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  if (!group) return <Preloader isActive />;

  return (
    <div>
      <Preloader isActive={isLoading} />
      <GroupProfileHeader group={group} />
      <TreatmentProtocolForm
        onSubmit={onSubmit}
        initialValues={defaultInitialValues}
        title={<FormattedMessage id="general.createTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.createTreatmentProtocol" />}
      />
    </div>
  );
};

CreateGroupTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(CreateGroupTreatmentProtocol);
