import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from './utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { reportPigs } from 'reducers/dailyCheckup/checkupEdit';
import { openPortalRight, closePortalRight } from 'reducers/rightToLeftPortal';
import { openModal, closeModal } from 'reducers/modals';
import {
  setDestroyedData,
  setSelectedData,
  selectItem as selectBarn,
  unselectItem as unselectBarn,
  resetData as resetBarnsData,
  setData as setBarnsList,
} from 'reducers/mobileListPicker';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import Input from 'components/Input';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
import MobilePortalSelector from 'components/MobilePortalSelector/MobilePortalSelector';
import CloseGroupModal from './modals/CloseGroupModal';
import ReportPigGroupMarketingModal from './modals/ReportPigGroupMarketingModal';
import ConditionRadioButtons from 'components/ConditionRadioButtons/ConditionRadioButtons';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import CollapseWithdrawalWarning from './components/CollapseWithdrawalWarning';
import InventoryConflictModal from './components/InventoryConflictModal';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileBarnRow from 'components/DailyCheckups/MobileBarnRow';
import WeightLabeledInput from 'components/Input/WeightLabeledInput';
// utils
import cn from 'classnames';
import update from 'react-addons-update';
import compact from 'lodash.compact';
import isEmpty from 'lodash.isempty';
import { isMobile } from 'react-device-detect';
import { animateScroll } from 'react-scroll';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import moment from 'moment';
import { sendNotify } from 'utils/airbrakeHelper';
import { getCommentByRole } from 'helpers.js';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import { getMigrationTypeLabel, isMigrationTypeNegative } from 'utils/checkupHelper';
// endpoints
import { getFarmBarns } from 'endpoints/farms';
// constants
import { comment_roles } from 'constants.js';
// styles
import './DailyCheckupReportMovements.scss';

class DailyCheckupReportMovements extends Component {

  constructor(props, { formatMessage }) {
    super(props);
    const initialState = this.getInitialValues(props.checkup);
    this.state = {
      comment: '',
      mentionsIds: [],
      migrations: [],
      multiSelectMode: false,
      barns: [],
      initialPigs: 0,
      ...initialState,
    };

    this.movementTypes = [
      { label: formatMessage({ id: 'general.shipmentReceived' }), value: 'placement', icon: 'arrow-right',
        description: <FormattedMessage id="general.addPigs" /> },
      { label: formatMessage({ id: 'general.transferOut' }), value: 'transfer', icon: 'arrow-left',
        description: <FormattedMessage id="general.removePigs" /> },
      { label: formatMessage({ id: 'general.sale' }), value: 'sale', icon: 'arrow-left',
        description: <FormattedMessage id="general.removePigs" /> },
      { label: formatMessage({ id: 'container.checkupEdit.reportMovement.fixCountByAdding' }), value: 'count_add',
        icon: 'exclamation-triangle',
        description: <FormattedMessage id="container.checkupEdit.reportMovement.correctionOnly" /> },
      { label: formatMessage({ id: 'container.checkupEdit.reportMovement.fixCountByRemoving' }), value: 'count_remove',
        icon: 'exclamation-triangle',
        description: <FormattedMessage id="container.checkupEdit.reportMovement.correctionOnly" /> },
    ];

  }

  componentDidMount() {
    const { setPageOptions, params, checkup, isEditMode, backLink, resetBarnsData } = this.props;
    const { migrations } = this.state;

    resetBarnsData();
    if (checkup.id === parseInt(params.id, 10)) {
      if (migrations.length === 1 && !migrations[0].migration_type) {
        this.handleFocusOnMobile(0)();
      }
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'pigMovements',
        inEditMode: isEditMode,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { setPageOptions, checkup, backLink, isEditMode } = this.props;
    const isCheckupUpdated =
      prevProps.checkup.id !== checkup.id ||
      prevProps.checkup.pig_migrations !== checkup.pig_migrations;

    if (isCheckupUpdated) {
      const initialState = this.getInitialValues(checkup);
      this.setState(initialState);
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'pigMovements',
        inEditMode: isEditMode,
      });
    }
  }

  componentWillUnmount() {
    const { resetBarnsData, setPageOptions } = this.props;
    resetBarnsData();
    setPageOptions({ mobileTitle: '' });
  }

  fetchFarmBarns = (farmId) => {
    const { isOnline, setBarnsList } = this.props;
    if (!isOnline) return;

    getFarmBarns(farmId)
      .then((barns) => {
        setBarnsList(barns);
        this.setState({ barns });
      });
  };

  getBarnOptions = (index) => {
    const { migrations, barns } = this.state;
    const { barn_ids } = migrations[index];
    return barn_ids
      ? barns.map((barn) => ({ ...barn, disabled: barn_ids.includes(barn.id) }))
      : barns;
  };

  getInitialPigs = ({ checkup, migrations }) => {
    return migrations.reduce(
      (result, migration) => {
        const amount = parseInt(migration.amount || 0, 10);
        return isMigrationTypeNegative(migration.migration_type) ? result + amount : result - amount;
      },
      checkup.pigs || 0
    );
  };

  getFormattedMigrations = (pig_migrations) => {
    const { currentUser: { current_company: { measurement_system } } } = this.props;

    return (pig_migrations && pig_migrations.length)
      ? pig_migrations.map((migration) => ({
        ...migration,
        estimated_weight: formatBackEndWeightToSystem(migration.estimated_weight, measurement_system),
        migration_type: migration.migration_type || (migration.amount < 0 ? 'count_remove' : 'count_add'),
        amount: Math.abs(migration.amount),
      }))
      : [{}];
  };

  getInitialValues = (checkup) => {
    const { PIG_MIGRATIONS_ROLE } = comment_roles;

    if (isEmpty(checkup)) return {};
    const { pig_migrations, pig_group } = checkup;
    if (pig_group) this.fetchFarmBarns(pig_group.farm_id);
    const migrations = this.getFormattedMigrations(pig_migrations);

    const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], PIG_MIGRATIONS_ROLE);

    return {
      multiSelectMode: migrations.length === 1 && !migrations[0].migration_type,
      migrations,
      comment: comment ||  '',
      mentionsIds: mentioned_user_ids || [],
      initialPigs: this.getInitialPigs({ checkup, migrations }),
    };
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  addMigration = () => {
    this.setState((prevState) => ({ ...prevState, migrations: [...prevState.migrations, {}] }));
  };

  onMigrationTypeChange = (index) => (option) => {
    const { migrations } = this.state;
    this.setState({
      migrations: update(migrations, {
        [index]: { $merge: { migration_type: option.value, amount: 0 } },
      }),
    });
  };

  onConditionChange = (index) => (condition) => {
    const { migrations } = this.state;
    this.setState({
      migrations: update(migrations, {
        [index]: { $merge: { condition } },
      }),
    });
  };

  addLocation = (index) => () => {
    const { migrations } = this.state;
    const { openPortalRight, setSelectedData } = this.props;

    if (isMobile) {
      const selectedIds = (migrations[index].barn_ids || []);
      const selected = this.state.barns.filter(({ id }) => selectedIds.includes(id));
      setSelectedData(selected);
      openPortalRight(
        <MobileListPicker
          title={<FormattedMessage id="general.pageTitle.addLocation" />}
          mode="add"
          idKey="id"
          rightButtonOptions={{ label: 'next' }}
          rowRenderer={this.renderMobileListRow(index)}
        />
      );
    } else {
      const barn_ids = update(migrations[index].barn_ids || [], { $push: [null] });
      this.setState({
        migrations: update(migrations, {
          [index]: { $merge: { barn_ids } },
        }),
      });
    }
  };

  handleBarnsFocusOnMobile = (index, barnIndex) => {
    const { migrations } = this.state;
    const { openPortalRight, setSelectedData } = this.props;

    if (isMobile) {
      const selectedIds = (migrations[index].barn_ids || []);
      const selected = this.state.barns.filter(({ id }) => selectedIds.includes(id));
      setSelectedData(selected);
      openPortalRight(
        <MobileListPicker
          title={<FormattedMessage id="general.pageTitle.changeLocation" />}
          mode="change"
          idKey="id"
          rightButtonOptions={{ label: 'next' }}
          rowRenderer={this.renderMobileListRow(index, barnIndex)}
        />
      );
    }
  };

  renderMobileListRow = (index, barnIndex) => (barn, mode, { isSelected, isDisabled }) => {
    const onRowClick = {
      change: () => this.onMobileBarnChange(barn, index, barnIndex),
      add: () => this.onMobileBarnAdd(barn, index),
    }[mode];
    const selectedRow = isSelected(barn);
    const disabledRow = isDisabled(barn);
    const actionBtnType = mode === 'change' ? 'button' : 'checkbox';
    return (
      <MobileBarnRow
        key={barn.id}
        barn={barn}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
    );
  };

  onMobileBarnAdd = (barn, index) => {
    const { migrations } = this.state;
    const { selectedTreatments, setDestroyedData, selectBarn, unselectBarn } = this.props;
    const selectedBarns = selectedTreatments.find(({ id }) => id === barn.id);
    const ids = migrations[index].barn_ids || [];

    if (selectedBarns) {
      unselectBarn(selectedBarns);
      const barn_ids = ids.filter((id) => id !== selectedBarns.id);
      this.setState({
        migrations: update(migrations, {
          [index]: { $merge: { barn_ids } },
        }),
      });
    } else {
      selectBarn(barn);
      const barn_ids = [...ids, barn.id];
      this.setState({
        migrations: update(migrations, {
          [index]: { $merge: { barn_ids } },
        }),
      });
    }
    setDestroyedData([]);
  };

  onMobileBarnChange = (barn, index, barnIndex) => {
    const { migrations } = this.state;
    const { setDestroyedData, closePortalRight } = this.props;

    const ids = migrations[index].barn_ids;
    const barn_ids = [...ids.slice(0, barnIndex), barn.id, ...ids.slice(barnIndex + 1)];

    this.setState({
      migrations: update(migrations, {
        [index]: { $merge: { barn_ids } },
      }),
    });
    setDestroyedData([]);
    closePortalRight();
  };

  onLocationsChange = (index, barnIndex) => (barnOption) => {
    const { migrations } = this.state;
    const barn_ids = update(migrations[index].barn_ids || [], { [barnIndex]: { $set: barnOption?.id || null } });
    this.setState({
      migrations: update(migrations, {
        [index]: { $merge: { barn_ids } },
      }),
    });
  };

  removeLocation = (index, barnIndex) => () => {
    const { migrations } = this.state;
    const barn_ids = update(migrations[index].barn_ids, { $splice: [[barnIndex, 1]] });
    this.setState({
      migrations: update(migrations, {
        [index]: { $merge: { barn_ids } },
      }),
    });
  };

  removeMigration = (index) => () => {
    const { migrations } = this.state;
    if (migrations[index].id) {
      this.setState({
        migrations: update(migrations, {
          [index]: { $merge: { _destroy: true } },
        }),
      });
    } else {
      migrations.splice(index, 1);
      this.setState({ migrations });
    }
  };

  onPigsAmountChange = (index) => (e) => {
    const { migrations } = this.state;
    const { value, validity } = e.target;
    const amount = this.formatAmount(value, index, validity.valid);

    this.setState({
      migrations: update(migrations, {
        [index]: { $merge: { amount } },
      }),
    });
  };

  onEstimatedWeightChange = (index) => (value) => {
    this.setState((prevState) => ({
      migrations: update(prevState.migrations, {
        [index]: { $merge: { estimated_weight: value || '' } },
      }),
    }));
  };

  formatAmount = (value, index, isValid) => {
    const { migrations } = this.state;
    if (!isValid) return migrations[index].amount;
    if (String(value)[0] === '0') return parseInt(value, 10);
    return (value || '');
  };

  selectInputText = (e) => {
    e.target.select();
  };

  isContinueDisabled = (migrations) => {
    const { initialPigs } = this.state;
    const pigsAmount = migrations.reduce(
      (result, migration) => {
        const amount = parseInt(migration.amount || 0, 10);
        if (migration._destroy) return result;
        return isMigrationTypeNegative(migration.migration_type) ? result - amount : result + amount;
      },
      initialPigs || 0
    );
    if (pigsAmount < 0) {
      return true;
    }
    return migrations
      .filter(({ _destroy }) => !_destroy)
      .some(({ migration_type, amount }) => !(migration_type && amount));
  };

  optionRenderer = ({ icon, label, description }) => (
    <div className="ReactSelect--option">
      <i className={`fa fa-${icon} mr-10`} />
      <span className="mr-5">{label}</span>
      <span className="description">({description})</span>
    </div>
  );

  valueRenderer = ({ label, description }) => (
    <div>{label} ({description})</div>
  );

  handleFocusOnMobile = (index) => () => {
    if (isMobile) {
      const { openPortalRight, closePortalRight } = this.props;
      const { migrations, multiSelectMode } = this.state;
      const currentValue = this.state.migrations[index].migration_type;

      if (!migrations[0].migration_type) {
        this.setState({ multiSelectMode: true });
      }

      openPortalRight(
        <MobilePortalSelector
          title={<FormattedMessage id="container.dailyCheckup.reportMovements.selectMovementType" />}
          multiSelectMode={multiSelectMode}
          onMultiSelectionClose={this.onMultiSelectionClose}
          options={this.movementTypes}
          currentValue={currentValue}
          setValue={this.onMigrationTypeChange(index)}
          closePortal={closePortalRight}
        />
      );
    }
  };

  onMultiSelectionClose = (selectedObjects) => {
    this.setState({
      migrations: selectedObjects.map((option) => ({ migration_type: option.value, amount: 0 })),
      multiSelectMode: false,
    });
  };

  afterSubmitMigrationsModals = ({ pig_group, pigs, pig_migrations }, onCloseModal) => {
    const modals = [];

    if (!pig_group.marketing_reported_at && pig_migrations.some((pm) => pm.migration_type === 'sale')) {
      modals.push(
        <ReportPigGroupMarketingModal pigGroup={pig_group} closeModal={onCloseModal} />
      );
    }

    if (pigs === 0) {
      modals.push(
        <CloseGroupModal pigGroup={pig_group} closeModal={onCloseModal} />
      );
    }

    return modals;
  };

  afterSubmitMigrations = (resource) => {
    const { openModal, closeModal } = this.props;
    let modals = [];

    const openQueueModal = () => {
      const component = modals.shift();
      if (component) {
        openModal(
          component,
          {
            position: isMobile ? 'bottom' : 'center',
            backdropStyles: { opacity: 0.9 },
            onClose: () => { setTimeout(openQueueModal, 500); },
          }
        );
      }
    };

    const closeQueueModal = () => {
      closeModal();
      setTimeout(openQueueModal, 500);
    };

    modals = this.afterSubmitMigrationsModals(resource, closeQueueModal);

    openQueueModal();
  };

  submitData = () => {
    const { router } = this.context;
    const { checkup, reportPigs, validateCheckup, backLink, makeCheckupCorrection, isEditMode, openModal,
      currentUser, isOnline } = this.props;
    const { current_company: { measurement_system } } = currentUser;
    const { migrations, comment, mentionsIds, initialPigs } = this.state;
    if (!validateCheckup(checkup)) return;
    const pigsAmount = migrations.reduce(
      (result, migration) => {
        const amount = parseInt(migration.amount || 0, 10);
        if (migration._destroy) return result;
        return isMigrationTypeNegative(migration.migration_type) ? result - amount : result + amount;
      },
      initialPigs || 0
    );

    if (!isEditMode && !isEmpty(checkup) && (pigsAmount < checkup.total_pigs_treated)) {
      openModal(
        <InventoryConflictModal handleConfirm={() => router.push(`/daily-checkup/${checkup.id}/report-treatments`)} />
      );
      return;
    }

    const pigMigrations = migrations.map((migration) => ({
      ...migration,
      estimated_weight: formatWeightToBackEndFormat(migration.estimated_weight, measurement_system),
      amount: isMigrationTypeNegative(migration.migration_type) ? -migration.amount : +migration.amount,
      barn_ids: migration.barn_ids ? compact(migration.barn_ids) : [],
    }));

    const data = {
      comments_pig_migrations: {
        text: comment,
        mentioned_user_ids: mentionsIds
      },
      pig_migrations: pigMigrations,
    };

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, data)
      : reportPigs(checkup.id, { resource: data });

    submitRequest
      .then((resource) => {
        router.push(backLink);
        if (resource && isOnline) this.afterSubmitMigrations(resource);
      })
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };


  renderWarningTitle = () => {
    const { checkup } = this.props;
    const warnings = checkup.withdrawal_warnings || [];

    const latest = warnings.reduce((m, v, i) => ((v.withdrawn_on > m.withdrawn_on) && i ? v : m));

    return (
      <div className={cn('warning-title')}>
        <i className={cn('fa fa-warning', 'warning-icon')} />
        <FormattedMessage
          id="container.checkupEdit.reportMovement.withdrawalWarnText"
          tagName="div"
          values={{
            attentionText: (
              <FormattedMessage id="container.checkupEdit.reportMovement.withdrawalWarn">
                {(text) => <span className={cn('bold')}>{text}</span>}
              </FormattedMessage>
            ),
            date: <b>{moment(latest.withdrawn_on).format('MMM DD, YYYY')}</b>,
          }}
        />
      </div>
    );
  };

  render() {
    const { isReportingMovements, isOnline, checkup, backLink, isEditMode,
      currentUser: { current_company } } = this.props;
    const loadsCount = checkup.up_to_date_commitments_count || 0;
    const { migrations, comment, initialPigs, barns, mentionsIds } = this.state;

    const showedMigrations = migrations.filter(({ _destroy }) => !_destroy);

    const hideTrash = (showedMigrations.length === 1)
      && (!showedMigrations[0].migration_type || !showedMigrations[0].amount);

    const pigsAmount = migrations.reduce(
      (result, migration) => {
        const amount = parseInt(migration.amount || 0, 10);
        if (migration._destroy) return result;
        return isMigrationTypeNegative(migration.migration_type) ? result - amount : result + amount;
      },
      initialPigs || 0
    );

    const isContinueDisabled = this.isContinueDisabled(migrations, checkup);
    const warnings = (checkup.withdrawal_warnings || []).sort((curr, next) => (
      Number(moment(curr.withdrawn_on) < moment(next.withdrawn_on))
    ));

    let migrationCounter = 1;

    return (
      <CenterBox isLoading={isReportingMovements} renderIf={Boolean(checkup)} className="DailyCheckupReportMovements">
        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={pigsAmount} />
        </StickyHeader>

        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title">
              <FormattedMessage id="general.pigMovements" />
            </h1>
          </div>
          <Link to={backLink}>
            <i className="fa fa-times close-center-box" />
          </Link>
        </section>

        <PigsUnderCareLine mobile pigsCount={pigsAmount} className="show-for-large" />

        <section className="center-box-body">
          {!isEditMode && !isEmpty(checkup) && (pigsAmount < checkup.total_pigs_treated) && (
            <MessageBox type="alert" isBorderHidden>
              <span className="head-treated-warning">
                <FormattedMessage id="component.alertBox.warning" tagName="strong" />&nbsp;
                <FormattedMessage id="container.checkupEdit.wrongNumberHeadTreated" />
              </span>
            </MessageBox>
          )}
          {isEditMode && (
            <MessageBox type="warning">
              <strong>
                <FormattedMessage id="component.alertBox.warning" />&nbsp;
              </strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}
          {isOnline && !isEditMode && !!loadsCount && (
            <MessageBox type="warning">
              <FormattedMessage
                id="component.checkupSegment.pendingLoadsCount"
                values={{
                  count: loadsCount,
                  a: (msg) => <Link className="schedule-link" to={`/daily-checkup/${checkup.id}/schedule`}>{msg}</Link>,
                }}
              />
            </MessageBox>
          )}

          {!!warnings.length && current_company.withdrawal_tracking_on && (
            <CollapsesBox className="mb-20">
              <CollapseWithdrawalWarning data={warnings} title={this.renderWarningTitle()} />
            </CollapsesBox>
          )}

          <div className="migrations-form">
            {migrations
              .map((migration, index) => !migration._destroy && (
                <div className="migration-block" key={`migration-${index}`}>
                  <div className="migration-input-row">
                    <div className="index-cell">
                      <div className="row-index">{migrationCounter++}</div>
                    </div>
                    <ReactSelect
                      backspaceRemoves={false}
                      className="movement-type-select"
                      deleteRemoves={false}
                      placeholder={<FormattedMessage id="container.dailyCheckup.reportMovements.selectMovementType" />}
                      options={this.movementTypes}
                      value={migration.migration_type}
                      onChange={this.onMigrationTypeChange(index)}
                      optionRenderer={this.optionRenderer}
                      valueRenderer={this.valueRenderer}
                      onFocus={this.handleFocusOnMobile(index)}
                      openOnClick={!isMobile}
                      disabled={!!migration.loading_commitment_id}
                      searchable
                      noInputMargin
                    />
                    {!(hideTrash || !!migration.loading_commitment_id) && (
                      <div className="trash-cell">
                        <i className="fa fa-trash-o trash-icon" onClick={this.removeMigration(index)} />
                      </div>
                    )}
                  </div>
                  {!!migration.migration_type && (
                    <div className="migration-inputs">
                      {/* PIGS AMOUNT */}
                      <div className="migration-input-row">
                        <label className="input-wrapper normalized">
                          <span className="input-label">{getMigrationTypeLabel(migration.migration_type)}</span>
                          <Input
                            type="number"
                            min="0"
                            max="99999"
                            placeholder="0"
                            step="1"
                            pattern="[0-9]*"
                            value={migration.amount || ''}
                            onChange={this.onPigsAmountChange(index)}
                            className="migration-input"
                          />
                          {!hideTrash && <div className="trash-cell" />}
                        </label>
                      </div>

                      {migration.migration_type === 'placement' && (
                        <div>
                          {/* ESTIMATED WEIGHT */}
                          <div className="migration-input-row">
                            <label className="input-wrapper normalized">
                              <FormattedMessage id="general.estAvgWeight">
                                {(text) => <span className="input-label">{text}</span>}
                              </FormattedMessage>
                              <WeightLabeledInput
                                value={migration.estimated_weight || ''}
                                onChange={this.onEstimatedWeightChange(index)}
                                onFocus={this.selectInputText}
                                className="migration-input"
                              />
                              {!hideTrash && <div className="trash-cell" />}
                            </label>
                          </div>

                          {/* CONDITION */}
                          <div className="migration-input-row">
                            <label className="input-wrapper">
                              <FormattedMessage id="general.condition">
                                {(text) => <span className="input-label">{text}</span>}
                              </FormattedMessage>
                              <ConditionRadioButtons
                                value={migration.condition}
                                onChange={this.onConditionChange(index)}
                                className="migration-input wide"
                              />
                              {!hideTrash && <div className="trash-cell" />}
                            </label>
                          </div>

                          {/* LOCATION / BARNS */}
                          {barns && !!barns.length && (
                            <div>
                              {(migration.barn_ids || []).map((barnId, barnIndex) => (
                                <div className="migration-input-row" key={`${barnId}-${barnIndex}`}>
                                  <label className="input-wrapper">
                                    <span className={cn('input-label', { 'show-for-large': barnIndex !== 0 })}>
                                      {barnIndex === 0 && (
                                        <FormattedMessage id="general.locationOnFarm" />
                                      )}
                                    </span>
                                    <div className="location-row">
                                      <ReactSelect
                                        key={barnId}
                                        options={this.getBarnOptions(index)}
                                        value={barnId}
                                        onChange={this.onLocationsChange(index, barnIndex)}
                                        onFocus={() => this.handleBarnsFocusOnMobile(index, barnIndex)}
                                        openOnClick={!isMobile}
                                        labelKey="name"
                                        valueKey="id"
                                        className="migration-input wide"
                                        placeholder={(
                                          <FormattedMessage
                                            id="container.dailyCheckup.reportMovements.selectLocation"
                                          />
                                        )}
                                        searchable
                                        noInputMargin
                                      />
                                      {migration.barn_ids.length > 0 && (
                                        <div className="trash-cell" onClick={this.removeLocation(index, barnIndex)}>
                                          <i className="fa fa-trash-o trash-icon" />
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>
                              ))}
                              {(migration.barn_ids?.length || 0) < barns.length && (
                                <div className="add-location" onClick={this.addLocation(index)}>
                                  <FormattedMessage id="general.addLocationWithPlus" />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
          </div>

          <button className="add-migration-button" onClick={this.addMigration}>
            <FormattedMessage id="container.checkupEdit.reportMovement.add" />
          </button>
          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={this.onCommentOpen}
            onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
            onMentionsChange={(mentions) => {
              this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
            }}
            value={comment}
            isOpen={!!comment}
            placeholder=""
          />
        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={backLink}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingMovements}
              className="submit wider"
              onClick={this.submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            stickyFooter
            primary
            disabled={isContinueDisabled || isReportingMovements}
            onClick={this.submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupReportMovements.contextTypes = {
  router: T.object.isRequired,
  formatMessage: T.func.isRequired,
};

DailyCheckupReportMovements.propTypes = {
  checkup: T.object,
  currentUser: T.object,
  params: T.object,
  isReportingMovements: T.bool.isRequired,
  setPageOptions: T.func.isRequired,
  reportPigs: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  closePortalRight: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  makeCheckupCorrection: T.func.isRequired,
  isOnline: T.bool.isRequired,
  backLink: T.string,
  selectedTreatments: T.array,
  setDestroyedData: T.func,
  selectBarn: T.func,
  unselectBarn: T.func,
  resetBarnsData: T.func,
  setBarnsList: T.func,
  setSelectedData: T.func,
  isEditMode: T.bool,
};

const enhance = compose(
  connect((state, props) => ({
    currentUser: state.auth.user,
    isOnline: state.network.isOnline,
    treatmentsList: state.mobileListPicker.dataList.resources,
    selectedTreatments: state.mobileListPicker.selected,
    destroyedTreatments: state.mobileListPicker.destroyed,
    isReportingMovements: props.isLoading || state.dailyCheckup.checkupEdit.isReportingMovements,
  }), {
    reportPigs,
    setPageOptions,
    openPortalRight,
    closePortalRight,
    openModal,
    closeModal,
    setDestroyedData,
    setSelectedData,
    selectBarn,
    unselectBarn,
    resetBarnsData,
    setBarnsList,
  }),
  withCorrection,
);

export default enhance(DailyCheckupReportMovements);
