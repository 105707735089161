import isEmpty from 'lodash.isempty';

export const isUserHasProductionRole = (user) => {
  if (!user || isEmpty(user)) return false;
  const { admin, super_admin, tenant_owner, owner = {}, caretaker = {}, manager = {}, vet = {} } = user.roles_map || {};
  const hasAdminRole = admin || super_admin || tenant_owner;
  const hasOwnerRole = !!(owner.company_ids || []).length || !!(owner.farm_ids || []).length;
  const hasCaretakerRole = !!(caretaker.company_ids || []).length || !!(caretaker.farm_ids || []).length;
  const hasManagerRole = !!(manager.company_ids || []).length || !!(manager.farm_ids || []).length;
  const hasVetRole = !!(vet.company_ids || []).length || !!(vet.farm_ids || []).length;
  return hasAdminRole || hasOwnerRole || hasCaretakerRole || hasManagerRole || hasVetRole;
};

export const isCompletedProdUser = (user) => {
  return user && user.registration_state === 'complete';
};

export const isCompletedTruckingUser = (user) => {
  const isRegistrationComplete = user.registration_state === 'complete';
  return isRegistrationComplete && user.has_trucking_roles && !isUserHasProductionRole(user);
};
