import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCheckup, updateAlert } from 'reducers/dailyCheckup/checkupEdit';
import { closeModal } from 'reducers/modals';
// components
import Modal from 'components/Modal/Modal';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// style
import drBotImage from './dr-bot.svg';
import './VetBotPhotoUpload.scss';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getAuthData } from 'utils/authData';
import Resumable from 'resumablejs';
import { toastr } from 'react-redux-toastr';

class VetBotPhotoUpload extends Component {

  componentDidMount() {
    const { checkup: { id } } = this.props;
    // copy-paste from components/MediaUploader/MediaUploader.js initializeFileUploader
    const uploadPath = `/api/daily_checkups/${id}/chunk`;
    this.R = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 3,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      maxFileSizeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          component: (
            <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
              {(text) => (<div className="rrt-text">{text}</div>)}
            </FormattedMessage>
          ),
        });
      },
    });
  }

  onPhotoSelected = (e) => {
    const { files } = e.target;
    const { closeModal, fetchCheckup, checkup, updateAlert, alert } = this.props;
    if (files.length) {
      this.R.addFile(files[0], e);
      this.R.on('fileAdded', () => {
        this.R.upload();
      });
      this.R.on('progress', () => {
        const width = this.R.progress() * 100;
        document.querySelector('.vet-bot-progress-bar').style.width = `${width}%`;
      });
      this.R.on('complete', () => {
        updateAlert(checkup.id, alert.id, { status: 'uploaded' })
          .then(() => {
            closeModal();
            fetchCheckup(checkup.id);
          })
          .catch(toastResponseErrors);
      });
    }
  };

  remindMeLater = () => {
    const { updateAlert, checkup, alert, closeModal } = this.props;
    updateAlert(checkup.id, alert.id, { status: 'remind_later' })
      .then(() => closeModal())
      .catch(toastResponseErrors);
  };

  render() {
    return (
      <Modal drBot className="VetBotPhotoUpload" title="Vet Bot">
        <section className="modal-body">
          <div className="vet-bot-wrapper">
            <div className="vet-bot-image">
              <img src={drBotImage} alt="Vet Bot" />
              <FormattedMessage id="general.drBot">
                {(text) => <span className="vet-bot-label">{text}</span>}
              </FormattedMessage>
            </div>
            <div className="text">
              <FormattedMessage id="component.modal.vetBot.photoUpload.text" />
            </div>
          </div>
          <Button transparent onClick={this.remindMeLater}>
            <FormattedMessage id="component.modal.vetBot.notNow" />
          </Button>
        </section>
        <section className="modal-footer flex sticky-on-mobile">
          <form
            action="/"
            method="post"
            encType="multipart/form-data"
          >
            <div className="vet-bot-progress-bar" />
            <label htmlFor="fileInput">
              <i className="fa fa-camera-fa mr-10" />
              <FormattedMessage id="component.modal.vetBot.launchCamera" />
            </label>
            <input type="file" id="fileInput" accept="image/*" onChange={this.onPhotoSelected} />
          </form>
        </section>
      </Modal>
    );
  }
}

VetBotPhotoUpload.contextTypes = {
  router: T.object.isRequired,
};

VetBotPhotoUpload.propTypes = {
  checkup: T.object.isRequired,
  alert: T.object.isRequired,
  updateAlert: T.func.isRequired,
  fetchCheckup: T.func.isRequired,
  closeModal: T.func.isRequired,
};

export default connect(null, {
  updateAlert,
  closeModal,
  fetchCheckup,
})(VetBotPhotoUpload);
