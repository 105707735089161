import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { FormattedMessage } from 'react-intl';
// endpoints
import { getTreatmentProtocolsFarmPigGroups, getTreatmentProtocolsFarm } from 'endpoints/treatmentProtocols';
// components
import TreatmentProtocolsGroupRow from './components/TreatmentProtocolsGroupRow';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import StickyHeader from 'components/StickyHeader';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import Preloader from 'components/Preloader';
import NothingBox from 'components/NothingBox';
// utils
import classnames from 'classnames/bind';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './TreatmentProtocols.module.scss';

const cn = classnames.bind(styles);

const TreatmentProtocolsGroups = ({ setPageOptions, setBreadcrumbs, params: { farmId }, router }) => {
  const [farm, setFarm] = useState({ data: {}, isLoading: false });
  const [pigGroups, setPigGroups] = useState({ data: [], isLoading: false });

  const fetchData = async () => {
    try {
      setFarm((prev) => ({ ...prev, isLoading: true }));
      setPigGroups((prev) => ({ ...prev, isLoading: true }));
      const farm = await getTreatmentProtocolsFarm(farmId);
      const pigGroups = await getTreatmentProtocolsFarmPigGroups(farmId);
      setFarm({ data: farm, isLoading: false });
      setPigGroups({ data: pigGroups, isLoading: false });
    } catch (error) {
      toastResponseErrors(error);
      router.push('/treatment-protocol-farms');
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: '/treatment-protocol-farms' });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocol-farms',
      },
      { label: farm.data.name, path: `/treatment-protocol-farms/${farmId}`, useLabelAsMobileTitle: true },
    ]);
  }, [farm]);

  const onGroupSelect = ({ farm_id, id }) => {
    return router.push(`/treatment-protocol-farms/${farm_id}/groups/${id}/treatment-protocols`);
  };

  const { open_pig_groups_count, pigs_count, federal_premise_id } = farm.data;
  const { data: groups } = pigGroups;

  return (
    <div className={cn('treatment-protocols')}>
      <FarmProfileHeader
        className="show-for-large"
        farm={farm.data}
        isLoading={farm.isLoading}
        actualFarmId={Number(farmId)}
        farmInfo={(
          <div className={cn('farm-details')}>
            <span>ID&nbsp;&bull;&nbsp;{federal_premise_id || 'N/A'}</span>
          </div>
        )}
        stats={[
          {
            label: <FormattedMessage id="general.openGroups" />,
            value: `${open_pig_groups_count}/${groups.length}`,
          },
          {
            label: <FormattedMessage id="general.puc" />,
            value: pigs_count || 0,
          },
        ]}
      />

      <StickyHeader className="hide-for-large">
        <PigsUnderCareLine mobile pigsCount={pigs_count} />
      </StickyHeader>

      <div className="row centered">
        <div className="small-12 column">
          <section className="panel-wrapper">
            <div className="panel">
              <div className="panel-heading">
                <h1 className="lighter"><FormattedMessage id="general.pageTitle.groups" /></h1>
              </div>
              <div className={cn('panel-body')}>
                <Preloader isActive={pigGroups.isLoading} />
                {groups.map((group) => (
                  <TreatmentProtocolsGroupRow
                    key={group.id}
                    group={group}
                    onSelect={() => onGroupSelect(group)}
                    farm={farm}
                  />
                ))}
                <NothingBox itemsName="groups" display={!groups.length} isLoading={pigGroups.isLoading}>
                  <FormattedMessage tagName="h1" id="component.nothingBox.noGroupsToDisplay" />
                </NothingBox>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

TreatmentProtocolsGroups.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(TreatmentProtocolsGroups);
