import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './Menu.module.scss';

const cn = classnames.bind(styles);

const CheckboxMenu = ({
  isOpened,
  currentValue,
  children,
  options,
  onConditionChange,
  onApply,
}) => (
  <div className={cn('menu', { 'opened': isOpened })}>
    <div onClick={(e) => e.stopPropagation()}>
      {options.map(({ value, label }, index) => (
        <Fragment key={index}>
          {value && (
            <CircleCheckbox
              className={cn('checkbox-item')}
              type="radio"
              name={value}
              checked={value === currentValue}
              onChange={onConditionChange}
              label={<span className={cn('checkbox-label', { 'checked': value === currentValue })}>{label}</span>}
            />
          )}
          {children(value, label, index)}
        </Fragment>
      ))}
    </div>
    <button onClick={onApply} className={cn('done-button')}>
      <FormattedMessage id="general.button.done" />
    </button>
  </div>
);

CheckboxMenu.propTypes = {
  isOpened: T.bool.isRequired,
  currentValue: T.string,
  options: T.array.isRequired,
  onConditionChange: T.func.isRequired,
  onApply: T.func.isRequired,
  children: T.func.isRequired,
};

export default CheckboxMenu;
