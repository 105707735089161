import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import InfoRow from 'components/InfoRow/InfoRow';
import Link from 'react-router/lib/Link';
// utils
import cn from 'classnames';
// styles
import styles from './TreatmentStepCard.module.scss';

// TODO: check, is it correct
const renderTreatmentRoutes = (routes) => routes.map((route, index) => (
  <Fragment key={index}>
    <FormattedMessage id={`general.meds.${route}`} />
    {index < routes.length - 1 && ', '}
  </Fragment>
));

const limitText = (text, limit) => (text.length > limit ? text.substring(0, limit) + '...' : text);

const columns = [
  {
    label: <FormattedMessage id="component.dataTable.headers.productName" />,
    // eslint-disable-next-line react/prop-types
    renderer: ({ treatment_product }) => (
      <div>
        <span className="semibold">{treatment_product.name}</span>
        <div className="pt-20">
          <div className="semibold"><FormattedMessage id="general.route" /></div>
          {treatment_product.route?.length ?
            <div>{renderTreatmentRoutes(treatment_product.route)}</div> :
            <FormattedMessage id="general.notAvail" />
          }
        </div>
      </div>
    ),
    flex: '1 1',
  },
  {
    label: <FormattedMessage id="general.information" />,
    // eslint-disable-next-line react/prop-types
    renderer: ({ treatment_product, treatment_protocol, comment, dosage_comment }) => (
      <Fragment>
        <div className="semibold"><FormattedMessage id="general.medicationInformation" /></div>
        {/* MEDICATION NOTES */}
        {treatment_product.notes ? (
          <Fragment>
            <span>{limitText(treatment_product.notes, 128)}</span>
            <div>
              <Link
                target="_blank"
                className="link"
                to={`/admin/health-variables/medications/${treatment_product.id}/information`}
              >
                <FormattedMessage id="general.readMore" />
              </Link>
            </div>
          </Fragment>
        ) : <FormattedMessage id="general.notAvail" />}
        {/* PRODUCT NOTES */}
        <div className="semibold mt-15"><FormattedMessage id="general.productNotes" /></div>
        {comment ?
          <span>{comment}</span> :
          <FormattedMessage id="general.notAvail" />
        }
        {/* TREATMENT PRODUCT DOSAGE NOTES */}
        <div className="semibold mt-15"><FormattedMessage id="general.dosageNotes" /></div>
        {dosage_comment ?
          <span>{dosage_comment}</span> :
          <FormattedMessage id="general.notAvail" />
        }
        {/* TREATMENT PROTOCOL NOTES */}
        <div className="semibold mt-15"><FormattedMessage id="general.treatmentProtocolNotes" /></div>
        {treatment_protocol.comment ?
          <span>{treatment_protocol.comment}</span> :
          <FormattedMessage id="general.notAvail" />
        }
      </Fragment>
    ),
    flex: '2 1',
  },
  {
    label: <FormattedMessage id="general.withdrawals" />,
    // eslint-disable-next-line react/prop-types
    renderer: ({ treatment_product }) => (
      <div>
        <InfoRow
          label={<span className="semibold"><FormattedMessage id="general.usWithdrawal" /></span>}
          value={treatment_product.withdrawal_value_us ?
            <FormattedMessage id="general.daysCount" values={{ count: treatment_product.withdrawal_value_us }} /> :
            <FormattedMessage id="general.notAvail" />
          }
        />
        <InfoRow
          label={<span className="semibold"><FormattedMessage id="general.exportWithdrawal" /></span>}
          value={treatment_product.withdrawal_value_export ?
            <FormattedMessage id="general.daysCount" values={{ count: treatment_product.withdrawal_value_export }} /> :
            <FormattedMessage id="general.notAvail" />
          }
        />
      </div>
    ),
    flex: '1 1',
  },
];

const renderHeaderRow = (columnData, index) => (
  <div key={index} style={{ flex: columnData.flex }} className={styles.cell}>
    {columnData.label}
  </div>
);

const renderRow = (data) => (columnData, index) => (
  <div key={index} style={{ flex: columnData.flex }} className={styles.cell}>
    {columnData.renderer(data)}
  </div>
);

const TreatmentProductInfo = ({ treatmentProtocolProduct }) => (
  <div className={styles['treatment-product-info']}>
    <div className={cn(styles.row, styles.header)}>
      {columns.map(renderHeaderRow)}
    </div>
    <div className={styles.body}>
      <div className={styles.row}>
        {columns.map(renderRow(treatmentProtocolProduct))}
      </div>
    </div>
  </div>
);

TreatmentProductInfo.propTypes = {
  treatmentProtocolProduct: T.object.isRequired,
};

export default TreatmentProductInfo;
