import React from 'react';
import T from 'prop-types';
// components
import SurveyBox from './components/SurveyBox';
import Panel from 'components/Panel';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import ToggleSwitcher from 'components/ToggleSwitcher';
import Input from 'components/Input';
import Button from 'components/Button';

const normalizeValue = (value) => {
  if (!value) {
    return 0;
  }
  if (Number(value) > 99) {
    return 99;
  }
  return value.length > 1 ? value.replace(/^0+/, '') : value;
};

const DailyCheckup = ({
  tenant_settings: {
    surveys,
    custom_survey,
    track_temperature,
    track_water_usage,
    water_consumption_monitoring,
  },
  updateSettings,
  setSettings,
  waterPercents,
  updateWaterPercent,
}) => (
  <Panel>
    <Panel.Heading title={<FormattedMessage id="general.dailyCheckup" />} />
    <Panel.Body>
      <section className="setting-section">
        <ToggleSwitcher
          name="track_water_usage"
          className="small-12 medium-12"
          text={<FormattedMessage id="component.settings.waterUsageTracking" />}
          description={
            <FormattedMessage id="component.settings.waterUsageTrackingDesc" />
          }
          checked={track_water_usage}
          onChange={({ target: { name, checked } }) => updateSettings({ [name]: checked })
          }
        >
          {track_water_usage && (
          <ToggleSwitcher
            name="water_consumption_monitoring"
            className="small-12 medium-12"
            text={<FormattedMessage id="component.settings.waterConsumptionMonitoring" />}
            description={<FormattedMessage id="component.settings.waterConsumptionMonitoringDesc" />}
            checked={water_consumption_monitoring}
            onChange={({ target: { name, checked } }) => updateSettings({ [name]: checked })
            }
          >
            {water_consumption_monitoring && (
            <div className="generate-alert">
              <div className="content-container">
                <span className="description">
                  <FormattedMessage id="component.settings.waterConsumptionMonitoring.alertBoxDesc" />
                </span>
                <div className="percent-box">
                  <Input
                    type="number"
                    value={normalizeValue(String(waterPercents))}
                    onChange={({ target: { value } }) => updateWaterPercent(Number(value))}
                  />
                  <span>%</span>
                </div>
              </div>
              <Button primary onClick={() => updateSettings({ water_consumption_drop_limit: waterPercents })}>
                <FormattedMessage id="general.button.save" />
              </Button>
            </div>
            )}
          </ToggleSwitcher>
          )}
        </ToggleSwitcher>
      </section>

      <section className="setting-section">
        <ToggleSwitcher
          name="track_temperature"
          className="small-12 medium-8"
          text={
            <FormattedMessage id="component.settings.temperatureTracking" />
          }
          description={
            <FormattedMessage id="component.settings.temperatureTrackingDesc" />
          }
          checked={track_temperature}
          onChange={({ target: { name, checked } }) => updateSettings({ [name]: checked })
          }
        >
          <Link
            to="/admin/health-variables/setpoints"
            className="mt-15 mr-15 button light wider"
          >
            <FormattedMessage id="general.manageSetPoints" />
          </Link>
        </ToggleSwitcher>
      </section>

      <section className="setting-section">
        <ToggleSwitcher
          name="custom_survey"
          className="small-12 medium-8"
          text={<FormattedMessage id="component.settings.customSurvey.title" />}
          description={
            <FormattedMessage id="component.settings.customSurvey.desc" />
          }
          checked={custom_survey}
          onChange={({ target: { name, checked } }) => updateSettings({ [name]: checked })
          }
        >
          {custom_survey && (
            <SurveyBox surveys={surveys} setSettings={setSettings} />
          )}
        </ToggleSwitcher>
      </section>
    </Panel.Body>
  </Panel>
);

DailyCheckup.propTypes = {
  updateSettings: T.func.isRequired,
  tenant_settings: T.object.isRequired,
  setSettings: T.func.isRequired,
  waterPercents: T.number.isRequired,
  updateWaterPercent: T.func.isRequired,
};

export default DailyCheckup;
