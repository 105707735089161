import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import FormattedWeight from 'components/FormattedWeight';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getPigGroupMigrations } from 'endpoints/pigGroups';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
import { isTablet } from 'react-device-detect';
import { capitalize } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { tableNames } from 'utils/constants';
// styles
import styles from './GroupTransfers.module.scss';

const cn = classnames.bind(styles);

const GroupTransfers = ({
  params,
  pigGroup,
  setTableParams,
  tableParams,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
  } = useDataTable((regParams) => getPigGroupMigrations(params.id, regParams), {
    setTableParams: (regParams) => setTableParams(tableNames.adminGroupTransfers, regParams),
    tableParams,
  });
  const { page, per_page, search, sort } = tableParams;
  const { total } = meta;

  useEffect(() => {
    fetchData({ ...tableParams, type: 'negative' }).catch(toastResponseErrors);
  }, []);

  const renderDateColumn = (rowData) => (
    <CustomColumn>
      <div className={cn('movement-date-box')}>
        <div className={cn('movement-icon')}>
          <i className="fa fa-arrow-left" />
        </div>
        <div className={cn('movement-date')}>{moment(rowData.delivery_date).format('MM/DD/YYYY')}</div>
      </div>
    </CustomColumn>
  );

  const renderHeadColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.head" />}>
      {Math.abs(rowData.amount)}
    </CustomColumn>
  );

  const renderWeightColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.estAvgWeight" />}>
      <FormattedWeight value={rowData.estimated_weight}  />
    </CustomColumn>
  );

  const renderTypeColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.type" />}>
      {capitalize(rowData.migration_type)}
    </CustomColumn>
  );

  const renderActionsColumn = (rowData) => (
    <div className="collapsible-value button-column">
      <Link to={`/groups/${params.id}/transfers/${rowData.id}/edit`} className="button small light-gray wide">
        <FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  const renderExpandable = (rowData) => (
    <div>
      <Link to={`/groups/${params.id}/transfers/${rowData.id}/edit`} className="button small light wide">
        <i className="fa fa-pencil mr-5" />
        <FormattedMessage id="general.button.edit" />
      </Link>
    </div>
  );

  const columns = [
    { label: <FormattedMessage id="general.date" />, flex: '1 1 140px', renderer: renderDateColumn,
      sortKey: 'delivery_date' },
    { label: <FormattedMessage id="general.head" />, flex: '1 1 160px', renderer: renderHeadColumn,
      sortKey: 'amount' },
    { label: <FormattedMessage id="general.estAvgWeight" />, flex: '1 1 135px', renderer: renderWeightColumn,
      sortKey: 'estimated_weight' },
    { label: <FormattedMessage id="general.type" />, flex: '1 1 135px', renderer: renderTypeColumn },
    { label: '', flex: '0 0 120px', renderer: renderActionsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '' },
    { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    totalItems: total,
    currentPage: page,
    perPage: per_page,
    onPageChange,
    onPerPageChange,
  };

  return (
    <section className="small-12 column">
      <Panel className={cn('group-sales-transfers')}>
        <Panel.Heading title={<FormattedMessage id="general.salesTransfers" />}>
          {!!pigGroup.pigs && (
            <Link
              to={`/groups/${pigGroup.id}/transfers/create`}
              className="button small primary wider show-for-large"
            >
              <FormattedMessage id="component.groupTransfers.createTransfer" />
            </Link>
          )}
        </Panel.Heading>
        <Panel.Body noPadding>
          <div className={cn('create-transfer-box', 'hide-for-large')}>
            {!!pigGroup.pigs && (
              <Link
                to={`/groups/${pigGroup.id}/transfers/create`}
                className={cn('create-transfer-btn', 'button primary')}
              >
                <FormattedMessage id="component.groupTransfers.createTransfer" />
              </Link>
            )}
          </div>

          <Preloader isActive={isLoading} />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            isLoading={isLoading}
            renderExpandable={renderExpandable}
            scrollable
          />

          <NothingBox
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <h2 className="lighter semibold">
              <FormattedMessage id="component.nothingBox.noTransfers" />
            </h2>
            <FormattedMessage
              tagName="p"
              id={`component.groupTransfers.need${pigGroup.pigs ? 'MovePigs' : 'Placement'}`}
            />
            {!!pigGroup.pigs && (
              <Link to={`/groups/${pigGroup.id}/transfers/create`} className="button primary">
                <FormattedMessage id="component.groupTransfers.createTransfer" />
              </Link>
            )}
          </NothingBox>
        </Panel.Body>
      </Panel>
    </section>
  );
};

GroupTransfers.propTypes = {
  params: T.object.isRequired,
  pigGroup: T.object,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.pigGroups.groupShow.data,
    tableParams: tableParamsSelector(state, tableNames.adminGroupTransfers),
  }), {
    setTableParams,
  },
)(GroupTransfers);
