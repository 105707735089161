import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from 'containers/DailyCheckup/utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { reportWaterUsage } from 'reducers/dailyCheckup/checkupEdit';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Input from 'components/Input';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
import penIcon from '../../../public/images/pen.svg';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { animateScroll } from 'react-scroll';
import { sendNotify } from 'utils/airbrakeHelper';
import { calculateMgPerHead, formatWater } from 'utils';
import { getCommentByRole } from 'helpers.js';
// styles
import './DailyCheckupReportWaterUsage.scss';
// constants
import { comment_roles } from 'constants.js';

const timeOptions = [{ label: '24-h', value: '24h' }];

class DailyCheckupReportWaterUsage extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialValues(props.checkup);
  }

  componentDidMount() {
    const { checkup, params, setPageOptions, isEditMode, backLink } = this.props;
    if (checkup.id === parseInt(params.id, 10)) {
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'waterUsage',
        inEditMode: isEditMode
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { setPageOptions, checkup, backLink, isEditMode } = this.props;
    if (prevProps.checkup !== checkup) {
      this.setState({
        ...this.getInitialValues(checkup)
      });
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'waterUsage',
        inEditMode: isEditMode
      });
    }
  }

  componentWillUnmount() {
    this.props.setPageOptions({ mobileTitle: '' });
  }

  getInitialValues = (checkup) => {
    const { measurementSystem } = this.props;
    const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], comment_roles.WATER_USAGE_ROLE);
    const [hours, minutes] = (checkup.water_usage_recorded_at || '10:00').split(':');
    const timeFormat = checkup.time_format || '24h';

    return {
      water_usage_value: formatWater(Number(checkup?.water_usage_value || 0), measurementSystem),
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
      timeFormat,
      hours,
      minutes,
    };
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  handleInputChange = ({ target: { value } }) => {
    const { water_usage_value } = this.state;
    if (
      !water_usage_value.toString().includes('.') &&
      (value || '').toString().substr(-1) === '.'
    ) {
      this.setState({ water_usage_value: value });
      return;
    }
    const pattern = /^\d+(\.\d{0,2})?$/; // float number with two decimal point
    if (`0${value}`.match(pattern)) {
      this.setState({ water_usage_value: Number(value) });
    }
  };

  handleTimeChange = ({ target: { value, name } }) => {

    let result = null;

    if (name === 'hours') {
      result = this.normalizeHours(value);
    }

    if (name === 'minutes') {
      result = this.normalizeMinutes(value);
    }

    this.setState({
      [name]: Number(result)
    });
  };

  normalizeValue = (value) => {
    if (!value) {
      return 0;
    }
    return value.length > 2 ? value.replace(/^0+/, '') : value;
  };

  handleTimeSelect = ({ target: { value } }) => {
    const [hours, minutes] = value.split(':');
    this.setState({
      hours,
      minutes,
    });
  }

  normalizeHours = (value) => {
    const { timeFormat } = this.state;

    if (!value) {
      return 0;
    }

    const time = Number(value.length > 2 ? value.replace(/^0+/, '') : value);


    if (timeFormat === '24h' && time > 24) {
      return 23;
    }

    if (timeFormat === 'pm' && time > 11) {
      return 11;
    }

    if (timeFormat === 'am' && time > 12) {
      return 12;
    }

    return time > 23 ? 23 : time;
  };

  normalizeMinutes = (value) => {
    if (!value) {
      return 0;
    }

    const time = Number(value.length > 2 ? value.replace(/^0+/gi, '') : value);

    return time > 59 ? 59 : time;
  };

  normalizeTime = (hours = 0, minutes = 0) => (
    `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
  );

  submitData = () => {
    const { router } = this.context;
    const {
      reportWaterUsage,
      checkup,
      validateCheckup,
      backLink,
      makeCheckupCorrection,
      isEditMode,
      currentUser,
      measurementSystem
    } = this.props;
    const { water_usage_value, comment, mentionsIds, hours, minutes, timeFormat } = this.state;

    if (!validateCheckup(checkup)) return;

    const resource = {
      water_usage_value: formatWater(water_usage_value, 'imperial', measurementSystem),
      comments_water_usage: {
        text: comment,
        mentioned_user_ids: mentionsIds
      },
      water_usage_recorded_at: this.normalizeTime(hours, minutes),
      time_format: timeFormat,
    };

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, resource)
      : reportWaterUsage(checkup.id, { resource });

    submitRequest
      .then(() => router.push(backLink))
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  isContinueDisabled = () => {
    const { water_usage_value: currentWaterUsageValue, comment, hours, minutes } = this.state;
    const { water_usage_value, comments = [], water_usage_recorded_at } = this.props.checkup;

    const commentData = getCommentByRole(comments, comment_roles.WATER_USAGE_ROLE);

    const [savedHours, savedMinutes] = (water_usage_recorded_at || '10:00').split(':');

    const isEqualTime = savedHours === hours && savedMinutes === minutes;

    const savedComment = commentData.comment || '';
    const currentComment = comment || '';
    const isEqualComment = savedComment === currentComment;
    const isEqualValue = +currentWaterUsageValue === +water_usage_value;

    return isEqualComment && isEqualValue && isEqualTime;
  };

  getWaterUsagePerHead = (checkup, currentValue) => {
    const { initial_pigs, pigs } = checkup;

    if (!initial_pigs && !pigs) return 0;
    return calculateMgPerHead(currentValue, initial_pigs || pigs);
  };

  render() {
    const { isReportingWater, checkup, backLink, isEditMode, measurementSystem } = this.props;
    const { comment, mentionsIds, water_usage_value, timeFormat, hours, minutes } = this.state;
    const isContinueDisabled = this.isContinueDisabled();
    const waterUsagePerHead = this.getWaterUsagePerHead(checkup, water_usage_value);
    const water_unit = measurementSystem === 'imperial' ? 'gallonage' : 'liters';

    return (
      <CenterBox
        className="DailyCheckupReportWaterUsage"
        isLoading={isReportingWater}
        renderIf={Boolean(checkup)}
      >
        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={checkup.pigs} />
        </StickyHeader>

        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title">
              <FormattedMessage id="general.waterUsage" />
            </h1>
          </div>
          <Link to={backLink}>
            <i className="fa fa-times close-center-box" />
          </Link>
        </section>

        <section className="center-box-body">
          {isEditMode && (
            <MessageBox type="warning">
              <strong>
                <FormattedMessage id="component.alertBox.warning" />
                &nbsp;
              </strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}
          <div className="water-usage-inputs">
            <div className="water-usage-row">
              <label className="input-wrapper">
                <span className="input-label">
                  <FormattedMessage id={`general.${water_unit}Consumed`} />
                </span>
                <Input
                  type="number"
                  placeholder="0"
                  pattern="[0-9]*"
                  value={water_usage_value || ''}
                  onChange={this.handleInputChange}
                  className="water-usage-input"
                  hasNoMargin
                  alignRight
                  bolded
                />
              </label>
            </div>
            <div className="water-usage-row desktop">
              <span className="input-label">
                <FormattedMessage id="general.timeRecorded" />
              </span>
              <Input
                name="hours"
                type="number"
                placeholder="0"
                pattern="[0-9]*"
                value={this.normalizeValue(String(hours))}
                onChange={this.handleTimeChange}
                className="water-usage-time"
                hasNoMargin
                alignRight
                bolded
              />
              <span className="colon-separator ">:</span>
              <Input
                name="minutes"
                type="number"
                pattern="[0-9]*"
                placeholder="0"
                value={this.normalizeValue(String(minutes))}
                onChange={this.handleTimeChange}
                className="water-usage-time"
                hasNoMargin
                alignRight
                bolded
              />
              <ReactSelect
                className="time-select"
                placeholder={
                  <FormattedMessage id="container.dailyCheckup.reportMovements.selectMovementType" />
                }
                options={timeOptions}
                value={timeFormat}
                onChange={(option) => {
                  this.setState({ timeFormat: option.value });
                }}
                searchable={false}
                noInputMargin
              />
            </div>
            <div className="water-usage-row mobile">
              <span className="input-label">
                <FormattedMessage id="general.timeRecorded" />
              </span>
              <Input
                name="hours"
                type="time"
                value={`${hours}:${minutes}`}
                onChange={this.handleTimeSelect}
                className="water-usage-time"
                hasNoMargin
                alignRight
                bolded
              />
              <img src={penIcon} alt="Edit Time" />
            </div>
            <div className="water-usage-row">
              <label className="input-wrapper">
                <span className="input-label">
                  <FormattedMessage id={`general.${water_unit}PerHead`} />
                </span>
                <Input
                  placeholder="n/a"
                  value={waterUsagePerHead}
                  className="water-usage-input"
                  hasNoMargin
                  alignRight
                  bolded
                  disabled
                />
              </label>
            </div>
          </div>
          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={this.onCommentOpen}
            onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
            onMentionsChange={(mentions) => {
              this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
            }}
            value={comment}
            isOpen={!!comment}
            placeholder=""
          />
        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={backLink}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingWater}
              className="submit wider"
              onClick={this.submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            stickyFooter
            primary
            disabled={isContinueDisabled || isReportingWater}
            onClick={this.submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupReportWaterUsage.contextTypes = {
  router: T.object.isRequired
};

DailyCheckupReportWaterUsage.propTypes = {
  checkup: T.object,
  isReportingWater: T.bool.isRequired,
  setPageOptions: T.func.isRequired,
  reportWaterUsage: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  isEditMode: T.bool,
  params: T.object,
  backLink: T.oneOfType([T.string, T.object]),
  makeCheckupCorrection: T.func,
  currentUser: T.object.isRequired,
  measurementSystem: T.string.isRequired
};

const enhance = compose(
  connect(
    (state, props) => ({
      currentUser: state.auth.user,
      measurementSystem: state.auth.user.current_company.measurement_system,
      isReportingWater: props.isLoading || state.dailyCheckup.checkupEdit.isReportingWater,
    }),
    { reportWaterUsage, setPageOptions }
  ),
  withCorrection
);

export default enhance(DailyCheckupReportWaterUsage);
