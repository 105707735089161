import { fetchFromAPI } from 'utils/api';

export const authValidateToken = (params) => {
  return fetchFromAPI('/auth/validate_token', { authRequest: true, ...params });
};

export const authSignIn = (user) => {
  return fetchFromAPI('/auth/sign_in', { method: 'post', body: JSON.stringify(user), authRequest: true })
    .then((response) => response.data)
    .catch((response) => {
      throw response;
    });
};

export const authSignOut = () => {
  return fetchFromAPI('/auth/sign_out', { method: 'delete' });
};

export const postAuthPassword = (resource) => {
  return fetchFromAPI('/auth/password', {
    method: 'post',
    body: JSON.stringify(resource),
  });
};

export const updateAuthPassword = (resource) => {
  return fetchFromAPI('/auth/password', {
    method: 'put',
    body: JSON.stringify(resource),
  });
};

export const checkResetPasswordToken = (token) => {
  return fetchFromAPI(`/auth/password/edit?reset_password_token=${token}`);
};

export const postCurrentCompany = (company_id) => (
  fetchFromAPI('/current_companies', {
    method: 'post',
    body: JSON.stringify({ resource: { company_id }, current_company_id: company_id }),
  })
    .then(({ resource }) => resource)
);
