import React from 'react';
import T from 'prop-types';
import Button from 'components/Button';
import cn from 'classnames';
import './MobileRow.scss';
import { FormattedMessage } from 'react-intl';
import { getMedType } from 'utils';

const MobileTreatmentRow = ({ treatment, actionBtnType, disabledRow, onRowClick, selectedRow }) => (
  <div
    onClick={onRowClick}
    className={cn('MobileRow', {
      'no-events': disabledRow,
      'select-row': actionBtnType === 'button',
      'divider': treatment.divider,
    })}
  >
    <div className={cn({ 'disable-for-select': disabledRow }, 'row-info')}>
      <i className={`fa fa-${getMedType(treatment)}`} />
      <span>
        <span>{treatment.name}</span>
        {treatment.common && (
          <span className="common">
            <FormattedMessage id="general.meds.common" />
          </span>
        )}
        {treatment.vaccine &&
          <span className="common">(<FormattedMessage id="general.vaccine" />)</span>}
      </span>
    </div>
    {actionBtnType === 'button' && (
      <Button disabled={selectedRow} className="select-btn" small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionBtnType === 'checkbox' && (
      <div className={cn('icon', { 'selected': selectedRow })}>
        <i className="checked fa fa-check-circle-bts" />
        <i className="unchecked fa fa-radio-unchecked" />
      </div>
    )}
  </div>
);

MobileTreatmentRow.propTypes = {
  actionBtnType: T.string.isRequired,
  treatment: T.object.isRequired,
  disabledRow: T.bool.isRequired,
  onRowClick: T.func.isRequired,
  selectedRow: T.bool.isRequired,
};

export default MobileTreatmentRow;
