import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// store
import { postComment } from 'reducers/siteVisitReport';
// components
import MentionsInput from 'components/MentionsInput';
import Button from 'components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
// hooks
import useCommentWithMentionsState from 'hooks/useCommentWithMentionsState';
// router
import { withRouter } from 'react-router';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './QuestionNewComment.module.scss';

const cn = classnames.bind(styles);

const svrQuestionMentionRoles = ['owner', 'caretaker', 'manager', 'vet'];

const QuestionNewComment = ({ setAnswer, question, params, postComment }) => {
  const { comment, mentions, resetCommentWithMentions, setComment, setMentions } = useCommentWithMentionsState();
  const { formatMessage } = useIntl();

  const handleSaveComment = async () => {
    try {
      const commentData = { comment, comment_mentions_attributes: mentions.map(({ id: user_id }) => ({ user_id })) };
      await postComment(commentData);
      resetCommentWithMentions();
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const handleToggleFlag = () => {
    setAnswer({ is_flagged: !question.answer?.is_flagged });
  };

  return (
    <div className={cn('note-section')}>
      <MentionsInput
        comment={comment}
        disabledIds={mentions.map((mention) => mention.id)}
        entityType="farm"
        entityId={params.farmId}
        inputType="square"
        mentionRoles={svrQuestionMentionRoles}
        onCommentChange={setComment}
        onMentionsChange={setMentions}
        placeholder={formatMessage({ id: 'general.tellUsMore' })}
      />

      <div className={cn('note-actions')}>
        {question?.answer?.value === 'fail' && (
          <div onClick={handleToggleFlag} className={cn('flag-button', { flagged: question.answer?.is_flagged  })}>
            <i className={cn('fa fa-flag')} />
            <span className={cn('flag-btn-label')}>
              {question.answer?.is_flagged ?
                <FormattedMessage id="general.unflagForFollowup" /> :
                <FormattedMessage id="general.flagForFollowup" />
              }
            </span>
          </div>
        )}

        <Button
          onClick={handleSaveComment}
          disabled={!comment}
          className={cn('save-btn')}
          primary
        >
          <FormattedMessage id="general.button.save" />
        </Button>
      </div>
    </div>
  );
};

QuestionNewComment.propTypes = {
  setAnswer: T.func.isRequired,
  question: T.object.isRequired,
  params: T.object.isRequired,
  postComment: T.func.isRequired,
};

export default withRouter(
  connect((state) => ({
    currentUser: state.auth.user,
  }), (dispatch, { questionIndex, categoryIndex }) => ({
    postComment: (commentData) => dispatch(postComment(categoryIndex, questionIndex, commentData)),
  }))(QuestionNewComment)
);
