import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import PaginationWrapper from 'components/PaginationWrapper';
import TableView from '../TableView';
import GridView from '../GridView';
// utils
import cn from 'classnames';
import { getLSSvrTableView, setLSSvrTableView } from 'utils/localStorageHelper';
// icons
import { ReactComponent as TableIcon } from './table.svg';
import { ReactComponent as GridIcon } from './grid.svg';

const SiteVisitReportsList = ({
  backLocationQuery,
  className,
  isLoading,
  isReportsEditable = false,
  paginationProps,
  resources = [],
}) => {
  const [viewMode, setViewMode] = useState(getLSSvrTableView() || 'grid');

  const toggleMode = () => {
    setViewMode((prevMode) => {
      const newMode = prevMode === 'table' ? 'grid' : 'table';
      setLSSvrTableView(newMode);
      return newMode;
    });
  };

  return (
    <Panel className={cn({ [className]: !!className })}>
      <Panel.Heading className="farms-panel-title" title={<FormattedMessage id="general.siteVisitReports" />}>
        <div className="cursor-pointer" onClick={toggleMode}>
          {viewMode !== 'table'
            ? <TableIcon height={20} width={20} />
            : <GridIcon height={20} width={20} />
          }
        </div>
      </Panel.Heading>
      <Panel.Body noPadding>
        <Preloader isActive={isLoading} />
        {viewMode === 'table'
          ? (
            <TableView
              backLocationQuery={backLocationQuery}
              resources={resources}
              paginationProps={paginationProps}
              isReportEditable={isReportsEditable}
              isLoading={isLoading}
            />
          )
          : <GridView resources={resources} backLocationQuery={backLocationQuery} />
        }
        <NothingBox
          itemsName="siteVisitReports"
          display={!resources.length}
          isLoading={isLoading}
        >
          <FormattedMessage tagName="h1" id="component.nothingBox.noSiteVisitReports" />
        </NothingBox>
        {viewMode === 'grid' && (
          <div className="mt-5 mb-5 ml-10 mr-10">
            <PaginationWrapper {...paginationProps} onlyBottom />
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};

SiteVisitReportsList.propTypes = {
  backLocationQuery: T.object,
  isLoading: T.bool.isRequired,
  isReportsEditable: T.bool,
  className: T.string,
  resources: T.array,
};

export default SiteVisitReportsList;
