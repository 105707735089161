import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateAuthUserData } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import CreateTenantForm from 'components/SuperAdmin/TenantForm/CreateTenantForm';
import Panel from 'components/Panel';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';
import moment from 'moment';
// superAdmin API
import { createTenant } from 'endpoints/superAdmin';
// constants
import { requiredTenantFields } from 'constants.js';

const initialValues = { expires_at: 45, country_code: 'US', status: 'trial', language: 'en', user: { language: 'en' } };

const TenantCreate = ({ router, user, updateAuthUserData }) => {

  const handleSubmit = (data) => {
    const expires_at = data.status === 'trial'
      ? moment(new Date()).add(data.expires_at, 'days').format()
      : null;
    const entity = pick(data, requiredTenantFields);
    const userData = pick(data.user, ['email', 'first_name', 'last_name', 'nickname', 'language']);
    const userEmail = (userData?.email || '').toLowerCase();
    const { phone, countryCode: country_code } = data.user?.phone_attributes || {};
    const resource = {
      ...entity,
      expires_at,
      user: {
        ...userData,
        email: userEmail,
        phone,
        country_code
      }
    };

    return createTenant(resource)
      .then((resource) => {
        router.push('/super-admin/tenants');
        updateAuthUserData({
          root_companies: [
            ...user.root_companies,
            resource,
          ]
        });
        showToastrMessage('component.toastr.tenant.created');
      })
      .catch(toastResponseErrors);
  };

  return (
    <div className="small-12 column">
      <section>
        <Panel>
          <Panel.Heading>
            <h2 className="lighter">
              <FormattedMessage id="general.createTenant" />
            </h2>
          </Panel.Heading>
          <Panel.Body>
            <CreateTenantForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
            />
          </Panel.Body>
        </Panel>
      </section>
    </div>
  );
};

TenantCreate.propTypes = {
  router: T.object.isRequired,
  user: T.object.isRequired,
  updateAuthUserData: T.func.isRequired,
};

export default connect(
  (state) => ({
    user: state.auth.user,
  }),
  { updateAuthUserData }
)(TenantCreate);
