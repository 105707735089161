import React, { useState } from 'react';
import T from 'prop-types';
// components
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
import ButtonRadioGroup  from 'components/ButtonRadioGroup/ButtonRadioGroup';
// store
import { setCurrentCompany } from 'reducers/auth';
// api
import { updateTenantSettings } from 'endpoints/tenantSettings';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
// constants
import { SVRFarmfeedTriggers } from './constants';
// styles
import styles from '../SiteVisitReports.module.scss';

const cn = classnames.bind(styles);

const FarmfeedEventsSection = ({ isSVREnabled, SVRFarmfeedTrigger, setCurrentCompany }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onTrigggerValueChange = ({ target: { value } }) => {
    updateTenantSettings({ svrs_farmfeed: value }).then(() => {
      setCurrentCompany({ svrs_farmfeed: value });
    })
      .catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const currentValue = SVRFarmfeedTriggers.find(({ value }) => value === SVRFarmfeedTrigger);

  return (
    <div className={cn('svr-farmfeed-trigger', { 'disabled-section': !isSVREnabled })}>
      <Preloader isActive={isLoading} />
      <div className={cn('title')}>
        <FormattedMessage
          tagName="b"
          id="general.triggerFarmfeedEvents"
          values={{ value: <b className="primary">{currentValue.label}</b> }}
        />
        <p className={cn('description')}>
          <FormattedMessage id={`general.triggerFarmfeedEvents.desc.${SVRFarmfeedTrigger}`} />
        </p>
      </div>
      <ButtonRadioGroup
        disabled={!isSVREnabled}
        className={cn('svr-ff-radio-group')}
        options={SVRFarmfeedTriggers}
        value={SVRFarmfeedTrigger}
        onChange={onTrigggerValueChange}
      />
    </div>
  );
};

FarmfeedEventsSection.propTypes = {
  isSVREnabled: T.bool.isRequired,
  SVRFarmfeedTrigger: T.string.isRequired,
  setCurrentCompany: T.func.isRequired,
};

export default connect((state) => ({
  isSVREnabled: state.auth.user.current_company.svrs,
  SVRFarmfeedTrigger: state.auth.user.current_company.svrs_farmfeed,
}), {
  setCurrentCompany
})(FarmfeedEventsSection);
