import { fetchFromAPI } from 'utils/api';

// farms
export const getTreatmentProtocolsFarms = (params) => {
  return fetchFromAPI('/treatment_protocols/farms', { params });
};

export const getTreatmentProtocolsFarm = (farmId) => {
  return fetchFromAPI(`/treatment_protocols/farms/${farmId}`).then((response) => response.resource);
};

export const getFarmTreatmentProtocols = (farmId, params) => {
  return fetchFromAPI(`/farms/${farmId}/treatment_protocols`, { params });
};

export const getFarmTreatmentProtocol = (farmId, treatmentProtocolId) => {
  return fetchFromAPI(`/farms/${farmId}/treatment_protocols/${treatmentProtocolId}`)
    .then((response) => response.resource);
};

export const createFarmTreatmentProtocol = (farmId, resource) => {
  return fetchFromAPI(`/farms/${farmId}/treatment_protocols`, { method: 'POST', body: JSON.stringify({ resource }) });
};

export const updateFarmTreatmentProtocol = (farmId, treatmentProtocolId, resource) => {
  return fetchFromAPI(
    `/farms/${farmId}/treatment_protocols/${treatmentProtocolId}`,
    { method: 'PUT', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

// TODO: update it according to backend changes
export const deleteFarmTreatmentProtocol = (id) => {
  return new Promise((resolve) => setTimeout(() => resolve(id), 2400));
};

// pig groups
export const getTreatmentProtocolsFarmPigGroups = (farmId, params) => {
  return fetchFromAPI(`/treatment_protocols/farms/${farmId}/pig_groups`, { params })
    .then((response) => response.resources);
};

export const getTreatmentProtocolsFarmPigGroup = (farmId, pigGroupId) => {
  return fetchFromAPI(`/treatment_protocols/farms/${farmId}/pig_groups/${pigGroupId}`)
    .then((response) => response.resource);
};

export const getPigGroupTreatmentProtocol = (pigGroupId, treatmentProtocolId) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/treatment_protocols/${treatmentProtocolId}`)
    .then((response) => response.resource);
};

export const getPigGroupTreatmentProtocols = (pigGroupId, params) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/treatment_protocols`, { params });
};

export const createPigGroupTreatmentProtocol = (pigGroupId, resource) => {
  return fetchFromAPI(
    `/pig_groups/${pigGroupId}/treatment_protocols`,
    { method: 'POST', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

export const updatePigGroupTreatmentProtocol = (pigGroupId, treatmentProtocolId, resource) => {
  return fetchFromAPI(
    `/pig_groups/${pigGroupId}/treatment_protocols/${treatmentProtocolId}`,
    { method: 'PUT', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

// Barnsheets

export const getPigGroupTreatmentProtocolProductSteps = (pigGroupId, params) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/treatment_protocol_product_steps`, { params });
};

// DailyCheckup

export const getPigGroupDailyCheckupTreatmentProtocolProductSteps = (pigGroupId, params) => {
  return fetchFromAPI(`/daily_checkups/pig_groups/${pigGroupId}/treatment_protocol_product_steps`, { params });
};

export const getPigGroupDailyCheckupTreatmentProtocolProductStep = (pigGroupId, stepId) => {
  return fetchFromAPI(`/daily_checkups/pig_groups/${pigGroupId}/treatment_protocol_product_steps/${stepId}`)
    .then((response) => response.resource);
};
