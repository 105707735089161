import React, { useState } from 'react';
import T from 'prop-types';
// components
import { Droppable } from 'react-beautiful-dnd';
import Preloader from 'components/Preloader';
import EmptySectionPlaceholder from '../EmptySectionPlaceholder';
import AddQuestionButton from '../AddQuestionButton';
import CategoryHeader from '../CategoryHeader';
import DraggableQuestion from '../DraggableQuestion';
// api
import {
  resetSVRQuestionsSettings,
  toggleSVRQuestion,
  createSVRQuestion,
  deleteSVRQuestion,
  updateSVRQuestion,
} from 'endpoints/admin/svr';
// utils
import uuid from 'uuid';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './DroppableCategory.module.scss';

const cn = classnames.bind(styles);

const draftQuestionTemplate = { label: '', is_enabled: true, draft: true };

const DroppableCategory = ({
  name,
  questions,
  categoryId,
  updateCategory,
  search,
  isCustomCategory,
  onCategoryDelete,
  onCategoryUpdate
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const processCategoryAction = async (callback) => {
    setIsLoading(true);
    try {
      await callback();
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const onCategoryReset = () => {
    processCategoryAction(async () => {
      const updatedCategory = await resetSVRQuestionsSettings(categoryId);
      updateCategory(updatedCategory);
    });
  };

  const onQuestionAdd = () => {
    updateCategory({ svr_questions: [...questions, { ...draftQuestionTemplate, id: uuid() }] });
  };

  const onQuestionDelete = (questionForDelete) => async () => {
    processCategoryAction(async () => {
      await deleteSVRQuestion(categoryId, questionForDelete.id);
      updateCategory({ svr_questions: questions.filter(({ id }) => id !== questionForDelete.id) });
    });
  };

  const onQuestionToggle = (toggledQuestion) => async () => {
    processCategoryAction(async () => {
      await toggleSVRQuestion(categoryId, toggledQuestion.id);
      updateCategory({ svr_questions:
        questions.map((question) => (question.id === toggledQuestion.id ?
          ({ ...toggledQuestion, is_enabled: !toggledQuestion.is_enabled, }) :
          question
        ))
      });
    });
  };

  const onQuestionUpdate = (questionForUpdate) => async (updatedLabel) => {
    processCategoryAction(async () => {
      // if empty label for new question
      if (!updatedLabel) {
        return updateCategory({ svr_questions: questions.slice(0, questions.length - 1) });
      }

      // if label hasn't changed
      if (updatedLabel === questionForUpdate.label) {
        return undefined;
      }

      // if question isn't created yet
      if (questionForUpdate.draft) {
        const data = { position: questions.length + 1, item_key: updatedLabel };
        const createdQuestion = await createSVRQuestion(categoryId, data);
        return updateCategory({
          svr_questions: questions
            .map((question) => (question.id === questionForUpdate.id ? createdQuestion : question))
        });
      }

      const data = { item_key: updatedLabel };
      const updatedQuestion = await updateSVRQuestion(categoryId, questionForUpdate.id, data);
      return updateCategory({
        svr_questions: questions.map((question) => (question.id === questionForUpdate.id ? updatedQuestion : question))
      });

    });
  };

  const hasDraftQuestions = questions.some(({ draft }) => draft);
  const visibleQuestions = search ? questions.filter(({ label }) => label.includes(search.toLowerCase())) : questions;
  const isSearchResultsEmpty = !visibleQuestions.length && search;
  const isEmptySection = !questions.length;
  const droppableType = isCustomCategory ? `custom-${name}` : name;

  const questionRenderer = (questionData, index) => (
    <DraggableQuestion
      questionId={questionData.id}
      label={questionData.label}
      index={index}
      key={questionData.id}
      isDragDisabled={!!search || !questionData.is_enabled || hasDraftQuestions}
      isDraft={!!questionData.draft}
      isCustom={!!questionData.custom}
      isEnabled={questionData.is_enabled}
      onToggle={onQuestionToggle(questionData)}
      onQuestionUpdate={onQuestionUpdate(questionData)}
      onDelete={onQuestionDelete(questionData)}
    />
  );

  return (
    <Droppable key={categoryId} type={droppableType} droppableId={`${categoryId}`}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={cn('category')}
        >
          <CategoryHeader
            isCustom={isCustomCategory}
            name={name}
            questions={questions}
            onReset={onCategoryReset}
            onDelete={() => onCategoryDelete(categoryId)}
            onUpdate={(updatedName) => onCategoryUpdate(categoryId, updatedName)}
          />
          {(isSearchResultsEmpty || isEmptySection) && (
            <EmptySectionPlaceholder hasSearch={!!search} categoryName={name} />
          )}
          <div className={cn('questions')}>
            <Preloader isActive={isLoading} />
            {visibleQuestions.map(questionRenderer)}
            {provided.placeholder}
            {!hasDraftQuestions && <AddQuestionButton onClick={onQuestionAdd} />}
          </div>
        </div>
      )}
    </Droppable>
  );
};

DroppableCategory.propTypes = {
  name: T.string.isRequired,
  questions: T.array.isRequired,
  categoryId: T.number.isRequired,
  updateCategory: T.func.isRequired,
  onCategoryUpdate: T.func.isRequired,
  onCategoryDelete: T.func.isRequired,
  search: T.string,
  isCustomCategory: T.bool.isRequired,
};

export default DroppableCategory;
