import React from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
// utils
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { getFarmNickname, getUserNickname } from 'utils';
// styles
import styles from './ActivityMentions.module.scss';

const ActivityMentions = ({ users = [], company, farm, isAdmin, className }) => (
  <div className={cn(styles['activity-mentions'], className)}>
    {!isEmpty(company) && (
      <span className={styles['line-mention']}>
        <Link to={`/barnsheets/companies/${company.id}`}>
          @{getFarmNickname(company)}
        </Link>
      </span>
    )}
    {!isEmpty(farm) && (
      <span className={styles['line-mention']}>
        <Link to={`/barnsheets/farms/${farm.id}`}>
          @{getFarmNickname(farm)}
        </Link>
      </span>
    )}
    {!isEmpty(users) && (
      <span className={styles['line-mention']}>
        {users.map((user) => (
          <Link key={user.id} to={isAdmin ? `/admin/users/${user.id}` : null}>
            @{getUserNickname(user)} &nbsp;
          </Link>
        ))}
      </span>
    )}
  </div>
);

ActivityMentions.propTypes = {
  className: T.string,
  users: T.array,
  company: T.object,
  farm: T.object,
  isAdmin: T.bool,
};

export default ActivityMentions;
