import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import cn from 'classnames';
// styles
import styles from './AddQuestionButton.module.scss';

const AddQuestionButton = ({ onClick }) => (
  <div className={cn(styles['add-question-btn'], 'mt-10')}>
    <span onClick={onClick} className={styles['btn-label']}>
      <FormattedMessage id="general.addQuestion" />
    </span>
  </div>
);


AddQuestionButton.propTypes = {
  onClick: T.func.isRequired,
};

export default AddQuestionButton;
