import React, { useState } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { openPortal } from 'reducers/portal';
// components
import { SingleDatePicker as ReactDatesPicker } from 'react-dates';
import SingleDatePickerMobile from './SingleDatePickerMobile';
// utils
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import withOutsideClick from 'react-onclickoutside';
import pick from 'lodash.pick';
// styles
import 'react-dates/lib/css/_datepicker.css';
import './SingleDatePicker.scss';

const SingleDatePicker = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const nestedProps = pick(props, ['isOutsideRange', 'placeholder', 'displayFormat', 'disabled']);
  const {
    className = '',
    date,
    disabled,
    onDateChange,
    openPortal,
    isOutsideRange,
    inputLabel = <i className="fa fa-calendar" />,
  } = props;

  const handleSetFocus = () => {
    if (!isFocused && !disabled) setIsFocused(true);
  };

  const handleChangeFocus = ({ focused }) => setIsFocused(focused);

  SingleDatePicker.handleClickOutside = () => setIsFocused(false);

  const handleOpenMobilePicker = (e) => {
    e.stopPropagation();
    if (disabled) return;
    openPortal(
      <SingleDatePickerMobile
        initialDate={date}
        onChange={onDateChange}
        isOutsideRange={isOutsideRange}
      />
    );
  };

  return (
    <div
      onClick={handleSetFocus}
      className={cn('SingleDatePickerWrapper', { [className]: !!className, focused: isFocused, isMobile })}
    >
      <ReactDatesPicker
        readOnly
        date={date}                         // momentPropTypes.momentObj or null,
        onDateChange={onDateChange}         // PropTypes.func.isRequired,
        onFocusChange={handleChangeFocus}
        focused={isFocused}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        {...nestedProps}
      />
      <div className={cn('mobile-overlay', { 'active': isMobile })} onClick={handleOpenMobilePicker} />
      <div className="picker-label">{inputLabel}</div>
    </div>
  );
};

SingleDatePicker.propTypes = {
  className: T.string,
  date: T.object,
  onDateChange: T.func.isRequired,
  openPortal: T.func.isRequired,
  inputLabel: T.oneOfType([T.string, T.node]),
  isOutsideRange: T.func,
};

const clickOutsideConfig = {
  handleClickOutside: () => SingleDatePicker.handleClickOutside
};

export default connect(null, { openPortal })(withOutsideClick(SingleDatePicker, clickOutsideConfig));
