import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setPageOptions } from 'reducers/layout';
// recompose
import withCorrection from './utils/withCorrectionHOC';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
// utils
import { animateScroll } from 'react-scroll';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { sendNotify } from 'utils/airbrakeHelper';
import { getCommentByRole } from 'helpers.js';
// constants
import { comment_roles } from 'constants.js';

const { CHECKUP_ROLE } = comment_roles;

class DailyCheckupNote extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialValues(this.props.checkup);
  }

  componentDidMount() {
    const { params: { id }, setPageOptions, checkup, backLink, isEditMode } = this.props;
    if (Number(id) === checkup.id) {
      setPageOptions({
        backLink: { link: backLink, icon: 'fa fa-times fs-18' },
        inEditMode: isEditMode,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { checkup } = this.props;
    if (prevProps.checkup?.id !== checkup?.id && checkup?.id) {
      this.setInitialData(this.props);
    }
  }

  getInitialValues = (checkup) => {
    const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], CHECKUP_ROLE);
    return ({
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
    });
  };

  setInitialData = ({ checkup, backLink, isEditMode }) => {
    const { params: { id }, setPageOptions } = this.props;
    if (Number(id) === checkup.id) {
      const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], CHECKUP_ROLE);

      setPageOptions({
        backLink: { link: backLink, icon: 'fa fa-times fs-18' },
        inEditMode: isEditMode,
      });
      this.setState({ comment: comment || '', mentionsIds: mentioned_user_ids || [] });
    }
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  submitData = () => {
    const { router } = this.context;
    const { comment, mentionsIds } = this.state;
    const { checkup, backLink, makeCheckupCorrection, currentUser } = this.props;

    makeCheckupCorrection(checkup.id, {
      comments_checkup: {
        text: comment,
        mentioned_user_ids: mentionsIds,
      },
    })
      .then(() => router.push(backLink))
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  isContinueDisabled = () => {
    const { checkup, isLoading } = this.props;
    const { comment: state_comment } = this.state;
    const { comment: checkup_comment, text } = getCommentByRole(checkup?.comments || [], CHECKUP_ROLE);
    const comment = checkup_comment || text;
    const initialComment = comment || '';
    const currentComment = state_comment || '';
    return isLoading || (initialComment === currentComment);
  };

  render() {
    const { checkup, isLoading, backLink } = this.props;
    const { comment, mentionsIds } = this.state;
    const isSubmitDisabled = this.isContinueDisabled();

    return (
      <CenterBox isLoading={isLoading} renderIf={Boolean(checkup)}>
        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title"><FormattedMessage id="general.note" /></h1>
          </div>
          <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
        </section>
        <section className="center-box-body">
          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={this.onCommentOpen}
            onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
            onMentionsChange={(mentions) => {
              this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
            }}
            value={comment}
            isOpen
            placeholder=""
          />
        </section>
        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={backLink}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isSubmitDisabled}
              className="submit wider"
              onClick={this.submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>
        <StickyFooter className="hide-for-large" hidden={isSubmitDisabled}>
          <Button
            primary
            stickyFooter
            disabled={isSubmitDisabled}
            onClick={this.submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupNote.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupNote.propTypes = {
  params: T.object,
  checkup: T.object,
  isLoading: T.bool,
  backLink: T.oneOfType([T.string, T.object]),
  makeCheckupCorrection: T.func,
  currentUser: T.object,
  isEditMode: T.bool,
  setPageOptions: T.func,
};


export default compose(
  connect((state) => ({
    currentUser: state.auth.user,
  }), { setPageOptions }),
  withCorrection,
)(DailyCheckupNote);
