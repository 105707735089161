import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import {
  formatTreatmentProtocolPayload,
  formatTreatmentProtocolResponse,
  markDestroyedItems,
  validateUpdatedTreatmentProtocol,
} from './helper';
// endpoints
import {
  getTreatmentProtocolsFarm,
  getFarmTreatmentProtocol,
  updateFarmTreatmentProtocol,
} from 'endpoints/treatmentProtocols';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from './components/TreatmentProtocolForm';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
// styles
import styles from './TreatmentProtocols.module.scss';

const EditFarmTreatmentProtocol = ({
  setBreadcrumbs,
  setPageOptions,
  router: { params: { farmId, treatmentProtocolId }, push },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [farm, setFarm] = useState(undefined);
  const [treatmentProtocol, setTreatmentProtocol] = useState(undefined);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setFarm(await getTreatmentProtocolsFarm(farmId));
      const fetchedTreatmentProtocol = await getFarmTreatmentProtocol(farmId, treatmentProtocolId);
      const formattedTreatmentProtocol = formatTreatmentProtocolResponse(fetchedTreatmentProtocol);
      setTreatmentProtocol(formattedTreatmentProtocol);
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      push(`/treatment-protocol-farms/${farmId}/treatment-protocols`);
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocol-farms/${farmId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    if (farm && treatmentProtocol) {
      setBreadcrumbs([
        {
          label: <FormattedMessage id="general.treatmentProtocolFarms" />,
          path: '/treatment-protocol-farms',
        },
        {
          label: farm.name,
          path: `/treatment-protocol-farms/${farmId}/treatment-protocols`,
        },
        {
          label: treatmentProtocol.name,
          path: `/treatment-protocol-farms/${farmId}/treatment-protocols/${treatmentProtocolId}/edit`,
          useLabelAsMobileTitle: true,
        },
      ]);
    }
  }, [farm, treatmentProtocol]);

  const onSubmit = async (updatedTreatmentProtocol) => {
    try {
      setIsLoading(true);
      const markedTreatmentProtocolPayload = markDestroyedItems(updatedTreatmentProtocol, treatmentProtocol);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(markedTreatmentProtocolPayload);
      await updateFarmTreatmentProtocol(farmId, treatmentProtocolId, treatmentProtocolPayload);
      push(`/treatment-protocol-farms/${farmId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  if (!farm || !treatmentProtocol) return <Preloader isActive />;

  return (
    <div>
      <Preloader isActive={isLoading} />
      <FarmProfileHeader
        className="show-for-large"
        farm={farm}
        actualFarmId={farm.id}
        farmInfo={(
          <div className={styles['farm-details']}>
            <span>ID&nbsp;&bull;&nbsp;{farm.federal_premise_id || 'N/A'}</span>
          </div>
        )}
      />
      <TreatmentProtocolForm
        onSubmit={onSubmit}
        validate={validateUpdatedTreatmentProtocol}
        initialValues={treatmentProtocol}
        title={<FormattedMessage id="general.editTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.updateTreatmentProtocol" />}
      />
    </div>
  );
};

EditFarmTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(EditFarmTreatmentProtocol);
