import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { submit as submitForm } from 'redux-form';
import { startGroup } from 'reducers/dailyCheckup/checkupFarmGroups';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openPortal } from 'reducers/portal';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import DailyCheckupStartGroup from './components/DailyCheckupStartGroup';
import DailyCheckupPigGroupCommitments from './components/DailyCheckupPigGroupCommitments';
import CreateShipmentMobileWizard from './components/CreateShipmentMobileWizard';
// hooks
import usePigGroup from 'hooks/pigGroups/usePigGroup';
// utils
import pick from 'lodash.pick';
import { isMobile } from 'react-device-detect';
import { showToastrMessage } from 'utils';
import { formatWeightToBackEndFormat } from 'utils/weightHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const DailyCheckupStartGroupSwitcher = ({
  isLogisticsEnabled,
  location,
  measurementSystem,
  openPortal,
  params: { id },
  router,
  setBreadcrumbs,
  setPageOptions,
  startGroup,
  submitForm,
}) => {
  const [isMobileWizardShouldOpen, setIsMobileWizardShouldOpen] = useState(false);
  const { pigGroup } = usePigGroup(id);
  const isSelectLoadPage = location.query?.mode === 'select-load';
  const commitmentId = isLogisticsEnabled ? location.query?.commitmentId : undefined;

  const handleStartGroup = (resource) => {
    return startGroup(id, resource)
      .then((response) => {
        const group = response?.value?.resource;
        showToastrMessage('component.toastr.shipment.successCreated');
        if (!group?.current_checkup_id && !group?.farm_id) {
          router.push('/daily-checkup');
        } else {
          router.push(`/daily-checkup/${group?.current_checkup_id || `farms/${group?.farm_id}`}`);
        }
      })
      .catch(toastResponseErrors);
  };

  const onSubmit = (data) => {
    const rest = pick(data, ['amount', 'estimated_weight', 'condition', 'loading_commitment_id']);
    const resource = {
      ...rest,
      estimated_weight: formatWeightToBackEndFormat(rest.estimated_weight, measurementSystem),
      migration_type: 'placement',
      barn_ids: data.barns.map((barn) => barn.id),
      comments_pig_migrations: { text: data.comment || '', mentioned_user_ids: [] },
      delivery_date: data.delivery_date?.format('YYYY-MM-DD') || '',
    };

    if ((resource.condition === 'poor') && !data.comment) {
      showToastrMessage('component.toastr.shipment.poorPigsNote', 'error', 'fa fa-ban');
      return;
    }

    handleStartGroup(resource);
  };

  const onMobileWizardSubmit = (resource) => {
    const enhancedResource = {
      ...resource,
      estimated_weight: formatWeightToBackEndFormat(resource.estimated_weight, measurementSystem),
    };
    return handleStartGroup(enhancedResource);
  };

  useEffect(() => {
    if (pigGroup?.id === +id) {
      setBreadcrumbs([
        { label: <FormattedMessage id="general.pageTitle.checkups" />, path: '/daily-checkup' },
        { label: pigGroup.farm.name, path: `/daily-checkup/farms/${pigGroup.farm_id}` },
        { label: pigGroup.name, useLabelAsMobileTitle: true },
      ]);
      setPageOptions({ backLink: `/daily-checkup/farms/${pigGroup.farm_id}` });
    }
  }, [setBreadcrumbs, pigGroup, id]);

  useEffect(() => {
    return () => {
      setPageOptions({ backLink: '' });
    };
  }, []);

  useEffect(() => {
    if (!isLogisticsEnabled && isSelectLoadPage) {
      router.push(location.pathname);
    }
  }, [isLogisticsEnabled, isSelectLoadPage]);

  useEffect(() => {
    if (isMobileWizardShouldOpen && pigGroup?.id) {
      openPortal(
        <CreateShipmentMobileWizard group={pigGroup} onSubmit={onMobileWizardSubmit} />
      );
      setIsMobileWizardShouldOpen(false);
    }
  }, [isMobileWizardShouldOpen, pigGroup]);

  const getCommitmentsCount = (count) => {
    if (count === 0) {
      if (isMobile) {
        setIsMobileWizardShouldOpen(true);
      } else {
        router.push(location.pathname);
      }
    }
  };

  const openMobileWizard = (commitment) => openPortal(
    <CreateShipmentMobileWizard group={pigGroup} onSubmit={onMobileWizardSubmit} commitment={commitment} />
  );

  if (isSelectLoadPage && isLogisticsEnabled) return (
    <DailyCheckupPigGroupCommitments
      getCommitmentsCount={getCommitmentsCount}
      openMobileWizard={openMobileWizard}
      pigGroupId={id}
      router={router}
      location={location}
    />
  );

  if (!isSelectLoadPage) return (
    <DailyCheckupStartGroup
      commitmentId={commitmentId}
      pigGroup={pigGroup}
      location={location}
      measurementSystem={measurementSystem}
      submitForm={submitForm}
      onSubmit={onSubmit}
    />
  );

  return null;
};

DailyCheckupStartGroupSwitcher.propTypes = {
  isLogisticsEnabled: T.bool.isRequired,
  openPortal: T.func.isRequired,
  location: T.object.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  startGroup: T.func.isRequired,
  submitForm: T.func.isRequired,
  measurementSystem: T.string.isRequired,
};

export default connect(
  (state) => ({
    isLogisticsEnabled: state.auth.user.current_company.logistics,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), {
    openPortal,
    setBreadcrumbs,
    setPageOptions,
    startGroup,
    submitForm,
  }
)(DailyCheckupStartGroupSwitcher);
