import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
// utils
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import pick from 'lodash.pick';
import {
  entityNameSections,
  sectionEntityKeys,
  sectionFieldsToCreate,
  sectionFieldsToUpdate,
  weightSections,
  userFilterSectionTypes,
} from './constants/userFilters';
import {
  isBarnSheetsFarmsPage,
  isBarnSheetsGroupsPage,
  isFarmFeedPage,
  isMySchedulePage,
  isSVRFarmsPage,
} from './locationHelper';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from './weightHelper';
import { tableNames } from './constants';

export function getLabelId(lastKey) {
  return `component.advancedSearchItem.${lastKey}`;
}

export function getLabelComponent(lastKey) {
  return <FormattedMessage id={getLabelId(lastKey)} />;
}

export function isRelativeDateCondition(condition) {
  return ['gt_days_ago', 'eq_days_ago', 'lt_days_ago'].includes(condition);
}

export function isAbsoluteDateCondition(condition) {
  return ['gt', 'eq', 'lt'].includes(condition);
}

export function isMultiItemsCondition(condition) {
  return ['cont_all_of', 'cont_any_of', 'cont_none_of'].includes(condition);
}

export function getSectionOptionValueKey(sectionType) {
  switch (sectionType) {
    case userFilterSectionTypes.dcDiagnoses:
      return 'diagnosis_ids';
    case userFilterSectionTypes.farmType:
      return 'farm_type';
    case userFilterSectionTypes.loadType:
      return 'load_type';
    case userFilterSectionTypes.loadingStatus:
    case userFilterSectionTypes.commitmentStatus:
      return 'status';
    default:
      return 'text';
  }
}

export function getSectionInputLabel(sectionType) {
  switch (sectionType) {
    case userFilterSectionTypes.currentWaterUsage:
      return <FormattedMessage id="general.gallons" />;
    case userFilterSectionTypes.dcInventory:
    case userFilterSectionTypes.pigsCount:
    case userFilterSectionTypes.totalDeaths:
      return <FormattedMessage id="general.pigs" />;
    case userFilterSectionTypes.dcEstimatedWeight:
    case userFilterSectionTypes.weight:
      return <FormattedWeight hasOnlyUnit />;
    case userFilterSectionTypes.openPigGroupsCount:
      return <FormattedMessage id="general.groups" />;
    default:
      return undefined;
  }
}

export function formatOptionsValues(options = []) {
  const optionValues = {};
  options.forEach(({ key, value }) => {
    optionValues[key] = value;
  });
  return optionValues;
}

export function isEqualFilters(currentFilter, nextFilter) {
  if (currentFilter.any_condition !== nextFilter.any_condition) return false;
  const currentSections = currentFilter.sections || [];
  const nextSections = nextFilter.sections || [];
  if (currentSections.length !== nextSections.length) return false;
  return currentSections.every((section, index) => {
    const { id, condition, type, options, target_id } = section;
    const { id: nextId, condition: nextCondition, type: nextType, options: nextOptions,
      target_id: nextTargetId } = nextSections[index];
    const formattedOptions = formatOptionsValues(options);
    const formattedNextOptions = formatOptionsValues(nextOptions);
    return id === nextId && condition === nextCondition && type === nextType && target_id === nextTargetId
      && isEqual(formattedOptions, formattedNextOptions);
  });
}

export function isEmptyUserFilterByPathname(state, pathname) {
  if (isFarmFeedPage(pathname)) {
    return isEmpty(state.farmfeed.filter);
  }
  if (isMySchedulePage(pathname)) {
    return isEmpty(state.tableParams[tableNames.allCommitments]?.filters?.[0]);
  }
  if (isBarnSheetsFarmsPage(pathname)) {
    return isEmpty(state.tableParams[tableNames.barnSheetsFarms]?.filters?.[0]);
  }
  if (isBarnSheetsGroupsPage(pathname)) {
    return isEmpty(state.tableParams[tableNames.barnSheetsGroups]?.filters?.[0]);
  }
  if (isSVRFarmsPage(pathname)) {
    return isEmpty(state.tableParams[tableNames.siteVisitReportsFarms]?.filters?.[0]);
  }
  return true;
}

export function formatFilterSectionOptions(section, formatter) {
  const { type, condition } = section;
  const options = section.options || [];
  if (weightSections.includes(type) && (condition !== 'any')) {
    return options.map((item) => ({ ...item, value: formatter(item.value) }));
  }
  return options;
}

export function formatSectionOptionsForSave(section, measurementSystem) {
  if (weightSections.includes(section.type) && (section.condition !== 'any')) {
    return section.options?.map((item) => ({
      ...item,
      value: formatWeightToBackEndFormat(item.value, measurementSystem),
    })) || [];
  }
  return section.options || [];
}

export function formatSectionOptionsForView(section, measurementSystem) {
  if (weightSections.includes(section.type) && (section.condition !== 'any')) {
    return section.options?.map((item) => ({
      ...item,
      value: formatBackEndWeightToSystem(item.value, measurementSystem),
    })) || [];
  }
  return section.options || [];
}

// it's for user, farm, group, source, company sections
export function getTargetData(section) {
  if (entityNameSections.includes(section.type)) {
    const entity = section[sectionEntityKeys[section.type]];
    return {
      [sectionEntityKeys[section.type]]: entity || {},
      target_id: entity?.id,
    };
  }
  return {};
}

export function getNecessarySections(sectionsToSave, isExistingFilter, isCreateFromCurrent, optionValueFormatter) {
  if (!isExistingFilter || !isCreateFromCurrent) {
    return sectionsToSave.map((item) => pick({
      ...item,
      options: formatFilterSectionOptions(item, (value) => optionValueFormatter(value, true)),
    }, sectionFieldsToUpdate));
  }

  // need for create new one from existed
  return sectionsToSave
    .filter(({ _destroy }) => !_destroy)
    .map((item) => pick({
      ...item,
      options: formatFilterSectionOptions(item, (value) => optionValueFormatter(value, true)),
    }, sectionFieldsToCreate));
}

export function isInvalidSectionData(filter, condition, options) {

  if (!condition) return true;

  const optionsValues = formatOptionsValues(options);

  switch (filter.type) {
    case userFilterSectionTypes.farmLocation:
    case userFilterSectionTypes.farmExternalLinkId:
    case userFilterSectionTypes.farmFederalPremiseId:
      return condition !== 'any' && !optionsValues.text;
    case userFilterSectionTypes.commitmentStatus:
    case userFilterSectionTypes.loadingStatus:
      return ['eq', 'not_eq'].includes(condition) && !optionsValues.status;
    case userFilterSectionTypes.loadType:
      return ['eq', 'not_eq'].includes(condition) && !optionsValues.load_type;
    case userFilterSectionTypes.farmType:
      return ['eq', 'not_eq'].includes(condition) && !(optionsValues.source_type || optionsValues.farm_type);
    case userFilterSectionTypes.farm:
    case userFilterSectionTypes.user:
    case userFilterSectionTypes.pigGroup:
    case userFilterSectionTypes.load:
    case userFilterSectionTypes.company:
    case userFilterSectionTypes.managementCompany:
    case userFilterSectionTypes.latestSvrCreatedBy: {
      return ['only', 'except'].includes(condition)
        ? isEmpty(filter[sectionEntityKeys[filter.type]])
        : !optionsValues.text;
    }
    case userFilterSectionTypes.avgTreated:
    case userFilterSectionTypes.compliance:
    case userFilterSectionTypes.currentWaterUsage:
    case userFilterSectionTypes.dcEstimatedWeight:
    case userFilterSectionTypes.dcInventory:
    case userFilterSectionTypes.avgMortalityRate:
    case userFilterSectionTypes.dcMortalityRate:
    case userFilterSectionTypes.mortalityRate:
    case userFilterSectionTypes.totalDeaths:
    case userFilterSectionTypes.pigsCount:
    case userFilterSectionTypes.openPigGroupsCount:
    case userFilterSectionTypes.weight: {
      const isEmptyRangeValues = !(optionsValues.value_start || optionsValues.count_from)
        && !(optionsValues.value_start || optionsValues.count_to);
      const isEmptyRange = condition === 'range' && isEmptyRangeValues;
      const isEmptyCount = !['range', 'any'].includes(condition) && !(optionsValues.value || optionsValues.count);
      return isEmptyRange || isEmptyCount;
    }
    case userFilterSectionTypes.arriveAt:
    case userFilterSectionTypes.date:
    case userFilterSectionTypes.latestSvrCreatedAt:
    case userFilterSectionTypes.pigGroupStartDate:
    case userFilterSectionTypes.startDate:
    case userFilterSectionTypes.closeDate:
    case userFilterSectionTypes.pigGroupCloseDate: {
      const isEmptyDays = isRelativeDateCondition(condition) && !optionsValues.days;
      const isEmptyDate = isAbsoluteDateCondition(condition) && !optionsValues.date;
      return isEmptyDate || isEmptyDays;
    }
    case userFilterSectionTypes.dcDiagnoses: {
      return isMultiItemsCondition(condition) && !optionsValues.diagnosis_ids?.length;
    }
    default:
      return false;
  }
}

export function getConditionTranslationsBySection(sectionType) {
  switch (sectionType) {
    case userFilterSectionTypes.arriveAt:
      return {
        lt_days_ago: getLabelId('arrivedMoreThan'),
        eq_days_ago: getLabelId('arrivedExactly'),
        gt_days_ago: getLabelId('arrivedLessThan'),
        gt: getLabelId('arrivedAfter'),
        eq: getLabelId('arrivedOn'),
        lt: getLabelId('arrivedBefore'),
        range: getLabelId('arrivedRange'),
      };
    case userFilterSectionTypes.date:
      return {
        lt_days_ago: getLabelId('dateMoreThan'),
        eq_days_ago: getLabelId('dateExactly'),
        gt_days_ago: getLabelId('dateLessThan'),
        gt: getLabelId('dateAfter'),
        eq: getLabelId('dateOn'),
        lt: getLabelId('dateBefore'),
        range: getLabelId('dateRange'),
      };
    case userFilterSectionTypes.pigGroupStartDate:
    case userFilterSectionTypes.startDate:
      return {
        lt_days_ago: getLabelId('startedMoreThan'),
        eq_days_ago: getLabelId('startedExactly'),
        gt_days_ago: getLabelId('startedLessThan'),
        gt: getLabelId('startedAfter'),
        eq: getLabelId('startedOn'),
        lt: getLabelId('startedBefore'),
        range: getLabelId('startedRange'),
      };
    case userFilterSectionTypes.closeDate:
    case userFilterSectionTypes.pigGroupCloseDate:
      return {
        lt_days_ago: getLabelId('closedMoreThan'),
        eq_days_ago: getLabelId('closedExactly'),
        gt_days_ago: getLabelId('closedLessThan'),
        gt: getLabelId('closedAfter'),
        eq: getLabelId('closedOn'),
        lt: getLabelId('closedBefore'),
        range: getLabelId('closedRange'),
      };
    case userFilterSectionTypes.latestSvrCreatedAt:
      return {
        lt_days_ago: getLabelId('latestReportDateMoreThan'),
        eq_days_ago: getLabelId('latestReportDateExactly'),
        gt_days_ago: getLabelId('latestReportDateLessThan'),
        gt: getLabelId('latestReportDateAfter'),
        eq: getLabelId('latestReportDateOn'),
        lt: getLabelId('latestReportDateBefore'),
        range: getLabelId('latestReportDateRange'),
      };
    case userFilterSectionTypes.avgMortalityRate:
    case userFilterSectionTypes.dcMortalityRate:
    case userFilterSectionTypes.mortalityRate:
      return {
        gt: getLabelId('mortalityRateGreaterThan'),
        lt: getLabelId('mortalityRateLessThan'),
        eq: getLabelId('mortalityRateIs'),
        not_eq: getLabelId('mortalityRateIsNot'),
        range: getLabelId('mortalityRateHasRange'),
        any: getLabelId('mortalityRateHasAnyValue'),
      };
    case userFilterSectionTypes.dcEstimatedWeight:
    case userFilterSectionTypes.weight:
      return {
        gt: getLabelId('weightGreaterThan'),
        lt: getLabelId('weightLessThan'),
        eq: getLabelId('weightIs'),
        not_eq: getLabelId('weightIsNot'),
        range: getLabelId('weightHasRange'),
        any: getLabelId('weightHasAnyValue'),
      };
    case userFilterSectionTypes.avgTreated:
      return {
        gt: getLabelId('treated24hGreaterThan'),
        lt: getLabelId('treated24hLessThan'),
        eq: getLabelId('treated24hIs'),
        not_eq: getLabelId('treated24hIsNot'),
        range: getLabelId('treated24hHasRange'),
        any: getLabelId('treated24hHasAnyValue'),
      };
    case userFilterSectionTypes.currentWaterUsage:
      return {
        gt: getLabelId('gallonsConsumedGreaterThan'),
        lt: getLabelId('gallonsConsumedLessThan'),
        eq: getLabelId('gallonsConsumedIs'),
        not_eq: getLabelId('gallonsConsumedIsNot'),
        range: getLabelId('gallonsConsumedHasRange'),
        any: getLabelId('gallonsConsumedHasAnyValue'),
      };
    case userFilterSectionTypes.openPigGroupsCount:
      return {
        gt: getLabelId('openGroupsGreaterThan'),
        lt: getLabelId('openGroupsLessThan'),
        eq: getLabelId('openGroupsIs'),
        not_eq: getLabelId('openGroupsIsNot'),
        range: getLabelId('openGroupsHasRange'),
        any: getLabelId('openGroupsHasAnyValue'),
      };
    case userFilterSectionTypes.totalDeaths:
      return {
        gt: getLabelId('mortalityGreaterThan'),
        lt: getLabelId('mortalityLessThan'),
        eq: getLabelId('mortalityIs'),
        not_eq: getLabelId('mortalityIsNot'),
        range: getLabelId('mortalityHasRange'),
        any: getLabelId('mortalityHasAnyValue'),
      };
    case userFilterSectionTypes.dcInventory:
    case userFilterSectionTypes.pigsCount:
      return {
        gt: getLabelId('inventoryGreaterThan'),
        lt: getLabelId('inventoryLessThan'),
        eq: getLabelId('inventoryIs'),
        not_eq: getLabelId('inventoryIsNot'),
        range: getLabelId('inventoryHasRange'),
        any: getLabelId('inventoryHasAnyValue'),
      };
    case userFilterSectionTypes.compliance:
      return {
        gt: getLabelId('complianceGreaterThan'),
        lt: getLabelId('complianceLessThan'),
        eq: getLabelId('complianceIs'),
        not_eq: getLabelId('complianceIsNot'),
        range: getLabelId('complianceHasRange'),
        any: getLabelId('complianceHasAnyValue'),
      };
    case userFilterSectionTypes.farmExternalLinkId:
      return {
        eq: getLabelId('farmIDIs'),
        not_eq: getLabelId('farmIDIsNot'),
        start: getLabelId('farmIDStartsWith'),
        end: getLabelId('farmIDEndsWith'),
        cont: getLabelId('farmIDContains'),
        not_cont: getLabelId('farmIDDoesNotContain'),
        any: getLabelId('farmIDHasAnyValue'),
      };
    case userFilterSectionTypes.farmFederalPremiseId:
      return {
        eq: getLabelId('premiseIDIs'),
        not_eq: getLabelId('premiseIDIsNot'),
        start: getLabelId('premiseIDStartsWith'),
        end: getLabelId('premiseIDEndsWith'),
        cont: getLabelId('premiseIDContains'),
        not_cont: getLabelId('premiseIDDoesNotContain'),
        any: getLabelId('premiseIDHasAnyValue'),
      };
    case userFilterSectionTypes.farmLocation:
      return {
        eq: getLabelId('locationIs'),
        not_eq: getLabelId('locationIsNot'),
        start: getLabelId('locationStartsWith'),
        end: getLabelId('locationEndsWith'),
        cont: getLabelId('locationContains'),
        not_cont: getLabelId('locationDoesNotContain'),
        any: getLabelId('locationHasAnyValue'),
      };
    case userFilterSectionTypes.farm:
      return {
        only: getLabelId('farmIs'),
        except: getLabelId('farmIsNot'),
        start: getLabelId('farmStartsWith'),
        end: getLabelId('farmEndsWith'),
        cont: getLabelId('farmContains'),
        not_cont: getLabelId('farmDoesNotContain'),
      };
    case userFilterSectionTypes.farmType:
      return {
        eq: getLabelId('farmTypeIs'),
        not_eq: getLabelId('farmTypeIsNot'),
        any: getLabelId('farmTypeHasAnyValue'),
        none: getLabelId('farmTypeIsUnknown'),
      };
    case userFilterSectionTypes.loadType:
      return {
        eq: getLabelId('loadTypeIs'),
        not_eq: getLabelId('loadTypeIsNot'),
        any: getLabelId('loadTypeHasAnyValue'),
      };
    case userFilterSectionTypes.commitmentStatus:
      return {
        eq: getLabelId('confirmationStatusIs'),
        not_eq: getLabelId('confirmationStatusIsNot'),
        any: getLabelId('confirmationStatusHasAnyValue'),
      };
    case userFilterSectionTypes.loadingStatus:
      return {
        eq: getLabelId('loadStatusIs'),
        not_eq: getLabelId('loadStatusIsNot'),
        any: getLabelId('loadStatusHasAnyValue'),
      };
    case userFilterSectionTypes.company:
    case userFilterSectionTypes.managementCompany:
      return {
        only: getLabelId('companyIs'),
        except: getLabelId('companyIsNot'),
        start: getLabelId('companyStartsWith'),
        end: getLabelId('companyEndsWith'),
        cont: getLabelId('companyContains'),
        not_cont: getLabelId('companyDoesNotContain'),
      };
    case userFilterSectionTypes.pigGroup:
      return {
        only: getLabelId('groupIs'),
        except: getLabelId('groupIsNot'),
        start: getLabelId('groupStartsWith'),
        end: getLabelId('groupEndsWith'),
        cont: getLabelId('groupContains'),
        not_cont: getLabelId('groupDoesNotContain'),
      };
    case userFilterSectionTypes.load:
      return {
        start: getLabelId('loadStartsWith'),
        end: getLabelId('loadEndsWith'),
        cont: getLabelId('loadContains'),
        not_cont: getLabelId('loadDoesNotContain'),
      };
    case userFilterSectionTypes.user:
      return {
        only: getLabelId('userIs'),
        except: getLabelId('userIsNot'),
      };
    case userFilterSectionTypes.latestSvrCreatedBy:
      return {
        only: getLabelId('latestReportAuthorIs'),
        except: getLabelId('latestReportAuthorIsNot'),
      };
    case userFilterSectionTypes.dcDiagnoses:
      return {
        cont_all_of: getLabelId('diagnosesCount'),
        cont_any_of: getLabelId('diagnosesCount'),
        cont_none_of: getLabelId('diagnosesCount'),
        any: getLabelId('diagnosesHasAnyValue'),
        none: getLabelId('diagnosesHasNoValue'),
      };
    default:
      return {};
  }
}
