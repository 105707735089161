import React, { Component, Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal, closeModal } from 'reducers/modals';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import CreationDateSection from './components/CreationDateSection';
import OwnershipSection from './components/OwnershipSection';
import FarmSection from './components/FarmSection';
import DeliveryDateRangeSection from './components/DeliveryDateRangeSection';
import SourceSection from './components/SourceSection';
import CustomGroupNameModal from '../components/CustomGroupNameModal';
import CommitmentsAssigningSection from './components/CommitmentsAssigningSection/CommitmentsAssigningSection';
// utils
import { getInitials, formatFarmOptionLabel, showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import classnames from 'classnames/bind';
// groups api
import {
  createAdminPigGroup,
  getAdminPigGroup,
  publishAdminPigGroup,
  updateAdminPigGroup,
} from 'endpoints/admin/pigGroups';
// styles, assets
import styles from './GroupCreate.module.scss';

const cn = classnames.bind(styles);

const initialFields = {
  created_on: null,
  company: {},
  farm: {},
  delivery_start: null,
  delivery_end: null,
  source: {},
};

class GroupCreate extends Component {

  state = {
    draft: initialFields,
    groupIdPlaceholder: getInitials(this.props.user.current_company.name) + '-0000-0000',
    openedSection: null,
    isLoading: false,
  };

  componentDidMount() {
    this.fetchPigGroupDraft();
  }

  componentDidUpdate(prevProps) {
    const { location: { query } } = this.props;
    if (isEmpty(query) && query !== prevProps.location.query) {
      this.setState({
        draft: initialFields,
        openedSection: null,
      });
    }
  }

  onOpenSection = (openedSection) => () => {
    this.setState({ openedSection });
  };

  onCancel = () => {
    this.setState({ openedSection: null });
  };

  onCustomNameModalOpen = () => {
    const { closeModal, openModal } = this.props;
    const { draft } = this.state;
    openModal(
      <CustomGroupNameModal
        initialName={draft.name}
        onClose={closeModal}
        onSubmit={this.savePigGroupDraft}
      />
    );
  };

  savePigGroupDraft = (data) => {
    const { location: { query } } = this.props;
    return (isEmpty(query))
      ? this.createPigGroupDraft(data)
      : this.updatePigGroupDraft(data);
  };

  formatFields = ({
    name, created_on, company, farm, delivery_start, delivery_end, external_source, source_entities
  }) => ({
    name,
    delivery_start: delivery_start ? moment(delivery_start) : null,
    delivery_end: delivery_end ? moment(delivery_end) : null,
    created_on: moment(created_on),
    company: company || {},
    farm: farm ? { value: farm.id, label: formatFarmOptionLabel(farm), name: farm.name } : {},
    source: external_source || {},
    source_entities,
  });

  publishGroup = () => {
    const { router, location: { query: { draftId } } } = this.props;

    publishAdminPigGroup(draftId)
      .then(() => {
        showToastrMessage('component.toastr.group.wasCreated');
        router.push('/groups');
      })
      .catch(toastResponseErrors);
  };

  createPigGroupDraft = (data) => {
    this.setState({ isLoading: true });
    createAdminPigGroup(data)
      .then((resource) => {
        this.setState({
          openedSection: null,
          isLoading: false,
          draft: this.formatFields(resource),
        });
        const { location: { pathname } } = this.props;
        this.props.router.push({ pathname, query: { draftId: resource.id } });
      })
      .catch((response) => {
        this.setState({ isLoading: false });
        toastResponseErrors(response);
      });
  };

  updatePigGroupDraft = (data) => {
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    updateAdminPigGroup(query.draftId, data)
      .then((resource) => {
        this.setState({
          openedSection: null,
          isLoading: false,
          draft: this.formatFields(resource),
        });
      })
      .catch((response) => {
        this.setState({ isLoading: false });
        toastResponseErrors(response);
      });
  };

  fetchPigGroupDraft = () => {
    const { location: { query } } = this.props;
    if (!isEmpty(query)) {
      this.setState({ isLoading: true });
      getAdminPigGroup(query.draftId)
        .then((resource) => {
          this.setState({
            openedSection: null,
            isLoading: false,
            draft: this.formatFields(resource),
          });
        })
        .catch((response) => {
          this.setState({ isLoading: false });
          toastResponseErrors(response);
        });
    }
  };

  renderSectionProps = (key) => ({
    className: cn('field-section'),
    isOpened: this.state.openedSection === key,
    isDisabled: this.state.openedSection && (this.state.openedSection !== key),
    onOpen: this.onOpenSection(key),
    onSave: this.savePigGroupDraft,
    onClose: this.onCancel,
  });

  render() {
    const { isLoading, draft, groupIdPlaceholder } = this.state;
    const { location: { query }, isLogisticsEnabled } = this.props;

    return (
      <div>
        <div className={cn('group-header')}>
          <div className={cn('name-block')}>
            <span className={cn('name')}>
              {draft.name || groupIdPlaceholder}
              {query.draftId && (
                <span className={cn('badge')}>
                  <FormattedMessage id="general.status.draft" />
                </span>
              )}
            </span>
            <span className={cn('add-custom-group-btn')} onClick={this.onCustomNameModalOpen}>
              <FormattedMessage id="component.groupCreate.useCustomGroupId" />
            </span>
          </div>
          <div className={cn('actions-block')}>
            <Link className={cn(query.draftId ? 'finish-later-btn' : 'button', 'mr-10')} to="/groups">
              <FormattedMessage id={`general.button.${query.draftId ? 'finishLater' : 'cancel'}`} />
            </Link>
            <Button disabled={isEmpty(draft.farm) || isEmpty(draft.company)} primary onClick={this.publishGroup}>
              <FormattedMessage id="general.button.createGroup" />
            </Button>
          </div>
        </div>
        <section className="small-12 column">
          <Panel>
            <Panel.Body noPadding>
              <Preloader isActive={isLoading} />
              <CreationDateSection
                data={draft.created_on}
                {...this.renderSectionProps('creationDate')}
              />
              <OwnershipSection
                data={draft.company}
                {...this.renderSectionProps('ownership')}
              />
              <FarmSection
                data={draft.farm}
                {...this.renderSectionProps('farm')}
              />
              {isLogisticsEnabled && (
                <CommitmentsAssigningSection
                  {...this.renderSectionProps('load')}
                  isDisabled={isEmpty(draft.farm)}
                  groupId={query.draftId}
                  data={draft.farm}
                />
              )}
              {!isLogisticsEnabled && (
                <Fragment>
                  <DeliveryDateRangeSection
                    startDate={draft.delivery_start}
                    endDate={draft.delivery_end}
                    {...this.renderSectionProps('deliveryDates')}
                  />
                  <SourceSection
                    data={draft.source_entities}
                    {...this.renderSectionProps('source')}
                  />
                </Fragment>
              )}
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

GroupCreate.propTypes = {
  user: T.object,
  location: T.object.isRequired,
  router: T.object.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isLogisticsEnabled: state.auth.user.current_company.logistics,
    user: state.auth.user,
  }), { openModal, closeModal }
)(GroupCreate);
