import React, { useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import TreatmentStepCard from 'components/TreatmentStepCard';
import NothingBox from 'components/NothingBox';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// constants
import { tableNames } from 'utils/constants';
// utils
import cn from 'classnames';
// api
import { getPigGroupDailyCheckupTreatmentProtocolProductSteps } from 'endpoints/treatmentProtocols';

const PigGroupTreatmentsPanel = ({ pigGroup, setTableParams, tableParams, isOnline }, { router }) => {
  const {
    data: { resources, isLoading, meta: { total } },
    fetchData,
    onPageChange,
    onPerPageChange,
  } = useDataTable((params) => getPigGroupDailyCheckupTreatmentProtocolProductSteps(pigGroup.id, params), {
    setTableParams: (params) => setTableParams(tableNames.pigGroupTreatments, params),
    tableParams,
  });

  useEffect(() => {
    if (isOnline) fetchData(tableParams);
  }, [isOnline]);

  const onTreatmentStepSubmit = (treatmentStepData) => {
    const { params: { id: dailyCheckupId } } = router;
    const { id } = treatmentStepData;
    router.push(`/daily-checkup/${dailyCheckupId}/report-treatment/${id}`);
  };

  return (
    <div className="row centered pv-20">
      <div className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.treatments" />} />
          <Panel.Body className="ph-20">
            <Preloader isActive={isLoading} />
            {resources.map((treatmentStepData, idx) => (
              <TreatmentStepCard
                key={treatmentStepData.id}
                onSubmit={() => onTreatmentStepSubmit(treatmentStepData)}
                treatmentStepData={treatmentStepData}
                pigGroup={pigGroup}
                className={cn('mb-10', { 'mt-10': idx !== 0 })}
              />
            ))}

            <NothingBox display={!resources.length}>
              <h2 className="lighter semibold">
                <FormattedMessage id="component.nothingBox.noTreatments" />
              </h2>
            </NothingBox>

            {!!resources.length && (
              <PaginationWrapper
                onlyBottom
                totalItems={total}
                currentPage={tableParams.page}
                onPageChange={onPageChange}
                perPage={tableParams.per_page}
                onPerPageChange={onPerPageChange}
              />
            )}
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

PigGroupTreatmentsPanel.propTypes = {
  pigGroup: T.object.isRequired,
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  isOnline: T.bool.isRequired,
};

PigGroupTreatmentsPanel.contextTypes = {
  router: T.object.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.pigGroupTreatments),
    isOnline: state.network.isOnline,
  }), {
    setTableParams,
  }
)(PigGroupTreatmentsPanel);
