import React from 'react';
import T from 'prop-types';
import './CircleCheckbox.scss';
import classnames from 'classnames';

const CircleCheckbox = ({ label, subLabel, className, type, name, checked, ...props }) => {
  const isRadio = type === 'radio';

  return (
    <label
      className={classnames(className, 'CircleCheckbox circle-checkbox', {
        'disabled': props.disabled,
        'checked': checked,
      })}
    >
      <div className="inner-wrapper">
        <input type={type} name={name} checked={checked} {...props} />
        <span className="icon">
          <i className={`checked fa ${isRadio ? 'fa-radio-button-checked' : 'fa-check-circle-bts'}`} />
          <i className={`unchecked fa ${isRadio ? 'fa-radio-button-unchecked' : 'fa-radio-unchecked'}`} />
        </span>
        <span className="checkbox-label">
          {label}
          {subLabel && <span className="sub-label">{subLabel}</span>}
        </span>
      </div>

    </label>
  );
};

CircleCheckbox.defaultProps = {
  type: 'checkbox',
  className: '',
  checked: false,
  onChange: () => null,
};

CircleCheckbox.propTypes = {
  type: T.string,
  className: T.string,
  checked: T.bool,
  label: T.oneOfType([T.string, T.object]),
  subLabel: T.string,
  name: T.string,
  disabled: T.bool,
  onChange: T.func,
};

export default CircleCheckbox;
