import React from 'react';
import T from 'prop-types';
// components
import Avatar from 'components/Avatar/Avatar';
import FarmTypeLabel from 'components/FarmTypeLabel';
import { GroupStatusBadge } from 'components/StatusBadge/StatusBadge';
import ResponsiveDropdown from 'components/ResponsiveDropdown';
import Preloader from 'components/Preloader';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge';
import TextLabel from '../../TextLabel/TextLabel';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import { ThreeDotsBtn } from 'components/Button/Button';
import PlusMoreHint from 'components/PlusMoreHint';
import ProfilePlaceholder from '../ProfilePlaceholder';
// utils
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
// styles
import './GroupProfileHeader.scss';

const GroupProfileHeader = ({ className, isSticky, children, dropdownOptions, isLoading, group, onEditName }) => {
  const groupFarm = group?.farm || {};
  const externalSource = group?.external_source || {};
  const textValues = { date: null };

  return (
    <div className={cn('GroupProfileHeader', className, { isSticky })}>
      <Preloader isActive={isLoading} />

      <ProfilePlaceholder isActive={isLoading && !group?.id}>
        <Avatar type="pig_group" avatarSize={50} iconSize={24} />

        <div className="group-information">
          <div className="group-info-wrapper">
            <span className="group-name">{group.name}</span>
            {onEditName &&
              <i className="fa fa-pencil-mdc" onClick={onEditName} />
            }
            <PigGroupMarketingBadge className="mr-5" pigGroup={group} isHintAttachedToBody />
            <GroupStatusBadge status={group.status} textValues={textValues} />
          </div>
          <div className="group-farm-info">
            <FormattedMessage id="general.farm" /> •&nbsp;
            <Link className="primary-link" to={`/barnsheets/farms/${groupFarm.id}/groups`}>{groupFarm.name}</Link>&nbsp;
            ({groupFarm.external_link_id || 'N/A'}) <FarmTypeLabel farmType={groupFarm.farm_type} />
            {' '}
            {group.barns && (
              <PlusMoreHint className="barns-line" items={group.barns}>
                <FormattedMessage id="general.barns" />
                {' • '}
              </PlusMoreHint>
            )}
            {!isEmpty(externalSource) && (
              <div>
                <FormattedMessage id="general.source" /> •&nbsp;
                <Link className="primary-link" to={`/sources/${externalSource.id}`}>
                  {externalSource.name || ''}
                </Link>&nbsp;
                <TextLabel className="fs-11">{externalSource.source_type}</TextLabel>
              </div>
            )}
          </div>
        </div>
      </ProfilePlaceholder>

      <div className="group-actions">
        {children}
        {!!dropdownOptions.length && (
          <ResponsiveDropdown
            options={dropdownOptions}
            className="group-profile-dropdown"
            trigger={<ThreeDotsBtn />}
          />
        )}
      </div>
    </div>
  );
};

GroupProfileHeader.propTypes = {
  group: T.object,
  className: T.string,
  isSticky: T.bool,
  children: T.any,
  isLoading: T.bool,
  dropdownOptions: T.array,
  onEditName: T.func,
};

GroupProfileHeader.defaultProps = {
  className: '',
  children: null,
  dropdownOptions: [],
  group: {},
};

export default GroupProfileHeader;
