import React from 'react';
import { FormattedMessage } from 'react-intl';

const periodLabelId = 'general.timeOptions.everyDaysPeriod';

export const SVRFrequencies = [
  { label: <FormattedMessage id="general.timeOptions.everyWeek" />, value: '0' },
  { label: <FormattedMessage id={periodLabelId} values={{ daysCount: 14 }} />, value: '1' },
  { label: <FormattedMessage id={periodLabelId} values={{ daysCount: 30 }} />, value: '2' },
  { label: <FormattedMessage id={periodLabelId} values={{ daysCount: 45 }} />, value: '3' },
  { label: <FormattedMessage id={periodLabelId} values={{ daysCount: 60 }} />, value: '4' },
  { label: <FormattedMessage id={periodLabelId} values={{ daysCount: 75 }} />, value: '5' },
  { label: <FormattedMessage id={periodLabelId} values={{ daysCount: 90 }} />, value: '6' },
];

export const SVRFarmfeedTriggers = [
  { label: <FormattedMessage id="general.none" />, value: 'none' },
  { label: <FormattedMessage id="general.important" />, value: 'important' },
  { label: <FormattedMessage id="component.advancedSearchItem.all" />, value: 'all' },
];
