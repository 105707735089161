import { fetchFromAPI } from 'utils/api';

export const downloadPigGroupCSV = (id) => {
  return fetchFromAPI(`/csv/pig_groups/${id}`, { blob: true })
    .catch((response) => {
      throw response;
    });
};

export const downloadAllPigGroupsCSV = () => {
  return fetchFromAPI('/csv/pig_groups', { blob: true })
    .catch((response) => {
      throw response;
    });
};

export const checkPigGroupName = (name) => {
  return fetchFromAPI('/pig_group_names', { params: { name } });
};

export const getPigGroup = (id) => {
  return fetchFromAPI(`/pig_groups/${id}`)
    .then((response) => response.resource);
};

export const getPigGroupMigrations = (id, params) => {
  return fetchFromAPI(`/pig_groups/${id}/pig_migrations`, { params })
    .catch((response) => {
      throw response;
    });
};

export const createPigGroupMigration = (id, bodyParams) => {
  return fetchFromAPI(`/pig_groups/${id}/confirm_placement`, { method: 'POST', body: JSON.stringify(bodyParams) })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const makePigGroupCorrections = (id, bodyParams) => {
  return fetchFromAPI(`/pig_groups/${id}/save_confirm_placement_draft`, {
    method: 'POST',
    body: JSON.stringify(bodyParams),
  })
    .catch((response) => {
      throw response;
    });
};

export const getGroupFlow = (groupId) => (
  fetchFromAPI(`/pig_groups/${groupId}/flow_records`)
    .catch((response) => {
      throw response;
    })
);

export const requestToCloseGroup = (id) => {
  return fetchFromAPI(`/pig_groups/${id}/request_close`, { method: 'post' })
    .catch((response) => {
      throw response;
    });
};

export const makePigGroupMarketing = (id) => {
  return fetchFromAPI(`/pig_groups/${id}/marketing`, { method: 'post' })
    .catch((response) => {
      throw response;
    });
};

export const downloadGroupAllTreatmentEfficacyCSV = (id) => {
  return fetchFromAPI(`/pig_groups/${id}/treatment_efficacies/csv`, { blob: true })
    .catch((response) => {
      throw response;
    });
};

export const getPigGroupWaterUsageCSV = (pigGroupId, params) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/daily_checkup_water_usages/csv`, { blob: true, params });
};

export function fetchPigGroupSalesResults(pigGroupId, params) {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/sales_results`, { params }).catch((errors) => {
    throw errors;
  });
}

export const getPigGroupCommitments = (pigGroupId, params) => {
  return fetchFromAPI(`/pig_groups/${pigGroupId}/commitments/schedule`, { params });
};

export const searchPigGroups = (params) => {
  return fetchFromAPI('/pig_groups/search', { params });
};
