import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setCurrentCompany } from 'reducers/auth';
// api
import { getTenantSettings, updateTenantSettings } from 'endpoints/tenantSettings';
// utils
import { toastResponseErrors } from '../../../utils/responseErrorsHelper';
import pick from 'lodash.pick';
import moment from 'moment';
import { formatBackEndWeightToSystem } from 'utils/weightHelper';
import { showToastrMessage } from 'utils';
// components
import Preloader from '../../../components/Preloader';
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';
// styles
import './Preferences.scss';

const Preferences = ({ children, setCurrentCompany, isSVRFeatureEnabled }) => {
  const [tenant_settings, setTenantSettings] = useState({
    settings: {
      measurement_system: 'imperial',
      temperature_system: 'fahrenheit',
      sms_compliance: false,
      inventory_reconciliation_active: true,
      inventory_reconciliation: 'mon',
      auto_report_emails: false,
      auto_report_period: 'weekly',
      withdrawal_tracking_on: false,
      comment_company_direct_post: false,
      withdrawal_weight_limit: 200,
      new_withdrawal_weight_limit: 200,
      track_mortality_reasons: false,
      company_withdrawal_warning_recipients: [],
      water_consumption_monitoring: false,
      water_consumption_drop_limit: 0,
      surveys: [{}],
    },
    isLoading: false,
    isLoaded: false,
  });
  const [waterPercents, setPercent] = useState();

  const navLinks = [
    { link: '/admin/preferences/settings', label: <FormattedMessage id="general.settings" /> },
    { link: '/admin/preferences/daily-checkup', label: <FormattedMessage id="general.dailyCheckup" /> },
    { link: '/admin/preferences/integrations', label: <FormattedMessage id="general.integrations" /> },
    {
      link: '/admin/preferences/site-visit-reports',
      label: <FormattedMessage id="general.siteVisitReports" />,
      isHidden: !isSVRFeatureEnabled,
    }
  ];

  const setSettings = (data) => {
    const settings = pick(data, ['sms_compliance', 'inventory_reconciliation', 'auto_report_emails',
      'auto_report_period', 'withdrawal_tracking_on', 'withdrawal_weight_limit', 'track_mortality_reasons',
      'comment_company_direct_post', 'temperature_system', 'company_withdrawal_warning_recipients',
      'measurement_system', 'surveys', 'custom_survey', 'track_water_usage', 'track_temperature',
      'water_consumption_monitoring', 'water_consumption_drop_limit', 'map_centralize', 'map_lat', 'map_lng',
      'map_zoom', 'treatment_protocols', 'treatment_protocol_contacts']);
    settings.inventory_reconciliation_active = settings.inventory_reconciliation !== 'disabled';
    settings.new_withdrawal_weight_limit = formatBackEndWeightToSystem(
      settings.withdrawal_weight_limit,
      data.measurement_system
    );
    if (!settings.inventory_reconciliation_active) settings.inventory_reconciliation = 'mon';
    setCurrentCompany({
      auto_report_period: data.auto_report_period,
      withdrawal_tracking_on: data.withdrawal_tracking_on,
      track_mortality_reasons: data.track_mortality_reasons,
      comment_company_direct_post: data.comment_company_direct_post,
      temperature_system: data.temperature_system,
      measurement_system: data.measurement_system,
      track_water_usage: data.track_water_usage,
      track_temperature: data.track_temperature,
      map_centralize: data.map_centralize,
      map_lat: data.map_lat,
      map_lng: data.map_lng,
      map_zoom: data.map_zoom,
      treatment_protocols: data.treatment_protocols,
    });

    setTenantSettings({
      settings: {
        ...settings,
        surveys: settings.surveys ?
          settings.surveys.map((survey) => ({
            ...survey, start_date: moment(survey.start_date).format('MM/DD/YYYY')
          })) :
          [],
        withdrawal_weight_limit: formatBackEndWeightToSystem(settings.withdrawal_weight_limit, data.measurement_system),
      },
      isLoaded: true,
      isLoading: false,
    });

    setPercent(settings.water_consumption_drop_limit || 10);
  };

  useEffect(() => {
    setTenantSettings({
      ...tenant_settings,
      isLoading: true,
    });
    getTenantSettings()
      .then((data) => {
        setSettings(data);
      });
  }, []);

  const handleSetSettings = (data) => {
    setTenantSettings({
      ...tenant_settings,
      settings: {
        ...tenant_settings.settings,
        ...data,
      }
    });
  };

  const updateSettings = (resource) => {
    setTenantSettings({ ...tenant_settings, isLoading: true });
    updateTenantSettings(resource)
      .then((data) => {
        const [resourceKey] = Object.keys(resource);
        if (resourceKey === 'water_consumption_drop_limit') {
          showToastrMessage('component.setting.waterConsumptionMonitoringSaved');
        }
        setSettings(data);
      })
      .catch((errors) => {
        setTenantSettings({ ...tenant_settings, isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  const childrenWithProps = React.Children.map(children, (child) => child && React.cloneElement(child,
    {
      tenant_settings: tenant_settings.settings,
      waterPercents,
      updateWaterPercent: setPercent,
      updateSettings,
      setSettings: handleSetSettings,
    }));

  return (
    <div className="Preferences">
      <Subnavigation links={navLinks} className="small-12 column" />
      <section className="small-12 column">
        <Preloader isActive={tenant_settings.isLoading} />
        {tenant_settings.isLoaded && childrenWithProps}
      </section>
    </div>
  );
};

Preferences.propTypes = {
  children: T.node,
  setCurrentCompany: T.func.isRequired,
  isSVRFeatureEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isSVRFeatureEnabled: state.auth.user.current_company.admin_svrs,
  }), { setCurrentCompany }
)(Preferences);
