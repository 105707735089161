import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reportWaterUsage } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import CheckupSegment from './CheckupSegment/CheckupSegment';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
// utils
import { calculateMgPerHead, formatWater } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getCheckupSegmentHeaderData, isSomeSectionReporting, thirdPartyWaterUsageDevices } from 'utils/checkupHelper';
import { getCommentByRole } from 'helpers.js';
import pick from 'lodash.pick';
// styles
import styles from './ReportedSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const { WATER_USAGE_ROLE } = comment_roles;

const ReportWaterUsageSegment = ({
  checkup,
  paramsCheckupId,
  isDisabled,
  isSubmitting,
  measurementSystem,
  reportWaterUsage,
  validateCheckup,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const { comments, water_usage_value, water_usage_reported, edge_water_usage,
    initial_pigs, pigs, maximus_water_usage, dicam_water_usage, barn_tools_water_usage } = checkup;
  const measure = measurementSystem === 'imperial' ? 'gallonage' : 'liters';
  const isEmptyValue = (water_usage_value === null);
  const isReported = Boolean(
    water_usage_reported ||
    edge_water_usage ||
    maximus_water_usage ||
    dicam_water_usage ||
    barn_tools_water_usage
  );

  // value taken from AP device: edge, maximus, dicam, barntool
  const waterUsageDeviceValue = edge_water_usage || maximus_water_usage || dicam_water_usage || barn_tools_water_usage;

  const handleReportNoWaterUsage = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportWaterUsage(paramsCheckupId, { all_good: true }).catch(toastResponseErrors);
  };

  return (
    <CheckupSegment
      isDisabled={isDisabled}
      isSegmentConditionChanged={isAllGoodReported}
      isSubmitting={isSubmitting}
      isReported={isReported}
      onEmptyDataSubmit={handleReportNoWaterUsage}
      isItemsCountHidden={water_usage_reported && !isEmptyValue}
      titleKey="component.checkupSegment.didYouRecordWater"
      titleKeyWhenReported="general.waterUsage"
      reportPageUrl={`/daily-checkup/${paramsCheckupId}/report-water-usage`}
      itemsCountPlaceholder="N/A"
      isButtonsHidden={waterUsageDeviceValue && !isEmptyValue}
      noData={isReported && isEmptyValue}
      extendedHeaderData={getCheckupSegmentHeaderData(pick(checkup, thirdPartyWaterUsageDevices))}
    >
      <div className={styles['reported-segment']}>
        {!isEmptyValue && (
          <div>
            <div className={styles.line}>
              <FormattedMessage id={`general.${measure}Consumed`} />
              <span className="semibold">
                {formatWater(water_usage_value, measurementSystem)}
              </span>
            </div>
            <div className={styles.line}>
              <FormattedMessage id={`general.${measure}PerHead`} />
              <span className="semibold">
                {calculateMgPerHead(formatWater(water_usage_value, measurementSystem), initial_pigs || pigs)}
              </span>
            </div>
          </div>
        )}
        <SegmentComment comment={getCommentByRole(comments, WATER_USAGE_ROLE)} />
      </div>
    </CheckupSegment>
  );
};

ReportWaterUsageSegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  reportWaterUsage: T.func.isRequired,
  measurementSystem: T.string.isRequired,
  validateCheckup: T.func.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingWater,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingWater,
  }), {
    reportWaterUsage,
  }
)(ReportWaterUsageSegment);
