import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
import { isSomeReportQuestionFlagged } from 'utils/SVRHelper';
// styles
import styles from './ReportCard.module.scss';

const cn = classnames.bind(styles);

const ReportCard = ({ report, backLocationQuery }, { router }) => {
  const lastUpdateDate = report.created_at
    ? moment(report.created_at, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : 'N/A';

  const handleClick = () => {
    router.push({
      pathname: `/svr-farms/${report.farm_id}/svrs/${report.id}/review`,
      query: backLocationQuery,
    });
  };

  const isReportFlagged = isSomeReportQuestionFlagged(report.svr_categories);

  return (
    <div onClick={handleClick} className={cn('report-card', { flagged: isReportFlagged })}>
      <div className={cn('top-content', { flagged: isReportFlagged })}>
        <UserAvatar user={report.user} size={25} />
      </div>
      <div className={cn('info-block')}>
        <div className={cn('title')}>
          <FormattedMessage id="general.siteVisitReport" />
        </div>
        {lastUpdateDate}
      </div>
      <div />
    </div>
  );
};

ReportCard.contextTypes = {
  router: T.object.isRequired,
};

ReportCard.propTypes = {
  backLocationQuery: T.object,
  report: T.object.isRequired,
};

export default ReportCard;
