import { fetchFromAPI } from 'utils/api';

export const toggleSVR = (resource) => {
  return fetchFromAPI('/admin/svrs/toggle_svrs', { method: 'put', body: JSON.stringify(resource) })
    .then((response) => response.resource);
};

export const setSVRReportFrequency = (frequency) => {
  return fetchFromAPI('/admin/svrs/set_svr_frequency', { method: 'put', body: JSON.stringify({ frequency }) })
    .then((response) => response.resource);
};

export const resetSVRQuestionsSettings = (categoryId) => {
  return fetchFromAPI(`/admin/svr_categories/${categoryId}/reset_questions`, { method: 'put' })
    .then((response) => response.resource);
};

export const toggleSVRQuestion = (categoryId, questionId) => {
  return fetchFromAPI(`/admin/svr_categories/${categoryId}/svr_questions/${questionId}/toggle`, { method: 'put' });
};

export const moveSVRQuestion = (categoryId, resource) => {
  return fetchFromAPI(`/admin/svr_categories/${categoryId}/svr_questions/update_sequence`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  }).then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const createSVRQuestion = (categoryId, resource) => {
  return fetchFromAPI(
    `/admin/svr_categories/${categoryId}/svr_questions`,
    { method: 'post', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

export const deleteSVRQuestion = (categoryId, questionId) => {
  return fetchFromAPI(`/admin/svr_categories/${categoryId}/svr_questions/${questionId}/delete`, { method: 'put' });
};

export const updateSVRQuestion = (categoryId, questionId, resource) => {
  return fetchFromAPI(
    `/admin/svr_categories/${categoryId}/svr_questions/${questionId}`,
    { method: 'put', body: JSON.stringify({ resource }) }
  ).then((response) => response.resource);
};

export const updateSVRQuestionsSequence = (categoryId, resource) => {
  return fetchFromAPI(
    `/admin/svr_categories/${categoryId}/svr_questions/update_sequence`,
    { method: 'put', body: JSON.stringify({ resource }) }
  );
};

export const createCategory = (resource) => {
  return fetchFromAPI('/admin/svr_categories', { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource);
};

export const deleteCategory = (categoryId) => {
  return fetchFromAPI(`/admin/svr_categories/${categoryId}/delete`, { method: 'put' });
};

export const updateCategory = (categoryId, resource) => {
  return fetchFromAPI(`/admin/svr_categories/${categoryId}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource);
};
