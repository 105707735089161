import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { openModal } from 'reducers/modals';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import DeleteTreatmentProtocolModal from '../components/DeleteTreatmentProtocolModal';
import TreatmentProtocolProductsTable from './TreatmentProtocolProductsTable';
import Preloader from 'components/Preloader';
import TreatmentProtocolProfileHeader from './TreatmentProtocolProfileHeader';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// endpoints
import {
  getTreatmentProtocolsFarm,
  getFarmTreatmentProtocol,
  updateFarmTreatmentProtocol
} from 'endpoints/treatmentProtocols';

const FarmTreatmentProtocolProfile = ({
  router,
  location: { pathname },
  params: { treatmentProtocolId, farmId },
  setPageOptions,
  setBreadcrumbs,
  openModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentProtocol, setTreatmentProtocol] = useState(null);
  const [farm, setFarm] = useState(null);

  const fetchData = async () => {
    try {
      setFarm(await getTreatmentProtocolsFarm(farmId));
      setTreatmentProtocol(await getFarmTreatmentProtocol(farmId, treatmentProtocolId));
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const onTreatmentProtocolStatusChange = async (isActive) => {
    try {
      setIsLoading(true);
      setTreatmentProtocol(await updateFarmTreatmentProtocol(farmId, treatmentProtocolId, { active: isActive }));
    } catch (error) {
      toastResponseErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO: update next functions after back-end changes
  const onDeleteTreatmentProtocol = () => undefined;

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (farm && treatmentProtocol) {
      const breadcrumbs = [
        { label: <FormattedMessage id="general.treatmentProtocolFarms" />, path: '/treatment-protocol-farms' },
        { label: farm.name, path: `/treatment-protocol-farms/${farm.id}/treatment-protocols` },
        { label: treatmentProtocol.name, path: pathname, useLabelAsMobileTitle: true },
      ];
      setPageOptions({ backLink: `/treatment-protocol-farms/${farm.id}/treatment-protocols` });
      setBreadcrumbs(breadcrumbs);
    }
  }, [farm, treatmentProtocol]);

  if (!farm || !treatmentProtocol) {
    return <Preloader isActive />;
  }

  const options = [
    {
      label: <FormattedMessage id="general.button.edit" />,
      onClick: () => router.push(`${pathname}/edit`),
    },
    {
      label: <FormattedMessage id={`general.button.${treatmentProtocol.active ? 'disable' : 'enable'}`} />,
      onClick: () => onTreatmentProtocolStatusChange(!treatmentProtocol.active), // passing reversed value
    },
    {
      label: <FormattedMessage id="general.button.delete" />,
      onClick: () => openModal(
        <DeleteTreatmentProtocolModal
          treatmentProtocol={treatmentProtocol}
          onDeleteTreatmentProtocol={onDeleteTreatmentProtocol}
        />
      ),
    },
  ];

  return (
    <div>
      <Preloader isActive={isLoading} />
      <TreatmentProtocolProfileHeader treatmentProtocol={treatmentProtocol} options={options} />
      <TreatmentProtocolProductsTable treatmentProducts={treatmentProtocol.treatment_protocol_products} />
    </div>
  );
};

FarmTreatmentProtocolProfile.propTypes = {
  router: T.object.isRequired,
  location: T.object.isRequired,
  params: T.object.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(null, {
  setPageOptions,
  setBreadcrumbs,
  openModal,
})(FarmTreatmentProtocolProfile);
