import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { addNewPostedActivity } from 'reducers/farmfeed';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import UserAvatar from 'components/UserAvatar/UserAvatar';
import Button from 'components/Button';
import FileAttachmentProgress from 'components/FileAttachmentProgress/FileAttachmentProgress';
import FarmFeedUserSelect from './FarmFeedUserSelect';
import Link from 'react-router/lib/Link';
import FarmFeedFarmSelect from './FarmFeedFarmSelect';
import ResponsivePlayer from 'components/ResponsivePlayer/ResponsivePlayer';
import ReactPlayer from 'react-player';
// endpoints
import {
  createDirectPost,
  deleteActivityAsset,
  deleteDirectPost,
  fetchActivityAsset,
  updateDirectPost,
} from 'endpoints/farmfeed';
// utils
import autosize from 'autosize';
import getUrls from 'get-urls';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
import initializeDirectPostResumable from 'utils/resumable/initializeDirectPostResumable';
import { getFarmNickname } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import './FarmFeedPost.scss';

export const MENTION_SOMEONE = 'MENTION_SOMEONE';
export const MENTION_FARM = 'MENTION_FARM';
export const POST_COMPANY_MESSAGE = 'POST_COMPANY_MESSAGE';

const initialState = {
  action: null,
  disabled: [],
  message: '',
  selectedFarm: {},
  selectedUsers: [],
  postCompany: null,
  filesInQueue: [],
  draftId: null,
  isLoading: false,
  videoUrls: [],
};

class FarmFeedPost extends Component {

  state = initialState;

  componentDidMount() {
    if (this.textarea) autosize(this.textarea);
  }

  componentWillUnmount() {
    const { draftId } = this.state;
    if (draftId) deleteDirectPost(draftId);
  }

  handleClickUploadButton = () => {
    if (!this.state.draftId) {
      createDirectPost({ visible: false })
        .then(({ id }) => {
          this.setState({ draftId: id });
          if (this.textarea) {
            autosize(this.textarea);
            this.textarea.focus();
          }
          this.R = initializeDirectPostResumable(id, {
            onFileAdded: this.onFileAdded,
            onFileSuccess: this.onFileSuccess,
            onFileProgress: this.updateFileProgress,
          });

          if (this.R.support) {
            const browseButton = document.querySelector('#resumable-browse');
            this.R.assignBrowse([browseButton]);
            browseButton.click();
          }
        });
    }
  };

  onFileAdded = (file) => {
    this.setState((prevState) => ({ ...prevState, filesInQueue: [...prevState.filesInQueue, file] }));
  };

  onFileSuccess = (file) => {
    const { draftId } = this.state;
    fetchActivityAsset(draftId, { chunk_identifier: file.uniqueIdentifier })
      .then((savedAsset) => {
        this.setState((prevState) => ({
          filesInQueue: prevState.filesInQueue.map((stateFile) => (
            stateFile.uniqueIdentifier === file.uniqueIdentifier
              ? { ...stateFile, savedAsset }
              : stateFile
          )),
        }));
      })
      .catch(toastResponseErrors);
  };

  removeFileFromQueue = (file) => {
    this.setState((prevState) => ({
      ...prevState,
      filesInQueue: prevState.filesInQueue.filter((f) => (f.uniqueIdentifier !== file.uniqueIdentifier)),
    }));
  };

  updateFileProgress = (file) => {
    this.setState((prevState) => ({
      ...prevState,
      filesInQueue: prevState.filesInQueue.map((stateFile) => (
        stateFile.uniqueIdentifier === file.uniqueIdentifier
          ? { ...stateFile, uploadProgress: file.progress() }
          : stateFile
      ))
    }));
  };

  removeFile = (file, isUploaded) => {
    if (!isUploaded) {
      file.cancel();
      return this.removeFileFromQueue(file);
    }

    const { draftId } = this.state;

    if (file.savedAsset?.id) {
      return deleteActivityAsset(draftId, file.savedAsset?.id)
        .then(() => {
          this.removeFileFromQueue(file);
        })
        .catch(toastResponseErrors);
    }
    return null;
  };

  cancelUpload = (file) => {
    file.cancel();
    this.removeFileFromQueue(file);
  };

  activate = (action) => {
    const { disabled } = this.state;
    const { current_company } = this.props;
    if (!disabled.includes(action)) {
      if (action === POST_COMPANY_MESSAGE) {
        this.setState({ disabled: [], action, postCompany: current_company });
      } else {
        this.setState({ action, postCompany: null });
      }
    }
  };

  changeAction = (field, disabledFields) => (value) => {
    const { action } = this.state;
    const disabled = isEmpty(value) ? [] : disabledFields;
    this.setState({ [field]: value, disabled });
    if (action !== POST_COMPANY_MESSAGE) {
      this.setState({ postCompany: null });
    }
  };

  checkDisabled = () => {
    const { message, selectedFarm, selectedUsers, postCompany, isLoading } = this.state;
    return isEmpty(message.trim())
      || (isEmpty(selectedFarm) && isEmpty(selectedUsers) && isEmpty(postCompany))
      || this.isUploading() || isLoading;
  };

  isUploading = () => (
    this.state.filesInQueue.some((file) => !file.isComplete())
  );

  postData = () => {
    const { draftId, message, selectedFarm, postCompany, selectedUsers } = this.state;
    const { addNewPostedActivity, toggleTypingMode } = this.props;
    const data = { message, visible: true };
    if (!isEmpty(selectedFarm)) data.farm_id = selectedFarm.item.id;
    if (!isEmpty(postCompany)) data.company_id = postCompany.id;
    if (!isEmpty(selectedUsers)) data.mentions = selectedUsers.map((user) => ({ user_id: user.item.id }));

    this.setState({ isLoading: true });
    (draftId ? updateDirectPost(draftId, data) : createDirectPost(data))
      .then((resource) => {
        this.setState(initialState);
        addNewPostedActivity(resource);
        toggleTypingMode();
        if (this.textarea) autosize(this.textarea);
      })
      .catch((response) => {
        toastResponseErrors(response);
        this.setState({ isLoading: false });
      });
  };

  getVideoUrls = (message) => {
    const urlsSet = getUrls(message);
    const videoUrls = [];
    urlsSet.forEach((url) => {
      if (ReactPlayer.canPlay(url)) videoUrls.push(url);
    });
    return videoUrls.length ? [videoUrls.shift()] : undefined;
  }

  handleMessageChange = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      videoUrls: this.getVideoUrls(value) || prevState.videoUrls,
      message: value,
    }));
  }

  render() {
    const { currentUser, isTypingMode, toggleTypingMode, current_company, className } = this.props;
    const { roles_map: { admin } } = currentUser;
    const { filesInQueue, isLoading, message, action, disabled, selectedFarm, postCompany, selectedUsers, draftId,
      videoUrls } = this.state;

    const buttons = [
      { action: MENTION_SOMEONE, icon: <i className="fa fa-ep-users" />,
        name: <FormattedMessage id="container.farmfeed.mentionSomeone" /> },
      { action: MENTION_FARM, icon: <i className="fa fa-ep-farm" />,
        name: <FormattedMessage id="container.farmfeed.mentionFarm" /> },
      { action: POST_COMPANY_MESSAGE, icon: <i className="fa fa-megaphone" />,
        name: <FormattedMessage id="container.farmfeed.post" />, hide: !admin },
    ];

    return (
      <div
        key={`draft-${draftId || 0}`}
        className={cn('FarmfeedPost', className, {
          'position-fixed': isTypingMode,
          'empty-message': !message && !isTypingMode
        })}
      >
        <div className="post-header">
          <i className="fa fa-pencil-mdc mr-5" />
          <FormattedMessage id="container.farmfeed.farmfeedPost" />
        </div>
        <div
          onClick={isTypingMode ? null : toggleTypingMode}
          className={cn('post-content', { 'not-adding': !isTypingMode })}
        >
          <div className="avatar">
            <UserAvatar user={currentUser} size={40} className="user-photo" />
            <FormattedMessage id="container.farmfeed.writeSmth">
              {(placeholder) => (
                <textarea
                  className={cn('text-block', { 'not-adding': !isTypingMode })}
                  ref={(ref) => { this.textarea = ref; }}
                  rows={1}
                  value={message}
                  onChange={this.handleMessageChange}
                  placeholder={placeholder}
                />
              )}
            </FormattedMessage>
          </div>

          <Preloader isActive={isLoading} />

          {videoUrls && videoUrls.map((url, ind) => (
            <div key={`video-url-${ind}`} className="video-url-block">
              <ResponsivePlayer url={url} />
            </div>
          ))}

          <div className={cn('mention-block', { 'hide': !isTypingMode })}>
            {action === MENTION_SOMEONE && (
              <FarmFeedUserSelect
                selectedUsers={selectedUsers}
                onChange={this.changeAction('selectedUsers', [MENTION_FARM, POST_COMPANY_MESSAGE])}
              />
            )}
            {action === MENTION_FARM && (
              <FarmFeedFarmSelect
                selectedFarm={selectedFarm}
                onChange={this.changeAction('selectedFarm', [MENTION_SOMEONE, POST_COMPANY_MESSAGE])}
              />
            )}
            {action === POST_COMPANY_MESSAGE && (
              <span className="company-line">
                &mdash;&nbsp;
                <Link className="company-mention" to={`/admin/companies/${current_company.id}`}>
                  @{getFarmNickname(postCompany)}
                </Link>
              </span>
            )}
          </div>

          <div className={cn('actions', { 'hide': !isTypingMode })}>
            <hr className="divider" />
            <div className={cn('action-button', { 'align-start': !admin })}>
              {buttons.map((item, index) => (
                !item.hide && (
                  <a
                    key={index}
                    onClick={() => this.activate(item.action)}
                    className={cn('mention-btn', {
                      'disabled': disabled.includes(item.action),
                      'active': (item.action === action),
                    })}
                  >
                    {item.icon}{item.name}
                  </a>
                )))}
            </div>
            {!!filesInQueue.length && (
              <div>
                <hr className="divider" />
                <div className="activity-files">
                  {filesInQueue.map((file) => {
                    const isUploaded = file.isComplete();
                    return (
                      <FileAttachmentProgress
                        key={file.uniqueIdentifier}
                        name={file.file.name}
                        isUploaded={isUploaded}
                        onRemove={() => this.removeFile(file, isUploaded)}
                        progress={file.uploadProgress || 0}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <div className="attach-file">
              <span
                id="resumable-browse"
                className="attach-btn"
                onClick={this.handleClickUploadButton}
              >
                <i className="fa fa-paper-clip" />
                <span className="attach-text"><FormattedMessage id="general.attachFile" /></span>
              </span>

              <Button onClick={this.postData} className="post-btn" disabled={this.checkDisabled()} small primary>
                <FormattedMessage id="general.post" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FarmFeedPost.propTypes = {
  className: T.string,
  currentUser: T.object,
  isTypingMode: T.bool,
  toggleTypingMode: T.func,
  current_company: T.object,
  addNewPostedActivity: T.func.isRequired,
};

export default connect((state) => ({
  current_company: state.auth.user.current_company,
}), { addNewPostedActivity })(FarmFeedPost);
