/* eslint-disable react/prop-types */
import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { CustomColumn } from 'components/DataTable/Columns/index';
// utils
import cn from 'classnames';

const renderFirstNameColumn = ({ first_name, error }) => {
  return (
    <CustomColumn
      label={<FormattedMessage id="component.userProfile.firstName" />}
      className={cn({ 'highlighted': error === 'farm' })}
    >
      {first_name || '-'}
    </CustomColumn>
  );
};

const renderLastNameColumn = ({ last_name, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.userProfile.lastName" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {last_name || '-'}
  </CustomColumn>
);

const renderEmailColumn = ({ email, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.userProfile.email" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {email || '-'}
  </CustomColumn>
);

const renderPhoneNumberColumn = ({ phone, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.dataTable.headers.farmName" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {phone  || '-'}
  </CustomColumn>
);

const renderPQANumberColumn = ({ pqa_number, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.userProfile.pqaNumber.placeholder" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {pqa_number   || '-'}
  </CustomColumn>
);

const renderPQAExpirationColumn = ({ pqa_expire, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.pqaExpiration" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {pqa_expire  || '-'}
  </CustomColumn>
);

const userColumns = () => ([
  { label: <FormattedMessage id="component.userProfile.firstName" />, flex: '1 1 140px', background: '#fff5f0',
    renderer: renderFirstNameColumn,  sortKey: 'first_name' },
  { label: <FormattedMessage id="component.userProfile.lastName" />, flex: '1 1 140px',
    renderer: renderLastNameColumn, sortKey: 'last_name' },
  { label: <FormattedMessage id="component.userProfile.email" />, flex: '1 1 140px',
    renderer: renderEmailColumn, sortKey: 'email' },
  { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
    renderer: renderPhoneNumberColumn, sortKey: 'phone' },
  { label: <FormattedMessage id="component.userProfile.pqaNumber.placeholder" />, flex: '1 1 140px',
    renderer: renderPQANumberColumn, sortKey: 'pqa_number' },
  { label: <FormattedMessage id="general.pqaExpiration" />, flex: '1 1 140px',
    renderer: renderPQAExpirationColumn, sortKey: 'pqa_expire'  },
]);

export default userColumns;
