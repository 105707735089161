import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableCategory from '../components/DroppableCategory';
import SearchBox from 'components/SearchBox';
import { FormattedMessage } from 'react-intl';
import CreateCategoryModal from '../components/CreateCategoryModal';
// store
import { openModal } from 'reducers/modals';
// api
import { getSVRCategories } from 'endpoints/svr';
import {
  updateSVRQuestionsSequence,
  createCategory,
  deleteCategory,
  updateCategory as updateSVRCategory,
} from 'endpoints/admin/svr';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { ADDITIONAL_CATEGORY_KEY } from 'utils/SVRHelper';
import { showToastrMessage } from 'utils';
// styles
import styles from '../SiteVisitReports.module.scss';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const CategoriesSection = ({ isSVREnabled, openModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState('');

  const fetchCategoriesData = async () => {
    setIsLoading(true);

    try {
      const categories = await getSVRCategories();
      const filteredCategories = categories.filter(({ item_key }) => item_key !== ADDITIONAL_CATEGORY_KEY);
      setCategories(filteredCategories);
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const updateQuestionsSequence = async (dndResult) => {
    const shallowCopy = [...categories];
    const categoryId = +dndResult.destination.droppableId;
    const categoryQuestions = shallowCopy.find(({ id }) => id === categoryId);
    const reorderedQuestionsData = reorder(
      categoryQuestions.svr_questions,
      dndResult.source.index,
      dndResult.destination.index
    );

    // optimistic
    setCategories((prevCategories) => prevCategories.map((categoryData) => (
      categoryData.id === categoryId ?
        { ...categoryData, svr_questions: reorderedQuestionsData } :
        categoryData
    )));

    const reorderedQuestionsIds = reorderedQuestionsData.map(({ id }) => id);

    setIsLoading(true);

    try {
      await updateSVRQuestionsSequence(categoryId, { question_ids: reorderedQuestionsIds });
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setCategories(shallowCopy);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isSVREnabled) {
      fetchCategoriesData();
    }
  }, [isSVREnabled]);

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    updateQuestionsSequence(result);
  };

  const updateCategory = (categoryIndex) => (updatedData) => {
    setCategories((prevCategories) => [
      ...prevCategories.slice(0, categoryIndex),
      { ...prevCategories[categoryIndex], ...updatedData },
      ...prevCategories.slice(categoryIndex + 1)
    ]);
  };

  const onCategoryCreate = async (categoryName) => {
    setIsLoading(true);
    try {
      const createdCategory = await createCategory({ item_key: categoryName });
      setCategories((prevCategories) => [...prevCategories, createdCategory]);
      showToastrMessage('component.toastr.svrCategory.created');
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const onCategoryDelete = async (categoryId) => {
    setIsLoading(true);
    try {
      await deleteCategory(categoryId);
      setCategories((prevCategories) => prevCategories.filter(({ id }) => id !== categoryId));
      showToastrMessage('component.toastr.svrCategory.deleted');
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const onCategoryUpdate = async (categoryId, updatedName) => {
    setIsLoading(true);
    try {
      const updatedCategory = await updateSVRCategory(categoryId, { item_key: updatedName });
      setCategories((prevCategories) => prevCategories
        .map((category) =>  (category.id === categoryId ? updatedCategory : category)));
      showToastrMessage('component.toastr.svrCategory.updated');
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const handleCategoryCreate = () => {
    openModal(<CreateCategoryModal onCategoryCreate={onCategoryCreate} />);
  };

  const categoryRenderer = (categoryData, index) => (
    <DroppableCategory
      isCustomCategory={!!categoryData.custom}
      search={search}
      name={categoryData.name}
      questions={categoryData.svr_questions}
      key={index}
      categoryId={categoryData.id}
      updateCategory={updateCategory(index)}
      onCategoryUpdate={onCategoryUpdate}
      onCategoryDelete={onCategoryDelete}
    />
  );

  return (
    <div className={styles['categories-section']}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Preloader isActive={isLoading} />
        <div className={styles.header}>
          <SearchBox className={styles['search-box']} initialValue={search} onChange={setSearch} />
          <Button onClick={handleCategoryCreate} className={styles['header-btn']} primary>
            <FormattedMessage id="component.modal.siteVisitReports.createCategory" />
          </Button>
        </div>
        {categories.map(categoryRenderer)}
      </DragDropContext>
    </div>
  );
};

CategoriesSection.propTypes = {
  isSVREnabled: T.bool.isRequired,
  openModal: T.func.isRequired,
};

export default connect((state) => ({
  isSVREnabled: state.auth.user.current_company.svrs,
}), { openModal })(CategoriesSection);
