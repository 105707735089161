import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';

import styles from './AISuggestionActivity.module.scss';

const cn = classnames.bind(styles);

const AISuggestionFeedback = ({ feedback, sendFeedback, type }) => {
  const [isChangeable, setIsChangeable] = useState(!!feedback);

  const handleFeedback = (answer) => {
    if (feedback && feedback.answer === answer) {
      setIsChangeable(true);
    } else {
      sendFeedback({ ...feedback, answer, variety: type }).then(() => {
        setIsChangeable(true);
      });
    }
  };

  if (isChangeable && feedback) return (
    <div className={cn('suggestion-feedback')}>
      <FormattedMessage
        id="component.AISuggestionFeedback.answer"
        values={{
          name: feedback?.user.full_name,
          decision: feedback?.answer,
        }}
      />
      <button
        className={cn('suggestion-change-btn')}
        onClick={() => setIsChangeable(false)}
      >
        <FormattedMessage id="component.AISuggestionFeedback.change" />
      </button>
    </div>
  );

  return (
    <div className={cn('suggestion-question')}>
      <FormattedMessage id="component.AISuggestionFeedback.isUseful" />
      <button
        onClick={() => handleFeedback('useful')}
        className={cn('suggestion-answer-btn')}
      >
        <FormattedMessage id="general.button.yes" />
      </button>
      <button
        onClick={() => handleFeedback('useless')}
        className={cn('suggestion-answer-btn')}
      >
        <FormattedMessage id="general.button.no" />
      </button>
    </div>
  );
};

AISuggestionFeedback.propTypes = {
  feedback: T.object,
  sendFeedback: T.func.isRequired,
  type: T.string.isRequired,
};

AISuggestionFeedback.defaultProps = {
  feedback: null,
};

export default AISuggestionFeedback;
