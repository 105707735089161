import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import { GroupStatusMetaFarms } from 'components/StatusBadge/StatusBadge';
import TruncateHint from 'components/TruncateHint';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import TableFilter from 'components/TableFilter';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import DataTable from 'components/DataTable/DataTable';
import ClosedIntegration from '../../ClosedIntegration/ClosedIntegration';
import NothingBox from 'components/NothingBox';
// utils
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import download from 'downloadjs';
import { isTablet } from 'react-device-detect';
// assets
import repeat from '../../../../../public/images/repeat.svg';
// styles
import './PullResults.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.errors" />, value: 'errors' },
];
const urlDownload = '/pull_results/download?direction=import&api=faslink';

class PullResults extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    this.renderRerunArray();
  }

  downloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  showSpaces = (isField) => {
    return isField ? 0  : 'N/A';
  };

  renderFarmIdColumn = (farm) => {
    const { data: { pig_group_name } } = farm;
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
        <TruncateHint name={pig_group_name || 'N/A'} className="groupIdName" />
      </CustomColumn>
    );
  };

  renderFarmNameColumn = ({ data: { farm_name } }) => {
    return (
      <CustomColumn label={<FormattedMessage id="component.dataTable.headers.farmName" />}>
        <TruncateHint name={farm_name || 'N/A'} className="groupIdName" />
      </CustomColumn>
    );
  };

  renderDeathColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_death } } } = this.props;
    return (
      <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.deaths" />}>
        {report_data.death ? report_data.death : this.showSpaces(show_death)}
      </CustomColumn>
    );
  };

  renderFeedBasedColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_feed_based_meds } } } = this.props;
    return (
      <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.deaths" />}>
        <TruncateHint name={report_data.feed_based_meds ?
          report_data.feed_based_meds.length.toString() :
          this.showSpaces(show_feed_based_meds)}
        />
      </CustomColumn>
    );
  };

  renderHeadTreatedColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_head_treated } } } = this.props;
    return (
      <CustomColumn textRight label={<FormattedMessage id="component.dataTable.headers.deaths" />}>
        {report_data.head_treated ? report_data.head_treated : this.showSpaces(show_head_treated)}
      </CustomColumn>
    );
  };

  renderImportedColumn = ({ data: { report_data } }) => {
    const { meta: { fields: { show_imported } } } = this.props;
    return (
      <CustomColumn textRight label={<FormattedMessage id="general.deaths.imported" />}>
        {report_data.imported ? report_data.imported :  this.showSpaces(show_imported)}
      </CustomColumn>
    );
  };

  renderRerunArray = () => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, sort: 'status asc' }).catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  onFilterChange = (filter) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, status: filter, sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  onSearchChangeArray = (searchName) => {
    this.setState({ isLoading: true });
    const { reqParams, fetchData } = this.props;
    fetchData({ ...reqParams, search: searchName,  sort: 'status asc' })
      .catch(toastResponseErrors)
      .finally(() => { this.setState({ isLoading: false }); });
  };

  renderPushResultColumn = ({ success }) => {
    return (
      <CustomColumn
        textCenter
        label={<FormattedMessage id="component.dataTable.headers.status" />}
        noBottomBorder
      >
        <div className="containerResult">
          <div className="itemResult">
            <GroupStatusMetaFarms
              status={success ? 'success' : 'error'}
            />
          </div>
          {!success  && (
          <div>
            <button className="resultBtn" onClick={this.renderRerunArray}>
              <img className="resultImg" src={repeat} alt="repeat" />
            </button>
          </div>
          )}
        </div>
      </CustomColumn>
    );
  };

  getHoverFeedBased = (feedBasedMedsArr) => (
    feedBasedMedsArr.map((item) => <div>{item}</div>)
  );

  downloadAll = () => {
    fetchFromAPI(urlDownload, { blob: true })
      .then((blob) => download(blob, 'Download', 'text/csv'))
      .catch(toastResponseErrors);
  };

  getUrlDownload = (success) => {
    return `${urlDownload}&success=${success ? 'true' : 'false'}`;
  };

  getNameDownload = (success) => {
    return success ? 'Successes' : 'Errors';
  };

  render() {
    const {
      reqParams: { page, per_page, search, sort, status },
      meta: { total, stats },
      fasLinkPull,
      active,
      onSortChange,
      onPageChange,
      onPerPageChange,
    } = this.props;
    const { isLoading } = this.state;
    const title = <FormattedMessage id="general.pageTitle.results" />;
    const datePush =  moment(Date.now()).format('MM/DD/YY HH:MM:ss GTM ZZ');
    const columns = [
      {
        label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 130px',
        renderer: this.renderFarmIdColumn, sortKey: 'pig_group_name'
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 130px',
        renderer: this.renderFarmNameColumn, sortKey: 'farm_name'
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.deaths" />, flex: '1 1 90px',
        renderer: this.renderDeathColumn, sortKey: 'death', textRight: true
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.feed_based" />, flex: '1 1 90px',
        renderer: this.renderFeedBasedColumn, sortKey: 'feed_based_meds', textRight: true
      },
      {
        label: <FormattedMessage id="general.headTreated" />, flex: '1 1 90px',
        renderer: this.renderHeadTreatedColumn, sortKey: 'head_treated', textRight: true
      },
      {
        label: <FormattedMessage id="general.deaths.imported" />, flex: '1 1 100px',
        renderer: this.renderImportedColumn, sortKey: 'imported', textRight: true
      },
      {
        label: <FormattedMessage id="component.dataTable.headers.pullResult" />, flex: '1 1 219px',
        renderer: this.renderPushResultColumn, sortKey: 'status'
      },

    ];
    const labelButton = <FormattedMessage id="general.button.download" />;

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    if (isLoading) {
      return <Preloader isActive={isLoading} />;
    }

    return (
      <>
        <Preloader isActive={isLoading} />
        {!isLoading && (
        <div className="small-12 column">
          {active ? (
            <div className="BarnsheetsGroups">
              <Panel className="mv-20">
                <Panel.Heading renderTitle={() => (
                  <h2 className="lighter show-for-large">
                    {title} {datePush}
                  </h2>
                )}
                >
                  <SearchBox initialValue={search} onChange={this.onSearchChangeArray} />
                </Panel.Heading>
                <Panel.Body noPadding>
                  <Preloader isActive={isLoading} />
                  <TableFilter
                    filters={tableFilters}
                    onFilterChange={this.onFilterChange}
                    activeFilter={status}
                    stats={stats}
                    className="mh-10"
                  >
                    <div className="containerButtons">
                      <button className="pushBtn" onClick={this.renderRerunArray}>
                        <img src={repeat} alt="repeat" />
                        <FormattedMessage id="component.dataTable.headers.resyncErrors" />
                      </button>
                      <DropdownButton
                        idKey="groups-dropdown"
                        ignoreScroll
                        label={labelButton}
                        buttonType="compact"
                        onClick={this.downloadAll}
                        dropDownData={
                          [{ label: <FormattedMessage id="general.download.successes" />,
                            onClick: this.downloadCSV(this.getUrlDownload(true),
                              this.getNameDownload(true)) },
                          { label: <FormattedMessage id="general.download.errors" />,
                            onClick: this.downloadCSV(this.getUrlDownload(false),
                              this.getNameDownload(false)) },
                          ]
                        }
                        customClass="show-for-large"
                        wide
                      />
                    </div>
                  </TableFilter>
                  {(!fasLinkPull.length) ? (
                    <NothingBox
                      display={!fasLinkPull.length}
                      itemsName="fasLinkPull"
                      isLoading={isLoading}
                      filter="pullResults"
                    />
                  ) : (
                    <DataTable
                      data={fasLinkPull}
                      getRowClass={({ success }) => ({ 'pink': !success })}
                      columns={columns}
                      sort={sort}
                      onSortChange={onSortChange}
                      paginationProps={paginationProps}
                      isExpandable={isTablet}
                      scrollable
                      tableKey="fasLinkPull"
                    />
                  )}

                </Panel.Body>
              </Panel>
            </div>
          ) : <ClosedIntegration link="prairie-systems" /> }
        </div>
        )}
      </>
    );
  }
}

PullResults.contextTypes = {
  router: T.object.isRequired,
};

PullResults.propTypes = {
  reqParams: T.object.isRequired,
  meta: T.object.isRequired,
  fetchData: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  fasLinkPull: T.array,
  active: T.bool,
};

const enhance = compose(
  connect(
    (state) => ({
      meta: state.dataTable.fasLinkPull.meta,
      reqParams: state.dataTable.fasLinkPull.params,
      isAdmin: state.auth.user.roles_map.admin,
      fasLinkPull: state.dataTable.fasLinkPull.resources,
      active: state.dataTable.fasLinkPull.meta.active,
    }), (dispatch) => ({
      fetchData: (query) => dispatch(fetchData('/pull_results?api=faslink', 'fasLinkPull', query)),
    })
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(PullResults);
