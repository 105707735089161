import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from 'containers/DailyCheckup/utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { reportTemperatures, fetchSetPoints } from 'reducers/dailyCheckup/checkupEdit';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import RecommendedSetPoint from './components/RecommendedSetPoint';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { animateScroll } from 'react-scroll';
import { sendNotify } from 'utils/airbrakeHelper';
import { calcSetPointValue } from 'utils';
import { getCommentByRole } from 'helpers.js';
// constants
import { comment_roles } from 'constants.js';

const temperatures = [
  { label: <FormattedMessage id="general.highTemperature" />, type: 'high_temperature' },
  { label: <FormattedMessage id="general.lowTemperature" />, type: 'low_temperature' },
];

class DailyCheckupReportTemps extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialValues(props.checkup);
  }

  componentDidMount() {
    const { checkup, params, setPageOptions, isEditMode, backLink } = this.props;
    if (checkup.id === parseInt(params.id, 10)) {
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'highLowTemps',
        inEditMode: isEditMode,
      });
    }
    this.setInitialTemps(checkup);
  }

  componentDidUpdate(prevProps) {
    const { setPageOptions, checkup, backLink, isEditMode } = this.props;
    const prevCheckupId = prevProps?.checkup?.id;
    const checkupId = checkup?.id;
    if (checkupId && (checkupId !== prevCheckupId)) {
      this.setState({
        ...this.getInitialValues(checkup),
      }, () => this.setInitialTemps(checkup));
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'highLowTemps',
        inEditMode: isEditMode,
      });
    }
  }

  componentWillUnmount() {
    this.props.setPageOptions({ mobileTitle: '' });
  }

  setInitialTemps = (checkup) => {
    if (!checkup.id) return;
    const { TEMPERATURE_ROLE } = comment_roles;
    const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], TEMPERATURE_ROLE);
    const { currentUser: { current_company: { temperature_system } }, fetchSetPoints } = this.props;

    fetchSetPoints().then((resources) => {
      const initialTemp = this.getRecommendedSetPoint(checkup, resources) || 60;

      this.setState({
        high_temperature: Math.round(calcSetPointValue(checkup.high_temperature || initialTemp, temperature_system)),
        low_temperature: Math.round(calcSetPointValue(checkup.low_temperature || initialTemp, temperature_system)),
        comment: comment || '',
        mentionsIds: mentioned_user_ids || [],
      });
    }).catch(toastResponseErrors);
  };

  getInitialValues = (checkup) => {
    const { currentUser: { current_company: { temperature_system } } } = this.props;
    const { TEMPERATURE_ROLE } = comment_roles;
    const { comment, mentioned_user_ids } = getCommentByRole(checkup?.comments || [], TEMPERATURE_ROLE);
    return {
      high_temperature: Math.round(calcSetPointValue(checkup?.high_temperature || 60, temperature_system)),
      low_temperature: Math.round(calcSetPointValue(checkup?.low_temperature || 60, temperature_system)),
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
    };
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  handleInputChange = (type) => (value) => {
    if (!this.state[type].toString().includes('.') && (value || '').toString().substr(-1) === '.') {
      this.setState({ [type]: value });
      return;
    }
    const pattern = /^\d+(\.\d{0,2}?)?$/; // float number with two decimal point
    if (`0${value}`.match(pattern)) {
      this.setState({ [type]: Number(value) });
    }
  };

  submitData = () => {
    const { router } = this.context;
    const { reportTemperatures, checkup, validateCheckup, backLink, makeCheckupCorrection, isEditMode,
      currentUser, currentUser: { current_company: { temperature_system } } } = this.props;
    const { high_temperature, low_temperature, comment, mentionsIds } = this.state;
    if (!validateCheckup(checkup)) return;

    const resource = {
      high_temperature: calcSetPointValue(high_temperature, 'fahrenheit', temperature_system),
      low_temperature: calcSetPointValue(low_temperature, 'fahrenheit', temperature_system),
      comments_temperature: { text: comment, mentioned_user_ids: mentionsIds },
    };

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, resource)
      : reportTemperatures(checkup.id, { resource });

    submitRequest
      .then(() => router.push(backLink))
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  getRecommendedSetPoint = (checkup, data) => {
    const farmType = checkup?.pig_group?.farm?.farm_type || '';
    if (!farmType) return null;

    const estWeight = checkup.estimated_weight || checkup?.pig_group?.estimated_weight;
    const foundSetPoint = data.find((item) => (
      (item.estimated_weight >= estWeight) || ((item.rule_type === 'gteq') && (estWeight > item.estimated_weight))));
    return foundSetPoint ? foundSetPoint[`${farmType}_value`] : null;
  };

  isContinueDisabled = () => {
    const { high_temperature, low_temperature, comment } = this.state;
    const { high_temperature: initial_high_temp, low_temperature: initial_low_temp, comments } = this.props.checkup;

    const commentData = getCommentByRole(comments, comment_roles.TEMPERATURE_ROLE);
    const savedComment = commentData.comment || '';
    const currentComment = comment || '';
    const isEqualComment = savedComment === currentComment;

    const isEqualTemps = (high_temperature === initial_high_temp) && (low_temperature === initial_low_temp);
    const wrongData = high_temperature < low_temperature;

    return (isEqualComment && isEqualTemps) || wrongData;
  };

  render() {
    const { isReportingTemps, checkup, backLink, isEditMode, temperaturePoints } = this.props;
    const { comment, mentionsIds } = this.state;
    const isContinueDisabled = this.isContinueDisabled();
    const recommendedSetPoint = this.getRecommendedSetPoint(checkup, temperaturePoints);

    return (
      <CenterBox className="DailyCheckupReportTemps" isLoading={isReportingTemps} renderIf={Boolean(checkup)}>

        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={checkup.pigs} />
        </StickyHeader>

        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title"><FormattedMessage id="general.highLowTemps" /></h1>
          </div>
          <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
        </section>

        <section className="center-box-body">
          {isEditMode && (
            <MessageBox type="warning">
              <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}
          <RecommendedSetPoint className="mb-10" value={recommendedSetPoint} />
          <div>
            {temperatures.map(({ type, label }, index) => (
              <NumberStepInput
                key={index}
                label={label}
                min={0}
                max={100}
                value={this.state[type] || ''}
                onChange={this.handleInputChange(type)}
              />
            ))}
          </div>
          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={this.onCommentOpen}
            onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
            onMentionsChange={(mentions) => {
              this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
            }}
            value={comment}
            isOpen={!!comment}
            placeholder=""
          />
        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={backLink}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingTemps}
              className="submit wider"
              onClick={this.submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            stickyFooter
            primary
            disabled={isContinueDisabled || isReportingTemps}
            onClick={this.submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupReportTemps.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupReportTemps.propTypes = {
  checkup: T.object,
  isReportingTemps: T.bool.isRequired,
  setPageOptions: T.func.isRequired,
  fetchSetPoints: T.func.isRequired,
  reportTemperatures: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  isEditMode: T.bool,
  params: T.object,
  backLink: T.oneOfType([T.string, T.object]),
  makeCheckupCorrection: T.func,
  currentUser: T.object.isRequired,
  temperaturePoints: T.array.isRequired,
};

const enhance = compose(
  connect(
    (state, props) => ({
      currentUser: state.auth.user,
      temperaturePoints: state.dailyCheckup.checkupEdit.temperaturePoints,
      isReportingTemps: props.isLoading || state.dailyCheckup.checkupEdit.isReportingTemps,
    }), { reportTemperatures, fetchSetPoints, setPageOptions }
  ),
  withCorrection,
);

export default enhance(DailyCheckupReportTemps);
