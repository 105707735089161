function formatPath(path) {
  return path.slice(-1) === '/'
    ? path.slice(0, path.length - 1)
    : path;
}

export function isFarmFeedPage(pathname = '') {
  return formatPath(pathname) === '/farmfeed';
}

export function isMySchedulePage(pathname = '') {
  return formatPath(pathname) === '/my-schedule';
}

export function isBarnSheetsFarmsPage(pathname = '') {
  return formatPath(pathname) === '/barnsheets/farms';
}

export function isBarnSheetsGroupsPage(pathname = '') {
  return formatPath(pathname) === '/barnsheets/groups';
}

export function isSVRFarmsPage(pathname = '') {
  return formatPath(pathname) === '/svr-farms';
}
