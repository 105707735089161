import React from 'react';
// utils
import { formatUnviewedAssetsCount } from 'utils';
import { authFrontendV2Url } from 'utils/urlBuilder';
import { isUserHasAnyRole, userRoleKeys } from 'utils/rolesHelper';
// components
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DeviceIcon } from 'assets/svg/video-call-btb.svg';

const svrRolesList = [
  userRoleKeys.super_admin,
  userRoleKeys.admin,
  userRoleKeys.tenant_owner,
  userRoleKeys.caretaker,
  userRoleKeys.farm_owner,
  userRoleKeys.manager,
  userRoleKeys.vet,
];

const getSidebarItems = (user, location, sidebarInfo, newActivitiesCount) => {
  const isAdmin = user.roles_map.admin;
  const showInventoryTab = user.current_company.inventory_used;
  const isTenantUseLogistics = user.current_company.logistics;
  const isTenantUseVirtualVisits = user.current_company.virtual_visits;
  const isTenantUseTreatmentProtocols =
    user.current_company.admin_treatment_protocols && user.current_company.treatment_protocols;
  const hasTruckingRoles = user.has_trucking_roles;
  const isSuperAdmin = user.roles_map.super_admin;
  const { groups, inventories } = sidebarInfo.needAttention;
  const { unviewedActivities, unviewedAssets, flaggedActivities } = sidebarInfo.counters;
  const unviewedAssetsCount = formatUnviewedAssetsCount(unviewedAssets);

  // checking current sidebar item
  const pathFirstCut = location.pathname.split('/')[1];
  const isInsightsActive = ['asset-map', 'analytics', 'treatments', 'auto-report', 'checkins'].includes(pathFirstCut);
  const isGroupsActive = ['groups', 'sources', 'destinations'].includes(pathFirstCut);
  const isBarnSheetsActive = pathFirstCut === 'barnsheets';
  const isVirtualVisitsActive = pathFirstCut === 'virtual-visits';

  // svr
  const isTenantUseSVR = user.current_company.svrs && user.current_company.admin_svrs;
  const isUserCanViewSVR = isUserHasAnyRole(user, svrRolesList);

  const farmFeedItem = {
    link: '/farmfeed',
    icon: 'fa fa-newspaper-o',
    name: <FormattedMessage id="component.sidebar.farmFeed" />,
    counter: unviewedActivities + newActivitiesCount,
  };
  const superAdminItem = {
    link: '/super-admin',
    icon: 'fa fa-key',
    name: <FormattedMessage id="component.sidebar.superAdmin" />,
    hide: !isSuperAdmin,
  };

  return {
    primary: [
      farmFeedItem,
      {
        link: '/farmfeed/flagged',
        icon: 'fa fa-bookmark',
        name: <FormattedMessage id="component.sidebar.flaggedActivities" />,
        counter: flaggedActivities,
        counterTheme: 'yellow',
      },
      {
        link: '/my-schedule',
        icon: 'fa fa-calendar',
        name: <FormattedMessage id="component.sidebar.mySchedule" />,
        hide: !isTenantUseLogistics,
      },
      {
        link: '/daily-checkup',
        icon: 'fa fa-edit',
        name: <FormattedMessage id="component.sidebar.dailyCheckup" />,
      },
      {
        link: '/treatment-protocol-farms',
        icon: 'fa fa-injectable',
        name: <FormattedMessage id="general.treatmentProtocols" />,
        showTooltip: true,
        hide: !isTenantUseTreatmentProtocols,
      },
      {
        link: '/virtual-visits',
        iconComponent: <DeviceIcon width={24} />,
        name: <FormattedMessage id="general.pageTitle.virtualVisits" />,
        isActive: isVirtualVisitsActive,
        hide: !isTenantUseVirtualVisits,
      },
      {
        link: '/svr-farms',
        icon: 'fa fa-site-visit-report',
        name: <FormattedMessage id="general.siteVisitReports" />,
        hide: !isTenantUseSVR || !isUserCanViewSVR,
      },
    ],

    secondary: [
      {
        link: isAdmin ? '/analytics' : '/auto-report',
        icon: 'fa fa-bar-chart',
        name: <FormattedMessage id="component.sidebar.insights" />,
        isActive: isInsightsActive,
      }, {
        link: '/barnsheets',
        icon: 'fa fa-ulist',
        name: <FormattedMessage id="component.sidebar.barnSheets" />,
        isActive: isBarnSheetsActive,
      },
    ],

    more: [
      {
        link: '/media-gallery',
        icon: 'fa fa-photo',
        name: <FormattedMessage id="component.sidebar.mediaGallery" />,
      }, {
        link: '/inventory',
        icon: 'fa fa-ep-piggy',
        name: <FormattedMessage id="component.sidebar.myInventory" />,
        hide: isAdmin || !showInventoryTab,
      }, {
        link: '/admin/inventory',
        icon: 'fa fa-ep-piggy',
        name: <FormattedMessage id="component.sidebar.inventory" />,
        hide: !isAdmin || !showInventoryTab,
        action_required: inventories,
      }, {
        link: '/tenant-assets',
        icon: 'fa fa-folder',
        name: <FormattedMessage id="general.resources" />,
        counter: unviewedAssetsCount,
      }, {
        link: '/groups',
        icon: 'fa fa-ep-groups',
        name: <FormattedMessage id="component.sidebar.groups" />,
        hide: !isAdmin,
        action_required: groups,
        isActive: isGroupsActive,
      }, {
        link: null,
        onClick: () => { window.location.href = authFrontendV2Url('scheduling'); },
        icon: 'fa fa-ep-logistics',
        name: <FormattedMessage id="component.sidebar.logistics" />,
        hide: !isTenantUseLogistics || !isAdmin,
      }, {
        link: null,
        onClick: () => { window.location.href = authFrontendV2Url('trucking'); },
        icon: 'fa fa-ep-truck',
        name: <FormattedMessage id="component.sidebar.trucking" />,
        hide: !isTenantUseLogistics || (!hasTruckingRoles && !isAdmin),
      }, {
        link: '/admin',
        icon: 'fa fa-gear',
        name: <FormattedMessage id="general.role.admin" />,
        hide: !isAdmin,
      },
      superAdminItem,
    ],

    expired: [farmFeedItem, superAdminItem],
  };
};

export default getSidebarItems;
