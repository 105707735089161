import React, { useState, Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Toggle from 'components/Toggle/Toggle';
import { ReactComponent as DNDIcon } from './dnd-icon.svg';
import { Draggable } from 'react-beautiful-dnd';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './DraggableQuestion.module.scss';

const cn = classnames.bind(styles);

const DraggableQuestion = ({
  index,
  questionId,
  label,
  isEnabled,
  isDragDisabled,
  isDraft,
  isCustom,
  onQuestionUpdate,
  onToggle,
  onDelete,
}) => {
  const [questionLabel, setQuestionLabel] = useState(label);
  const [isEditing, setEditing] = useState(isDraft);

  const toggleEditing = () => {
    if (!isEditing) {
      setQuestionLabel(label);
    }

    if (isEditing) {
      onQuestionUpdate(questionLabel);
      setQuestionLabel(questionLabel || label);
    }

    setEditing(!isEditing);
  };

  const onQuestionValueChange = ({ target: { value } }) => setQuestionLabel(value);

  const handleEnterPress = ({ key }) => {
    if (key === 'Enter') {
      toggleEditing();
    }
  };

  return (
    <Draggable
      isDragDisabled={isDragDisabled}
      index={index}
      key={questionId}
      draggableId={`${questionId}-${label}`}
    >
      {(provided) => (
        <div
          className="pb-10"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={cn('question', { disabled: !isEnabled, editing: isEditing })}>
            <DNDIcon className={cn('dnd-icon')} />

            {isEditing ? (
              <FormattedMessage id="general.enterCustomQuestion">
                {(placeholder) => (
                  <input
                    onKeyPress={handleEnterPress}
                    onBlur={toggleEditing}
                    className={cn('question-input')}
                    defaultValue={questionLabel}
                    placeholder={placeholder}
                    autoFocus
                    onChange={onQuestionValueChange}
                  />
                )}
              </FormattedMessage>
            ) : questionLabel }
            <div className={cn('control-btn-group')}>
              {isCustom && (
              <Fragment>
                <i onClick={toggleEditing} className={cn('fa fa-pencil-mdc', 'icon-btn', 'mr-15')} />
                <i onClick={onDelete} className={cn('fa fa-trash-o', 'icon-btn')} />
              </Fragment>
              )}
              <Toggle handleChange={onToggle} className={cn('toggler')} isChecked={isEnabled} />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

DraggableQuestion.propTypes = {
  isEnabled: T.bool.isRequired,
  index: T.number.isRequired,
  questionId: T.oneOfType([T.string, T.number]).isRequired,
  label: T.string.isRequired,
  isDragDisabled: T.bool.isRequired,
  isDraft: T.bool.isRequired,
  onQuestionUpdate: T.func.isRequired,
  onToggle: T.func.isRequired,
  onDelete: T.func.isRequired,
};

export default DraggableQuestion;
