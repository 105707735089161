import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setPageOptions } from 'reducers/layout';
import { setTableParams } from 'reducers/tableParams';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoints
import { getTreatmentProtocolsFarms } from 'endpoints/treatmentProtocols';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentProtocolsFarmRow from './components/TreatmentProtocolsFarmRow';
import NothingBox from 'components/NothingBox';
import Panel from 'components/Panel';
import SearchBox from 'components/SearchBox';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
// utils
import { tableNames } from 'utils/constants';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const TreatmentProtocolsFarms = ({
  reqParams,
  router,
  setTableParams,
  setBreadcrumbs,
  setPageOptions,
}) => {
  const {
    data: {
      resources,
      isLoading,
      meta: { total },
    },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
  } = useDataTable(getTreatmentProtocolsFarms, {
    tableParams: reqParams,
    setTableParams: (params) => setTableParams(tableNames.treatmentProtocolFarms, params),
  });
  const { page, per_page, search } = reqParams;

  useEffect(() => {
    fetchData(reqParams).catch(toastResponseErrors);
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocol-farms',
        useLabelAsMobileTitle: true,
      },
    ]);
    setPageOptions({ backLink: '' });
  }, []);

  const renderTitle = () => (
    <h1 className="lighter">
      <FormattedMessage id="general.farms" />
    </h1>
  );

  const isSowUnitFarm = (farm) => farm.farm_type === 'su';

  const onFarmSelect = (farm) => {
    const redirectPath = isSowUnitFarm(farm)
      ? `/treatment-protocol-farms/${farm.id}/treatment-protocols`
      : `/treatment-protocol-farms/${farm.id}/groups`;

    router.push(redirectPath);
  };

  return (
    <div className="row centered">
      <div className="small-12 column">
        <section>
          <Panel>
            <Panel.Heading className="farms-panel-title" renderTitle={renderTitle}>
              <SearchBox onChange={onSearchChange} initialValue={search} disableFocus />
            </Panel.Heading>
            <Panel.Body className="farms-panel-body">
              <Preloader isActive={isLoading} />

              {resources.map((farm) => (
                <TreatmentProtocolsFarmRow
                  key={farm.id}
                  onSelect={() => onFarmSelect(farm)}
                  farm={farm}
                />
              ))}

              <NothingBox
                itemsName="farms"
                display={!resources.length}
                isLoading={isLoading}
                search={search}
              >
                <FormattedMessage tagName="h1" id="component.nothingBox.noFarmsToDisplay" />
              </NothingBox>

              <PaginationWrapper
                currentPage={page}
                perPage={per_page}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
                totalItems={total}
                onlyBottom
              />
            </Panel.Body>
          </Panel>
        </section>
      </div>
    </div>
  );
};

TreatmentProtocolsFarms.propTypes = {
  reqParams: T.shape({
    page: T.number,
    per_page: T.number,
    search: T.string,
  }),
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    reqParams: tableParamsSelector(state, tableNames.treatmentProtocolFarms),
  }),
  { setBreadcrumbs, setPageOptions, setTableParams }
)(TreatmentProtocolsFarms);
