import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ScrollableWrap from 'components/ScrollableWrap';
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
// utils
import cn from 'classnames';
import { isVideoAsset } from 'utils';
// styles
import './MediaViewerAttachedItems.scss';
// assets
import magicLoopSvg from '../../../public/images/magic.svg';

const MediaViewerAttachedItems = ({
  assets,
  currentIndex,
  hasPredictionIcon,
  isCollapsed,
  isFullScreenMode,
  isOpen,
  setIsOpen,
  selectAsset,
}) => {
  const getAssetPreviewUrl = (asset) => {
    const { url } = asset;
    return url.thumb || url.small || url.medium || url.original || url.origin;
  };

  return (
    <div className={cn('MediaViewerAttachedItems', { 'is-collapsed': isCollapsed, 'is-fullscreen': isFullScreenMode })}>
      <div className="items-row-wrapper">
        <span className="items-row-title" onClick={setIsOpen}>
          <FormattedMessage id={`component.mediaViewerAttachedItems.${!isOpen ? 'showFiles' : 'hideFiles'}`} />
          <i className={cn('ml-5 fa fa-arrow-down', { 'rotated': !isOpen })} />
        </span>
        <div className={cn('items-row-files', { 'is-open': isOpen })}>
          <ScrollableWrap className="scrollable-assets">
            {assets.map((asset, index) => (
              <div className="asset-preview" key={index}>
                {!!asset.prediction_image_tags_count && hasPredictionIcon && (
                  <img src={magicLoopSvg} alt="" className="ai-icon" />
                )}
                <div
                  className={cn('image', { 'active': index === currentIndex })}
                  style={{ backgroundImage: 'url(' + getAssetPreviewUrl(asset) + ')' }}
                  title={asset.filename}
                  onClick={() => selectAsset(index)}
                />
                {isVideoAsset(asset) && <VideoPreviewOverlay size="25" simple />}
              </div>
            ))}
          </ScrollableWrap>
        </div>
      </div>
    </div>
  );
};

MediaViewerAttachedItems.propTypes = {
  isOpen: T.bool.isRequired,
  assets: T.array.isRequired,
  currentIndex: T.number.isRequired,
  selectAsset: T.func.isRequired,
  setIsOpen: T.func.isRequired,
  hasPredictionIcon: T.bool.isRequired,
  isCollapsed: T.bool.isRequired,
  isFullScreenMode: T.bool.isRequired,
};

export default MediaViewerAttachedItems;
