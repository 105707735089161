import React, { Component } from 'react';
import T from 'prop-types';
// redux, actions, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { fetchData, updateData, setDataItem } from 'reducers/dataTable';
// component
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import TableFilter from 'components/TableFilter';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import DataTable from 'components/DataTable/DataTable';
import NothingBox from 'components/NothingBox';
import Button from 'components/Button';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import RolesPlaceholder from 'components/Admin/RolesPlaceholder';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
import isEmpty from 'lodash.isempty';
import cn from 'classnames';
import { showToastrMessage } from 'utils';
// barns API
import { updateFarmBarn, updateFarmBarnsSort } from 'endpoints/admin/farms';
// assets
import './FarmBarns.scss';
import dragLinesSvg from '../../../../public/images/drag-lines.svg';

const labels = {
  buttons: {
    disable: <FormattedMessage id="general.button.disable" />,
    enable: <FormattedMessage id="general.button.enable" />,
    manageBarn: <FormattedMessage id="general.manageBarn" />,
  },
  dataTable: {
    barnID: <FormattedMessage id="component.dataTable.headers.barnID" />,
    capacity: <FormattedMessage id="component.dataTable.headers.capacity" />,
    status: <FormattedMessage id="component.dataTable.headers.status" />,
  },
  barns: <FormattedMessage id="general.barns" />,
};

const tableFilters = [
  { label: <FormattedMessage id="general.allBarns" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
];

class FarmBarns extends Component {

  state = {
    isBarnUpdating: false,
  };

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  onStatusChange = (status) => {
    const { fetchData, reqParams } = this.props;
    fetchData({ ...reqParams, page: 1, status }).catch(toastResponseErrors);
  };

  onUpdateBarn = (id, active) => {
    const { setDataItem, params: { id: farmId } } = this.props;

    this.setState({ isBarnUpdating: true });
    updateFarmBarn(farmId, id, { active: !active })
      .then((resource) => {
        showToastrMessage(`component.toastr.barn.${resource.active ? 'activated' : 'deactivated'}`);
        setDataItem(resource);
        this.setState({ isBarnUpdating: false });
      })
      .catch((response) => {
        this.setState({ isBarnUpdating: false });
        return toastResponseErrors(response);
      });
  };

  onUpdateBarnSort = (listIds) => {
    const { updateData, params: { id: farmId } } = this.props;

    this.setState({ isBarnUpdating: true });
    updateFarmBarnsSort(farmId, listIds)
      .then((response) => {
        updateData(response);
        this.setState({ isBarnUpdating: false });
      })
      .catch((response) => {
        this.setState({ isBarnUpdating: false });
        return toastResponseErrors(response);
      });
  };

  renderBarnIdColumn = ({ id, name }) => {
    const { params: { id: farmId }, reqParams: { status } } = this.props;
    return (
      <CustomColumn flexRow>
        <img className={cn('double-line-img handle', { 'no-drop': !!status })} src={dragLinesSvg} alt="" />
        <Link to={`/admin/farms/${farmId}/barns/${id}`} className="color-primary">{name}</Link>
      </CustomColumn>
    );
  };

  renderUserRoleColumn = ({ capacity }) => (
    <CustomColumn flexRow label={labels.dataTable.capacity}>
      {capacity || '-'}
    </CustomColumn>
  );

  renderStatusColumn = ({ active }) => (
    <CustomColumn noBottomBorder label={labels.dataTable.status}>
      <StatusBadge status={active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  renderActionsColumn = ({ id, active }, { rowIndex }) => {
    const { params: { id: farmId } } = this.props;
    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${id}-${rowIndex}`}
          wide
          buttonType="small light-gray"
          label={labels.buttons[`${active ? 'disable' : 'enable'}`]}
          onClick={() => this.onUpdateBarn(id, active)}
          dropDownData={[
            { label: labels.buttons.manageBarn, url: `/admin/farms/${farmId}/barns/${id}` },
          ]}
          customClass="show-for-large"
        />
        <Link to={`/admin/farms/${farmId}/barns/${id}`} className="button light hide-for-large">
          {labels.buttons.manageBarn}
        </Link>
        <Button lightGray onClick={() => this.onUpdateBarn(id, active)} className="hide-for-large">
          {labels.buttons[`${active ? 'disable' : 'enable'}`]}
        </Button>
      </div>
    );
  };

  renderExpandable = ({ id, active }) => {
    const { params: { id: farmId } } = this.props;
    return (
      <div>
        <Link to={`/admin/farms/${farmId}/barns/${id}`} className="button small light">
          <i className="fa fa-gear mr-5" />
          {labels.buttons.manageBarn}
        </Link>
        <Button small light onClick={() => this.onUpdateBarn(id, active)}>
          <i className={`fa fa-${active ? 'times' : 'check'}-circle mr-5`} />
          {labels.buttons[`${active ? 'disable' : 'enable'}`]}
        </Button>
      </div>
    );
  };

  onOrderUpdate = (e) => {
    const { resources } = this.props;
    // in sortable.js indexes starts from 1
    const newIndex = e.newIndex - 1;
    const oldIndex = e.oldIndex - 1;
    const movedElementId = resources[oldIndex].id;
    const listIds = resources.map(({ id }) => id);
    listIds.splice(oldIndex, 1); // remove from old place
    listIds.splice(newIndex, 0, movedElementId); // add to new place
    this.onUpdateBarnSort(listIds);
  };

  render() {
    const { isLoading, resources, reqParams: { status }, params: { id: farmId } } = this.props;
    const { isBarnUpdating } = this.state;
    const isEmptyTable = !resources.length && !status;
    const lastBarn = resources[resources.length - 1];

    const columns = [
      { label: labels.dataTable.barnID, flex: '1 0 250px', renderer: this.renderBarnIdColumn },
      { label: labels.dataTable.capacity, flex: '1 0 100px', renderer: this.renderUserRoleColumn },
      { label: labels.dataTable.status, flex: '1 0 100px', renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 150px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    return (
      <Panel className="FarmBarns">
        <Panel.Heading title={labels.barns} />
        <Panel.Body noPadding>
          {!isEmptyTable && (
            <TableFilter
              filters={tableFilters}
              activeFilter={status}
              onFilterChange={this.onStatusChange}
              className="ph-10"
            >
              {!isEmpty(resources) && (
                <>
                  <Link
                    className="importTableBtn"
                    to={`/csv-import/select-mode?from=barns&entityId=${farmId}`}
                  >
                    <FormattedMessage id="general.import" />
                  </Link>
                  <Link to={`/admin/farms/${farmId}/barns/create`} className="button small primary wider">
                    <FormattedMessage id="general.createBarn" />
                  </Link>
                </>
              )}
            </TableFilter>
          )}

          <Preloader isActive={isLoading || isBarnUpdating} />

          <DataTable
            data={resources}
            columns={columns}
            isExpandable={isTablet}
            renderExpandable={this.renderExpandable}
            getRowClass={({ id }) => cn({ 'last-row': lastBarn.id === id })}
            onOrderUpdate={this.onOrderUpdate}
            scrollable
            isDraggable={!status}
            tableKey="farmBarns"
          />

          <NothingBox
            itemsName="barns"
            display={!resources.length}
            isLoading={isLoading}
            filter={status}
          >
            <FormattedMessage tagName="h1" id="component.nothingBox.farmHasNoBarns" />
            <Link
              className="importBtnEmpty"
              to={`/csv-import/select-mode?from=barns&entityId=${farmId}`}
            >
              <FormattedMessage id="general.import" />
            </Link>
            <Link to={`/admin/farms/${farmId}/barns/create`} className="button primary mt-10">
              <FormattedMessage id="general.createBarn" />
            </Link>
            <RolesPlaceholder />
          </NothingBox>

        </Panel.Body>
      </Panel>

    );
  }
}

FarmBarns.propTypes = {
  resources: T.array.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  updateData: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  params: T.object,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.farmBarns.resources,
      reqParams: state.dataTable.farmBarns.params,
      isLoading: state.dataTable.farmBarns.isLoading,
    }), (dispatch, { params: { id } }) => ({
      fetchData: (query) => dispatch(fetchData(`/admin/farms/${id}/barns`, 'farmBarns', query)),
      setDataItem: (data) => dispatch(setDataItem(data, 'farmBarns')),
      updateData: (res) => dispatch(updateData(res, 'farmBarns')),
    })
  ),
);

export default enhance(FarmBarns);
