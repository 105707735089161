import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setCurrentCompany } from 'reducers/auth';
// components
import EditTenantForm from 'components/SuperAdmin/TenantForm/EditTenantForm';
import { FormattedMessage } from 'react-intl';
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import Button from 'components/Button';
import CompanyProfileHeader from 'components/ProfileHeader/CompanyProfileHeader/CompanyProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import { TenantStatusBadge } from 'components/StatusBadge/StatusBadge';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
import pick from 'lodash.pick';
import moment from 'moment';
// superAdmin API
import { getTenant, deactivateTenant, activateTenant, updateTenant } from 'endpoints/superAdmin';
// styles
import './TenantEdit.scss';
// constants
import { tenantExpirationOptions, requiredTenantFields } from 'constants.js';

function formatTenantResponse(tenant) {
  let status = tenant.tenant_status;
  const daysToExpire = moment(tenant.expires_at).diff(new Date(), 'days');
  if (tenant.tenant_status === 'trial') {
    if (daysToExpire === 0) {
      status = 'trial_expired_today';
    } else {
      status = 'trial_expired_in_days';
    }
  }
  return { ...tenant, status, daysToExpire };
}

function calcExpiredDate(submitData) {
  if (submitData.status === 'trial' && submitData.expires_at) {
    return moment().add(submitData.expires_at, 'days').format();
  }
  if (submitData.status === 'full_access') return null;
  return undefined;
}

const TenantEdit = ({
  currentCompany,
  params: { id },
  router,
  setCurrentCompany,
}) => {
  const [{ tenant, isLoading }, setState] = useState({
    tenant: null,
    isLoading: false,
  });
  const [expiresDaysCount, setExpiresDaysCount] = useState(5);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    getTenant(id)
      .then((tenant) => {
        setState({ tenant: formatTenantResponse(tenant), isLoading: false });
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        router.push('/farmfeed');
      });
  }, [id]);

  const updateTenantPromise = (request) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    return request()
      .then((tenant) => {
        setState({ tenant: formatTenantResponse(tenant), isLoading: false });
        return tenant;
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const handleDeactivateTenant = () => {
    return updateTenantPromise(() => deactivateTenant(id))
      .then(() => {
        showToastrMessage('component.toastr.tenant.deactivated');
      });
  };

  const handleActivateTenant = () => {
    return updateTenantPromise(() => activateTenant(id))
      .then(() => {
        showToastrMessage('component.toastr.tenant.activated');
      });
  };

  const handleApplyTrialPeriod = () => {
    return updateTenantPromise(
      () => updateTenant(id, { expires_at: moment(new Date()).add(expiresDaysCount, 'days').format() })
    )
      .then(() => {
        showToastrMessage('component.toastr.tenant.trialApplied');
      });
  };

  const handleSubmitForm = (data) => {
    const entity = pick(data, requiredTenantFields);
    const resource = { ...entity, expires_at: calcExpiredDate(data) };

    setState((prevState) => ({ ...prevState, isLoading: true }));
    return updateTenant(id, resource)
      .then((resource) => {
        if (resource.id === currentCompany.id) {
          setCurrentCompany(resource);
        }
        router.push('/super-admin/tenants');
        showToastrMessage('component.toastr.tenant.updated');
      })
      .catch((errors) => {
        toastResponseErrors(errors);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  const companyStats = [
    { label: <FormattedMessage id="general.activeUsers" />, value: tenant?.active_users_count || 'N/A' },
    { label: <FormattedMessage id="general.since" />, value: moment(tenant?.created_at).format('MMM, YYYY') },
  ];

  const dropdownOptions = [
    { label: <FormattedMessage id="general.tenants.visit" />, onClick: () => router.push(`/switch-company/${id}`) },
    { label: <FormattedMessage id="general.tenants.activate" />, onClick: handleActivateTenant,
      hide: tenant?.tenant_status !== 'deactivated' },
    { label: <FormattedMessage id="general.tenants.deactivate" />, onClick: handleDeactivateTenant,
      hide: !tenant || tenant?.tenant_status === 'deactivated' },
  ];

  const trialExpiredByStatus = tenant?.tenant_status === 'trial_expired';
  const trialExpiredByDate = (tenant?.daysToExpire || 0) < 0;
  const initialValues = tenant
    ? { ...pick(tenant, requiredTenantFields), status: tenant.expires_at ? 'trial' : 'full_access' }
    : undefined;

  return (
    <div className="small-12 column TenantEdit">
      <CompanyProfileHeader
        company={tenant || {}}
        isLoading={isLoading}
        actualCompanyId={Number(id)}
        type="tenant"
        statusBadge={<TenantStatusBadge status={tenant?.status} textValues={{ daysToExpire: tenant?.daysToExpire }} />}
        className="tenant-profile-header"
        dropdownOptions={dropdownOptions}
      >
        <ProfileHeaderStats stats={companyStats} className="tenant-stats1" />
      </CompanyProfileHeader>
      <section>
        <div className="small-12 column">
          {(trialExpiredByStatus || trialExpiredByDate) && (
            <div className="panel trial-expired-panel">
              <div className="panel-heading">
                <i className="fa fa-exclamation-triangle" />
                <FormattedMessage id="component.tenantEdit.periodHasExpired" />
              </div>
              <div className="panel-body">
                <div className="extend-trial">
                  <FormattedMessage id="component.tenantEdit.extendPeriodBy" />
                  <ResponsiveSelect
                    grey
                    noInputMargin
                    className="expires-days-select"
                    options={tenantExpirationOptions}
                    input={{ value: expiresDaysCount, onChange: setExpiresDaysCount }}
                  />
                </div>
                <div>
                  <Button primary onClick={handleApplyTrialPeriod}>
                    <FormattedMessage id="general.button.apply" />
                  </Button>
                </div>
              </div>
            </div>
          )}
          <Panel>
            <Panel.Heading>
              <h2 className="lighter">
                <FormattedMessage id="component.tenantEdit.tenantInfo" />
              </h2>
            </Panel.Heading>
            <Panel.Body>
              <Preloader isActive={isLoading} />
              <EditTenantForm initialValues={initialValues} onSubmit={handleSubmitForm} />
            </Panel.Body>
          </Panel>
        </div>
      </section>
    </div>
  );
};

TenantEdit.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  currentCompany: T.object,
  setCurrentCompany: T.func,
};

export default connect((state) => ({
  currentCompany: state.auth.user.current_company,
}), {
  setCurrentCompany,
})(TenantEdit);
