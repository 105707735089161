import React, { useEffect, useState } from 'react';
// components
import Preloader from 'components/Preloader';
import ToggleSwitcher from 'components/ToggleSwitcher';
import Divider from 'components/Divider';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { ADDITIONAL_CATEGORY_KEY } from 'utils/SVRHelper';
// api
import { getSVRCategories } from 'endpoints/svr';
import { toggleSVRQuestion } from 'endpoints/admin/svr';
// styles
import styles from '../SiteVisitReports.module.scss';

const AdditionalSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [{ name, settings, id }, setAdditionalSettings] = useState({ name: '', settings: [], id: '' });

  const fetchAdditionalSettings = async () => {
    try {
      setIsLoading(true);
      const SVRCategories = await getSVRCategories();
      const additionalSettings = SVRCategories.find(({ item_key }) => item_key === ADDITIONAL_CATEGORY_KEY);
      setAdditionalSettings({
        name: additionalSettings.name,
        settings: additionalSettings.svr_questions,
        id: additionalSettings.id,
      });
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdditionalSettings();
  }, []);

  const onToggle = (settingData) => async () => {
    try {
      setIsLoading(true);
      await toggleSVRQuestion(id, settingData.id);
      setAdditionalSettings((prevSettings) => ({
        ...prevSettings,
        settings: settings.map((setting) => (setting.id === settingData.id ?
          { ...setting, is_enabled: !setting.is_enabled } :
          setting
        ))
      }));
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  const togglerRenderer = (settingData, index) => (
    <div key={index} className="mt-20">
      <ToggleSwitcher
        className={styles.toggler}
        text={settingData.label}
        checked={settingData.is_enabled}
        onChange={onToggle(settingData)}
      />
      <Divider className="mt-20" />
    </div>
  );

  const settingsCount = settings.length;

  if (!settingsCount) {
    return null;
  }

  const enabledSettingsCount = settings.filter(({ is_enabled }) => is_enabled).length;

  return (
    <div className={styles['additional-section']}>
      <Preloader isActive={isLoading} />
      <div className={cn(styles.title, 'pb-15')}>
        <span className="semibold">{name}</span>
        &nbsp;
        {enabledSettingsCount}/{settingsCount}
      </div>
      {settings.map(togglerRenderer)}
    </div>
  );
};

export default AdditionalSection;
