import { useIntl } from 'react-intl';
import { userFilterSectionTypes } from 'utils/constants/userFilters';

export default function useAvailFarmFeedFilterSections() {
  const { formatMessage } = useIntl();
  return [
    {
      value: userFilterSectionTypes.date,
      label: formatMessage({ id: 'general.eventDate' })
    },
    {
      value: userFilterSectionTypes.aiTreatmentPrediction,
      label: formatMessage({ id: 'component.advancedSearchItem.aiTreatment' }),
    },
    {
      value: userFilterSectionTypes.aiMortalityPrediction,
      label: formatMessage({ id: 'component.advancedSearchItem.aiMortality' }),
    },
    {
      value: userFilterSectionTypes.aiImagePrediction,
      label: formatMessage({ id: 'component.advancedSearchItem.aiComputerVision' }),
    },
    {
      value: userFilterSectionTypes.dcAsset,
      label: formatMessage({ id: 'component.advancedSearchItem.dailyCheckupMedia' }),
    },
    {
      value: userFilterSectionTypes.svr,
      label: formatMessage({ id: 'general.siteVisitReport' }),
    },
    {
      value: userFilterSectionTypes.pigGroupStatus,
      label: formatMessage({ id: 'general.groupStatus' }),
    },
    {
      value: userFilterSectionTypes.pigGroup,
      label: formatMessage({ id: 'general.groupId' }),
    },
    {
      value: userFilterSectionTypes.pigGroupStartDate,
      label: formatMessage({ id: 'general.startDate' }),
    },
    {
      value: userFilterSectionTypes.pigGroupCloseDate,
      label: formatMessage({ id: 'general.closeDate' }),
    },
    {
      value: userFilterSectionTypes.company,
      label: formatMessage({ id: 'general.companyName' }),
    },
    {
      value: userFilterSectionTypes.dcInventory,
      label: formatMessage({ id: 'general.pageTitle.inventory' }),
    },
    {
      value: userFilterSectionTypes.dcMortalityRate,
      label: formatMessage({ id: 'general.mortalityRate' }),
    },
    {
      value: userFilterSectionTypes.dcEstimatedWeight,
      label: formatMessage({ id: 'general.estAvgWeight' }),
    },
    {
      value: userFilterSectionTypes.compliance,
      label: formatMessage({ id: 'general.compliance' }),
    },
    {
      value: userFilterSectionTypes.farm,
      label: formatMessage({ id: 'general.farmName' }),
    },
    {
      value: userFilterSectionTypes.farmType,
      label: formatMessage({ id: 'general.farmType' }),
    },
    {
      value: userFilterSectionTypes.farmLocation,
      label: formatMessage({ id: 'general.location' }),
    },
    {
      value: userFilterSectionTypes.farmExternalLinkId,
      label: formatMessage({ id: 'general.farmId' }),
    },
    {
      value: userFilterSectionTypes.farmFederalPremiseId,
      label: formatMessage({ id: 'general.federalPremiseId' }),
    },
    {
      value: userFilterSectionTypes.flagged,
      label: formatMessage({ id: 'general.pageTitle.flaggedactivities' }),
    },
    {
      value: userFilterSectionTypes.user,
      label: formatMessage({ id: 'general.user' }),
    },
    {
      value: userFilterSectionTypes.dcDiagnoses,
      label: formatMessage({ id: 'general.diagnoses' }),
    },
  ];
}
