import React, { useRef } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchMentions } from 'reducers/mentions';
// components
import { MentionsInput as ReactMentionsInput, Mention } from 'react-mentions';
import { useIntl } from 'react-intl';
import UserAvatar from 'components/UserAvatar';
// utils
import cn from 'classnames';
import { getCachedMentionsKey } from 'utils';
import { isIOS } from 'react-device-detect';
// assets
import './MentionsInput.scss';

const renderUserSuggestion = (suggestion) => {
  const { user, display } = suggestion;
  if (user) return (
    <div className="user-suggestion-row">
      <UserAvatar user={user} className="user-avatar" size="40" />
      <div>
        <div className="semibold">{user.full_name || user.email}</div>
        <div className="muted">{display}</div>
      </div>
    </div>
  );

  return (
    <div>
      <span className="semibold">{display}</span>
    </div>
  );
};

const MentionsInput = ({
  comment,
  disabledIds = [],
  entityId,
  entityType,
  fetchMentions,
  hasMentionBtn = false,
  isEditCommentMode = false,
  inputType = 'circle',
  mentionsList,
  mentionRoles,
  onCommentChange,
  onMentionsChange,
  placeholder,
}) => {
  const { formatMessage } = useIntl();
  const mentionsInputRef = useRef();
  const filteredMentionsList = disabledIds.length
    ? mentionsList.filter((item) => !disabledIds.includes(item.id))
    : mentionsList;

  const handleFetchMentions = () => fetchMentions(entityId, entityType, mentionRoles);

  const handleChangeEvent = () => {
    mentionsInputRef.current?.wrappedInstance.refs.input.focus();
    if (comment.length && comment.substring(comment.length - 1) !== '@') onCommentChange(comment + ' @');
    if (!comment.length) onCommentChange('@');
  };

  const handleMentionsOpen = () => {
    if (!mentionsList.length) {
      handleFetchMentions().then(handleChangeEvent);
      return;
    }
    handleChangeEvent();
  };

  const handleChange = (event, comment, newPlainTextValue, mentions) => {
    onCommentChange(comment);
    onMentionsChange(mentions);
  };

  const mentionsInputPlaceholder = !placeholder && placeholder !== ''
    ? formatMessage({ id: 'component.comments.writeComment' })
    : placeholder;

  return (
    <div
      className={cn('MentionsInputBox', {
        isIOS,
        'is-edit-comment': isEditCommentMode,
        'is-square-input': inputType === 'square',
      })}
    >
      <ReactMentionsInput
        value={comment || ''}
        onChange={handleChange}
        onFocus={!mentionsList.length ? handleFetchMentions : null}
        markup="@[__display__(__id__)]"
        placeholder={mentionsInputPlaceholder}
        className="MentionsInput"
        ref={mentionsInputRef}
      >
        <Mention
          trigger="@"
          type="user"
          data={filteredMentionsList}
          renderSuggestion={renderUserSuggestion}
          appendSpaceOnAdd
        />
      </ReactMentionsInput>
      {hasMentionBtn && <div className="open-mentions-btn" onClick={handleMentionsOpen}>@</div>}
    </div>
  );
};

MentionsInput.propTypes = {
  fetchMentions: T.func.isRequired,
  entityId: T.oneOfType([T.number, T.string]).isRequired,
  entityType: T.string.isRequired,
  mentionsList: T.array.isRequired,
  onCommentChange: T.func.isRequired,
  onMentionsChange: T.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  customMentionsList: T.array,
  disabledIds: T.array,
  inputType: T.oneOf(['square', 'circle']),
  isEditCommentMode: T.bool,
  hasMentionBtn: T.bool,
  mentionRoles: T.array,
  comment: T.string,
  placeholder: T.string,
};

export default connect(
  (state, { mentionRoles, entityId, entityType, customMentionsList }) => ({
    mentionsList: customMentionsList || state.mentions[getCachedMentionsKey(entityType, entityId, mentionRoles)] || [],
  }), {
    fetchMentions,
  }
)(MentionsInput);
