/* eslint no-console: 0 */
import React, { Component } from 'react';
import T from 'prop-types';
import { API_URL } from '../../constants';
// components
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router';
import Button from 'components/Button';
import VideoPreviewOverlay from '../VideoPreviewOverlay';
import AudioPreview from 'components/AudioPreview';
import Preloader from 'components/Preloader';
// redux
import { connect } from 'react-redux';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { bytesToSize, isImageAsset, isVideoAsset, isAudioAsset, isAudioRecordSupported } from 'utils';
import { addMediaRequest, deleteAssetFromMediaRequest, updateCheckupOffline,
  generateAssetObject } from 'utils/offlineHelper';
import { getAuthData } from 'utils/authData';
import { toastr } from 'react-redux-toastr';
import Resumable from 'resumablejs';
import cn from 'classnames';
import findIndex from 'lodash.findindex';
// endpoints
import { getCheckupChunkAsset } from 'endpoints/checkup/checkupEdit';
// assets
import './MediaUploader.scss';

let dragCounter = 0;
const body = document.getElementById('root');

const types = ['jpg', 'jpeg', 'gif', 'png', 'mp3', 'm4a', 'aac', 'webm', 'wav', 'mov', 'mp4', 'avi'];
const fileTypes = types.concat(types.map((ext) => ext.toUpperCase()));

class MediaUploader extends Component {

  state = {
    filesInQueue: [],
    dragOver: false,
  };

  componentDidMount() {
    if (document) {
      body.addEventListener('dragenter', this.onDragEnter);
      body.addEventListener('dragleave', this.onDragLeave);
      body.addEventListener('drop', this.onDropEevent);
      this.initializeFileUploader();
    }
  }

  componentWillUnmount() {
    if (document) {
      body.removeEventListener('dragenter', this.onDragEnter);
      body.removeEventListener('dragleave', this.onDragLeave);
      body.removeEventListener('drop', this.onDropEevent);
    }
  }

  // File Uploader

  initializeFileUploader = () => {
    const { checkup: { id }, paramsCheckupId } = this.props;
    const uploadPath = `${API_URL}/daily_checkups/${paramsCheckupId || id}/chunk`;
    const R = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 3,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      fileType: fileTypes,
      fileTypeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          className: 'file-type-error',
          component: (
            <FormattedMessage
              id="component.toastr.availFileTypes.text"
              values={{ filename: file.name, types: types.join(', ') }}
            >
              {(text) => <div className="rrt-text">{text}</div>}
            </FormattedMessage>
          ),
        });
      },
      maxFileSizeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          component: (
            <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
              {(text) => (<div className="rrt-text">{text}</div>)}
            </FormattedMessage>
          ),
        });
      },
    });

    if (R.support) {
      const browseButton = document.getElementById('resumable-browse');
      const browseLink = document.getElementById('resumable-browse-link');
      const dropzoneElement = document.getElementById('resumable-dropzone');
      R.assignDrop(dropzoneElement);
      R.assignBrowse([browseButton, browseLink]);
      R.on('fileAdded', this.onFileAdded);
      R.on('fileSuccess', this.onFileSuccess);
      R.on('fileError', this.onFileError);
      R.on('fileProgress', this.updateFileProgress);
    } else {
      console.warn('ResumableJS not supported!');
    }
  };

  onFileAdded = (file) => {
    const { validateCheckup, checkup, isOnline, uploadAssetComplete } = this.props;
    const R = file.resumableObj;
    const { filesInQueue } = this.state;
    this.setState({ dragOver: false });
    dragCounter = 0;
    if (validateCheckup(checkup)) {

      this.setState({ filesInQueue: [...filesInQueue, file] });

      if (isOnline) {
        R.upload();
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        addMediaRequest(checkup, { uniqueIdentifier: file.uniqueIdentifier, file: file.file, notes: '' });
        const newAsset = generateAssetObject(file, { dc_id: checkup.id, url: { original: reader.result } });
        uploadAssetComplete(newAsset);
        this.removeFileFromQueue(file);
      };
    }
  };

  updateFileProgress = (file) => {
    const { filesInQueue } = this.state;
    const index = findIndex(filesInQueue, { uniqueIdentifier: file.uniqueIdentifier });
    const updatedFile = {
      ...filesInQueue[index],
      uploadProgress: file.progress(),
      cancelable: file.progress() < 0.7
    };
    this.setState({
      filesInQueue: [
        ...filesInQueue.slice(0, index),
        updatedFile,
        ...filesInQueue.slice(index + 1),
      ],
    });
  };

  removeFileFromQueue = (file) => {
    const { filesInQueue } = this.state;
    const fileIndex = findIndex(filesInQueue, { uniqueIdentifier: file.uniqueIdentifier });
    this.setState({
      filesInQueue: [
        ...filesInQueue.slice(0, fileIndex),
        ...filesInQueue.slice(fileIndex + 1),
      ],
    });
  };

  onFileSuccess = (file) => {
    const { checkup, uploadAssetComplete } = this.props;
    getCheckupChunkAsset(checkup.id, { chunk_identifier: file.uniqueIdentifier })
      .then((asset) => {
        setTimeout(() => {
          const newAsset = { ...asset, dc_id: checkup.id };
          uploadAssetComplete(newAsset);
          updateCheckupOffline({
            ...checkup,
            dc_assets: [newAsset, ...checkup.dc_assets]
          });
          this.removeFileFromQueue(file);
        }, 500);
      })
      .catch(toastResponseErrors);
  };

  onFileError = (file, message) => {
    console.log('ResumableJS: onFileError', file.fileName);
    toastResponseErrors(message);
  };

  cancelUpload = (file) => () => {
    const { isOnline, checkup } = this.props;
    if (isOnline) {
      file.cancel();
    } else {
      deleteAssetFromMediaRequest(checkup.id, file.uniqueIdentifier);
    }
    this.removeFileFromQueue(file);
  };

  // Drag & Drop

  onDragEnter = () => {
    dragCounter++;
    if (dragCounter === 1) {
      this.setState({ dragOver: true });
    }
  };

  onDragLeave = () => {
    dragCounter--;
    if (dragCounter === 0) {
      this.setState({ dragOver: false });
    }
  };

  onDropEevent = () => {
    dragCounter = 0;
    this.setState({ dragOver: false });
  };

  // Assets Actions
  openMediaViewer = (asset) => {
    const { openViewer, checkup, isOnline } = this.props;
    if (!isOnline) return;
    const assets = checkup.dc_assets.filter((item) => isImageAsset(item) || isVideoAsset(item));
    const assetIndex = findIndex(assets, { id: asset.id });

    openViewer(assets, assetIndex, checkup, 'uploader');
  };

  getAssetPreviewUrl = (asset) => {
    const { url } = asset;
    return url.thumb || url.small || url.medium || url.original || url.origin;
  };

  render() {
    const { checkup: { dc_assets, id }, deleteAsset, headerLess, isUploading } = this.props;
    const { filesInQueue, dragOver } = this.state;
    const assetsList = dc_assets.filter((asset) => !asset.category);
    const isDropzoneEmpty = !assetsList.length && !filesInQueue.length;

    return (
      <div className="MediaUploader">
        <Preloader isActive={isUploading} />

        {!headerLess && (
          <div className="uploader-header">
            <FormattedMessage id="general.media">
              {(text) => (
                <div className="segment-title">
                  {text} • <span>{assetsList.length}</span>
                </div>
              )}
            </FormattedMessage>
            {isAudioRecordSupported() && (
              <Link
                to={`/daily-checkup/${id}/record-audio`}
                className="button light show-for-large record-audio-button"
              >
                <i className="fa fa-mic color-primary" />
              </Link>
            )}
          </div>
        )}

        <div className={cn('uploader-body', { 'empty': isDropzoneEmpty, 'dragOver': dragOver })}>

          <div id="resumable-dropzone" className={cn('dropzone show-for-large', { 'active': dragOver })} />

          <div className={cn('dropzone-placeholder show-for-large', { 'minimized': !isDropzoneEmpty })}>
            <i className="fa fa-cloud-upload-ios" />
            <FormattedMessage
              id="component.mediaUploader.dropFilesOrBrowse"
              values={{
                textLink: (
                  <a id="resumable-browse-link">
                    <FormattedMessage id="component.mediaUploader.browse" />
                  </a>
                ),
              }}
            />
          </div>

          <div className="assets-list">

            {/* FILES IN QUEUE */}
            { !!filesInQueue.length &&
              filesInQueue.map((file, index) => {
                const progress = Math.ceil((file.uploadProgress || 0) * 99);
                const progressBarStyle = { width: `${progress}%` };

                return (
                  <div className="asset-wrapper" key={`${index}-${file.uniqueIdentifier}`}>
                    <div className="assets-item">
                      <div className="asset-info" title={file.fileName}>
                        <div className="uploading-asset-info">
                          <div className="asset-name">{file.fileName}</div>
                          <div className="asset-size">{bytesToSize(file.size)}</div>
                        </div>
                        <div className="upload-progress">
                          <div className="progress-value" style={progressBarStyle} />
                        </div>
                        <FormattedMessage id="general.uploadingProgress" values={{ progress }}>
                          {(text) => (
                            <div className="asset-description">{text}</div>
                          )}
                        </FormattedMessage>
                      </div>
                      {file.cancelable && (
                        <div className="asset-actions">
                          <i className="fa fa-times" onClick={this.cancelUpload(file)} />
                        </div>
                      )}
                    </div>
                  </div>

                );
              })}

            {/* UPLOADED ASSETS */}
            {!!assetsList.length &&
              assetsList.map((asset, index) => {
                if (isAudioAsset(asset)) {
                  return (
                    <div className="asset-wrapper" key={`${index}-${asset.id}`}>
                      <AudioPreview
                        controlSize="40"
                        className="assets-item audio-item"
                        onDelete={() => deleteAsset(asset.id)}
                        asset={asset}
                      />
                      {asset.description && (
                        <div className="asset-note">
                          <div className="title">
                            <FormattedMessage id="general.note" />
                          </div>
                          <div className="description">
                            {asset.description}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }

                return (
                  <div className="asset-wrapper" key={`${index}-${asset.id}`}>
                    <div className="assets-item">
                      <div className="asset-preview">
                        <div
                          className="image"
                          style={{ backgroundImage: 'url(' + this.getAssetPreviewUrl(asset) + ')' }}
                          onClick={() => this.openMediaViewer(asset)}
                        />
                        {isVideoAsset(asset) && <VideoPreviewOverlay size="30" simple />}
                      </div>
                      <div className="asset-info" title={asset.filename}>
                        <div className="asset-name">
                          <a onClick={() => this.openMediaViewer(asset)}>{asset.filename}</a>
                        </div>
                        <div className="asset-description">
                          <FormattedMessage id={`general.${isVideoAsset(asset) ? 'video' : 'image'}`} />
                          {' | '}
                          {bytesToSize(asset.media_size)}
                        </div>
                      </div>
                      <div className="asset-actions">
                        <i className="fa fa-trash-o" onClick={() => deleteAsset(asset.id)} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="uploader-footer">
          {isAudioRecordSupported() && (
            <Link to={`/daily-checkup/${id}/record-audio`} className="button light hide-for-large">
              <i className="fa fa-mic color-primary" />
            </Link>
          )}
          <Button light id="resumable-browse" className="hide-for-large">
            <i className="fa fa-camera-fa color-primary" />
          </Button>
        </div>
      </div>
    );
  }
}

MediaUploader.propTypes = {
  paramsCheckupId: T.string,
  checkup: T.object.isRequired,
  isUploading: T.bool,
  uploadAssetComplete: T.func.isRequired,
  openViewer: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  headerLess: T.bool,
  isOnline: T.bool,
};

export default connect(
  (state) => ({
    isOnline: state.network.isOnline,
  }),
)(MediaUploader);
