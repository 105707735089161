import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateUserSetting, fetchUserSetting } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import PreferenceCollapse from './components/PreferenceCollapse';
import PreferenceItem from './components/PreferenceItem';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import ToggleSwitcher from 'components/ToggleSwitcher';
import Preloader from 'components/Preloader';
import CircleCheckbox from 'components/CircleCheckbox/CircleCheckbox';
import BrowserSection from './components/BrowserSection';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isEmpty from 'lodash.isempty';
import { isEqualKeys } from 'utils';
// helper
import { notifiedAboutSettings, emailSettings, smsSettings, pushSettings, smsSettingsKeys, messagingSettingsKeys,
  emailSettingsKeys, pushSettingsKeys, tabsSubtitleKeys, findSubtitleTitleKey } from './components/helper';
// styles
import './Messaging.scss';
// assets
import everyPigImage from '../../../public/static/icons/icon_32x32.png';

class Messaging extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      messagingTabSubtitleKey: 'notificationsOff',
      messagingTabSubtitleSoundsKey: 'soundsOff',
      emailTabSubtitleKey: 'notificationsOff',
      deviceTabSubtitleKey: 'notificationsOff',
      smsTabSubtitleKey: 'notificationsOff',
      ...this.setSubtitles(props),
    };
  }

  componentDidMount() {
    const { user: { id }, fetchUserSetting, isLoaded } = this.props;
    if (!isLoaded) fetchUserSetting(id).catch(toastResponseErrors);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userSetting } = this.props;

    tabsSubtitleKeys.map(({ key, data }) => (
      this.isEqualPreferencesKeys(key, data, nextProps.userSetting, userSetting)
    ));

    if (nextProps.userSetting.sounds_effects !== userSetting.sounds_effects) {
      const soundsEffectsKey = nextProps.userSetting.sounds_effects ? 'soundsOn' : 'soundsOff';
      this.setState({ messagingTabSubtitleSoundsKey: soundsEffectsKey });
    }
  }

  isEqualPreferencesKeys = (tabSubtitle, tabSubtitleKeys, nextUserSetting, currentUserSetting) => {
    if (!isEqualKeys(tabSubtitleKeys, nextUserSetting, currentUserSetting)) {
      this.setState({ [tabSubtitle]: findSubtitleTitleKey(nextUserSetting, tabSubtitleKeys) });
    }
  };

  setSubtitles = ({ userSetting }) => {
    if (isEmpty(userSetting)) return {};
    return {
      smsTabSubtitleKey: findSubtitleTitleKey(userSetting, smsSettingsKeys),
      emailTabSubtitleKey: findSubtitleTitleKey(userSetting, emailSettingsKeys),
      deviceTabSubtitleKey: findSubtitleTitleKey(userSetting, pushSettingsKeys),
      messagingTabSubtitleKey: findSubtitleTitleKey(userSetting, messagingSettingsKeys),
      messagingTabSubtitleSoundsKey: userSetting.sounds_effects ? 'soundsOn' : 'soundsOff',
    };
  };

  onCollapseToggle = (currentTab) => (
    this.setState((prevState) => ({ activeTab: currentTab !== prevState.activeTab ? currentTab : null }))
  );

  renderMessagingTabSubtitleKey = () => {
    const { messagingTabSubtitleKey, messagingTabSubtitleSoundsKey } = this.state;
    return (
      <span>
        <FormattedMessage id={`component.messaging.${messagingTabSubtitleKey}`} />
        {', '}
        <FormattedMessage id={`component.messaging.${messagingTabSubtitleSoundsKey}`} />
      </span>
    );
  };

  renderSmsTabSubtitleKey = () => {
    const { user: { phone } } = this.props;
    if (!phone) return 'container.myProfile.settings.smsActivation.subtitle';
    return `component.messaging.${this.state.smsTabSubtitleKey}`;
  };

  handleRadioEmailChanged = (newValue) => {
    const { user: { id }, updateUserSetting } = this.props;

    const newEmailState = {};
    emailSettingsKeys.forEach((valueKey) => {
      newEmailState[valueKey] = newValue;
    });
    updateUserSetting(newEmailState, id).catch(toastResponseErrors);
  };

  checkEmailPreferences = () => {
    const { userSetting } = this.props;
    const checkedItems = emailSettingsKeys.filter((item) => userSetting[item]);
    return checkedItems.length > 0;
  };

  handleChange = (valueKey) => {
    const { user: { id }, updateUserSetting, userSetting } = this.props;
    updateUserSetting({ [valueKey]: !userSetting[valueKey] }, id).catch(toastResponseErrors);
  };

  isNotificationAvailable = (type) => {
    const { isLogisticsEnabled, isSVREnabled, isTreatmentProtocolsEnabled } = this.props;
    return type ? {
      logistics: isLogisticsEnabled,
      svr: isSVREnabled,
      treatmentProtocols: isTreatmentProtocolsEnabled,
    }[type] : true;
  }

  render() {
    const { user: { phone }, isLoading, userSetting } = this.props;
    const { isMobile } = this.context;
    const { activeTab, emailTabSubtitleKey, deviceTabSubtitleKey } = this.state;

    return (
      <CollapsesBox className="profile-messaging">
        <PreferenceCollapse
          isOpened={activeTab === 'messaging'}
          onToggle={() => this.onCollapseToggle('messaging')}
          icon={<img alt="Every Pig" src={everyPigImage} role="presentation" />}
          title={<FormattedMessage id="container.myProfile.messagingTab" />}
          subtitle={this.renderMessagingTabSubtitleKey()}
        >
          <Preloader isActive={(activeTab === 'messaging') && isLoading} />
          <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.sounds" tagName="strong" />}>
            <ToggleSwitcher
              checked={userSetting.sounds_effects}
              onChange={() => this.handleChange('sounds_effects')}
              text={<FormattedMessage id="container.myProfile.settings.soundsEffects" />}
              description={<FormattedMessage id="container.myProfile.settings.soundsEffects.desc" />}
            />
          </PreferenceItem>
          <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.notifiedAbout" tagName="strong" />}>
            {notifiedAboutSettings.map(({ valueKey, textKey, type }, index) => this.isNotificationAvailable(type) && (
              <ToggleSwitcher
                key={index}
                className="mv-20"
                checked={userSetting[valueKey]}
                onChange={() => this.handleChange(valueKey)}
                text={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}`} />}
                description={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}.desc`} />}
              />
            ))}
          </PreferenceItem>
        </PreferenceCollapse>

        <PreferenceCollapse
          isOpened={activeTab === 'email'}
          onToggle={() => this.onCollapseToggle('email')}
          icon={<i className="fa fa-envelope" />}
          title={<FormattedMessage id="general.email" />}
          subtitle={<FormattedMessage id={`component.messaging.${emailTabSubtitleKey}`} />}
        >
          <Preloader isActive={(activeTab === 'email') && isLoading} />
          <PreferenceItem
            title={<FormattedMessage id="container.myProfile.settings.email.myEmailPreference" tagName="strong" />}
          >
            <div className="email-preference">
              <CircleCheckbox
                className="email-manage mb-15"
                type="radio"
                label={<FormattedMessage id="container.myProfile.settings.email.CheckAllNotifications" />}
                name="email-preference-yes"
                onChange={() => this.handleRadioEmailChanged(true)}
                checked={this.checkEmailPreferences()}
              />
              <CircleCheckbox
                className="email-manage"
                type="radio"
                label={<FormattedMessage id="container.myProfile.settings.email.CheckImportantNotifications" />}
                name="email-preference-no"
                onChange={() => this.handleRadioEmailChanged(false)}
                checked={!this.checkEmailPreferences()}
              />
            </div>
          </PreferenceItem>
          <PreferenceItem
            title={<FormattedMessage id="container.myProfile.settings.email.allNotifications" tagName="strong" />}
          >
            {emailSettings.map(({ valueKey, textKey, type }, index) => this.isNotificationAvailable(type) && (
              <ToggleSwitcher
                key={index}
                className="mv-20"
                checked={userSetting[valueKey]}
                onChange={() => this.handleChange(valueKey)}
                text={<FormattedMessage id={`container.myProfile.settings.email.${textKey}`} />}
                description={<FormattedMessage id={`container.myProfile.settings.email.${textKey}.desc`} />}
              />
            ))}
          </PreferenceItem>
        </PreferenceCollapse>

        <PreferenceCollapse
          isOpened={activeTab === 'device'}
          onToggle={() => this.onCollapseToggle('device')}
          icon={<i className="fa fa-desktop" />}
          title={<FormattedMessage id="container.myProfile.deviceTab" />}
          subtitle={<FormattedMessage id={`component.messaging.${deviceTabSubtitleKey}`} />}
        >
          <Preloader isActive={(activeTab === 'device') && isLoading} />
          {!isMobile && (
            <PreferenceItem
              title={<FormattedMessage id="container.myProfile.settings.desktop.title" tagName="strong" />}
            >
              <FormattedMessage id="container.myProfile.settings.desktop.desc" />
              <BrowserSection />
            </PreferenceItem>
          )}
          <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.mobile.title" tagName="strong" />}>
            <FormattedMessage id="container.myProfile.settings.mobile.desc" />
          </PreferenceItem>
          <PreferenceItem title={<FormattedMessage id="container.myProfile.settings.push.all" tagName="strong" />}>
            {pushSettings.map(({ valueKey, textKey, type }, index) => this.isNotificationAvailable(type) && (
              <ToggleSwitcher
                key={index}
                className="mv-20"
                checked={userSetting[valueKey]}
                onChange={() => this.handleChange(valueKey)}
                text={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}`} />}
                description={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}.desc`} />}
              />
            ))}
          </PreferenceItem>
        </PreferenceCollapse>

        <PreferenceCollapse
          isOpened={activeTab === 'sms'}
          onToggle={() => this.onCollapseToggle('sms')}
          icon={<i className="fa fa-cellphone" />}
          title={<FormattedMessage id="container.myProfile.smsTab" />}
          subtitle={<FormattedMessage id={this.renderSmsTabSubtitleKey()} />}
        >
          <Preloader isActive={(activeTab === 'sms') && isLoading} />
          <PreferenceItem
            title={(
              <FormattedMessage
                id={`container.myProfile.settings.sms${phone ? 'Notifications' : 'Activation'}`}
                tagName="strong"
              />
            )}
          >
            {!phone && (
              <FormattedMessage
                id="container.myProfile.settings.smsActivation.text"
                values={{
                  linkedText: (
                    <FormattedMessage id="general.cellphoneNumber">
                      {(text) => (<Link to="/profile/edit#phone" className="link">{text}</Link>)}
                    </FormattedMessage>
                  ),
                }}
              />
            )}
            {phone && smsSettings.map(({ valueKey, textKey, type }, index) => this.isNotificationAvailable(type) && (
              <ToggleSwitcher
                key={index}
                className="mv-20"
                checked={userSetting[valueKey]}
                onChange={() => this.handleChange(valueKey)}
                text={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}`} />}
                description={<FormattedMessage id={`container.myProfile.settings.notification.${textKey}.desc`} />}
              />
            ))}
          </PreferenceItem>
        </PreferenceCollapse>
      </CollapsesBox>
    );
  }
}

Messaging.propTypes = {
  updateUserSetting: T.func.isRequired,
  fetchUserSetting: T.func.isRequired,
  user: T.object.isRequired,
  userSetting: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  isSVREnabled: T.bool.isRequired,
  isTreatmentProtocolsEnabled: T.bool.isRequired,
};

Messaging.contextTypes = {
  isMobile: T.bool,
};

export default connect(
  (state) => ({
    user: state.auth.user,
    isLogisticsEnabled: state.auth.user.current_company.logistics,
    userSetting: state.auth.user_setting,
    isLoading: state.auth.isSettingLoading,
    isLoaded: state.auth.isSettingLoaded,
    isSVREnabled: state.auth.user.current_company.svrs && state.auth.user.current_company.admin_svrs,
    isTreatmentProtocolsEnabled: state.auth.user.current_company.treatment_protocols &&
      state.auth.user.current_company.admin_treatment_protocols,
  }),
  { updateUserSetting, fetchUserSetting }
)(Messaging);
