import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { reportTreatments } from 'reducers/dailyCheckup/checkupEdit';
// components
import { FormattedMessage } from 'react-intl';
import OralTreatmentLabel from './TreatmentProductLabels/OralTreatmentLabel';
import InjectableTreatmentLabel from './TreatmentProductLabels/InjectableTreatmentLabel';
import CheckupSegment from './CheckupSegment/CheckupSegment';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// utils
import classnames from 'classnames/bind';
import { filterMedsByType } from 'utils';
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getCommentByRole } from 'helpers.js';
// styles
import styles from './ReportTreatmentsSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const { TREATMENTS_ROLE } = comment_roles;
const cn = classnames.bind(styles);

const ReportTreatmentsSegment = ({
  checkup,
  paramsCheckupId,
  isDisabled,
  isSubmitting,
  reportTreatments,
  validateCheckup,
  openModal,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const treatments = (checkup.dc_treatments || []).filter(({ _destroy }) => !_destroy);
  const treatment_count = treatments.length;

  const { injectable, oral, topical } = filterMedsByType(treatments);
  const isReported = checkup.treatment_count !== null;

  const protocolTreatmentsCount = checkup.up_to_date_tp_steps_count;

  const handleReportNoTreatments = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportTreatments(paramsCheckupId, { all_good: true }).catch(toastResponseErrors);
  };

  const handleOpenConfirmationModal = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.medications" />}
        actionBtnLabel={<FormattedMessage id="component.modal.confirmReportMigrations.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={handleReportNoTreatments}
      >
        <FormattedMessage id="component.modal.confirmReportMedications.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  return (
    <CheckupSegment
      isReported={isReported}
      isSubmitting={isSubmitting}
      isDisabled={isDisabled}
      isSegmentConditionChanged={isAllGoodReported}
      onEmptyDataSubmit={protocolTreatmentsCount ? handleOpenConfirmationModal : handleReportNoTreatments}
      itemsCount={treatments.length}
      titleKey="component.checkupSegment.doYouHaveMeds"
      titleKeyWhenReported="general.medications"
      reportPageUrl={`/daily-checkup/${paramsCheckupId}/report-treatments`}
      noData={isReported && !treatment_count}
      warningText={protocolTreatmentsCount
        ? (
          <FormattedMessage
            id="component.checkupSegment.pendingTreatmentsCount"
            values={{ count: protocolTreatmentsCount, a: (msg) => msg }}
          />
        )
        : null
      }
    >
      <div className={cn('reported-treatments')}>
        {!!treatment_count && (
          <>
            <div className={cn('treatment-title')}>
              <FormattedMessage id="container.checkupEdit.reportMedications.injectable" />
              <FormattedMessage id="general.meds.head" />
            </div>
            {injectable.map(({ id, quantity, head_count, treatment_product }) => (
              <div className={cn('treatment-line')} key={id || treatment_product.id}>
                <span>
                  <InjectableTreatmentLabel quantity={quantity} product={treatment_product} />
                </span>
                <span>{head_count}</span>
              </div>
            ))}
            {!injectable.length && (
              <div className={cn('treatment-line')}>
                <div className={cn('color-medium-gray')}>
                  <FormattedMessage id="general.none" />
                </div>
              </div>
            )}
            <div className={cn('treatment-title')}>
              <FormattedMessage id="container.checkupEdit.reportMedications.oral" />
              <FormattedMessage id="general.meds.head" />
            </div>
            {oral.map(({ id, quantity, head_count, gallons_value, treatment_product }) => (
              <div className={cn('treatment-line')} key={id || treatment_product.id}>
                <span>
                  <OralTreatmentLabel quantity={quantity} gallonsValue={gallons_value} product={treatment_product} />
                </span>
                <span>{head_count}</span>
              </div>
            ))}
            {!oral.length && (
              <div className={cn('treatment-line')}>
                <div className={cn('color-medium-gray')}>
                  <FormattedMessage id="general.none" />
                </div>
              </div>
            )}
            <div className={cn('treatment-title')}>
              <FormattedMessage id="container.checkupEdit.reportMedications.topical" />
              <FormattedMessage id="general.meds.head" />
            </div>
            {topical.map(({ id, treatment_product, head_count }) => (
              <div className={cn('treatment-line')} key={id || treatment_product.id}>
                <span>{treatment_product.name}</span>
                <span>{head_count}</span>
              </div>
            ))}
            {!topical.length && (
              <div className={cn('treatment-line')}>
                <div className={cn('color-medium-gray')}>
                  <FormattedMessage id="general.none" />
                </div>
              </div>
            )}
          </>
        )}
        <SegmentComment comment={getCommentByRole(checkup.comments || [], TREATMENTS_ROLE)} />
      </div>
    </CheckupSegment>
  );
};

ReportTreatmentsSegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  isDisabled: T.bool.isRequired,
  isSubmitting: T.bool.isRequired,
  reportTreatments: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingTreatments,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingTreatments,
  }), {
    reportTreatments,
    openModal,
  }
)(ReportTreatmentsSegment);
