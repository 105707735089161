import { fetchFromAPI } from 'utils/api';
import {
  deleteIDBSVR,
  getIDBSVRCategories,
  getIDBAllSVRs,
  getIDBFarmSVRs,
  getIDBSVR,
  getIDBSVRFarms,
  getIDBDetailedSVRFarm,
  deleteIDBExistedSVR,
  mergeSVRsToIDB,
  mergeSVRFarmsToIDB,
  mergeSVRDetailedFarmsToIDB,
  mergeIDBSVR,
  updateIDBSVRCategories,
} from 'utils/offlineSVRHelper';
import {
  getAnsweredSVRQuestions,
  uploadAssetToSVR,
  getMiscellaneousCategory,
  mergeCategoryAnswers,
  buildCustomSVRCategory,
} from 'utils/SVRHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';

export const fetchFarmsSVRs = (farmId, params) => {
  return navigator.onLine
    ? fetchFromAPI(`/farms/${farmId}/svrs`, { params })
      .then((response) => {
        mergeSVRsToIDB(response.resources);
        return response;
      })
      .catch((response) => {
        throw response;
      })
    : getIDBFarmSVRs(farmId, params);
};

export const createFarmSVR = (farmId, resource) => {
  return fetchFromAPI(`/farms/${farmId}/svrs`, { method: 'post', body: JSON.stringify({ resource }) })
    .then((response) => response.resource);
};

export const updateFarmSVR = (farmId, svrId, resource) => {
  return fetchFromAPI(`/farms/${farmId}/svrs/${svrId}`, { method: 'put', body: JSON.stringify({ resource }) })
    .then((response) => response.resource);
};

export const getFarmSVR = (farmId, svrId) => {
  return navigator.onLine
    ? fetchFromAPI(`/farms/${farmId}/svrs/${svrId}`)
      .then((response) => response.resource)
      .catch((response) => {
        throw response;
      })
    : getIDBSVR(svrId);
};

export const fetchSVRFarm = (farmId) => {
  return navigator.onLine
    ? fetchFromAPI(`/svrs/farms/${farmId}`)
      .then((response) => {
        mergeSVRDetailedFarmsToIDB(response.resource);
        return response.resource;
      })
      .catch((response) => {
        throw response;
      })
    : getIDBDetailedSVRFarm(farmId);
};

export const getSVRCategories = () => {
  if (!navigator.onLine) {
    return getIDBSVRCategories();
  }
  return fetchFromAPI('/svr_categories')
    .then((response) => {
      updateIDBSVRCategories(response.resources);
      return response.resources;
    })
    .catch((response) => {
      throw response;
    });
};

export const getSVRsFarms = (params = {}) => {
  return navigator.onLine
    ? fetchFromAPI('/svrs/farms', { params })
      .then((response) => {
        mergeSVRFarmsToIDB(response.resources);
        return response;
      })
      .catch((response) => {
        throw response;
      })
    : getIDBSVRFarms(params);
};

export const createSVRComment = (questionId, resource) => {
  return fetchFromAPI(`/svr_question_values/${questionId}/comments`, {
    method: 'post',
    body: JSON.stringify({ resource }),
  }).then((response) => response.resource);
};

export const updateSVRComment = (questionId, commentId, resource) => {
  return fetchFromAPI(`/svr_question_values/${questionId}/comments/${commentId}`, {
    method: 'put',
    body: JSON.stringify({ resource }),
  }).then((response) => response.resource);
};

export const deleteSVRComment = (questionId, commentId) => {
  return fetchFromAPI(`/svr_question_values/${questionId}/comments/${commentId}`, { method: 'delete' });
};

export const detachSVRAsset = (questionValueId, asset) => {
  return navigator.onLine ?
    fetchFromAPI(`/svr_question_values/${questionValueId}/assets/${asset.id}`, { method: 'delete' })
      .then(() => Promise.resolve({ ...asset, action: 'delete' })) :
    Promise.resolve({ ...asset, action: 'delete' });
};

export const deleteFarmDraftSVR = (farmId, svrId) => {
  return navigator.onLine
    ? fetchFromAPI(`/farms/${farmId}/svrs/${svrId}`, { method: 'delete' })
      .then(() => {
        deleteIDBSVR(+svrId);
      })
    : deleteIDBExistedSVR(svrId);
};

export const getSiteVisitReportPDF = (farmId, svrId) => {
  return fetchFromAPI(`/farms/${farmId}/svrs/${svrId}.pdf`, { blob: true });
};

// TODO: rewrite next functions, looks bad

export const attachSVRAssets = (answerId, assets) => {
  return navigator.onLine ? Promise.all(assets.map((mediaObj) => {
    return uploadAssetToSVR(answerId, mediaObj.asset);
  })) : Promise.resolve(assets.map((asset) => ({ ...asset, action: 'create' })));
};

const updateSVRAssets = (mediaQuestion) => {
  if (!mediaQuestion) return Promise.resolve();

  return Promise.all(mediaQuestion.answer.value.map((mediaObj) => {
    if (mediaObj.action === 'create') {
      return uploadAssetToSVR(mediaQuestion.answer.id, mediaObj.asset);
    }

    if (mediaObj.action === 'delete' && mediaObj.id) {
      return detachSVRAsset(mediaQuestion.answer.id, mediaObj);
    }

    return Promise.resolve();
  }));
};

export const updateSVRComments = (commentedCategories, updatedCategories) => {
  const comments = commentedCategories
    .map(({ svr_questions }) => svr_questions.map(({ answer }) => answer?.comments))
    .flat(2);
  const allQuestions = (updatedCategories || commentedCategories).map(({ svr_questions }) => svr_questions).flat(1);
  const actionComments = comments.filter((comment) => comment?.action);
  if (!actionComments.length) return Promise.resolve();
  return Promise.all(actionComments.map((comment) => {
    if (comment.action === 'create') {
      const answerId = allQuestions.find(({ item_key }) => item_key === comment.question_key)?.answer?.id;
      if (!answerId) return Promise.reject();
      return createSVRComment(answerId, comment)
        .catch(toastResponseErrors);
    }

    if (comment.action === 'update') {
      const answerId = allQuestions.find(({ item_key }) => item_key === comment.question_key)?.answer?.id;
      if (!answerId) return Promise.reject();
      return updateSVRComment(answerId, comment.id, comment)
        .catch(toastResponseErrors);
    }
    if (comment.action === 'delete' && comment.id) {
      const answerId = allQuestions.find(({ item_key }) => item_key === comment.question_key)?.answer?.id;
      if (!answerId) return Promise.reject();
      return deleteSVRComment(answerId, comment.id, comment)
        .catch(toastResponseErrors);
    }

    return Promise.resolve();
  }));
};

export const execSVRsSync = async (callback) => {
  try {
    const allSVRs = await getIDBAllSVRs();
    const needsActionReports = allSVRs.filter((report) => !!report.action);

    return needsActionReports.map(async (report) => {
      if (report.action === 'delete') {
        await deleteFarmDraftSVR(report.id, report.farm_id);
        await deleteIDBSVR(report.id);
        return Promise.resolve();
      }

      if (report.action === 'create') {
        const updatedSVR = await createFarmSVR(report.farm_id, {
          is_draft: report.is_draft,
          questions: getAnsweredSVRQuestions(report.svr_categories),
          custom_questions: report.svr_custom_questions,
        });
        await updateSVRComments(
          [...report.svr_categories, buildCustomSVRCategory(report.svr_custom_questions)],
          [...updatedSVR.svr_categories, buildCustomSVRCategory(updatedSVR.svr_custom_questions)]
        );
        const oldMiscellaneousCategory = getMiscellaneousCategory(report.svr_categories);
        const newMiscellaneousCategory = getMiscellaneousCategory(updatedSVR.svr_categories);
        const updatedCategory = mergeCategoryAnswers(oldMiscellaneousCategory, newMiscellaneousCategory);
        const mediaQuestion = updatedCategory.svr_questions
          .find(({ answer, question_type }) => question_type === 'media' && answer?.value?.length);
        await updateSVRAssets(mediaQuestion);
        await deleteIDBSVR(report.id);
        callback(report.id, updatedSVR.id);
        return Promise.resolve(updatedSVR);
      }

      if (report.action === 'update') {
        const resource = !report.is_draft ? { is_draft: false } : {};
        const updatedSVR = await updateFarmSVR(report.farm_id, report.id, {
          questions: getAnsweredSVRQuestions(report.svr_categories),
          custom_questions: report.svr_custom_questions,
          ...resource,
        });
        await updateSVRComments(
          [...report.svr_categories, buildCustomSVRCategory(report.svr_custom_questions)],
          [...updatedSVR.svr_categories, buildCustomSVRCategory(updatedSVR.svr_custom_questions)]
        );
        const oldMiscellaneousCategory = getMiscellaneousCategory(report.svr_categories);
        const mediaQuestion = oldMiscellaneousCategory.svr_questions
          .find(({ answer, question_type }) => question_type === 'media' && answer?.value?.length);
        await updateSVRAssets(mediaQuestion);
        await mergeIDBSVR({ id: report.id, action: undefined });
        return Promise.resolve(updatedSVR);
      }

      return Promise.resolve();
    });
  } catch (errors) {
    throw errors;
  }
};

export const getSVRChunkAsset = (answerId, params) => {
  return fetchFromAPI(`/svr_question_values/${answerId}/chunk/asset`, { params })
    .then((response) => response.resource)
    .catch((response) => {
      throw response;
    });
};

export const applySvrFarmsFilter = (params) => {
  return fetchFromAPI('/svrs/farms/filter', { method: 'post', body: JSON.stringify(params) })
    .catch((errors) => {
      throw errors;
    });
};
