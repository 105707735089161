import React from 'react';
import T from 'prop-types';
// components
import SidebarItem from './SidebarItem';
import SidebarDropdownItem from './SidebarDropdownItem';
import { FormattedMessage } from 'react-intl';
// styles
import cn from 'classnames';
import './SidebarGroup.scss';

const renderItems = (items) => items.map((item, idx) => <SidebarItem key={idx} item={item} />);

const SidebarGroup = ({ items, isCollapsed, isExpired, sidebarCollapsed }) => (
  <div className="SidebarGroup">
    {isExpired && (
      <ul className={cn('group-list', { 'is-collapsed': isCollapsed })}>
        {renderItems(items.expired)}
      </ul>
    )}

    {!isExpired && (
      <ul className={cn('group-list', { 'is-collapsed': isCollapsed })}>
        {renderItems(items.primary)}

        <li className="divider" />

        {renderItems(items.secondary)}
        {!sidebarCollapsed && renderItems(items.more)}

        {sidebarCollapsed && (
          <SidebarDropdownItem
            label={<FormattedMessage id="component.sidebar.more" />}
            items={items.more}
            isCollapsedSidebar={isCollapsed}
            isOpenAbove
          />
        )}
      </ul>
    )}
  </div>
);

SidebarGroup.propTypes = {
  items: T.object.isRequired,
  isCollapsed: T.bool,
  isExpired: T.bool,
  sidebarCollapsed: T.bool.isRequired,
};

export default SidebarGroup;
