import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import ShipmentForm from 'containers/Groups/GroupShipments/components/ShipmentForm';
import CenterBox from 'components/CenterBox';
import TruckingRouteCard from 'components/TruckingRouteCard/TruckingRouteCard';
// hooks
import useCommitment from 'hooks/commitments/useCommitment';
// utils
import moment from 'moment';
import { getPigGroupStartDate } from 'utils/pigGroupsHelper';
import { getCommitmentDirection, getAvgWeightBasedOnCommitment } from 'utils/commitmentsHelper';
import { currentUtcOffsetTime } from 'utils/timeHelper';
import { formatBackEndWeightToSystem } from 'utils/weightHelper';

const DailyCheckupStartGroup = ({
  commitmentId,
  pigGroup,
  submitForm,
  onSubmit,
  location: { pathname, query },
  measurementSystem,
}) => {
  const { commitment, isLoading } = useCommitment(commitmentId);

  const handleSubmit = (data) => {
    onSubmit(commitmentId ? { ...data, loading_commitment_id: commitment.id } : data);
  };

  const getInitialValues = () => {
    const defaultDeliveryDate = pigGroup?.farm ? currentUtcOffsetTime(pigGroup.farm.utc_offset) : moment();
    if (!commitmentId) return {
      delivery_date: defaultDeliveryDate,
      barns: [{}],
    };
    if (commitmentId && !commitment) return {
      delivery_date: defaultDeliveryDate,
      barns: [],
    };
    const avgWeight = getAvgWeightBasedOnCommitment(commitment);
    return {
      delivery_date: getPigGroupStartDate(commitment.arrive_at, defaultDeliveryDate),
      amount: commitment.head_count,
      estimated_weight: formatBackEndWeightToSystem(avgWeight, measurementSystem),
      barns: commitment.barn_id ? [{ id: commitment.barn_id }] : [],
      comment: commitment.comment || '',
    };
  };

  const getBackLink = () => {
    if (commitmentId || query.from === 'select-load') return { pathname, query: { mode: 'select-load' } };
    return `/daily-checkup/farms/${pigGroup?.farm_id}`;
  };

  const backLink = getBackLink();
  return (
    <CenterBox className="DailyCheckupCreateShipment" isLoading={isLoading}>

      <section className="center-box-header">
        <h1 className="title">
          <FormattedMessage id="general.startGroup" values={{ name: pigGroup?.name }} />
        </h1>
        <Link to={backLink}>
          <i className="fa fa-times close-center-box" />
        </Link>
      </section>

      <section className="center-box-body">
        {commitment && (
          <TruckingRouteCard
            className="mh-20 mb-20"
            direction={getCommitmentDirection(commitment)}
            commitments={commitment.loading.commitments}
            loadType={commitment.loading.load_type}
          />
        )}
        <ShipmentForm
          groupFarmId={pigGroup?.farm_id}
          onSubmit={handleSubmit}
          initialValues={getInitialValues()}
          minDeliveryDate={moment(pigGroup?.created_on)}
          hasComment
        />
      </section>

      <section className="center-box-footer">
        <div className="buttons text-right">
          <Link className="button mr-10" to={backLink}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button primary disabled={isLoading} className="submit wider" onClick={() => submitForm('group-shipment')}>
            <FormattedMessage id="general.button.createShipment" />
          </Button>
        </div>
      </section>
    </CenterBox>
  );
};

DailyCheckupStartGroup.propTypes = {
  pigGroup: T.object,
  submitForm: T.func.isRequired,
  location: T.object.isRequired,
  onSubmit: T.func.isRequired,
  measurementSystem: T.string.isRequired,
  commitmentId: T.string,
};

export default DailyCheckupStartGroup;
