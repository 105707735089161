import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import SelectField from 'components/ReduxFormFields/SelectField/SelectField';
import { formValueSelector, reduxForm, change } from 'redux-form';
import FormErrorsBox from 'components/FormErrorsBox';
import Button from 'components/Button';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import Field from 'components/Field';
import FarmFormTooltip from './FarmFormTooltip';
import FarmMarker from 'components/GoogleMap/Markers/FarmMarker/FarmMarker';
import GoogleMapSelect from 'components/GoogleMap/GoogleMapSelect';
import AddressSelect from './AddressSelect';
import CoordsSelect from './CoordsSelect';
import FederalPremiseIDField from 'components/Admin/Farms/Fields/FederalPremiseIDField';
import FarmTypeField from 'components/Admin/Farms/Fields/FarmTypeField';
import FarmManagerField from 'components/Admin/Farms/Fields/FarmManagerField';
import CircleCheckboxField from 'components/Admin/Farms/Fields/CircleCheckboxField';
import Popover from 'components/Popover';
// constants
import { utcOffsets } from 'constants.js';
// utils, hooks
import useCountries from 'utils/hooks/useCountries';
import { getFullAddress } from 'utils';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
// constants
import { addressRadioOptions, customErrors } from 'constants/farms';
// styles
import './FarmEditForm.scss';

const FarmEditForm = ({
  addressObj,
  auto_refresh_utc_offset = false,
  change,
  currentCompany,
  farm_type,
  handleSubmit,
  initialValues,
  pristine,
  submitting,
  utcOffset,
}) => {
  const [selectedAddressType, setAddressType] = useState('address');
  const { formatMessage } = useIntl();
  const { countries } = useCountries();

  const changeAddressType = (event) => {
    const { target: { value } } = event;
    setAddressType(value);
  };

  const handleAutoRefreshChange = (isChecked) => {
    if (isChecked && utcOffset !== initialValues.utc_offset) {
      change('utc_offset', initialValues.utc_offset);
    }
  };

  const selected = !isEmpty(addressObj) ? getFullAddress(addressObj) : '';
  const isSourceAssigned = initialValues?.assign_external_source || false;

  return (
    <div className="FarmEditForm">
      <div className="form-content-block">
        <form onSubmit={handleSubmit} className="farm-form-edit">
          <div className="row">
            <div className="small-12 column">
              <FormErrorsBox
                formName="edit-farm"
                customErrors={customErrors}
              />
            </div>
          </div>

          {/* FARM NAME */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.farmName" />}
                  placeholderKey="general.farmName"
                  name="name"
                />
              </label>
            </div>
          </div>

          <div className="row">
            <div className="small-12 column">
              <ButtonRadioGroup
                options={addressRadioOptions}
                value={selectedAddressType}
                onChange={changeAddressType}
              />
            </div>
          </div>

          <div className="row margin-top-1">
            {selectedAddressType === 'address' ? (
              <div className="small-12 column">
                <label>
                  <AddressSelect
                    label={<FormattedMessage id="general.farmAddress" />}
                    hasPoweredByLabel
                    selected={selected}
                    countriesData={countries}
                    changeValue={(name, val) => change(name, val)}
                    placeholder={<FormattedMessage id="general.typeAddress" />}
                  />
                </label>
              </div>
            ) : (
              <div className="column coords-select-form">
                <CoordsSelect
                  changeValue={(name, val) => change(name, val)}
                  countriesData={countries}
                  lat={addressObj?.lat}
                  lng={addressObj?.lng}
                />
              </div>
            )}
          </div>

          {/* FEDERAL PREMISE ID */}
          <FederalPremiseIDField name="federal_premise_id" className="row mt-15" />

          {/* FARM ID OR TATTOO NUMBER */}
          <div className="row margin-top-1">
            <div className="small-11 medium-11 column">
              <label>
                <Field
                  label={<FormattedMessage id="general.farmIdOptional" />}
                  placeholderKey="general.farmId"
                  name="external_link_id"
                  maxLength={8}
                  pattern="[0-9]*"
                />
              </label>
            </div>
            <FarmFormTooltip
              className="mt-35 pl-0 small-1 medium-1 column"
              overlay={formatMessage({ id: 'container.admin.farm.externalLinkId.hint' })}
            />
          </div>

          {/* FARM TYPE */}
          <FarmTypeField name="farm_type" />

          {/* FARM MANAGEMENT PROVIDER */}
          <FarmManagerField name="manager_id" currentCompany={currentCompany} />

          {/* UTC OFFSET  */}
          <div className="row">
            <div className="small-12 column">
              <label>
                <Field
                  disabled={auto_refresh_utc_offset}
                  label={<FormattedMessage id="general.timeZone" />}
                  placeholderKey="general.chooseTimeZone"
                  name="utc_offset"
                  component={SelectField}
                  options={utcOffsets}
                  valueKey="value"
                  labelKey="label"
                />
              </label>
            </div>
          </div>

          <CircleCheckboxField
            className="mb-15"
            name="auto_refresh_utc_offset"
            label={<FormattedMessage tagName="b" id="general.autoRefreshUTC" />}
            onChangeCallback={handleAutoRefreshChange}
          />

          <CircleCheckboxField
            className="mb-15"
            name="external"
            label={<FormattedMessage tagName="b" id="general.externalFarm" />}
          />

          <CircleCheckboxField
            isDisabled={isSourceAssigned}
            className={cn('mb-15', { 'is-disabled': isSourceAssigned })}
            name="assign_external_source"
            label={(
              <Popover placement="right" content={<FormattedMessage id="general.sourcingTracking.description" />}>
                <div>
                  <FormattedMessage tagName="b" id="general.sourcingTracking" />
                </div>
              </Popover>
            )}
          />

          {farm_type === 'su' && (
            <CircleCheckboxField
              className="mb-15"
              name="loose_sow_housed"
              label={<FormattedMessage tagName="b" id="general.looseSowHoused" />}
            />
          )}

        </form>
        <div className="map-block">
          <GoogleMapSelect
            onChangeAdress={(data) => change('addressObj', data)}
            addressData={addressObj}
          >
            {(position) => <FarmMarker {...position} farm={{ farm_type }} />}
          </GoogleMapSelect>
        </div>
      </div>
      <div className="button-submit">
        <Button
          onClick={handleSubmit}
          primary
          className="mobile-full-width"
          disabled={pristine || submitting}
        >
          <FormattedMessage id="general.button.saveChanges" />
        </Button>
      </div>
    </div>
  );
};

FarmEditForm.propTypes = {
  handleSubmit: T.func.isRequired,
  currentCompany: T.object,
  addressObj: T.object,
  auto_refresh_utc_offset: T.bool,
  farm_type: T.string,
  pristine: T.bool,
  submitting: T.bool,
  change: T.func.isRequired,
  utcOffset: T.number,
};

const selector = formValueSelector('edit-farm');

const formComponent = reduxForm({
  form: 'edit-farm',
  enableReinitialize: true,
})(FarmEditForm);

export default connect(
  (state) => ({
    currentCompany: state.auth.user.current_company,
    farm_type: selector(state, 'farm_type'),
    addressObj: selector(state, 'addressObj'),
    auto_refresh_utc_offset: selector(state, 'auto_refresh_utc_offset'),
    utcOffset: selector(state, 'utc_offset'),
  }), {
    change,
  }
)(formComponent);
