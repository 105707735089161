import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import {
  formatTreatmentProtocolPayload,
  formatTreatmentProtocolResponse,
  markDestroyedItems,
  validateUpdatedTreatmentProtocol
} from './helper';
// endpoints
import {
  getTreatmentProtocolsFarmPigGroup,
  getPigGroupTreatmentProtocol,
  updatePigGroupTreatmentProtocol,
} from 'endpoints/treatmentProtocols';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from './components/TreatmentProtocolForm';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';

const EditGroupTreatmentProtocol = ({
  setBreadcrumbs,
  setPageOptions,
  router: {
    params: {
      farmId,
      groupId,
      treatmentProtocolId,
    },
    push,
  }
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [group, setGroup] = useState(undefined);
  const [treatmentProtocol, setTreatmentProtocol] = useState(undefined);

  const fetchData = async () => {
    try {
      setGroup(await getTreatmentProtocolsFarmPigGroup(farmId, groupId));
      const fetchedTreatmentProtocol = await getPigGroupTreatmentProtocol(groupId, treatmentProtocolId);
      const formattedTreatmentProtocol = formatTreatmentProtocolResponse(fetchedTreatmentProtocol);
      setTreatmentProtocol(formattedTreatmentProtocol);
    } catch (error) {
      toastResponseErrors(error);
      push(`/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols`);
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    if (group && treatmentProtocol) {
      setBreadcrumbs([
        {
          label: <FormattedMessage id="general.treatmentProtocolFarms" />,
          path: '/treatment-protocol-farms',
        },
        {
          label: group.name,
          path: `/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols`,
        },
        {
          label: treatmentProtocol.name,
          path: `/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols/${treatmentProtocolId}/edit`,
          useLabelAsMobileTitle: true,
        },
      ]);
    }
  }, [group, treatmentProtocol]);

  const onSubmit = async (updatedTreatmentProtocol) => {
    try {
      setIsLoading(true);
      const markedTreatmentProtocolPayload = markDestroyedItems(updatedTreatmentProtocol, treatmentProtocol);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(markedTreatmentProtocolPayload);
      await updatePigGroupTreatmentProtocol(groupId, treatmentProtocolId, treatmentProtocolPayload);
      push(`/treatment-protocol-farms/${farmId}/groups/${groupId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  if (!group || !treatmentProtocol) return <Preloader isActive />;

  const isProtocolInheritedFromFarm = !!treatmentProtocol.farm_treatment_protocol;

  return (
    <div>
      <Preloader isActive={isLoading} />
      <GroupProfileHeader group={group} />
      <TreatmentProtocolForm
        isNameChangeable={!isProtocolInheritedFromFarm}
        isProductsChangeable={!isProtocolInheritedFromFarm}
        validate={validateUpdatedTreatmentProtocol}
        onSubmit={onSubmit}
        initialValues={treatmentProtocol}
        title={<FormattedMessage id="general.editTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.updateTreatmentProtocol" />}
      />
    </div>
  );
};

EditGroupTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(EditGroupTreatmentProtocol);
