import React, { useEffect } from 'react';
import T from 'prop-types';
// store
import { fetchSVRFarm, fetchSVR, resetData, deleteDraftSVR } from 'reducers/siteVisitReport';
import { setPageOptions } from 'reducers/layout';
// components
import Preloader from 'components/Preloader';
import SiteVisitReportEdit from './SiteVisitReportEdit';
import SiteVisitReportMobileEdit from './SiteVisitReportMobileEdit';
import SiteVisitReportConfirmation from './SiteVisitReportConfirmation';
// utils
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getSVRBackLink } from 'utils/SVRHelper';

const SiteVisitReport = ({
  location,
  params: { farmId, svrId },
  router,
  setPageOptions,
  isDataLoaded,
  fetchSVRFarm,
  fetchSVR,
  resetData,
  deleteDraftSVR,
}) => {
  const { query, pathname } = location;
  const { push } = router;
  const fetchData = async () => {
    try {
      await fetchSVRFarm(farmId);
      await fetchSVR(farmId, svrId);
    } catch (error) {
      toastResponseErrors(error);
      push('/svr-farms');
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ isFullScreenMode: true });

    return () => {
      setPageOptions({ isFullScreenMode: false });
      resetData();
    };
  }, []);

  if (!isDataLoaded) {
    return <Preloader isCentered isActive />;
  }

  const isConfirmPage = query.mode === 'confirmation';

  if (isConfirmPage) {
    return <SiteVisitReportConfirmation />;
  }

  const backLink = getSVRBackLink(farmId, query);

  const onClose = async () => {
    try {
      await deleteDraftSVR();
      push(backLink);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const onSave = () => push(backLink);
  const onPreview = () => push({ pathname, query: { ...query, mode: 'confirmation' } });
  const svrEditProps = { onClose, onSave, onPreview };

  if (isMobile) {
    return <SiteVisitReportMobileEdit {...svrEditProps} router={router} location={location} />;
  }

  return (
    <SiteVisitReportEdit {...svrEditProps} />
  );
};

SiteVisitReport.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  fetchSVRFarm: T.func.isRequired,
  fetchSVR: T.func.isRequired,
  resetData: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  deleteDraftSVR: T.func.isRequired,
  isDataLoaded: T.bool.isRequired,
  location: T.object.isRequired,
};

export default connect(({ siteVisitReport }) => ({
  farm: siteVisitReport.farm,
  isDataLoaded: !!siteVisitReport.report && !!siteVisitReport.farm,
}), {
  deleteDraftSVR,
  fetchSVRFarm,
  fetchSVR,
  setPageOptions,
  resetData,
})(SiteVisitReport);
