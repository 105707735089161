import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ToggleSwitcher from 'components/ToggleSwitcher';
import ResponsiveSelect from 'components/ResponsiveSelect';
import Preloader from 'components/Preloader';
// store
import { setCurrentCompany } from 'reducers/auth';
// api
import { toggleSVR, setSVRReportFrequency } from 'endpoints/admin/svr';
// utils
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// constants
import { SVRFrequencies } from './constants';
// styles
import styles from '../SiteVisitReports.module.scss';

const cn = classnames.bind(styles);

const GeneralSection = ({
  isSVREnabled,
  SVRReportFrequency,
  setCurrentCompany,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSVRToggle = ({ target: { checked } }) => {
    setIsLoading(true);

    toggleSVR({ svrs: checked }).then(() => {
      setCurrentCompany({ svrs: checked });
    }).catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSVRReportFrequencyChange = (frequency) => {
    setIsLoading(true);
    setSVRReportFrequency(frequency).then(() => {
      setCurrentCompany({ svrs_frequency: frequency });
    }).catch(toastResponseErrors)
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={cn('general-settings-section', 'pb-30')}>
      <Preloader isActive={isLoading} />
      <ToggleSwitcher
        onChange={onSVRToggle}
        checked={isSVREnabled}
        text={<FormattedMessage id="general.siteVisitReport" />}
        description={<FormattedMessage id="general.siteVisitReport.desc" />}
      >
        <div className={cn({ 'disabled-section': !isSVREnabled })}>
          <div className="semibold pb-10 mt-20">
            <FormattedMessage id="general.reportFrequency" />
          </div>
          <ResponsiveSelect
            value={SVRReportFrequency}
            className={cn('frequency-select')}
            options={SVRFrequencies}
            onChange={onSVRReportFrequencyChange}
          />
          <div className={cn('info-row', 'mt-15')}>
            <i className={cn('fa fa-info-circle', 'icon')} />
            <FormattedMessage id="general.everypigWillUse" />
          </div>
        </div>
      </ToggleSwitcher>
    </div>
  );
};

GeneralSection.propTypes = {
  isSVREnabled: T.bool.isRequired,
  SVRReportFrequency: T.string,
  setCurrentCompany: T.func.isRequired,
};

export default connect((state) => ({
  isSVREnabled: state.auth.user.current_company.svrs,
  SVRReportFrequency: state.auth.user.current_company.svrs_frequency,
}), { setCurrentCompany })(GeneralSection);
