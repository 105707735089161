import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './MobileDiseasesRow.module.scss';

const cn = classnames.bind(styles);

const MobileDiseasesRow = ({ name, actionBtnType, disabledRow, onRowClick, selectedRow }) => (
  <div
    className={cn('MobileRow', { 'no-events': disabledRow, 'select-row': actionBtnType === 'button' })}
    onClick={onRowClick}
  >
    <div className={cn({ 'selected-disease': disabledRow })}>
      {name}
    </div>
    {actionBtnType === 'button' && (
      <Button disabled={selectedRow} className={cn('select-btn')} small primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    )}
    {actionBtnType === 'checkbox' && (
      <div className={cn('icon', { 'selected': selectedRow })}>
        <i className={cn('checked', 'fa fa-check-circle-bts')} />
        <i className={cn('unchecked', 'fa fa-radio-unchecked')} />
      </div>
    )}
  </div>
);

MobileDiseasesRow.propTypes = {
  actionBtnType: T.string.isRequired,
  name: T.string.isRequired,
  disabledRow: T.bool.isRequired,
  onRowClick: T.func.isRequired,
  selectedRow: T.bool.isRequired,
};

export default MobileDiseasesRow;
