import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { fetchPigGroup } from 'reducers/pigGroups/pigGroupShow';
import { setPageOptions } from 'reducers/layout';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ShipmentForm from './components/ShipmentForm';
import PigsUnderCareLine from 'components/PigsUnderCareLine';
import TruckingRouteCard from 'components/TruckingRouteCard/TruckingRouteCard';
import InventoryConflictModal from '../GroupProfile/modals/InventoryConflictModal';
// hooks
import useFetchById from 'hooks/useFetchById';
// utils
import moment from 'moment';
import pick from 'lodash.pick';
import { showToastrMessage } from 'utils';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import { getCommitmentDirection, getAvgWeightForShipment } from 'utils/commitmentsHelper';
import { getCommitmentPlacementDate } from 'utils/pigGroupsHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// api
import { createPigGroupMigration, makePigGroupCorrections } from 'endpoints/pigGroups';
import { getCommitment } from 'endpoints/commitments';

const ShipmentCreate = ({
  fetchPigGroup,
  location: { query, pathname },
  measurementSystem,
  openModal,
  params: { id: paramsGroupId },
  pigGroup,
  router,
  setPageOptions,
  submit,
}) => {
  const queryCommitmentId = query?.commitmentId;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [{ data: commitment, isLoading }] = useFetchById(getCommitment, queryCommitmentId, {
    onSuccess: (commitment) => {
      if (commitment.pig_group_id !== +paramsGroupId || commitment.status !== 'accepted') {
        router.push(pathname);
      }
    },
    onError: () => router.push(pathname),
  });

  useEffect(() => {
    if (pigGroup.id !== parseInt(paramsGroupId, 10)) {
      fetchPigGroup(paramsGroupId);
    }
    setPageOptions({ mobileTitle: 'createShipment' });
    return () => {
      setPageOptions({ mobileTitle: '' });
    };
  }, []);

  const prepareResource = (data) => {
    const resource = pick(data, ['amount', 'condition']);

    return {
      ...resource,
      delivery_date: data.delivery_date.format('YYYY-MM-DD'),
      loading_commitment_id: commitment?.id,
      barn_ids: data.barns.length ? data.barns.map((barn) => barn.id) : [],
      migration_type: 'placement',
      estimated_weight: formatWeightToBackEndFormat(data.estimated_weight, measurementSystem),
    };
  };

  const handleMakeCorrections = (resource) => {
    makePigGroupCorrections(paramsGroupId, { resource })
      .then(() => {
        // TO PREVIEW CREATED CORRECTIONS
        router.push(`/barnsheets/groups/${paramsGroupId}?editMode=true`);
      })
      .catch(toastResponseErrors);
  };

  const handleSubmitData = (data) => {
    const resource = prepareResource(data);

    setIsSubmitting(true);
    createPigGroupMigration(paramsGroupId, { resource })
      .then(() => {
        showToastrMessage('component.toastr.shipment.successCreated');
        router.goBack();
      })
      .catch((response) => {
        setIsSubmitting(false);
        if (response?.errors?.migration_conflict) {
          // INFORM ABOUT MIGRATIONS ERROR
          openModal(
            <InventoryConflictModal onSubmit={() => handleMakeCorrections(resource)} />
          );
        } else {
          toastResponseErrors(response);
        }
      });
  };

  const getInitialValues = () => {
    const initialBarns = [{}];
    if (!queryCommitmentId || !commitment?.id) return {
      delivery_date: moment(),
      barns: initialBarns,
    };
    const calculatedAvgWeight = getAvgWeightForShipment(commitment.loading.commitments);
    return {
      delivery_date: getCommitmentPlacementDate(commitment.arrive_at, pigGroup.created_on),
      amount: commitment.head_count,
      estimated_weight: formatBackEndWeightToSystem(calculatedAvgWeight, measurementSystem),
      barns: commitment.barn_id ? [{ id: commitment.barn_id }] : initialBarns,
    };
  };

  const cancelLink = commitment?.id
    ? `/groups/${paramsGroupId}/scheduled-loads`
    : `/groups/${paramsGroupId}/shipments`;

  return (
    <CenterBox>
      <section className="center-box-header show-for-large">
        <div className="title">
          <FormattedMessage id="general.createShipmentWithName" values={{ name: pigGroup.name }} />
        </div>
        <Link to={cancelLink}><i className="fa fa-times close-center-box" /></Link>
      </section>

      <PigsUnderCareLine pigsCount={pigGroup.pigs} mobile />

      <section className="center-box-body">
        {commitment && (
          <TruckingRouteCard
            className="mh-20 mb-20"
            direction={getCommitmentDirection(commitment)}
            commitments={commitment.loading.commitments}
            loadType={commitment.loading.load_type}
          />
        )}
        <ShipmentForm
          groupFarmId={pigGroup.farm_id}
          initialValues={getInitialValues()}
          onSubmit={handleSubmitData}
          minDeliveryDate={moment(pigGroup.created_on)}
        />
      </section>

      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={cancelLink}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button
            primary
            className="submit wider"
            onClick={() => submit('group-shipment')}
            disabled={isLoading || isSubmitting}
          >
            <FormattedMessage id="general.button.createShipment" />
          </Button>
        </div>
      </section>

      <StickyFooter className="hide-for-large">
        <Button
          primary
          className="submit"
          onClick={() => submit('group-shipment')}
          disabled={isLoading || isSubmitting}
        >
          <FormattedMessage id="general.button.createShipment" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

ShipmentCreate.propTypes = {
  measurementSystem: T.string.isRequired,
  params: T.object.isRequired,
  router: T.object.isRequired,
  location: T.object.isRequired,
  pigGroup: T.object,
  submit: T.func.isRequired,
  fetchPigGroup: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.pigGroups.groupShow.data,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), {
    submit,
    fetchPigGroup,
    setPageOptions,
    openModal,
  }
)(ShipmentCreate);
