import { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setNetworkConnect, setProgressSync } from 'reducers/network';
import { fetchFarmGroups } from 'reducers/dailyCheckup/checkupFarmGroups';
import { fetchFarms } from 'reducers/dailyCheckup/checkupFarms';
import { fetchDraftSiteVisitReports } from 'reducers/svr/svrs';
// offline
import { getLastPreloadData, updateLastPreloadData, clearOfflineCheckups,
  clearOfflineRequests, clearOfflineMediaRequests } from 'utils/offlineHelper';
import { clearIDBSVRCategories } from 'utils/offlineSVRHelper';
// utils
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { getLSRefreshIndexedDB, removeLSRefreshIndexedDB } from 'utils/localStorageHelper';
import { isCheckupPigGroupsLocation, isCheckupFarmsLocation, isFarmSVRsLocation, isFakeSVRLocation } from 'utils';
// api
import { getSymptoms, getTreatments, getMortalityReasons, getTemperaturePoints, getFarmsForOffline,
  getPigGroupsForOffline } from 'endpoints/common';
import { execDailyCheckupsSync, execMediaSync } from 'endpoints/checkup/checkupEdit';
import { execSVRsSync, getSVRCategories } from 'endpoints/svr';

class NetworkManager extends Component {

  componentDidMount() {
    window.addEventListener('online', this.handleOnline, false);
    window.addEventListener('offline', this.handleOffline, false);
    this.initialPreloadData();
  }

  componentDidUpdate({ current_company_id: prevCompanyId }) {
    const { current_company_id } = this.props;
    if (prevCompanyId !== current_company_id && current_company_id) {
      this.sendRequestsForOffline(current_company_id);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline, false);
    window.removeEventListener('offline', this.handleOffline, false);
  }

  isSyncStarted = false;

  syncEntities = new Set();

  startSync = (requestsCount, syncingData = 'syncingDC') => {
    const isRequestsExists = requestsCount > 0;
    if (isRequestsExists) this.syncEntities.add(syncingData);
    if (!this.isSyncStarted && isRequestsExists) {
      this.props.setProgressSync({ progress: 10, syncingData });
      this.isSyncStarted = true;
    }
  };

  finishSync = () => {
    if (this.isSyncStarted) {
      const syncingData = this.syncEntities.size === 1
        ? [...this.syncEntities][0]
        : 'syncingSVRAndDC';
      this.props.setProgressSync({ progress: 100, syncingData });
      this.isSyncStarted = false;
      this.syncEntities.clear();
    }
  };

  makeReFetchData = (requestsCount) => {
    const { pathname, fetchDraftSiteVisitReports, fetchFarmGroups, fetchFarms, params } = this.props;
    if (requestsCount && isCheckupPigGroupsLocation(pathname)) {
      fetchFarmGroups(params.id);
    }
    if (requestsCount && isCheckupFarmsLocation(pathname)) {
      fetchFarms(params.id);
    }
    if (requestsCount && isFarmSVRsLocation(pathname)) {
      fetchDraftSiteVisitReports(params.farmId);
    }
  };

  changeFakeSVRToCreated = (svrId, createdSvrId) => {
    const { params, pathname } = this.props;
    const { router } = this.context;
    if (params.svrId === svrId && isFakeSVRLocation(pathname)) {
      router.push(`/svr-farms/${params.farmId}/svrs/${createdSvrId}`);
    }
  }

  initialPreloadData = () => {
    const { current_company_id, setNetworkConnect } = this.props;
    if (navigator.onLine) {
      this.preloadDataForIndexedDB(current_company_id).then(() => {
        if (current_company_id) this.handleOnline();
      });
    } else {
      setNetworkConnect({ isOnline: false });
    }
  };

  handleOnline = () => {
    const { setNetworkConnect, isSVREnabled } = this.props;
    setNetworkConnect({ isOnline: true });

    const getSVRTRequests = () => {
      return isSVREnabled
        ? execSVRsSync(this.changeFakeSVRToCreated)
          .then((svrRequests) => {
            this.startSync(svrRequests.length, 'syncingSVR');
            return svrRequests;
          })
        : Promise.resolve([]);
    };

    execDailyCheckupsSync()
      .then((requests) => {
        getSVRTRequests().then((svrRequests) => {
          this.startSync(svrRequests.length, 'syncingSVR');
          this.startSync(requests.length, 'syncingDC');
          Promise.all([...svrRequests, ...requests]).then(() => {
            execMediaSync().then((mediaRequests) => {
              this.startSync(mediaRequests.length, 'syncingDC');
              Promise.all(mediaRequests).then(() => {
                this.finishSync();
                this.makeReFetchData(requests.length + svrRequests.length);
              });
            });
          });
        });
      });
  };

  handleOffline = () => {
    this.props.setNetworkConnect({ isOnline: false });
  };

  sendRequestsForOffline = (companyId) => {
    const { setProgressSync, isSVREnabled } = this.props;
    setProgressSync({ progress: 10, syncingData: 'preloadData' });
    const requests = [
      getFarmsForOffline(),
      getPigGroupsForOffline(),
      getTreatments(),
      isSVREnabled ? getSVRCategories() : clearIDBSVRCategories(),
      getSymptoms(),
      getMortalityReasons(),
      getTemperaturePoints(),
      updateLastPreloadData(companyId),
      clearOfflineCheckups(),
      clearOfflineRequests(),
      clearOfflineMediaRequests(),
    ];
    return Promise.all(requests).then(() => {
      setProgressSync({ progress: 100, syncingData: 'preloadData' });
    });
  };

  preloadDataForIndexedDB = (company_id) => {
    return new Promise((resolve, reject) => {

      if (!company_id) {
        resolve();
        return;
      }

      getLastPreloadData(company_id)
        .then((preloadData = {}) => {

          const isNewDay = !moment().startOf('day').isSame(moment(preloadData.loaded_at).startOf('day'));
          const isForceRefresh = getLSRefreshIndexedDB();

          if (isEmpty(preloadData) || isNewDay || isForceRefresh) {
            removeLSRefreshIndexedDB();
            this.sendRequestsForOffline(company_id).then(resolve).catch(reject);
          } else {
            resolve();
          }
        })
        .catch(reject);
    });
  };

  render() {
    return null;
  }
}

NetworkManager.contextTypes = {
  router: T.object.isRequired,
};

NetworkManager.propTypes = {
  setNetworkConnect: T.func.isRequired,
  fetchDraftSiteVisitReports: T.func.isRequired,
  fetchFarmGroups: T.func.isRequired,
  fetchFarms: T.func.isRequired,
  current_company_id: T.number,
  setProgressSync: T.func.isRequired,
  pathname: T.string.isRequired,
  params: T.object.isRequired,
  isSVREnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    current_company_id: state.auth.user?.current_company?.id,
    isSVREnabled: state.auth.user?.current_company?.svrs,
  }), {
    setNetworkConnect,
    fetchDraftSiteVisitReports,
    fetchFarmGroups,
    fetchFarms,
    setProgressSync,
  }
)(NetworkManager);
