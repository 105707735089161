import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import VerticalAligner from 'components/VerticalAligner';
import Collapse from 'components/Collapse/Collapse';
import GroupSourceTypeLabel from 'components/GroupSourceTypeLabel';
import PigGroupMarketingBadge from 'components/PigGroupMarketingBadge';
import Link from 'react-router/lib/Link';
import FormattedWeight from 'components/FormattedWeight';
// utils
import { isMobile } from 'react-device-detect';
import { formatMortality, formatDaysToWeeks } from 'utils';
import { fetchFromAPI } from 'utils/api';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
// styles
import styles from './GroupInfoCollapse.module.scss';

const cn = classnames.bind(styles);

const GroupInfoCollapse = ({
  className,
  checkupId,
  customEndpoint,
  group = {},
  isAdmin,
  titleClassName,
}) => {
  const [{ data, isLoading, isLoaded }, setState] = useState({ data: {}, isLoading: false, isLoaded: false });
  const { external_source: source, mortality_rate, estimated_weight, pigs, total_pigs_in, days_on_feed } = data;

  const handleToggleCollapse = () => {
    if (!isLoaded) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      fetchFromAPI(customEndpoint || `/daily_checkups/${checkupId}/pig_group`)
        .then(({ resource }) => {
          setState({ isLoading: false, isLoaded: true, data: resource });
        })
        .catch((response) => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
          toastResponseErrors(response);
        });
    }
  };

  const renderSourceName = (source) => {
    if (!source?.name) return 'N/A';
    const sourceName = isMobile ? `${source.name.slice(0, 14)}...` : source.name;
    return isAdmin
      ? <Link to={`/sources/${source.id}`} className={cn('primary-link')}>{sourceName}</Link>
      : <span>{sourceName}</span>;
  };

  return (
    <Collapse
      className={className}
      titleClassName={titleClassName}
      title={(
        <VerticalAligner>
          <strong>{group.name}</strong>
          <PigGroupMarketingBadge
            isHintAttachedToBody
            className="ml-10"
            hintClassName={cn('group-badge')}
            pigGroup={group}
            size="small"
          />
        </VerticalAligner>
      )}
      onToggle={handleToggleCollapse}
      isLoading={isLoading}
    >
      <div className={cn('collapse-body')}>
        <div className={cn('group-info-line')}>
          <div className={cn('puc-label-col')}>
            <FormattedMessage id="general.puc" />
            <span className={cn('pigs-in')}>
              <FormattedMessage id="general.totalPigsIn" />
            </span>
          </div>
          <div className={cn('puc-value-col')}>
            <span className="semibold">{pigs}</span>
            <span className={cn('pigs-in-value')}>{total_pigs_in}</span>
          </div>
        </div>
        <div className={cn('group-info-line')}>
          <FormattedMessage id="general.estAvgWeight" />
          <strong>
            <FormattedWeight value={Math.floor(estimated_weight)}  />
          </strong>
        </div>
        <div className={cn('group-info-line')}>
          <FormattedMessage id="general.currentMortality" />
          <strong>{formatMortality(mortality_rate)}</strong>
        </div>
        <div className={cn('group-info-line')}>
          <FormattedMessage id="general.weeksOnFeed" />
          <strong>{formatDaysToWeeks(days_on_feed)}</strong>
        </div>
        <div className={cn('group-info-line')}>
          <FormattedMessage id="general.source" />
          <div className={cn('source')}>
            <div>
              {renderSourceName(source)}
              {source && (
                <GroupSourceTypeLabel
                  className={cn('source-label', { 'commingled': source.source_type === 'co' })}
                  sourceType={source.source_type === 'co' ? 'commingled' : 'externalOrigin'}
                />
              )}
            </div>
          </div>
        </div>
        {source && source.commingled_children && (
          <div className={cn('group-info-line', 'source-list')}>
            {source.commingled_children.map((item) => (
              <div key={item.id} className={cn('source-list-item')}>
                <span>{item.name}</span>
                <GroupSourceTypeLabel
                  className={cn('source-label')}
                  sourceType="externalOrigin"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Collapse>
  );
};

GroupInfoCollapse.propTypes = {
  group: T.object,
  checkupId: T.oneOfType([T.string, T.number]),
  className: T.string,
  titleClassName: T.string,
  customEndpoint: T.string,
  isAdmin: T.bool,
};

export default GroupInfoCollapse;
