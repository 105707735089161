import React, { useEffect, Fragment } from 'react';
import T from 'prop-types';
// redux
import { setTableParams } from 'reducers/tableParams';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { openPortalRight } from 'reducers/rightToLeftPortal';
import { setIsSchedulePageOutdated, setPageOptions } from 'reducers/layout';
// components
import AcceptCommitmentModal from 'components/CommitmentModal/AcceptCommitmentModal';
import { FormattedMessage, useIntl } from 'react-intl';
import UserFilter from 'components/UserFilter';
import UserFilterMobile from 'components/UserFilterMobile';
import UserFilterMobileHeader from 'components/UserFilterMobile/UserFilterMobileHeader';
import Preloader from 'components/Preloader';
import PaginationWrapper from 'components/PaginationWrapper';
import NothingBox from 'components/NothingBox';
import CommitmentCard from 'components/CommitmentCard/CommitmentCard';
import Panel from 'components/Panel';
import DeclineCommitmentModal from 'components/CommitmentModal/DeclineCommitmentModal';
import DateLine from 'components/DateLine/DateLine';
import OfflineContainer from 'components/OfflineScreen/OfflineContainer';
import OutdatedScheduleModal from './components/OutdatedScheduleModal';
// hooks
import useDidMountEffect from 'hooks/useDidMountEffect';
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import { useDropdownActions } from 'hooks/useDropdownContext';
import { useMediaQuery } from 'react-responsive';
// utils
import cn from 'classnames';
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getCommitmentDirection } from 'utils/commitmentsHelper';
import { tableNames } from 'utils/constants';
import { allOrAnyLoadingsOptions, userFilterGroupNames, userFilterSectionTypes } from 'utils/constants/userFilters';
// api
import { acceptCommitment, fetchScheduledCommitments, applyCommitmentsFilter } from 'endpoints/commitments';
// styles
import styles from './MySchedule.module.scss';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

const initialFilter = {
  sections: [{ type: 'PigMigration', condition: 'not_exist', key: 1, options: [] }],
  type: 'PigMigration',
};

const MySchedule = ({
  isOnline,
  isSchedulePageOutdated,
  openModal,
  openPortalRight,
  router: { push },
  setBreadcrumbs,
  setTableParams,
  setIsSchedulePageOutdated,
  setPageOptions,
  tableParams,
}) => {
  const { openDropdown } = useDropdownActions();
  const { formatMessage } = useIntl();
  const {
    data: { resources, isLoading, meta: { total } },
    fetchData,
    onPageChange,
    onPerPageChange,
    onUserFilterSectionsChange,
    onUserFilterSegmentSave,
    setDataByIndex,
  } = useDataTable(fetchScheduledCommitments, {
    setTableParams: (params) => setTableParams(tableNames.allCommitments, params),
    tableParams,
    filterFetcher: applyCommitmentsFilter,
    userFilterGroupName: userFilterGroupNames.loadCommitments,
  });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const userFilter = tableParams.filters?.[0];
  const filterSectionsCount = userFilter?.sections?.length;
  const hasMobileFilterHeader = !isDesktop && filterSectionsCount > 0;
  // TODO: change this logic
  const isFiltersTouched = 'filters' in tableParams; // presence of 'filters' key means that there were manipulations with filters

  const sections = [
    { value: userFilterSectionTypes.load, label: formatMessage({ id: 'general.loadName' }) },
    { value: userFilterSectionTypes.loadType, label: formatMessage({ id: 'general.loadType' }) },
    { value: userFilterSectionTypes.arriveAt, label: formatMessage({ id: 'component.advancedSearchItem.arriveAt' }) },
    { value: userFilterSectionTypes.commitmentStatus, label: formatMessage({ id: 'general.confirmationStatus' }) },
    { value: userFilterSectionTypes.loadingStatus, label: formatMessage({ id: 'general.loadStatus' }) },
    { value: userFilterSectionTypes.pigMovements, label: formatMessage({ id: 'general.pigMovements' }) },
  ];

  const handleOpenMobileFilters = () => {
    openPortalRight(
      <UserFilterMobile
        allOrAnyConditionLabel={<FormattedMessage id="component.advancedSearchItem.allLoadsFilters" />}
        appliedFilter={tableParams.filters?.[0] || {}}
        filterSections={sections}
        filterGroupName={userFilterGroupNames.loadCommitments}
        setFilter={(filter) => fetchData({ ...tableParams, filters: filter ? [filter] : undefined })}
      />,
    );
  };

  const handleSetPageOptions = () => {
    setPageOptions({
      rightButton: !isDesktop && isOnline ? {
        icon: 'fa fa-filter',
        onClick: handleOpenMobileFilters,
      } : '',
    });
  };

  useDidMountEffect(handleSetPageOptions, [tableParams, isDesktop, isOnline]);

  useEffect(() => {
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.mySchedule" />, useLabelAsMobileTitle: true }
    ]);
    handleSetPageOptions();
    return () => {
      setPageOptions({ rightButton: '' });
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      fetchData({ ...tableParams, filters: isFiltersTouched ? tableParams.filters : [initialFilter] });
    }
  }, [isOnline]);

  useEffect(() => {
    if (isSchedulePageOutdated) {
      openModal(
        <OutdatedScheduleModal
          onLeave={() => setIsSchedulePageOutdated(false)}
          onRefresh={() => {
            fetchData({ ...tableParams, page: 1 }).then(() => {
              setIsSchedulePageOutdated(false);
            });
          }}
        />,
        { closeable: false }
      );
    }
  }, [isSchedulePageOutdated]);

  const handleGetOptions = (commitment, index) => {
    const { loading, status, id, acceptable, declinable } = commitment;
    const direction = getCommitmentDirection(commitment);
    const isCommitmentAccepted = status === 'accepted';
    const isCommitmentDeclined = status === 'declined';

    const acceptLoad = () => acceptCommitment(id)
      .then((resource) => {
        setDataByIndex(index, { ...resource, direction });
        openModal(<AcceptCommitmentModal loadName={loading.name} />);
      }).catch(toastResponseErrors);

    const declineLoad = () => {
      openModal(
        <DeclineCommitmentModal
          onSubmit={(resource) => setDataByIndex(index, { ...resource, ...direction })}
          commitment={commitment}
          load={loading}
        />
      );
    };

    return [
      { onClick: () => push(`/my-schedule/${commitment.id}`), light: true,
        label: <FormattedMessage id="general.button.reviewLoad" /> },
      { onClick: () => acceptLoad(loading), blue: true, label: <FormattedMessage id="general.confirm" />,
        hide: !acceptable || isCommitmentAccepted },
      { onClick: () => declineLoad(loading), red: true, label: <FormattedMessage id="general.reportConflict" />,
        hide: !declinable || isCommitmentDeclined },
    ];
  };

  return (
    <div
      className={cn('row centered', styles['my-schedule-page'], {
        [styles['has-mobile-filters']]: hasMobileFilterHeader,
      })}
    >
      <OfflineContainer>
        {!isDesktop && filterSectionsCount > 0 && (
          <UserFilterMobileHeader
            onClick={handleOpenMobileFilters}
            onClose={() => fetchData({ ...tableParams, filters: undefined })}
            sectionsCount={filterSectionsCount}
            segmentName={userFilter?.name}
          />
        )}
        {isDesktop && (
          <UserFilter
            isVisible
            filterGroupName={userFilterGroupNames.loadCommitments}
            availFilterSections={sections}
            className={styles.filters}
            listOptions={allOrAnyLoadingsOptions}
            onSectionsChange={onUserFilterSectionsChange}
            initialFilter={isFiltersTouched ? tableParams.filters?.[0] : initialFilter}
            onSave={onUserFilterSegmentSave}
          />
        )}
        <Panel className={styles['schedule-panel']}>
          <Panel.Heading title={<FormattedMessage id="general.pageTitle.mySchedule" />} />
          <Panel.Body className="ph-20">
            <Preloader isActive={isLoading} />
            {resources.map((commitment, idx) => {
              const isCommitmentPending = commitment.status === 'pending';
              const options = handleGetOptions(commitment, idx);
              const mobileOptions = [
                {
                  onClick: () => push(`/my-schedule/${commitment.id}`),
                  light: !isCommitmentPending,
                  primary: isCommitmentPending,
                  label: <FormattedMessage id="general.button.reviewLoad" />,
                }
              ];
              return (
                <Fragment key={commitment.id}>
                  {(idx === 0 || (formatDate(commitment.arrive_at) !== formatDate(resources[idx - 1].arrive_at))) && (
                    <DateLine date={formatDate(commitment.arrive_at)} />
                  )}
                  <CommitmentCard
                    className="mv-10"
                    onDropdownOpen={(e) => openDropdown(e, options)}
                    commitment={commitment}
                    options={options}
                    mobileOptions={mobileOptions}
                    openModal={openModal}
                  />
                </Fragment>
              );
            })}
            <NothingBox display={!resources.length} isLoading={isLoading}>
              <h2 className="lighter semibold">
                <FormattedMessage id="general.nothingBox.noLoads" />
              </h2>
            </NothingBox>
            {!!resources.length && (
              <PaginationWrapper
                onlyBottom
                totalItems={total}
                currentPage={tableParams.page}
                onPageChange={onPageChange}
                perPage={tableParams.per_page}
                onPerPageChange={onPerPageChange}
              />
            )}
          </Panel.Body>
        </Panel>
      </OfflineContainer>
    </div>
  );

};

MySchedule.propTypes = {
  isOnline: T.bool.isRequired,
  isSchedulePageOutdated: T.bool.isRequired,
  setBreadcrumbs: T.func.isRequired,
  router: T.object.isRequired,
  tableParams: T.object.isRequired,
  openModal: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  setTableParams: T.func.isRequired,
  setIsSchedulePageOutdated: T.func.isRequired,
  setPageOptions: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.allCommitments),
    isOnline: state.network.isOnline,
    isSchedulePageOutdated: state.layout.isSchedulePageOutdated,
  }), {
    setBreadcrumbs,
    setIsSchedulePageOutdated,
    setPageOptions,
    setTableParams,
    openModal,
    openPortalRight,
  }
)(MySchedule);
