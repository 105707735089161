import React from 'react';
import T from 'prop-types';
import Select from '../Select';
import { useIntl } from 'react-intl';

const PaginationSelect = (props) => {
  const { formatMessage } = useIntl();

  return (
    <label>
      <div className="table-select">
        <div className="title">{formatMessage({ id: 'general.button.view' })}</div>
        <Select
          value={props.current}
          onChange={(e) => props.onChange(parseInt(e.target.value, 10))}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </Select>
      </div>
    </label>
  );
};

PaginationSelect.propTypes = {
  current: T.number,
  onChange: T.func,
};

PaginationSelect.defaultProps = {
  current: 25
};

export default PaginationSelect;
