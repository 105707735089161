import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openModal } from 'reducers/modals';
import { setTableParams } from 'reducers/tableParams';
// components
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import { FormattedMessage } from 'react-intl';
import DataTable from 'components/DataTable/DataTable';
import DeleteTreatmentProtocolModal from './components/DeleteTreatmentProtocolModal';
import { Link } from 'react-router';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import { CustomColumn } from 'components/DataTable/Columns';
import Avatar from 'components/Avatar/Avatar';
import StatusBadge from 'components/StatusBadge/StatusBadge';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// endpoint
import {
  getTreatmentProtocolsFarm,
  getFarmTreatmentProtocols,
  updateFarmTreatmentProtocol,
} from 'endpoints/treatmentProtocols';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import classnames from 'classnames/bind';
import isEmpty from 'lodash.isempty';
// constants
import { tableNames } from 'utils/constants';
// styles
import styles from './TreatmentProtocols.module.scss';

const cn = classnames.bind(styles);

const FarmTreatmentProtocols = ({
  params: {
    farmId
  },
  location: {
    pathname,
  },
  setBreadcrumbs,
  setPageOptions,
  openModal,
  router,
  tableParams,
  setTableParams,
}) => {
  const [farm, setFarm] = useState({});
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSearchChange,
    onStatusChange,
  } = useDataTable((params) => getFarmTreatmentProtocols(farmId, params), {
    setTableParams: (params) => setTableParams(tableNames.farmTreatmentProtocols, params),
    tableParams,
  });

  const { page, per_page, search, status } = tableParams;
  const { total, stats } = meta;

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  const treatmentProtocolsTableFilters = [
    { label: <FormattedMessage id="general.allTreatmentProtocols" />, value: '' },
    { label: <FormattedMessage id="general.status.active" />, value: 'active' },
    { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
  ];

  const fetchFarm = async () => {
    try {
      setFarm(await getTreatmentProtocolsFarm(farmId));
    } catch (error) {
      toastResponseErrors(error);
      router.push('/treatment-protocol-farms');
    }
  };

  useEffect(() => {
    fetchFarm();
    fetchData();
    setPageOptions({ backLink: '/treatment-protocol-farms' });
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.treatmentProtocolFarms" />,
        path: '/treatment-protocol-farms',
      },
      {
        label: `${farm.name || ''}`,
        path: pathname,
        useLabelAsMobileTitle: true,
      },
    ]);
  }, [farm]);

  const onTreatmentProtocolStatusChange = async (treatmentProtocolId, isActive) => {
    try {
      await updateFarmTreatmentProtocol(farmId, treatmentProtocolId, { active: isActive });
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  // TODO: update next functions after back-end changes
  const onDeleteTreatmentProtocol = () => undefined;

  const handleDeleteTreatmentProtocol = (treatmentProtocol) => {
    openModal(
      <DeleteTreatmentProtocolModal
        treatmentProtocol={treatmentProtocol}
        onDeleteTreatmentProtocol={() => onDeleteTreatmentProtocol(treatmentProtocol)}
      />
    );
  };

  const renderNameColumn = (rowData) => (
    <CustomColumn flexRow label={<FormattedMessage id="component.dataTable.headers.name" />}>
      <Avatar type="treatment-protocol" avatarSize={35} iconSize={16} className="mr-10" />
      <Link className="primary" to={`${pathname}/${rowData.id}`}>
        {rowData.name}
      </Link>
    </CustomColumn>
  );

  const renderStatusColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={rowData.active ? 'active' : 'disabled'} />
    </CustomColumn>
  );

  const renderActionsColumn = (rowData, rowIndex) => {
    const options = [
      {
        label: <FormattedMessage id="general.button.edit" />,
        onClick: () => router.push(`${pathname}/${rowData.id}/edit`),
      },
      {
        label: <FormattedMessage id={`general.button.${rowData.active ? 'disable' : 'enable'}`} />,
        onClick: () => onTreatmentProtocolStatusChange(rowData.id, !rowData.active).then(fetchData),
      },
      {
        label: <FormattedMessage id="general.button.delete" />,
        onClick: () => handleDeleteTreatmentProtocol(rowData),
        hide: true,
      }
    ];
    if (!options.length) return null;

    const firstOption = options[0];
    const restOptions = options.slice(1);

    return (
      <div className="collapsible-value button-column">
        <DropdownButton
          idKey={`${rowData.id}-${rowIndex}`}
          label={firstOption.label}
          buttonType="small light-gray"
          onClick={firstOption.onClick}
          dropDownData={restOptions}
          customClass="show-for-large"
          wide
        />
        <Link onClick={firstOption.onClick} className="button light hide-for-large">
          {firstOption.label}
        </Link>
      </div>
    );
  };

  const columns = [
    {
      label: <FormattedMessage id="component.dataTable.headers.name" />,
      flex: '1 1 130px',
      renderer: renderNameColumn,
    },
    {
      label: <FormattedMessage id="component.dataTable.headers.status" />,
      flex: '1 1 100px',
      renderer: renderStatusColumn,
    },
    {
      label: '',
      flex: '0 0 135px',
      renderer: renderActionsColumn,
      fixed: true,
    },
  ];

  return (
    <div>
      <FarmProfileHeader
        className="show-for-large"
        farm={farm}
        isLoading={isEmpty(farm)}
        actualFarmId={Number(farmId)}
        farmInfo={(
          <div className={cn('farm-details')}>
            <span>ID&nbsp;&bull;&nbsp;{farm.federal_premise_id || 'N/A'}</span>
          </div>
        )}
      />
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.treatmentProtocols" />}>
            <SearchBox initialValue={search} onChange={onSearchChange} />
          </Panel.Heading>
          <Panel.Body noPadding>
            <Preloader isActive={isLoading} />
            <TableFilter
              filters={treatmentProtocolsTableFilters}
              activeFilter={status}
              onFilterChange={onStatusChange}
              stats={stats}
              className="ph-10"
            >
              <Link to={`${pathname}/create`} className="button small primary wider">
                <FormattedMessage id="general.button.addTreatmentProtocol" />
              </Link>
            </TableFilter>
            <DataTable
              data={resources}
              columns={columns}
              scrollable
              isLoading={isLoading}
              paginationProps={paginationProps}
            />
            <NothingBox
              search={search}
              itemsName="treatmentProtocols"
              display={!resources.length}
              isLoading={isLoading}
            >
              <h2 className="lighter semibold">
                <FormattedMessage id="component.nothingBox.noTreatmentProtocols" />
              </h2>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </section>
    </div>
  );
};

FarmTreatmentProtocols.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openModal: T.func.isRequired,
  location: T.object.isRequired,
  tableParams: T.object.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect((state) => ({
  tableParams: tableParamsSelector(state, tableNames.farmTreatmentProtocols),
}), { setPageOptions, setBreadcrumbs, openModal, setTableParams })(FarmTreatmentProtocols);
