import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Button';
import MessageBox from 'components/MessageBox';
// style
import './InventoryConflictModal.scss';

const InventoryConflictModal = ({ closeModal, onSubmit }) => {
  const submitAndClose = () => {
    onSubmit();
    closeModal();
  };

  return (
    <Modal className="InventoryConflictModal" title={<FormattedMessage id="component.modal.inventoryConflict.title" />}>
      <section className="modal-body">
        <MessageBox type="alert">
          <FormattedMessage
            id="component.modal.inventoryConflict.attentionText"
            values={{
              title: <FormattedMessage tagName="b" id="component.modal.inventoryConflict.warning" />,
            }}
          />
        </MessageBox>
        <FormattedMessage id="component.modal.inventoryConflict.message">
          {(text) => <p className="message">{text}</p>}
        </FormattedMessage>
      </section>
      <section className="modal-footer sticky-on-mobile">
        <Button default onClick={closeModal} className="mr-10">
          <FormattedMessage id="general.button.cancel" />
        </Button>
        <Button primary onClick={submitAndClose}>
          <FormattedMessage id="general.button.reconcileNow" />
        </Button>
      </section>
    </Modal>
  );
};

InventoryConflictModal.propTypes = {
  onSubmit: T.func.isRequired,
  closeModal: T.func.isRequired,
};

export default connect(null, { closeModal })(InventoryConflictModal);
