import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { formValueSelector, submit } from 'redux-form';
import { fetchPigGroupMigration, clearPigGroupMigration } from 'reducers/pigGroups/pigGroupMigrationShow';
import { setPageOptions } from 'reducers/layout';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ShipmentForm from './components/ShipmentForm';
import PigsUnderCareLine from 'components/PigsUnderCareLine';
import InventoryConflictModal from '../GroupProfile/modals/InventoryConflictModal';
import Preloader from 'components/Preloader';
// utils
import moment from 'moment';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { showToastrMessage } from 'utils';
// groups api
import { createPigGroupMigration, makePigGroupCorrections } from 'endpoints/pigGroups';

const ShipmentEdit = ({
  clearPigGroupMigration,
  fetchPigGroupMigration,
  formAmount,
  isLoading,
  measurementSystem,
  migration,
  openModal,
  params: { migrationId, id: groupId },
  router,
  setPageOptions,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchPigGroupMigration(migrationId);
    setPageOptions({ mobileTitle: 'editShipment' });
    return () => {
      setPageOptions({ mobileTitle: '' });
      clearPigGroupMigration();
    };
  }, []);

  const prepareResource = (data) => ({
    ...pick(data, ['amount', 'delivery_date', 'condition']),
    barn_ids: data.barns.length ? data.barns.map((barn) => barn.id) : [],
    migration_type: 'placement',
    estimated_weight: formatWeightToBackEndFormat(data.estimated_weight, measurementSystem),
  });

  const handleMakeCorrections = (bodyParams) => {
    makePigGroupCorrections(groupId, bodyParams)
      .then(() => {
        // TO PREVIEW CREATED CORRECTIONS
        router.push(`/barnsheets/groups/${groupId}?editMode=true`);
      })
      .catch(toastResponseErrors);
  };

  const handleGoBack = () => router.goBack();

  const handleSubmit = (data) => {
    const bodyParams = {
      resource: prepareResource(data),
      pig_migration_id: migrationId,
    };

    setIsSubmitting(true);
    createPigGroupMigration(groupId, bodyParams)
      .then(() => {
        showToastrMessage('component.toastr.shipment.successUpdated');
        handleGoBack();
      })
      .catch((response) => {
        setIsSubmitting(false);
        if (response?.errors?.migration_conflict) {
          // INFORM ABOUT MIGRATIONS ERROR
          openModal(
            <InventoryConflictModal onSubmit={() => handleMakeCorrections(bodyParams)} />
          );
        } else {
          toastResponseErrors(response);
        }
      });
  };

  const getInitialValues = () => {
    if (migration.id !== Number(migrationId)) return undefined;
    if (isEmpty(migration)) return undefined;
    return {
      barns: migration.barns?.length ? migration.barns : [{}],
      delivery_date: moment(migration.delivery_date || migration.created_at),
      amount: migration.amount,
      estimated_weight: formatBackEndWeightToSystem(migration.estimated_weight, measurementSystem),
      condition: migration.condition,
    };
  };

  const isEditDisabled = isLoading || isSubmitting || !formAmount;

  return (
    <CenterBox>
      <section className="center-box-header show-for-large">
        <div className="title">
          <FormattedMessage
            id="general.editShipmentWithName"
            values={{ name: migration?.pig_group?.name }}
          />
        </div>
        <Link onClick={handleGoBack}><i className="fa fa-times close-center-box" /></Link>
      </section>

      <PigsUnderCareLine pigsCount={migration?.pig_group?.pigs || 0} mobile />

      <section className="center-box-body">
        <Preloader isActive={isLoading || isSubmitting} />
        <ShipmentForm
          groupFarmId={migration?.pig_group?.farm_id}
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
          minDeliveryDate={migration.pig_group?.created_on ? moment(migration.pig_group.created_on) : undefined}
        />
      </section>

      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" onClick={handleGoBack}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button primary className="submit wider" disabled={isEditDisabled} onClick={() => submit('group-shipment')}>
            <FormattedMessage id="general.editShipment" />
          </Button>
        </div>
      </section>

      <StickyFooter className="hide-for-large edit-shipment-footer">
        <Button primary className="submit" disabled={isEditDisabled} onClick={() => submit('group-shipment')}>
          <FormattedMessage id="general.editShipment" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

ShipmentEdit.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  migration: T.object,
  isLoading: T.bool,
  submit: T.func,
  fetchPigGroupMigration: T.func.isRequired,
  clearPigGroupMigration: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  openModal: T.func.isRequired,
  measurementSystem: T.string.isRequired,
  formAmount: T.number,
};

const selector = formValueSelector('group-shipment');

export default connect(
  (state) => ({
    formAmount: selector(state, 'amount'),
    migration: state.pigGroups.groupMigrationShow.data,
    isLoading: state.pigGroups.groupMigrationShow.isLoading,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), {
    submit,
    fetchPigGroupMigration,
    clearPigGroupMigration,
    setPageOptions,
    openModal,
  },
)(ShipmentEdit);
