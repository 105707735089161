import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from './utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { reportTreatments } from 'reducers/dailyCheckup/checkupEdit';
import { openPortalRight, closePortalRight } from 'reducers/rightToLeftPortal';
import {
  fetchList as fetchTreatmentsList,
  setDestroyedData,
  setSelectedData,
  selectItem as selectTreatment,
  unselectItem as unselectTreatment,
  resetData as resetTreatmentsData,
} from 'reducers/mobileListPicker';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import CenterBox from 'components/CenterBox';
import CommentBox from 'components/CommentBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileTreatmentRow from 'components/DailyCheckups/MobileTreatmentRow';
import ButtonRadioGroup from 'components/ButtonRadioGroup/ButtonRadioGroup';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import AttentionBox from 'components/AttentionBox/AttentionBox';
import OralTreatmentUnit from 'components/DailyCheckups/TreatmentProductLabels/OralTreatmentUnit';
import Translation from 'components/Translation';
import WithdrawalWarningBox from './components/WithdrawalWarningBox';
// utils
import { animateScroll } from 'react-scroll';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getMedType, withdrawalParams, formatDosageType } from 'utils';
import update from 'react-addons-update';
import memoize from 'lodash.memoize';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import cn from 'classnames';
import moment from 'moment';
import { sendNotify } from 'utils/airbrakeHelper';
import { getCommentByRole } from 'helpers.js';
import { getCheckupBackUrls } from 'utils/checkupHelper';
// assets
import './DailyCheckupReportTreatments.scss';
// constants
import { comment_roles } from 'constants.js';

const efficacyOptions = [
  { value: false, label: <FormattedMessage id="general.button.yes" /> },
  { value: true, label: <FormattedMessage id="general.button.no" /> },
];

function buildHeadsCountData(treatments) {
  return treatments
    .filter(({ treatment_product_id, _destroy }) => treatment_product_id && !_destroy)
    .map(({ head_count }) => head_count)
    .sort((curr, next) => (curr - next));
}

function calcTotalHeadCount(treatmentsHeads) {
  if (!treatmentsHeads.length) return 0;
  if (treatmentsHeads.length === 1) return treatmentsHeads[0];
  return Math.max(...treatmentsHeads);
}

class DailyCheckupReportTreatments extends Component {

  state = {
    comment: '',
    mentionsIds: [],
    headCount: 0,
  };

  componentDidMount() {
    const { fetchTreatmentsList } = this.props;
    this.setInitialData(this.props);
    fetchTreatmentsList('/treatment_products');
  }

  componentDidUpdate(prevProps) {
    const { checkup, selectedTreatments } = this.props;
    const { checkup: prevCheckup } = prevProps;
    if (checkup.id !== prevCheckup?.id) {
      this.setInitialData(this.props);
    }


    if (selectedTreatments !== prevProps.selectedTreatments) {
      const treatmentsHeadsArr = buildHeadsCountData(prevProps.selectedTreatments);
      const nextTreatmentsHeadsArr = buildHeadsCountData(selectedTreatments);
      if (!isEqual(treatmentsHeadsArr, nextTreatmentsHeadsArr)) {
        const value = calcTotalHeadCount(nextTreatmentsHeadsArr);
        if (value !== this.state.headCount) this.setState({ headCount: value });
      }
    }
  }

  componentWillUnmount() {
    const { resetTreatmentsData, setPageOptions } = this.props;
    resetTreatmentsData();
    setPageOptions({ mobileTitle: '' });
  }

  setInitialData = ({ checkup, isEditMode }) => {
    const { setSelectedData, setPageOptions, location } = this.props;
    const { TREATMENTS_ROLE } = comment_roles;

    if (isEmpty(checkup)) return;

    const { comment, mentioned_user_ids } = getCommentByRole(checkup.comments || [], TREATMENTS_ROLE);
    const { prevPageUrl } = getCheckupBackUrls(checkup, location);

    setPageOptions({
      mobileTitle: 'reportMedications',
      backLink: {
        link: prevPageUrl,
        icon: 'fa fa-times fs-18',
      },
      inEditMode: isEditMode,
    });

    this.setState({
      comment: comment || '',
      mentionsIds: mentioned_user_ids || [],
      headCount: checkup.total_pigs_treated,
    });

    if (!checkup.dc_treatments.length) {
      setSelectedData([{}]);
    } else {
      // TODO: backend returns quantity in string format (fix?)
      const dcTreatments = checkup.dc_treatments.map((treatment) => ({
        ...treatment, quantity: parseFloat(treatment.quantity) || null,
        gallons_value: parseFloat(treatment.gallons_value) || null,
      }));
      setSelectedData(dcTreatments);
    }
  };

  openMobilePicker = (checkup, backLink) => {
    const { openPortalRight } = this.props;
    const title = <FormattedMessage id="general.pageTitle.selectMedications" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        idKey="treatment_product_id"
        backLinkOptions={{ url: backLink }}
        rightButtonOptions={{ label: 'next' }}
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  renderMobileListRow = (treatment, mode, { isSelected, isDisabled }) => {
    const onRowClick = {
      select: () => this.onMobileTreatmentAdd(treatment),
      change: () => this.onMobileTreatmentChange(treatment),
      add: () => this.onMobileTreatmentAdd(treatment),
    }[mode];
    const selectedRow = isSelected(treatment);
    const disabledRow = isDisabled(treatment);
    const actionBtnType = mode === 'change' ? 'button' : 'checkbox';
    return (
      <MobileTreatmentRow
        key={treatment.id}
        treatment={treatment}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
    );
  };

  addRow = () => {
    const { openPortalRight, setSelectedData, selectedTreatments } = this.props;

    if (!isMobile) {
      setSelectedData([...selectedTreatments, {}]);
      return;
    }

    const title = <FormattedMessage id="general.pageTitle.addMedications" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        mode="add"
        idKey="treatment_product_id"
        rightButtonOptions={{ label: 'next' }}
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  removeRow = (index) => () => {
    const { selectedTreatments, destroyedTreatments, setDestroyedData, setSelectedData } = this.props;
    const data = [...selectedTreatments];
    const destroyData = [];
    if (data[index].id) {
      destroyData.push({
        ...data[index],
        _destroy: true,
      });
    }
    setSelectedData(update(selectedTreatments, { $splice: [[index, 1]] }));
    if (destroyData.length) setDestroyedData([...destroyedTreatments, ...destroyData]);
  };

  onTreatmentChange = (index, treatmentProduct) => {
    if (!treatmentProduct) return;
    const { id, vaccine, route, dosage_measure, dosage_type } = treatmentProduct;
    const { selectedTreatments, checkup, destroyedTreatments, setDestroyedData, setSelectedData } = this.props;
    const destroyedTreatment = destroyedTreatments.find(({ treatment_product_id }) => treatment_product_id === id);
    const newTreatment = destroyedTreatment
      ? {
        ...destroyedTreatment,
        _destroy: undefined,
      }
      : {
        id: selectedTreatments[index].id,
        treatment_product_id: parseInt(id, 10),
        skip_efficacy_survey: false,
      };
    const defaultValue = dosage_measure === 'head' && dosage_type === 'ml' ? 1 : checkup?.pigs || 0;
    const headCount = (vaccine || route.includes('oral')) ? defaultValue : 0;

    const overrideAttrs = {
      treatment_product: treatmentProduct,
      quantity: 0.00,
      head_count: headCount,
    };

    const newTreatments = update(selectedTreatments, {
      [index]: { $set: Object.assign(newTreatment, overrideAttrs) },
    });

    if (destroyedTreatment) {
      setDestroyedData(destroyedTreatments.filter(({ treatment_product_id }) => (
        treatment_product_id !== destroyedTreatment.treatment_product_id)));
    }
    setSelectedData(newTreatments);
  };

  onDetailInputChange = (value, key, index) => {
    const { selectedTreatments, setSelectedData, checkup: { pigs } } = this.props;
    const prev = selectedTreatments[index]?.[key];
    let newValue = ['quantity', 'gallons_value'].includes(key)
      ? this.parseDecimalText(value, prev)
      : parseInt(value, 10);
    if (newValue !== prev) {
      if (key === 'head_count') {
        newValue = newValue <= pigs ? newValue : pigs;
      }
      const treatments = update(selectedTreatments, {
        [index]: { $merge: { [key]: parseFloat(newValue) } },
      });
      setSelectedData(treatments);
    }
  };

  onEfficacySurveyChange = (e, index) => {
    const { selectedTreatments, setSelectedData } = this.props;
    const oldVal = selectedTreatments[index]?.skip_efficacy_survey;
    const newVal = e.target.value === 'true';

    if (newVal !== oldVal) {
      const treatments = update(selectedTreatments, {
        [index]: { $merge: { skip_efficacy_survey: newVal } },
      });
      setSelectedData(treatments);
    }
  };

  parseDecimalText = (text, prev) => {
    const pattern = /^[+-]?\d+(\.\d+)?$/; // float number
    if (text === '') return text;
    if (!`0${text}0`.match(pattern)) return prev;
    return text;
  };

  optionRenderer = (option) => {
    const className = getMedType(option);
    return (
      <span className={`medication ${className} ${option.divider ? 'divider' : ''}`}>
        <span>
          <i className={`fa fa-${className}`} />
          {option.name}
        </span>
        <span className="right-text">
          {option.common && <FormattedMessage id="general.meds.common" />}
          {option.vaccine && <>(<FormattedMessage id="general.vaccine" />)</>}
        </span>
      </span>
    );
  };

  onMobileTreatmentAdd = (treatment) => {
    const { id, route, vaccine, dosage_measure, dosage_type } = treatment;
    const {
      checkup, selectedTreatments, destroyedTreatments, setDestroyedData, selectTreatment,
      unselectTreatment
    } = this.props;
    const selectedTreatment = selectedTreatments.find(({ treatment_product_id }) => treatment_product_id === id);
    const destroyedTreatment = destroyedTreatments.find(({ treatment_product_id }) => treatment_product_id === id);
    const newTreatment = destroyedTreatment
      ? {
        ...destroyedTreatment,
        _destroy: undefined,
      }
      : {
        treatment_product_id: id,
        skip_efficacy_survey: false,
      };
    const defaultValue = dosage_measure === 'head' && dosage_type === 'ml' ? 1 : checkup?.pigs || 0;
    const headCount = (vaccine || route.includes('oral')) ? defaultValue : 0;
    const overrideAttrs = {
      treatment_product: treatment,
      quantity: 0.00,
      head_count: headCount,
    };

    if (selectedTreatment) {
      unselectTreatment(selectedTreatment);
      if (selectedTreatment.id) {
        setDestroyedData([...destroyedTreatments, { ...selectedTreatment, _destroy: true }]);
      }
    } else {
      selectTreatment(Object.assign(newTreatment, overrideAttrs));
    }

    if (destroyedTreatment) {
      setDestroyedData(destroyedTreatments.filter(({ treatment_product_id }) => (
        treatment_product_id !== destroyedTreatment.treatment_product_id)));
    }
  };

  onMobileTreatmentChange = (treatment) => {
    const {
      checkup,
      selectedTreatments,
      destroyedTreatments,
      setDestroyedData,
      setSelectedData,
      closePortalRight
    } = this.props;
    const { index } = this.state;
    const destroyed = { ...selectedTreatments[index], _destroy: true };
    const treatments = update(selectedTreatments, {
      [index]: {
        $set: {
          treatment_product_id: treatment.id,
          treatment_product: treatment,
          head_count: (treatment.vaccine || treatment.route.includes('oral')) ? (checkup?.pigs || 0) : 0,
          quantity: 0,
          skip_efficacy_survey: false,
        },
      },
    });
    setDestroyedData([...destroyedTreatments, destroyed]);
    setSelectedData(treatments);
    closePortalRight();
  };

  onMobileModeChange = (index) => {
    this.setState({ index });
    const { openPortalRight } = this.props;
    const title = <FormattedMessage id="general.pageTitle.changeMedication" />;
    openPortalRight(
      <MobileListPicker
        title={title}
        mode="change"
        idKey="treatment_product_id"
        rowRenderer={this.renderMobileListRow}
      />
    );
  };

  submitData = () => {
    const {
      checkup, selectedTreatments, destroyedTreatments, reportTreatments, validateCheckup, currentUser,
      location, makeCheckupCorrection, isEditMode
    } = this.props;
    const { comment, mentionsIds, headCount } = this.state;
    const { router } = this.context;

    // validateCheckup is declared in DailyCheckupContainer
    if (!validateCheckup(checkup)) {
      return;
    }

    const filteredList = selectedTreatments.filter((item) => item.treatment_product_id);
    const destroyedFilteredList = destroyedTreatments.filter(({ id, _destroy }) => !!id && !!_destroy);
    const resource = {
      dc_treatments: [...filteredList, ...destroyedFilteredList],
      comments_treatments: { text: comment, mentioned_user_ids: mentionsIds },
      total_pigs_treated: headCount,
    };
    const { checkupUrl } = getCheckupBackUrls(checkup, location);

    const submitRequest = isEditMode
      ? makeCheckupCorrection(checkup.id, resource)
      : reportTreatments(checkup.id, { resource });

    submitRequest
      .then(() => router.push(checkupUrl))
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };

  isContinueDisabled = memoize((treatments, checkup) => {
    if (treatments.length === 0) {
      return !checkup.treatment_count;
    }

    const dcEndDayPigsCount = checkup?.pigs || 0;

    return treatments.some(({ gallons_value, head_count, quantity, treatment_product_id, treatment_product }) => (
      !treatment_product_id ||
      !head_count ||
      (head_count > dcEndDayPigsCount) ||
      ((treatment_product.dosage_measure === 'gal') && !gallons_value) ||
      ((getMedType(treatment_product) !== 'topical') && !parseFloat(quantity))
    ));
  });

  getUnselectedTreatments = (selectedTreatment) => {
    const { treatmentsList, selectedTreatments } = this.props;

    return treatmentsList.filter(({ id }) => (
      id === selectedTreatment.treatment_product_id ||
      !selectedTreatments.some((selected) => selected.treatment_product_id === id)));
  };

  setHeadCount = (val) => {
    const { selectedTreatments } = this.props;

    const value = Number.isInteger(val) ? val : parseInt(val.target.value, 10);
    const input = document.querySelector('#head-total-count');
    const isFocused = (document.activeElement === input);
    const headCountArr = buildHeadsCountData(selectedTreatments);

    if (!headCountArr.length) return;

    const headCount = isFocused && headCountArr.length > 1
      ? value
      : this.validateHeadCount(headCountArr, value);

    if (this.state.headCount !== headCount) this.setState({ headCount });
  };

  setHeadCountBlur = (e) => {
    const { selectedTreatments } = this.props;
    const value = parseInt(e.target.value, 10);
    const headCountArr = buildHeadsCountData(selectedTreatments);

    if (!headCountArr.length) return;

    this.setState({ headCount: this.validateHeadCount(headCountArr, value) });
  };

  validateHeadCount = (headCountArr, value) => {
    const { checkup: { pigs } } = this.props;

    if (headCountArr.length === 1) return headCountArr[0];

    const minHeadTreatment = Math.min(...headCountArr);

    if (value < minHeadTreatment) return minHeadTreatment;
    if (value > pigs) return pigs;
    return value;
  };

  handleFocus = (e) => {
    e.target.select();
  };

  onCommentOpen = () => {
    animateScroll.scrollToBottom({ containerId: 'bodybag' });
  };

  handleBlur = ({ target: { value } }, key, index) => {
    const { selectedTreatments, setSelectedData, checkup: { pigs } } = this.props;
    const prev = selectedTreatments[index]?.[key];
    let newValue = (key === 'quantity')
      ? this.parseDecimalText(value, prev)
      : parseInt(value, 10);
    if (newValue !== prev) {
      if (key === 'head_count') newValue = newValue <= pigs ? newValue : pigs;
      const treatments = update(selectedTreatments, {
        [index]: { $merge: { [key]: parseFloat(newValue) } },
      });
      setSelectedData(treatments);
    }
  };

  needWithdrawalWarningMessage = (treatmentProduct) => {
    const { value, period } = withdrawalParams(treatmentProduct);
    const { checkup, withdrawalTrackingOn } = this.props;
    const isWithdrawalWarnActual = moment(checkup.created_on).add(value, period) >= moment();

    return withdrawalTrackingOn && isWithdrawalWarnActual;
  };

  getTreatmentLabel = (treatmentProduct) => {
    if (!treatmentProduct) return null;
    const { measurementSystem } = this.props;
    const { route, dosage_measure } = treatmentProduct;
    const dosage_type = formatDosageType(treatmentProduct.dosage_type, measurementSystem);
    const type = <FormattedMessage id={`general.short.${dosage_type}`} />;

    if (route.includes('oral')) {
      const msgKey = dosage_measure === 'head' ? 'oralLabel' : 'unitsAdministered';
      return (
        <FormattedMessage id={`container.checkupEdit.reportMedications.${msgKey}`} values={{ type }} />
      );
    }

    return (
      <FormattedMessage
        id="container.checkupEdit.reportMedications.injectableLabel"
        values={{ type, measure: <FormattedMessage id={`general.${dosage_measure}`} /> }}
      />
    );
  };

  render() {
    const { checkup, isReportingTreatments, isEditMode, selectedTreatments, location, isOnline } = this.props;
    const { up_to_date_tp_steps_count: protocolTreatmentsCount } = checkup;
    const { comment, headCount, mentionsIds } = this.state;
    const isContinueDisabled = this.isContinueDisabled(selectedTreatments, checkup);
    // hide trash can if it unsaved last row
    const hideTrash = (selectedTreatments.length) === 1 && !selectedTreatments[0].id;
    const { prevPageUrl } = getCheckupBackUrls(checkup, location);

    return (
      <CenterBox
        isLoading={isReportingTreatments}
        renderIf={Boolean(checkup)}
        className="DailyCheckupReportTreatments"
      >
        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title"><FormattedMessage id="general.medications" /></h1>
          </div>
          <Link to={prevPageUrl}><i className="fa fa-times close-center-box" /></Link>
        </section>
        <PigsUnderCareLine mobile pigsCount={checkup.pigs} />
        <section className="center-box-body">
          {isEditMode && (
            <MessageBox type="warning">
              <strong><FormattedMessage id="component.alertBox.warning" />&nbsp;</strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}
          {isOnline && !isEditMode && !!protocolTreatmentsCount && (
            <MessageBox type="warning">
              <FormattedMessage
                id="component.checkupSegment.pendingTreatmentsCount"
                values={{
                  count: protocolTreatmentsCount,
                  a: (msg) => <Link className="primary" to={`/daily-checkup/${checkup.id}/treatments`}>{msg}</Link>,
                }}
              />
            </MessageBox>
          )}
          <div className="treatment-rows">
            {selectedTreatments.map((treatment, index) => {
              const {
                treatment_product_id, treatment_product, head_count, quantity, skip_efficacy_survey,
                gallons_value, treatment_protocol_product_step_id,
              } = treatment;

              // is treatment used from treatment protocol
              const isTreatmentProtocolProduct = treatment_protocol_product_step_id !== null &&
                treatment_protocol_product_step_id !== undefined;

              return (
                <div className="treatment-row" key={`treatment-${index}-${treatment_product_id}`}>
                  <div className="select-treatment-line">
                    <div className={cn('index-cell', { disabled: isTreatmentProtocolProduct })}>
                      <div className="row-index">{index + 1}</div>
                    </div>
                    <div className="select-treatment-wrapper">
                      {isMobile
                        ? (
                          <div
                            className={cn('mobile-treatment-input', { disabled: isTreatmentProtocolProduct })}
                            onClick={() => this.onMobileModeChange(index)}
                          >
                            <div className="treatment-name">
                              {treatment_product?.name || (
                                <span className={cn('placeholder')}>
                                  <FormattedMessage id="general.pageTitle.selectMedications" />
                                </span>
                              )}
                            </div>
                            <i className="change-treatment-icon fa fa-angle-right-btb" />
                          </div>
                        )
                        : (
                          <ReactSelect
                            placeholder={<FormattedMessage id="general.pageTitle.selectMedications" />}
                            options={this.getUnselectedTreatments(treatment)}
                            valueKey="id"
                            labelKey="name"
                            arrowRenderer={() => <i className="fa fa-angle-down" />}
                            value={treatment_product_id}
                            onChange={(value) => this.onTreatmentChange(index, value)}
                            resetValue={{}}
                            disabled={isTreatmentProtocolProduct}
                            clearable={false}
                            optionRenderer={this.optionRenderer}
                          />
                        )}
                    </div>
                    <div className={cn('trash-cell', { 'hidden': hideTrash || isTreatmentProtocolProduct })}>
                      <i className="fa fa-trash-o trash-icon" onClick={this.removeRow(index)} />
                    </div>
                  </div>

                  {isTreatmentProtocolProduct && (
                    <MessageBox type="default" icon={<i className="fa fa-info-circle" />}>
                      <FormattedMessage id="general.productIsAppliedAccordingToTreatmentProtocol" />
                    </MessageBox>
                  )}

                  {treatment_product_id && this.needWithdrawalWarningMessage(treatment_product)
                  && (<WithdrawalWarningBox treatmentProduct={treatment_product} checkup={checkup} />)}
                  {treatment_product_id && treatment_product.dosage_type === 'unit' && (
                    <AttentionBox className="oral-attention">
                      <FormattedMessage id="general.unitSize" />&nbsp;•&nbsp;
                      <OralTreatmentUnit product={treatment_product} />
                    </AttentionBox>
                  )}
                  {treatment_product_id && treatment_product.notes && (
                    <AttentionBox showIcon={false} className="oral-attention">
                      <span>
                        <b><FormattedMessage id="general.notes" /> •&nbsp;</b>
                        <Translation text={treatment_product.notes}>
                          {treatment_product.notes}
                        </Translation>
                      </span>
                    </AttentionBox>
                  )}
                  {treatment_product_id && (
                    <div
                      className={cn(
                        'select-values-line',
                        { 'skip-margin-bottom': treatment_product.efficacy_survey && !isEditMode }
                      )}
                    >
                      <div className="index-cell" />
                      <div className="treatment-details">
                        <div className="treatment-details-row">
                          <i className="fa fa-exclamation-triangle-bts icon" />
                          <NumberStepInput
                            className="treatment-number-input"
                            label={<FormattedMessage id="container.checkupEdit.reportMedications.headTreated" />}
                            value={head_count || ''}
                            onChange={(value) => {
                              this.onDetailInputChange(value, 'head_count', index);
                            }}
                            onFocus={this.handleFocus}
                            onBlur={(e) => this.handleBlur(e, 'head_count', index)}
                          />
                        </div>
                        {!treatment_product.route.includes('topical') && (
                          <div className="treatment-details-row">
                            <i
                              className={cn(
                                'fa fa-exclamation-triangle-bts icon',
                                { 'visible': treatment_product.vaccine && (quantity === 0) },
                              )}
                            />
                            <NumberStepInput
                              label={this.getTreatmentLabel(treatment_product)}
                              value={
                                quantity ||
                                (treatment_product.dosage_measure === 'head' && treatment_product.dosage_type === 'ml' ?
                                  0.25 :
                                  0)
                              }
                              onChange={(value) => this.onDetailInputChange(value, 'quantity', index)}
                              onFocus={this.handleFocus}
                              onBlur={(e) => this.handleBlur(e, 'quantity', index)}
                              hasWarning={treatment_product.vaccine && (quantity === 0)}
                              type="number"
                              className="treatment-number-input"
                              step={treatment_product.quantity_step || 0.25}
                              normalizeValue={(value) => value.toString()}
                            />
                          </div>
                        )}
                        {treatment_product.dosage_measure === 'gal' &&
                        !treatment_product.route.includes('topical') && (
                          <div className="treatment-details-row">
                            <i
                              className={cn(
                                'fa fa-exclamation-triangle-bts icon',
                                { 'visible': treatment_product.vaccine && (quantity === 0) },
                              )}
                            />
                            <NumberStepInput
                              className="treatment-number-input"
                              label={<FormattedMessage id="general.gallonsWater" />}
                              value={gallons_value || ''}
                              onChange={(value) => this.onDetailInputChange(value, 'gallons_value', index)}
                              onFocus={this.handleFocus}
                              onBlur={(e) => this.handleBlur(e, 'gallons_value', index)}
                            />
                          </div>
                        )}
                      </div>
                      <div className={cn('trash-cell', { 'hidden': hideTrash })} />
                    </div>
                  )}
                  {!isEditMode && treatment_product?.efficacy_survey && (
                    <div className="select-values-line">
                      <div className="index-cell" />
                      <div className="treatment-efficacy-survey">
                        <div className="tes-label">
                          <FormattedMessage id="container.checkupEdit.reportMedications.skipEfficacySurvey" />
                        </div>
                        <ButtonRadioGroup
                          options={efficacyOptions}
                          value={skip_efficacy_survey || false}
                          onChange={(e) => this.onEfficacySurveyChange(e, index)}
                          className="tes-options"
                        />
                      </div>
                      <div className={cn('trash-cell', { 'hidden': hideTrash })} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <button className="add-treatment" onClick={this.addRow}>
            <FormattedMessage id="container.checkupEdit.reportMedications.add" />
          </button>

          <CommentBox
            hasMentions
            disabledIds={mentionsIds}
            entityId={checkup?.pig_group?.farm_id}
            entityType="farm"
            onOpen={this.onCommentOpen}
            onValueChange={(comment) => this.setState((prevState) => ({ ...prevState, comment }))}
            onMentionsChange={(mentions) => {
              this.setState((prevState) => ({ ...prevState, mentionsIds: mentions.map((i) => i.id) }));
            }}
            value={comment}
            isOpen={!!comment}
            placeholder=""
          />

          <div className="treatment-details">
            <div className="treatment-details-row total-count">
              <i className="fa fa-exclamation-triangle-bts icon visible" />
              <NumberStepInput
                label={<FormattedMessage id="container.checkupEdit.reportMedications.totalTreated" />}
                value={headCount}
                onChange={this.setHeadCount}
                onFocus={this.handleFocus}
                onBlur={this.setHeadCountBlur}
                hasWarning
                className="treatment-number-input always-show-warning"
                id="head-total-count"
              />
            </div>
          </div>

        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={prevPageUrl}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingTreatments}
              className="submit wider"
              onClick={this.submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            stickyFooter
            primary
            disabled={isContinueDisabled || isReportingTreatments}
            onClick={this.submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupReportTreatments.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupReportTreatments.propTypes = {
  checkup: T.object,
  location: T.object.isRequired,
  isReportingTreatments: T.bool.isRequired,
  treatmentsList: T.array.isRequired,
  selectedTreatments: T.array.isRequired,
  destroyedTreatments: T.array.isRequired,
  reportTreatments: T.func.isRequired,
  fetchTreatmentsList: T.func.isRequired,
  setDestroyedData: T.func.isRequired,
  setSelectedData: T.func.isRequired,
  selectTreatment: T.func.isRequired,
  unselectTreatment: T.func.isRequired,
  resetTreatmentsData: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  closePortalRight: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  setPageOptions: T.func,
  makeCheckupCorrection: T.func,
  isEditMode: T.bool,
  withdrawalTrackingOn: T.bool,
  currentUser: T.object,
  measurementSystem: T.string,
  isOnline: T.bool.isRequired,
};

export default compose(
  connect((state, props) => ({
    treatmentsList: state.mobileListPicker.dataList.resources,
    selectedTreatments: state.mobileListPicker.selected,
    destroyedTreatments: state.mobileListPicker.destroyed,
    isReportingTreatments: props.isLoading || state.dailyCheckup.checkupEdit.isReportingTreatments,
    withdrawalTrackingOn: state.auth.user.current_company.withdrawal_tracking_on,
    currentUser: state.auth.user,
    measurementSystem: state.auth.user.current_company.measurement_system,
    isOnline: state.network.isOnline,
  }), {
    reportTreatments,
    setPageOptions,
    fetchTreatmentsList,
    setDestroyedData,
    setSelectedData,
    selectTreatment,
    unselectTreatment,
    resetTreatmentsData,
    openPortalRight,
    closePortalRight,
  }),
  withCorrection,
)(DailyCheckupReportTreatments);
