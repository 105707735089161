import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import UpdateCategoryNameModal from '../UpdateCategoryNameModal';
// utils
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
// store
import { openModal } from 'reducers/modals';
// styles
import styles from './CategoryHeader.module.scss';

const cn = classnames.bind(styles);

const CategoryHeader = ({ name, questions, onReset, onDelete, onUpdate, isCustom, openModal }) => {
  const allQuestionsCount = questions.length;
  const enabledQuestionsCount = questions.filter(({ is_enabled }) => is_enabled).length;

  const handleReset = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.resetAllDefaults" />}
        actionBtnLabel={<FormattedMessage id="component.modal.siteVisitReports.resetAllDefaults.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.siteVisitReports.resetAllDefaults.warning" />}
        handleConfirm={onReset}
      >
        <FormattedMessage
          id="component.modal.siteVisitReports.resetAllDefaults.text"
          values={{ name: <strong>{name}</strong> }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  const handleDelete = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.siteVisitReports.deleteCategory" />}
        actionBtnLabel={<FormattedMessage id="component.modal.siteVisitReports.deleteCategory.confirm" />}
        actionBtnProps={{ primary: true }}
        warningMessage={<FormattedMessage id="component.modal.siteVisitReports.resetAllDefaults.warning" />}
        handleConfirm={onDelete}
      >
        <FormattedMessage
          id="component.modal.siteVisitReports.deleteCategory.text"
          values={{ name: <strong>{name}</strong> }}
          tagName="p"
        />
      </ConfirmationModal>
    );
  };

  const handleUpdate = () => {
    openModal(<UpdateCategoryNameModal onSubmit={onUpdate} name={name} />);
  };

  return (
    <div>
      <div className={cn('category-header', 'pb-15')}>
        <span className="semibold">{name}</span>
        &nbsp;
        {enabledQuestionsCount}/{allQuestionsCount}
        <div className={cn('btn-group')}>
          {!isCustom ? (
            <div onClick={handleReset} className={cn('btn')}>
              <FormattedMessage id="general.resetAllDefaults" />
            </div>
          ) : (
            <>
              <div onClick={handleUpdate} className={cn('btn')}>
                <FormattedMessage id="general.button.rename" />
              </div>
              <div onClick={handleDelete} className={cn('btn')}>
                <FormattedMessage id="general.button.delete" />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

CategoryHeader.propTypes = {
  name: T.string.isRequired,
  questions: T.array.isRequired,
  isCustom: T.bool.isRequired,
  onReset: T.func.isRequired,
  onDelete: T.func.isRequired,
  onUpdate: T.func.isRequired,
  openModal: T.func.isRequired,
};

export default connect(null, { openModal })(CategoryHeader);
