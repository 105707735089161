import React from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
// utils
import cn from 'classnames';
// styles
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ breadcrumbs, className = '' }) => {
  const breadcrumbsLevelsCount = breadcrumbs.length;
  return (
    <h3 className={cn(styles.breadcrumbs, { [className]: !!className })}>
      {breadcrumbs.map((level, index) => {
        const isFirstLevel = index === 0;
        const isLastLevel = index === breadcrumbsLevelsCount - 1;
        return (
          <span key={index} className={styles['breadcrumbs-tab']}>
            {!isFirstLevel && <span className={styles.separator}>|</span>}
            <Link
              to={level.path || null}
              className={cn({ [styles['now-active']]: isLastLevel })}
            >
              {level.label}
            </Link>
          </span>
        );
      })}
    </h3>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: T.array.isRequired,
  className: T.string.isRequired,
};

export default Breadcrumbs;
