import React from 'react';
import T from 'prop-types';
// components
import ReportCard from 'components/SiteVisitReports/ReportCard';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './GridView.module.scss';

const cn = classnames.bind(styles);

const GridView = ({ resources, backLocationQuery }) => (
  <div className={cn('grid-view')}>
    {resources.map((report) => <ReportCard key={report.id} report={report} backLocationQuery={backLocationQuery} />)}
  </div>
);

GridView.propTypes = {
  resources: T.array.isRequired,
  backLocationQuery: T.object,
};

export default GridView;
