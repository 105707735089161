import React, { Component } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { bytesToSize } from 'utils';
import { getAuthData } from 'utils/authData';
import { toastr } from 'react-redux-toastr';
import Resumable from 'resumablejs';
import cn from 'classnames';
// assets, styles
import uploadCsv from '../../../../public/images/upload_csv.svg';
import checkImage from '../../../../public/images/checkCard.svg';
import uploadFileImg from '../../../../public/images/upload-file.svg';
import close from '../../CustomModal/toClose.svg';
import './CSVUploader.scss';

let dragCounter = 0;

const types = ['csv'];
const fileTypes = types.concat(types.map((ext) => ext.toUpperCase()));

class CSVUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dragOver: false,
    };
    this.uploaderBody = React.createRef();
    this.resumableDropzone = React.createRef();
    this.resumableBrowseLink = React.createRef();
  }

  componentDidMount() {
    if (this.uploaderBody.current) {
      this.uploaderBody.current.addEventListener('dragenter', this.onDragEnter);
      this.uploaderBody.current.addEventListener('dragleave', this.onDragLeave);
      this.uploaderBody.current.addEventListener('drop', this.onDropEevent);
      this.initializeFileUploader();
    }
  }

  componentWillUnmount() {
    if (this.uploaderBody.current) {
      this.uploaderBody.current.removeEventListener('dragenter', this.onDragEnter);
      this.uploaderBody.current.removeEventListener('dragleave', this.onDragLeave);
      this.uploaderBody.current.removeEventListener('drop', this.onDropEevent);
    }
  }

  componentDidUpdate(prevProps) {
    const { uploadedFile } = this.props;
    if (!uploadedFile && prevProps.uploadedFile !== uploadedFile) {
      this.initializeFileUploader();
    }
  }

  // File Uploader

  initializeFileUploader = () => {
    const R = new Resumable({
      headers: { Accept: '*/*', ...getAuthData() },
      simultaneousUploads: 3,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      fileType: fileTypes,
      fileTypeErrorCallback: (file) => {
        this.setState({
          dragOver: false,
        }, () => {
          dragCounter = 0;
          toastr.error('', '', {
            icon: <i className="fa fa-ban" />,
            className: 'file-type-error',
            component: (
              <FormattedMessage
                id="component.toastr.availFileTypes.text"
                values={{ filename: file.name, types: types.join(', ') }}
              >
                {(text) => <div className="rrt-text">{text}</div>}
              </FormattedMessage>
            )
          });
        });
      },
      maxFileSizeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className="fa fa-ban" />,
          component: (
            <FormattedMessage
              id="general.fileSizeExceeded"
              values={{ size: '300mb', filename: file.name }}
            >
              {(text) => <div className="rrt-text">{text}</div>}
            </FormattedMessage>
          )
        });
      }
    });

    if (R.support) {
      const { uploadedFile } = this.props;
      const browseLink = this.resumableBrowseLink.current;
      const dropzoneElement = this.resumableDropzone.current;

      if (browseLink && !uploadedFile) {
        R.assignDrop(dropzoneElement);
      }
      // browseLink will be null if file already uploaded
      if (browseLink) {
        const arr = Array.from(browseLink.childNodes);
        if (!arr.find((item) => item.tagName === 'INPUT')) {
          R.assignBrowse(browseLink);
        }

      }
      R.on('fileAdded', this.onFileAdded);
      R.on('fileSuccess', this.onFileSuccess);
      R.on('fileError', this.onFileError);
    } else {
      console.warn('ResumableJS not supported!');
    }
  };

  onFileAdded = (file) => {
    const R = file.resumableObj;
    this.setState({ dragOver: false });
    dragCounter = 0;

    R.upload();
  };

  onFileSuccess = (file) => {
    const { item: { type }, onSelectFile } = this.props;
    onSelectFile(file, type);
  };

  onFileError = (file, message) => {
    console.log('ResumableJS: onFileError', file.fileName);
    toastResponseErrors(message);
  };

  // Drag & Drop

  onDragEnter = () => {
    dragCounter++;
    if (dragCounter === 1) {
      this.setState({ dragOver: true });
    }
  };

  onDragLeave = () => {
    dragCounter--;
    if (dragCounter === 0) {
      this.setState({ dragOver: false });
    }
  };

  onDropEevent = () => {
    dragCounter = 0;
    this.setState({ dragOver: false });
  };

  delete = (itemType, file) => {
    const { resumableObj, uniqueIdentifier } = file;
    const { deleteUploadedFile } = this.props;
    resumableObj.removeFile(file.file);
    deleteUploadedFile(itemType, uniqueIdentifier);
  };

  render() {
    const {
      uploadedFile,
      setImportAction,
      item: { type: itemType },
      item,
      importAction,
    } = this.props;
    const { dragOver } = this.state;
    const isDropzoneEmpty = !uploadedFile;
    return (
      <div className="uploadLabelBlock">
        <div className="uploadTitle">
          <FormattedMessage id={item.title} />
          <div className="uploadDuplicate">
            <FormattedMessage id={`general.csvImport.uploadDuplicate.${itemType}`} tagName="i" />
          </div>
        </div>
        <div className="CSVUploader">
          <div
            ref={this.uploaderBody}
            className={cn('uploader-body', {
              empty: isDropzoneEmpty,
              dragOver,
              'border-none': !isDropzoneEmpty,
            })}
          >
            <div
              key={uploadedFile ? 'uploaded' : 'empty'}
              ref={this.resumableDropzone}
              className={cn('dropzone show-for-large', { active: dragOver })}
            />
            {!uploadedFile && (
              <div
                className={cn('dropzone-placeholder show-for-large', {
                  minimized: !isDropzoneEmpty
                })}
              >
                <div className="contentUpload">
                  <img src={uploadCsv} alt="Upload" />
                  <div className="wrapperInfo">
                    <FormattedMessage
                      id="general.csvImport.upload"
                      values={{
                        link: (
                          <a ref={this.resumableBrowseLink}>
                            <FormattedMessage id="general.csvImport.upload.link" />
                          </a>
                        )
                      }}
                    />
                    <FormattedMessage id="general.csvImport.upload.types" />
                  </div>
                </div>
              </div>
            )}


            {uploadedFile && (
              <div className="wrapperAssetUpload">
                <div className="asset-actions">
                  <img
                    className="delete-img"
                    src={close}
                    alt="close"
                    onClick={() => this.delete(itemType, uploadedFile)}
                  />
                </div>
                <div className="assets-list">
                  {/* UPLOADED ASSETS */}
                  <div className="asset-wrapper">
                    <div className="assets-item">
                      <img src={uploadFileImg} alt="file" />
                      <div className="asset-info" title={uploadedFile.fileName}>
                        <div className="asset-description">
                          <FormattedMessage
                            tagName="b"
                            id="general.csvImport.upload.assets"
                            values={{ nameFile: (<span>{uploadedFile.fileName}</span>) }}
                          />
                          {bytesToSize(uploadedFile.size)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="updateExisting"
          onClick={() => setImportAction(importAction === 'creating' ? 'updating' : 'creating')}
        >
          {importAction === 'creating' ? (
            <div className="icon" />
          ) : (
            <img src={checkImage} alt="check" />
          )}
          <FormattedMessage id={`general.csvImport.updateExisting.${itemType}`} tagName="b" />
        </div>
      </div>
    );
  }
}

CSVUploader.defaultProps = {
  importAction: 'creating',
};

CSVUploader.propTypes = {
  uploadedFile: T.object,
  deleteUploadedFile: T.func.isRequired,
  onSelectFile: T.func.isRequired,
  item: T.object,
  setImportAction: T.func.isRequired,
  importAction: T.string,
};

export default CSVUploader;
