import React from 'react';
import T from 'prop-types';
// components
import TimeAgo from 'components/TimeAgo/TimeAgo';
import FarmTypeLabel from 'components/FarmTypeLabel';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SiteVisitReportsFarmRow.module.scss';

const cn = classnames.bind(styles);

const getStatusIcon = (status) => {
  switch (status) {
    case 'active':
      return <div className={cn('red-marker')} />;
    case 'disabled':
      return <div className={cn('fa fa-lock', 'status-icon')} />;
    default:
      return null;
  }
};

const SiteVisitReportsFarmRow = ({ farm, onSelect }) => (
  <div className={cn('svr-farm-row')}>
    <div className={cn('icon-section')}>
      {getStatusIcon(farm.status)}
    </div>
    <div className={cn('name-section')}>
      <span className={cn('farm-name')}>
        {farm.name}
        <FarmTypeLabel className="ml-10" farmType={farm.farm_type} />
      </span>
      <span className={cn('farm-manager')}>
        {farm.manager?.name || <FormattedMessage id="general.notAvail" />}
      </span>
    </div>
    <div className={cn('vertical-divider')} />
    <div className={cn('report-time-section')}>
      {farm.last_svr_created_at && farm.last_svr_created_by ? (
        <FormattedMessage
          id="general.lastReportSubmitted"
          values={{ time_ago: <TimeAgo date={farm.last_svr_created_at} />, user_full_name: farm.last_svr_created_by }}
        />
      ) :
        <FormattedMessage id="general.noReportsSubmitted" />
      }
    </div>
    <div className={cn('action-section')}>
      <Button onClick={onSelect} className={cn('select-btn')} primary>
        <FormattedMessage id="general.button.select" />
      </Button>
    </div>
  </div>
);

SiteVisitReportsFarmRow.propTypes = {
  farm: T.object.isRequired,
  onSelect: T.func.isRequired,
};

export default SiteVisitReportsFarmRow;
