import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setCurrentCompany } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import ToggleSwitcher from 'components/ToggleSwitcher';
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import Button from 'components/Button';
import NumberStepInput from 'components/NumberStepInput/NumberStepInput';
import Input from 'components/Input';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import TenantSettingTogglePanel from 'components/TenantSettingTogglePanel/TenantSettingTogglePanel';
// utils
import range from 'lodash.range';
import cn from 'classnames';
import { capitalize, showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// API
import { getTenantSettings, updateTenantSettings } from 'endpoints/tenantSettings';
// assets
import './DeathAlerts.scss';

const deathAlertTypes = [
  { key: 'chronic_death_alert', label: 'chronic' },
  { key: 'acute_death_alert', label: 'acute' },
  { key: 'euthanasia_death_alert', label: 'euthanized' },
];


const minDeathCount = 1;
const maxDeathCount = 100;
const selectOptions = range(minDeathCount, maxDeathCount + 1).map((value) => ({
  value,
  label: value,
}));

const options = [
  { label: <FormattedMessage id="container.deathAlerts.triggerType.number" />, value: false },
  { label: <FormattedMessage id="container.deathAlerts.triggerType.percent" />, value: true },
];

const initialValues = {
  percent_death_alerts: false,
  chronic_death_alert: false,
  acute_death_alert: false,
  euthanasia_death_alert: false,
  chronic_death_alert_count: '',
  acute_death_alert_count: '',
  euthanasia_death_alert_count: '',
};

const defaultCountValue = 3;
const defaultPercentValue = 10;

class DeathAlerts extends Component {

  state = {
    isLoading: false,
    isLoaded: false,
    savedValues: initialValues,
    values: initialValues,
  };

  componentDidMount() {
    this.getDeathAlerts();
  }

  getDeathAlerts = () => {
    this.setState({ isLoading: true });
    getTenantSettings()
      .then((response) => {
        const { acute_death_alert_count, chronic_death_alert_count, euthanasia_death_alert_count } = response;
        const values = this.formatValues(response);
        this.setState({
          isLoading: false,
          isLoaded: true,
          savedValues: {
            ...values,
            acute_death_alert_count: Number(acute_death_alert_count),
            chronic_death_alert_count: Number(chronic_death_alert_count),
            euthanasia_death_alert_count: Number(euthanasia_death_alert_count),
          },
          values,
        });
      })
      .catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  formatValues = ({ acute_death_alert_count, chronic_death_alert_count, euthanasia_death_alert_count,
    percent_death_alerts }) => {
    const defaultValue = percent_death_alerts ? defaultPercentValue : defaultCountValue;
    return {
      percent_death_alerts,
      acute_death_alert_count: Number(acute_death_alert_count) || defaultValue,
      chronic_death_alert_count: Number(chronic_death_alert_count) || defaultValue,
      euthanasia_death_alert_count: Number(euthanasia_death_alert_count) || defaultValue,
      acute_death_alert: Boolean(acute_death_alert_count),
      chronic_death_alert: Boolean(chronic_death_alert_count),
      euthanasia_death_alert: Boolean(euthanasia_death_alert_count),
    };
  };

  onTriggerTypeChange = (data) => {
    const percent_death_alerts = data.percent_death_alerts === 'true';
    const defaultValue = percent_death_alerts ? defaultPercentValue : defaultCountValue;
    this.setState((prevState) => {
      const data = {};
      deathAlertTypes.forEach(({ key }) => {
        data[`${key}_count`] = (!prevState.values[`${key}_count`])
          ? defaultValue
          : prevState.values[`${key}_count`];
      });
      return {
        values: {
          ...prevState.values,
          ...data,
          percent_death_alerts,
        },
      };
    });
  };

  onChange = (field, value) => {
    this.setState((prevState) => ({
      values: {
        ...prevState.values,
        [field]: value,
      },
    }));
  };

  onTogglerChange = (field) => (event) => this.onChange(field, event.target.checked);

  onSelectChange = (field) => (value) => this.onChange(field, Number(value));

  onInputChange = (field) => (e) => {
    let { value } = e.target;
    if (value === '') value = 0;
    if (value > maxDeathCount || value < 0) return;
    this.onChange(field, value);
  };

  onMobileInputChange = (field) => (value) => this.onChange(field, Number(value));

  onSubmit = () => {
    const { values } = this.state;
    const { setCurrentCompany } = this.props;
    const data = { percent_death_alerts: values.percent_death_alerts };
    deathAlertTypes.forEach(({ key }) => {
      data[`${key}_count`] = (values[key] && values[`${key}_count`]) ? values[`${key}_count`] : null;
    });

    this.setState({ isLoading: true });
    updateTenantSettings(data)
      .then((response) => {
        this.setState({ isLoading: false });
        setCurrentCompany({ percent_death_alerts: response.percent_death_alerts });
        const { acute_death_alert_count, chronic_death_alert_count, euthanasia_death_alert_count } = response;
        const values = this.formatValues(response);
        this.setState({
          isLoading: false,
          isLoaded: true,
          savedValues: {
            ...values,
            acute_death_alert_count: Number(acute_death_alert_count),
            chronic_death_alert_count: Number(chronic_death_alert_count),
            euthanasia_death_alert_count: Number(euthanasia_death_alert_count),
          },
          values,
        });
        showToastrMessage('component.toastr.deathAlerts.updated');
      }).catch((errors) => {
        this.setState({ isLoading: false });
        return toastResponseErrors(errors);
      });
  };

  isContinueDisabled = () => {
    const { isLoaded, isLoading, values: { acute_death_alert_count, acute_death_alert, chronic_death_alert_count,
      chronic_death_alert, euthanasia_death_alert_count, euthanasia_death_alert, percent_death_alerts },
    savedValues } = this.state;

    const hasEmptyValue =
      (!acute_death_alert_count && acute_death_alert) ||
      (!chronic_death_alert_count && chronic_death_alert) ||
      (!euthanasia_death_alert_count && euthanasia_death_alert);

    if (isLoading || !isLoaded || hasEmptyValue) return true;

    const acuteTogglerNotChanged = savedValues.acute_death_alert === acute_death_alert;
    const chronicTogglerNotChanged = savedValues.chronic_death_alert === chronic_death_alert;
    const euthanasiaTogglerNotChanged = savedValues.euthanasia_death_alert === euthanasia_death_alert;

    const togglerNotChanged = acuteTogglerNotChanged && chronicTogglerNotChanged && euthanasiaTogglerNotChanged;

    const acuteValueNotChanged = savedValues.acute_death_alert_count === acute_death_alert_count;
    const chronicValueNotChanged = savedValues.chronic_death_alert_count === chronic_death_alert_count;
    const euthanasiaValueNotChanged = savedValues.euthanasia_death_alert_count === euthanasia_death_alert_count;

    const valuesNotChanged =
      (!acute_death_alert || acuteValueNotChanged) &&
      (!chronic_death_alert || chronicValueNotChanged) &&
      (!euthanasia_death_alert || euthanasiaValueNotChanged);

    const triggerTypeNotChanged = percent_death_alerts === savedValues.percent_death_alerts;

    return togglerNotChanged && valuesNotChanged && triggerTypeNotChanged;
  };

  render() {
    const { isLoading, values: { percent_death_alerts }, values } = this.state;
    const isContinueDisabled = this.isContinueDisabled();

    return (
      <div>
        <TenantSettingTogglePanel
          options={options}
          value={percent_death_alerts}
          onChange={this.onTriggerTypeChange}
          settingKey="percent_death_alerts"
          titleKey="container.deathAlerts.triggerType"
          isDisabled={isLoading}
          isBordered
        />

        <section>
          <Panel className="DeathAlerts">
            <Panel.Heading title="Death Loss" />
            <Panel.Body>
              <Preloader isActive={isLoading} />
              {deathAlertTypes.map(({ key, label }) => (
                <section className="setting-section" key={key}>
                  <ToggleSwitcher
                    name={key}
                    className="small-12 medium-8"
                    text={<FormattedMessage id={`container.deathAlerts.${label}`} />}
                    checked={!!values[key]}
                    onChange={this.onTogglerChange(key)}
                  >
                    <div className={cn('section-description show-for-large', { disabled: !values[key] })}>
                      {!percent_death_alerts && (
                        <FormattedMessage
                          id="container.deathAlerts.triggerNumberAlert"
                          values={{
                            type: <b>{capitalize(label)}</b>,
                            inputField: (
                              <ResponsiveSelect
                                options={selectOptions}
                                disabled={!values[key]}
                                input={{
                                  value: values[`${key}_count`],
                                  onChange: this.onSelectChange(`${key}_count`),
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                      {percent_death_alerts && (
                        <FormattedMessage
                          id={`container.deathAlerts.triggerPercentAlert.${label}`}
                          values={{
                            type: <b>{capitalize(label)}</b>,
                            triggerType: <b>percent</b>,
                            inputField: (
                              <Input
                                className="death-percent-input"
                                placeholder={0.0}
                                value={values[`${key}_count`]}
                                min={minDeathCount}
                                max={maxDeathCount}
                                onChange={this.onInputChange(`${key}_count`)}
                                disabled={!values[key]}
                                type="number"
                              />
                            ),
                          }}
                        />
                      )}
                    </div>
                    <div
                      className={cn('section-description-mobile hide-for-large', {
                        disabled: !values[key],
                      })}
                    >
                      <FormattedMessage tagName="div" id="container.deathAlerts.triggerAfterAmount" />
                      <NumberStepInput
                        value={values[`${key}_count`] || ''}
                        min={minDeathCount}
                        max={maxDeathCount}
                        onChange={this.onMobileInputChange(`${key}_count`)}
                        isDisabled={!values[key]}
                      />
                    </div>
                  </ToggleSwitcher>
                </section>
              ))}

              <div className="buttons">
                <Button className="alerts-save-btn" disabled={isContinueDisabled} primary onClick={this.onSubmit}>
                  <FormattedMessage id="general.button.saveChanges" />
                </Button>
              </div>
            </Panel.Body>
          </Panel>
        </section>
      </div>
    );
  }
}

DeathAlerts.propTypes = {
  setCurrentCompany: T.func.isRequired,
};

export default connect(null, { setCurrentCompany })(DeathAlerts);
