import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchData } from 'reducers/dataTable';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DateRangePicker from 'components/DatePicker/DateRangePicker';
import ReactSelect from 'components/Select/ReactSelect';
import WaterUsagePanel from '../components/WaterUsagePanel';
import BarnsheetsWaterUsageTable from './BarnsheetsWaterUsageTable';
// api
import { getPigGroupWaterUsageCSV } from 'endpoints/pigGroups';
// utils
import cn from 'classnames';
import moment from 'moment';
import round from 'lodash.round';
import download from 'downloadjs';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getDatesFromRange } from 'utils/treatmentChartsHelper.js';
// constants
import { antibioticStatsOptions } from 'constants.js';
// styles
import './BarnsheetsWaterUsage.scss';

const calculateAverages = (resources) => {
  let sum = 0;
  let count = 0;
  let lastAverage = 0;
  const result = [];

  for (let i = resources.length - 1; i >= 0; i--) {
    const { gal_per_head } = resources[i];
    let average = 0;
    if (gal_per_head) {
      count++;
      sum += gal_per_head;
      average = round(sum / (count), 2);
      lastAverage = average;
    }

    result.push({
      ...resources[i],
      average: average || lastAverage,
    });
  }
  return result;
};

const calculateAdditions = (resources) => calculateAverages(resources.map((res, idx, arr) => {
  const nextItem = arr[idx + 1];
  const { water_usage_value, created_on, pigs } = res;
  const formatDate = moment(created_on, 'YYYY-MM-DD').format('MMM DD');
  const gal_per_head = water_usage_value && (water_usage_value / pigs);

  const defaultRes = {
    ...res,
    change: 0,
    gal_per_head,
    tooltip_date: moment(created_on).format('MMM DD, YYYY'),
    formattedValue: round(gal_per_head, 2),
    date: formatDate,
  };

  if (!nextItem || !nextItem.water_usage_value || !pigs) {
    return defaultRes;
  }
  const { pigs: nextPigs, water_usage_value: nextWaterUse } = nextItem;
  const nextValue = nextWaterUse / nextPigs;
  const diff = gal_per_head - nextValue;
  const change = (diff / nextValue) * 100;
  return {
    ...defaultRes,
    change,
  };
}));

class BarnsheetsWaterUsage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      period_name: 'year_to_date',
      ...getDatesFromRange('year_to_date'),
    };
  }

  componentDidMount = () => {
    const { fetchData } = this.props;

    fetchData();
  };

  downloadCSV = (e) => {
    e.stopPropagation();
    const { pigGroup: { id, name } } = this.props;
    const { startDate, endDate } = this.state;

    const date_start = startDate.format('YYYY-MM-DD');
    const date_end = endDate.format('YYYY-MM-DD');

    getPigGroupWaterUsageCSV(id, { date_start, date_end })
      .then((blob) => download(blob, `group-${name}-water-usage-${date_start}-${date_end}`, 'text/csv'))
      .catch(toastResponseErrors);
  }

  sortByNewest = (data) => [...data.sort((a, b) =>  moment(b.created_on).diff(moment(a.created_on)))]

  sortByOldest = (data) => [...data.sort((a, b) =>  moment(a.created_on).diff(moment(b.created_on)))]

  filterResources = (resources, start_date, end_date) => {
    const { startDate: state_startDate, endDate: state_endDate } = this.state;
    let filtered = resources;
    const startDate = start_date || state_startDate;
    const endDate = end_date || state_endDate;

    if (startDate && endDate) {
      filtered = resources.filter(({ created_on }) => moment(
        created_on,
        'YYYY-MM-DD'
      ).isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), null, '[]'));
    }

    return filtered;
  }

  onDateChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  }

  onPeriodSelect = (option) => {
    if (!option) return;
    const { value } = option;
    if (value !== this.state.period_name) {
      const dates = getDatesFromRange(value);
      this.setState({ period_name: value, ...dates });
    }
  };

  calculateLastPeriodGrowth = (filteredResources) => {
    const { startDate, endDate } = this.state;
    const { resources } = this.props;
    const periodLength = moment(endDate).diff(startDate, 'days');
    const prev_startDate = moment(startDate).subtract(periodLength, 'days');
    const prev_endDate = moment(endDate).subtract(periodLength, 'days');

    const prev_resources = this.filterResources(resources, prev_startDate, prev_endDate);

    const curr_avg = filteredResources[filteredResources.length - 1];
    const prev_avg = prev_resources[filteredResources.length - 1];

    if (curr_avg && prev_avg) {
      const diff = round(curr_avg.average - prev_avg.average, 2);
      const growth = (diff / curr_avg.average) * 100;
      return {
        diff,
        growth
      };
    }

    return {
      diff: 0,
      growth: 0,
    };
  }

  render() {
    const { isLoading, resources } = this.props;
    const filteredResources = this.filterResources(resources);
    const { diff, growth } = this.calculateLastPeriodGrowth(filteredResources);
    const { startDate, endDate, period_name } = this.state;

    return (
      <div className={cn('BarnsheetsWaterUsage')}>
        <div className={cn('mb-20 filters-wrap')}>
          {period_name === 'custom' && (
            <DateRangePicker
              className={cn('date-picker')}
              startDate={startDate}
              endDate={endDate}
              isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
              onDatesChange={this.onDateChange}
            />
          )}
          <ReactSelect
            className={cn('select-period')}
            placeholder={<FormattedMessage id="general.selectPeriod" />}
            options={antibioticStatsOptions}
            valueKey="value"
            labelKey="name"
            value={period_name}
            onChange={this.onPeriodSelect}
            noInputMargin
            searchable={false}
          />
          <Button className={cn('download-button')} primary onClick={this.downloadCSV} disabled={isLoading}>
            <FormattedMessage id="general.button.download" />
          </Button>
        </div>
        <WaterUsagePanel
          isLoading={isLoading}
          waterUsageData={this.sortByOldest(filteredResources)}
          growth={growth}
          diff={diff}
        />
        <BarnsheetsWaterUsageTable
          data={this.sortByNewest(filteredResources)}
        />
      </div>
    );
  }
}

BarnsheetsWaterUsage.contextTypes = {
  router: T.object.isRequired,
};

BarnsheetsWaterUsage.propTypes = {
  fetchData: T.func.isRequired,
  pigGroup: T.object.isRequired,
  isLoading: T.bool.isRequired,
  resources: T.array.isRequired,
};


export default connect(
  (state) => ({
    currentUser: state.auth.user,
    resources: calculateAdditions(state.dataTable.barnsheetsWaterUsage.resources),
    isLoading: state.dataTable.barnsheetsWaterUsage.isLoading,
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
  }), (dispatch, { params }) => (
    {
      fetchData: () => dispatch(fetchData(`/pig_groups/${params.id}/daily_checkup_water_usages`,
        'barnsheetsWaterUsage')),
    }
  )
)(BarnsheetsWaterUsage);
