import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './EmptySectionPlaceholder.module.scss';

const cn = classnames.bind(styles);

const EmptySectionPlaceholder = ({ categoryName, hasSearch }) => (
  <div className={cn('empty-container')}>
    <FormattedMessage
      id={hasSearch ? 'component.emptySearchResult' : 'component.emptyCategory'}
      values={{ category_name: categoryName }}
    />
  </div>
);

EmptySectionPlaceholder.propTypes = {
  hasSearch: T.bool.isRequired,
  categoryName: T.string.isRequired,
};

export default EmptySectionPlaceholder;
