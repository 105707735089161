import React, { useState } from 'react';
import T from 'prop-types';
// components
import UserAvatar from 'components/UserAvatar/index';
import PaginationDots from './PaginationDots';
import DiagnosisBadge from 'components/DiagnosisBadge';
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
// utils
import cn from 'classnames';
import TimeAgo from 'react-timeago';
import { isVideoAsset } from 'utils';
// styles
import './ImageBox.scss';

const AssetBox = ({ assets, farm, pigGroup, assetsData, openViewer, type, global_created_at }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { creator, url } = assets[currentIndex];
  const currentAsset = assets[currentIndex];

  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % assets.length);
  };

  const prevImage = () => {
    setCurrentIndex((prev) => (prev === 0 ? assets.length - 1 : prev - 1));
  };

  const handleViewer = () => {
    if (type === 'gallery') {
      openViewer(assets, currentIndex, { farm, pig_group: pigGroup }, 'gallery');
    }

    if (type === 'barnsheetsGallery') {
      const currentAsset = assets[currentIndex];
      const index = assetsData.indexOf(currentAsset);
      openViewer(assetsData, index, { farm, pig_group: pigGroup }, 'barnsheetsGallery');
    }
  };

  return (
    <div className="ImageBox">
      <div className={cn('ImageBox__preview', { 'hide-nav': assets.length < 2 })}>
        <div
          className="bg-image animated fadeIn"
          onClick={handleViewer}
          key={`asset_${currentIndex}`}
          style={{ backgroundImage: `url(${url.thumb || url.small || url.medium})` }}
        >
          {isVideoAsset(assets[currentIndex]) && <VideoPreviewOverlay asset={assets[currentIndex]} />}
        </div>

        <div className="image-overlay" />

        {creator && (
          <UserAvatar user={creator} size={28} className="author-avatar" />
        )}

        {assets.length > 1 && (
          <PaginationDots assetsLength={assets.length} currentIndex={currentIndex} />
        )}

        <div className={cn('navigation', { 'hidden': assets.length < 2 })}>
          <div className="navigation-arrow left" onClick={prevImage}>
            <i className="fa fa-arrow-left" />
          </div>
          <div className="navigation-arrow right" onClick={nextImage}>
            <i className="fa fa-arrow-right" />
          </div>
        </div>
      </div>

      <div className="ImageBox__description">
        <div className="description">
          <div className="farm-name">{farm.name} <span className="type-badge">{farm.farm_type || 'other'}</span></div>
          <div className="group-name">{pigGroup.name}</div>
        </div>

        <div className="statistic">
          <div className="time-ago mt-5">
            <TimeAgo date={global_created_at} />
          </div>
        </div>
      </div>
      <DiagnosisBadge diagnosed={!!currentAsset.image_tags_count} />
    </div>
  );
};

AssetBox.defaultProps = {
  type: 'gallery',
  assetsData: [],
};

AssetBox.propTypes = {
  assets: T.array.isRequired,
  farm: T.object.isRequired,
  pigGroup: T.object.isRequired,
  openViewer: T.func.isRequired,
  global_created_at: T.string.isRequired,
  type: T.string,
  assetsData: T.array,
};

export default AssetBox;
