import React, { useState, useEffect, useMemo } from 'react';
import T from 'prop-types';
// redux, recompose
import { connect } from 'react-redux';
import { compose } from 'recompose';
// components
import { formValueSelector, Field, FieldArray, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import NumberInputField from 'components/ReduxFormFields/NumberInputField/NumberInputField';
import MultiLineSelect from 'components/ReduxFormFields/MultiLineSelect/MultiLineSelect';
import DatePickerField from 'components/ReduxFormFields/DatePickerField/DatePickerField';
import WeightField from 'components/ReduxFormFields/WeightField/WeightField';
import ConditionRadioButtons from 'components/ConditionRadioButtons/ConditionRadioButtons';
import CommentBox from 'components/CommentBox';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
// endpoints
import { getFarmBarns } from 'endpoints/farms';
// styles
import styles from './ShipmentForm.module.scss';

const cn = classnames.bind(styles);

const renderConditionField = ({ input, meta: { touched, error } }) => (
  <div className={cn('shipment-field', 'mb-15', { 'has-error': touched && error })}>
    <div className="row">
      <div className="small-12 medium-3 column label-wrapper">
        <FormattedMessage id="general.condition" />
      </div>
      <div className="small-12 medium-9 column">
        <ConditionRadioButtons {...input} />
      </div>
      <div className="small-12 medium-3 column" />
    </div>
  </div>
);

const renderNotes = ({ input }) => (
  <CommentBox
    {...input}
    onValueChange={input.onChange}
    value={input.value || ''}
    isOpen={!!input.value}
  />
);

const ShipmentForm = ({
  barns,
  groupFarmId,
  handleSubmit,
  hasComment,
  minDeliveryDate,
}) => {
  const [options, setOptions] = useState([]);

  const formattedOptions = useMemo(() => {
    return barns?.length
      ? options.map((option) => ({ ...option, disabled: !!barns.find(({ id }) => id === option.id) }))
      : options;
  }, [barns, options]);

  useEffect(() => {
    if (groupFarmId) {
      getFarmBarns(groupFarmId).then(setOptions);
    }
  }, [groupFarmId]);

  const normalizeNumber = (value) => (Math.abs(parseInt(value, 10)));

  const isOutsideDeliveryDateRange = (day) => {
    if (!minDeliveryDate) return day.isAfter(moment(), 'day');
    return day.isAfter(moment(), 'day') || day.isBefore(minDeliveryDate, 'day');
  };

  return (
    <form onSubmit={handleSubmit} className={cn('shipment-form')}>
      <div className="small-12 column">
        <Field
          label={<FormattedMessage id="general.shipmentDate" />}
          name="delivery_date"
          component={DatePickerField}
          className={cn('shipment-field', 'mb-15')}
          isOutsideRange={isOutsideDeliveryDateRange}
        />
        <Field
          label={<FormattedMessage id="general.headPlaced" />}
          name="amount"
          component={NumberInputField}
          normalize={normalizeNumber}
          className={cn('shipment-field', 'mb-15')}
        />
        <Field
          label={<FormattedMessage id="general.estAvgWeight" />}
          name="estimated_weight"
          component={WeightField}
          className={cn('shipment-field', 'mb-15')}
        />
        <Field
          name="condition"
          component={renderConditionField}
        />
        {!!formattedOptions.length && (
          <FieldArray
            options={formattedOptions}
            name="barns"
            label={<FormattedMessage id="general.locationOnFarm" />}
            placeholder={<FormattedMessage id="general.addLocation" />}
            addRowLabel={<FormattedMessage id="general.addLocationWithPlus" />}
            component={MultiLineSelect}
          />
        )}
        {hasComment && (
          <Field
            name="comment"
            component={renderNotes}
          />
        )}
      </div>
    </form>
  );
};

ShipmentForm.propTypes = {
  handleSubmit: T.func.isRequired,
  groupFarmId: T.number,
  barns: T.array,
  minDeliveryDate: T.object,
  hasComment: T.bool,
};

const selector = formValueSelector('group-shipment');

const enhance = compose(
  reduxForm({
    form: 'group-shipment',
    enableReinitialize: true,
  }),
  connect(
    (state) => ({
      barns: selector(state, 'barns'),
    })
  ),
);

export default enhance(ShipmentForm);
