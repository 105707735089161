import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { setTableParams } from 'reducers/tableParams';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { openPortalRight } from 'reducers/rightToLeftPortal';
import { setPageOptions } from 'reducers/layout';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
import useGroupsColumnRenderers from 'hooks/barnSheets/useGroupsColumnRenderers';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { useMediaQuery } from 'react-responsive';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import BarnSheetsContainer from '../BarnSheetsContainer';
import SearchBox from 'components/SearchBox';
import NothingBox from 'components/NothingBox';
import TableFilter from 'components/TableFilter';
import DataTable from 'components/DataTable/DataTable';
import ArrowColumn from 'components/DataTable/Columns/ArrowColumn';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
import UserFilter from 'components/UserFilter';
import UserFilterMobile from 'components/UserFilterMobile';
import UserFilterMobileHeader from 'components/UserFilterMobile/UserFilterMobileHeader';
import { ReactComponent as DownloadIcon } from 'assets/svg/download-btn.svg';
// api
import {
  fetchBarnSheetsGroups,
  applyBarnSheetsGroupsFilter,
  downloadBarnSheetsGroupsCSV,
  downloadBarnSheetsGroupsFilterCSV,
} from 'endpoints/barnsheets/groups';
// utils, constants
import moment from 'moment';
import cn from 'classnames';
import download from 'downloadjs';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isTablet } from 'react-device-detect';
import { tableNames } from 'utils/constants';
import { allOrAnyGroupsOptions, userFilterGroupNames, userFilterSectionTypes } from 'utils/constants/userFilters';
// styles
import './BarnSheetsGroups.scss';

const tableFilters = [
  { label: <FormattedMessage id="general.allGroups" />, value: '' },
  { label: <FormattedMessage id="general.status.opened" />, value: 'opened' },
  { label: <FormattedMessage id="general.status.closed" values={{ date: null }} />, value: 'closed' },
];

const BarnSheetsGroups = ({
  enableEditMode,
  isAdmin,
  openPortalRight,
  setBreadcrumbs,
  setPageOptions,
  setTableParams,
  tableParams,
}, {
  router,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onStatusChange,
    onSearchChange,
    onUserFilterSectionsChange,
    onUserFilterSegmentSave,
  } = useDataTable(fetchBarnSheetsGroups, {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsGroups, params),
    tableParams,
    filterFetcher: applyBarnSheetsGroupsFilter,
    userFilterGroupName: userFilterGroupNames.barnSheetsPigGroups,
  });
  const { formatMessage } = useIntl();
  const { page, per_page, search, sort, status, filters } = tableParams;
  const { total, stats } = meta;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const userFilter = filters?.[0];
  const filterSectionsCount = userFilter?.sections?.length;
  const hasMobileFilterHeader = !isDesktop && filterSectionsCount > 0;

  const sections = [
    { value: userFilterSectionTypes.pigGroup, label: formatMessage({ id: 'general.groupId' }) },
    { value: userFilterSectionTypes.farm, label: formatMessage({ id: 'general.farmName' }) },
    { value: userFilterSectionTypes.farmType, label: formatMessage({ id: 'general.farmType' }) },
    { value: userFilterSectionTypes.pigsCount, label: formatMessage({ id: 'general.pageTitle.inventory' }) },
    { value: userFilterSectionTypes.mortalityRate, label: formatMessage({ id: 'general.mortalityRate' }) },
    { value: userFilterSectionTypes.compliance, label: formatMessage({ id: 'general.compliance' }) },
    { value: userFilterSectionTypes.weight, label: formatMessage({ id: 'general.estAvgWeight' }) },
    { value: userFilterSectionTypes.startDate, label: formatMessage({ id: 'general.startDate' }) },
    { value: userFilterSectionTypes.closeDate, label: formatMessage({ id: 'general.closeDate' }) },
  ];

  const handleOpenMobileFilters = () => {
    openPortalRight(
      <UserFilterMobile
        allOrAnyConditionLabel={<FormattedMessage id="component.advancedSearchItem.allGroupsFilters" />}
        appliedFilter={userFilter || {}}
        filterSections={sections}
        filterGroupName={userFilterGroupNames.barnSheetsPigGroups}
        setFilter={(filter) => fetchData({ ...tableParams, filters: filter ? [filter] : undefined })}
      />,
    );
  };

  const handleSetPageOptions = () => {
    setPageOptions({
      rightButton: !isDesktop ? {
        icon: 'fa fa-filter',
        onClick: handleOpenMobileFilters,
      } : '',
    });
  };

  useDidMountEffect(handleSetPageOptions, [tableParams, isDesktop]);

  useEffect(() => {
    fetchData({ ...tableParams, sort: tableParams.sort || 'status asc' }).catch(toastResponseErrors);
    setBreadcrumbs([
      {
        label: <FormattedMessage id="general.pageTitle.barnsheets" />,
        path: '/barnsheets/groups',
        useLabelAsMobileTitle: true,
      }
    ]);
    handleSetPageOptions();
    return () => {
      setPageOptions({ rightButton: '' });
    };
  }, []);

  const handleTurnOnEditMode = (id) => {
    enableEditMode(id).then(() => router.push(`/barnsheets/groups/${id}?editMode=true`));
  };

  const handleDownloadCSV = () => {
    const fileName = `barnsheets-pig-groups-${moment().format('MM-DD-YYYY-h-mm-a')}.csv`;
    return (userFilter ? downloadBarnSheetsGroupsFilterCSV : downloadBarnSheetsGroupsCSV)(tableParams)
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  const {
    renderGroupNameColumn,
    renderFarmNameColumn,
    renderFarmTypeColumn,
    renderStartDateColumn,
    renderPigsInColumn,
    renderInventoryColumn,
    renderMortalityRateColumn,
    renderEstimatedWeightColumn,
    renderTreatedColumn,
    renderProgressColumn,
    renderWaterUsageDiffColumn,
    renderStatusColumn,
    renderButtonsColumn,
    renderExpandable,
  } = useGroupsColumnRenderers({ isAdmin, handleTurnOnEditMode });

  const columns = [
    { label: <FormattedMessage id="component.dataTable.headers.groupId" />, flex: '1 1 145px',
      renderer: renderGroupNameColumn, sortKey: 'name' },
    { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 130px',
      renderer: renderFarmNameColumn, sortKey: 'farm_name' },
    { label: <FormattedMessage id="general.type" />, flex: '1 1 55px', renderer: renderFarmTypeColumn,
      sortKey: 'farm_type' },
    { label: <FormattedMessage id="component.dataTable.headers.startDate" />, flex: '1 1 95px',
      renderer: renderStartDateColumn, sortKey: 'started_on', textRight: true },
    { label: <FormattedMessage id="general.pigsIn" />, flex: '1 1 80px', renderer: renderPigsInColumn,
      sortKey: 'total_pigs_in', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.inventory" />, flex: '1 1 70px',
      renderer: renderInventoryColumn, sortKey: 'pigs', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.mortRate" />, flex: '1 1 90px',
      renderer: renderMortalityRateColumn, sortKey: 'mortality_rate', textRight: true },
    { label: <FormattedMessage id="general.estAvgWt" />, flex: '1 1 100px', renderer: renderEstimatedWeightColumn,
      sortKey: 'estimated_weight', textRight: true },
    { label: <FormattedMessage id="general.treatedPeriod" values={{ period: '24h' }} />, flex: '1 1 140px',
      renderer: renderTreatedColumn, textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.compliance" />, flex: '1 1 90px',
      renderer: renderProgressColumn, sortKey: 'progress', textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.waterUsage" />, flex: '1 1 90px',
      renderer: renderWaterUsageDiffColumn, textRight: true },
    { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 130px',
      renderer: renderStatusColumn, sortKey: 'status' },
    { label: '', flex: '0 0 45px', renderer: renderButtonsColumn, fixed: true,
      className: isTablet ? 'hide-for-large' : '' },
    { label: '', flex: '0 0 45px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
      hasPinnedIcon: true },
  ];

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };

  return (
    <BarnSheetsContainer
      className={cn('BarnSheetsGroups barn-sheets', { 'is-desktop': isDesktop })}
      headerContent={hasMobileFilterHeader && (
        <UserFilterMobileHeader
          onClick={handleOpenMobileFilters}
          onClose={() => fetchData({ ...tableParams, filters: undefined })}
          sectionsCount={filterSectionsCount}
          segmentName={userFilter?.name}
        />
      )}
    >
      {isDesktop && (
        <UserFilter
          isVisible
          filterGroupName={userFilterGroupNames.barnSheetsPigGroups}
          availFilterSections={sections}
          className="bs-filters"
          listOptions={allOrAnyGroupsOptions}
          onSectionsChange={onUserFilterSectionsChange}
          initialFilter={tableParams.filters?.[0]}
          onSave={onUserFilterSegmentSave}
        >
          {!!resources.length && (
            <div className="export-csv-btn" onClick={handleDownloadCSV}>
              <DownloadIcon width={13} className="mh-5" />
              <FormattedMessage id="general.exportCSV" />
            </div>
          )}
        </UserFilter>
      )}
      <Panel className="bs-table-panel panel-padding">
        <Panel.Heading
          renderTitle={() => (
            <h2 className="lighter show-for-large"><FormattedMessage id="general.pageTitle.groups" /></h2>
          )}
        >
          <SearchBox initialValue={search} onChange={onSearchChange} />
        </Panel.Heading>
        <Panel.Body noPadding>
          <Preloader isActive={isLoading} />

          <TableFilter
            filters={tableFilters}
            onFilterChange={onStatusChange}
            activeFilter={status}
            stats={stats}
            className="mh-10"
          />

          <DataTable
            data={resources}
            columns={columns}
            sort={sort}
            onSortChange={onSortChange}
            paginationProps={paginationProps}
            isExpandable={isTablet}
            isLoading={isLoading}
            renderExpandable={renderExpandable}
            scrollable
          />

          <NothingBox
            itemsName="barnsheets"
            display={!resources.length}
            isLoading={isLoading}
            search={search}
          >
            <h2 className="lighter"><FormattedMessage id="component.nothingBox.noBarnSheets" /></h2>
          </NothingBox>
        </Panel.Body>
      </Panel>
    </BarnSheetsContainer>
  );
};

BarnSheetsGroups.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsGroups.propTypes = {
  tableParams: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  isAdmin: T.bool,
  enableEditMode: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setTableParams: T.func.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsGroups),
    isAdmin: state.auth.user.roles_map.admin,
  }), {
    openPortalRight,
    setBreadcrumbs,
    setPageOptions,
    setTableParams,
    enableEditMode,
  }
)(BarnSheetsGroups);
