import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { closeModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import MessageBox from 'components/MessageBox';
import Modal from 'components/Modal';
// utils
import cn from 'classnames';

const ConfirmationModal = ({
  title,
  className,
  actionBtnLabel,
  actionBtnProps,
  closeModal,
  children,
  warningMessage,
  handleConfirm,
  noBorder,
}) => {
  const onSubmit = () => {
    handleConfirm();
    closeModal();
  };
  return (
    <Modal title={title} className={cn({ [className]: !!className })}>
      <section className="modal-body">
        {warningMessage && (
          <MessageBox isBorderHidden={noBorder} type="alert">
            <span>{warningMessage}</span>
          </MessageBox>
        )}
        <div>{children}</div>
      </section>
      <section className="modal-footer text-right">
        <Button className="margin-right-1" onClick={closeModal}><FormattedMessage id="general.button.cancel" /></Button>
        <Button {...actionBtnProps} onClick={onSubmit}>{actionBtnLabel}</Button>
      </section>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  title: T.oneOfType([T.string, T.object]).isRequired,
  className: T.string,
  actionBtnLabel: T.oneOfType([T.string, T.object]),
  actionBtnProps: T.object,
  children: T.node,
  warningMessage: T.oneOfType([T.string, T.object]),
  handleConfirm: T.func.isRequired,
  closeModal: T.func.isRequired,
  noBorder: T.bool,
};

ConfirmationModal.defaultProps = {
  actionBtnProps: {},
  actionBtnLabel: <FormattedMessage id="component.modal.allGood.confirm" />,
};

export default connect(null, { closeModal })(ConfirmationModal);
