import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Subnavigation from 'components/Subnavigation';
// redux
import { connect } from 'react-redux';

const HealthVariables = ({ children, isLogisticsEnabled }) => {
  const navLinks = [
    { link: '/admin/health-variables/medications', label: <FormattedMessage id="general.products" /> },
    { link: '/admin/health-variables/symptoms', label: <FormattedMessage id="general.symptoms" /> },
    { link: '/admin/health-variables/diseases', label: <FormattedMessage id="general.diseases" /> },
    { link: '/admin/health-variables/death-alerts', label: <FormattedMessage id="general.deathLoss" /> },
    { link: '/admin/health-variables/mortality-reasons', label: <FormattedMessage id="general.mortalityReasonsBL" /> },
    { link: '/admin/health-variables/setpoints', label: <FormattedMessage id="general.setPoints" /> },
    { link: '/admin/health-variables/genetics', label: <FormattedMessage id="general.genetics" />,
      isHidden: !isLogisticsEnabled },
  ];

  return (
    <div>
      <Subnavigation links={navLinks} className="small-12 column" />
      <section className="small-12 column">
        {children}
      </section>
    </div>
  );
};

HealthVariables.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  isLogisticsEnabled: T.bool.isRequired,
};

export default connect((state) => ({
  isLogisticsEnabled: state.auth.user.current_company.logistics,
}))(HealthVariables);
