import React, { Component } from 'react';
import T from 'prop-types';
// redux, recompose
import { compose } from 'recompose';
import withDataTableController from 'components/DataTable/DataTableController/DataTableController';
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { fetchData, setDataItem } from 'reducers/dataTable';
// components
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import UserAvatar from 'components/UserAvatar';
import TableFilter from 'components/TableFilter';
import SearchBox from 'components/SearchBox';
import DataTable from 'components/DataTable/DataTable';
import { ArrowColumn, CustomColumn } from 'components/DataTable/Columns';
import NothingBox from 'components/NothingBox';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// api
import { deleteUserFromAdmin } from 'endpoints/admin/users';
// utils
import pick from 'lodash.pick';
import values from 'lodash.values';
import cn from 'classnames';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { showToastrMessage } from 'utils';

const tableFilters = [
  { label: <FormattedMessage id="general.allUsers" />, value: '' },
  { label: <FormattedMessage id="general.status.active" />, value: 'active' },
  { label: <FormattedMessage id="general.status.pending" />, value: 'pending' },
  { label: <FormattedMessage id="general.status.disabled" />, value: 'disabled' },
  { label: <FormattedMessage id="general.status.incomplete" />, value: 'incomplete' },
];

class Users extends Component {

  componentDidMount() {
    const { fetchData, reqParams } = this.props;
    fetchData(reqParams).catch(toastResponseErrors);
  }

  getDropdownData = ({ id, email, status, registration_state, disabled }) => {
    const userActions = {
      resendInvite: {
        label: <FormattedMessage id="general.button.resendInvite" />,
        onClick: () => this.reInviteUser(id),
      },
      deleteUser: {
        label: <FormattedMessage id="general.deleteUser" />,
        onClick: () => this.deleteUser(id),
      },
      sendPassReset: {
        label: <FormattedMessage id="general.button.sendPasswordReset" />,
        onClick: () => this.sendPassReset(email),
      },
      grantAccess: {
        label: <FormattedMessage id="general.button.enableUser" />,
        onClick: () => this.enableUser(id, disabled),
      },
      revokeAccess: {
        label: <FormattedMessage id="general.button.disableUser" />,
        onClick: () => this.disableUser(id),
      },
    };
    const allowedActions = values({
      active: pick(userActions, ['revokeAccess', 'sendPassReset']),
      pending: pick(userActions, ['resendInvite', 'revokeAccess']),
      incomplete: pick(userActions, ['revokeAccess', 'sendPassReset']),
      disabled: pick(userActions, ['grantAccess', 'sendPassReset']),
    }[status]);

    // delete allowed only for 'invited' or 'bound' registration stage
    if (['invited', 'bound'].includes(registration_state)) {
      allowedActions.push(userActions.deleteUser);
    }

    return allowedActions;
  };

  reInviteUser = (id) => {
    const { setDataItem } = this.props;
    return fetchFromAPI(`/users/${id}/reinvite`, { method: 'put' })
      .then(({ resource }) => {
        setDataItem(resource);
        showToastrMessage('component.toastr.userInvite.resent');
      })
      .catch(toastResponseErrors);
  };

  deleteUser = (id) => {
    const { fetchData, reqParams } = this.props;
    deleteUserFromAdmin(id)
      .then(() => {
        showToastrMessage('component.toastr.user.deleted');
        fetchData(reqParams).catch(toastResponseErrors);
      })
      .catch(toastResponseErrors);
  };

  enableUserAction = (id, disabled) => {
    const { setDataItem } = this.props;
    const endpoint = `/admin/users/${id}/${disabled ? 'enable' : 'activations'}`;
    const method = disabled ? 'put' : 'post';

    fetchFromAPI(endpoint, { method })
      .then(({ resource }) => {
        setDataItem(resource);
        showToastrMessage('component.toastr.user.activated');
      })
      .catch(toastResponseErrors);
  };

  enableUser = (id, disabled) => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.button.enableUser" />}
        actionBtnLabel={<FormattedMessage id="component.modal.enableUser.confirm" />}
        actionBtnProps={{ blue: true }}
        handleConfirm={() => this.enableUserAction(id, disabled)}
      >
        <FormattedMessage id="component.modal.enableUser.text" tagName="p" />
      </ConfirmationModal>
    );
  };

  disableUserAction = (id) => {
    const { setDataItem } = this.props;
    fetchFromAPI(`/admin/users/${id}/disable`, { method: 'put' })
      .then(({ resource }) => {
        setDataItem(resource);
        showToastrMessage('component.toastr.user.deactivated');
      })
      .catch(toastResponseErrors);
  };

  disableUser = (id) => {
    this.props.openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.button.disableUser" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableUser.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableUser.warning" />}
        handleConfirm={() => this.disableUserAction(id)}
      />
    );
  };

  sendPassReset = (email) => {
    const redirect_url = window.location.origin + '/set-password';
    const reqParams = { method: 'post', body: JSON.stringify({ email, redirect_url }) };
    return fetchFromAPI('/auth/password', reqParams)
      .then(() => {
        showToastrMessage('component.toastr.passwordReset.sent');
      })
      .catch(toastResponseErrors);
  };

  renderUserColumn = (user) => (
    <CustomColumn>
      <div className="user-info">
        <UserAvatar className="mr-10" user={user} size={35} />
        <div className="info truncate">
          <Link className="name truncate black" to={`/admin/users/${user.id}`}>{user.full_name}</Link>
          <a className="email truncate hide-for-large">{user.email}</a>
        </div>
      </div>
    </CustomColumn>
  );

  renderEmailColumn = ({ email }) => (
    <CustomColumn className="show-for-large" label={<FormattedMessage id="general.email" />}>
      {email}
    </CustomColumn>
  );

  renderUserRolesColumn = ({ roles_count }) => (
    <CustomColumn label={<FormattedMessage id="general.userRoles" />}>
      {roles_count}
    </CustomColumn>
  );

  renderStatusColumn = ({ status }) => (
    <CustomColumn noBottomBorder label={<FormattedMessage id="component.dataTable.headers.status" />}>
      <StatusBadge status={status} />
    </CustomColumn>
  );

  renderActionsColumn = (user, { rowIndex }) => (
    <div className="collapsible-value button-column">
      <DropdownButton
        idKey={`${user.id}-${rowIndex}`}
        wide
        label={<FormattedMessage id="general.button.edit" />}
        buttonType="small light-gray"
        url={`/admin/users/${user.id}/farm-roles`}
        dropDownData={this.getDropdownData(user)}
        customClass="show-for-large"
      />
      {['active', 'pending'].includes(user.status) && (
        <Link to={`/admin/users/${user.id}/farm-roles`} className="button light hide-for-large">
          <FormattedMessage id="general.button.edit" />
        </Link>
      )}
      {['disabled', 'incomplete'].includes(user.status) && (
        <Button light onClick={() => this.deleteUser(user.id)} className="hide-for-large">
          <FormattedMessage id="general.button.delete" />
        </Button>
      )}
      {user.status === 'pending' && (
        <Button secondary onClick={() => this.reInviteUser(user.id)} className="hide-for-large">
          <FormattedMessage id="general.button.resendInvite" />
        </Button>
      )}
      {user.status === 'active' && (
        <Button darkGrey onClick={() => this.disableUser(user.id)} className="hide-for-large">
          <FormattedMessage id="general.button.disableUser" />
        </Button>
      )}
      {user.status === 'disabled' && (
        <Button darkGrey onClick={() => this.enableUser(user.id, user.disabled)} className="hide-for-large">
          <FormattedMessage id="general.button.enableUser" />
        </Button>
      )}
      {user.status === 'incomplete' && (
        <Link to={`/admin/users/${user.id}/farm-roles`} className="button warning hide-for-large">
          <FormattedMessage id="general.button.completeSetup" />
        </Link>
      )}
    </div>
  );

  renderExpandable = ({ id, disabled, status, email }) => (
    <div>
      {status === 'pending' && (
        <Button small light onClick={() => this.reInviteUser(id)}>
          <i className="fa fa-envelope mr-5" />
          <FormattedMessage id="general.button.resendInvite" />
        </Button>
      )}
      {status !== 'pending' && (
        <Button small light onClick={() => this.sendPassReset(email)}>
          <i className="fa fa-envelope mr-5" />
          <FormattedMessage id="general.button.sendPasswordReset" />
        </Button>
      )}
      {status === 'active' && (
        <Button small light onClick={() => this.disableUser(id)}>
          <i className="fa fa-times-circle mr-5" />
          <FormattedMessage id="general.button.disableUser" />
        </Button>
      )}
      {['disabled', 'incomplete'].includes(status) && (
        <Button small light onClick={() => this.deleteUser(id)}>
          <i className="fa fa-trash-o mr-5" />
          <FormattedMessage id="general.deleteUser" />
        </Button>
      )}
      {status === 'disabled' && (
        <Button small light onClick={() => this.enableUser(id, disabled)}>
          <i className="fa fa-check-circle mr-5" />
          <FormattedMessage id="general.button.enableUser" />
        </Button>
      )}
      {status === 'incomplete' && (
        <Link to={`/admin/users/${id}/farm-roles`} className="button small light">
          <i className="fa fa-exclamation-circle mr-5" />
          <FormattedMessage id="general.button.completeSetup" />
        </Link>
      )}
      {['active', 'pending'].includes(status) && (
        <Link to={`/admin/users/${id}/farm-roles`} className="button small light">
          <i className="fa fa-gear mr-5" />
          <FormattedMessage id="general.manageUser" />
        </Link>
      )}
    </div>
  );

  render() {
    const { isLoading, resources, meta: { total, stats }, onPageChange, onPerPageChange, onSortChange,
      onSearchChange, onStatusChange, reqParams: { page, per_page, status, search, sort } } = this.props;
    const { isTablet } = this.context;

    const isEmptyTable = !resources.length && !status && !search;

    const columns = [
      { label: <FormattedMessage id="component.dataTable.headers.name" />, flex: '2 1 200px',
        renderer: this.renderUserColumn, sortKey: 'full_name' },
      { label: <FormattedMessage id="general.email" />, flex: '2 1 200px', renderer: this.renderEmailColumn,
        sortKey: 'email' },
      { label: <FormattedMessage id="general.userRoles" />, flex: '1 1 100px', renderer: this.renderUserRolesColumn,
        sortKey: 'roles_count' },
      { label: <FormattedMessage id="component.dataTable.headers.status" />, flex: '1 1 100px',
        renderer: this.renderStatusColumn },
      { label: '', flex: '0 0 135px', renderer: this.renderActionsColumn, fixed: true,
        className: cn({ 'hide-for-large': isTablet }) },
      { label: '', flex: '0 0 40px', renderer: () => <ArrowColumn />, fixed: true, hide: !isTablet,
        hasPinnedIcon: true },
    ];

    const paginationProps = {
      onPageChange,
      onPerPageChange,
      totalItems: total,
      currentPage: page,
      perPage: per_page,
    };

    return (
      <section className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.allUsers" />}>
            {!isEmptyTable &&
              <SearchBox initialValue={search} onChange={onSearchChange} />}
          </Panel.Heading>
          <Panel.Body noPadding>
            {!isEmptyTable && (
              <TableFilter
                filters={tableFilters}
                activeFilter={status}
                onFilterChange={onStatusChange}
                stats={stats}
                className="ph-10"
              >
                <Link
                  className="importTableBtn"
                  to="/csv-import/select-mode?from=users"
                >
                  <FormattedMessage id="general.import" />
                </Link>
                <Link to="/admin/users/create" className="button small primary wider">
                  <FormattedMessage id="general.button.createUser" />
                </Link>
              </TableFilter>
            )}

            <Preloader isActive={isLoading} />

            <DataTable
              data={resources}
              columns={columns}
              sort={sort}
              onSortChange={onSortChange}
              paginationProps={paginationProps}
              isExpandable={isTablet}
              renderExpandable={this.renderExpandable}
              scrollable
              tableKey="adminUsers"
            />

            <NothingBox
              itemsName="users_roles"
              display={!resources.length}
              isLoading={isLoading}
              search={search}
              filter={status}
            >
              <FormattedMessage id="component.nothingBox.anyUsersYet" tagName="h1" />
              <FormattedMessage id="component.nothingBox.createUserAccount" tagName="p" />
              <Link className="importBtnEmpty" to="/csv-import/select-mode?from=users">
                <FormattedMessage id="general.import" />
              </Link>
              <Link to="/admin/users/create" className="button primary mt-10">
                <FormattedMessage id="general.button.createUser" />
              </Link>
            </NothingBox>
          </Panel.Body>
        </Panel>
      </section>
    );
  }
}

Users.propTypes = {
  resources: T.array.isRequired,
  fetchData: T.func.isRequired,
  setDataItem: T.func.isRequired,
  onSearchChange: T.func.isRequired,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
  onSortChange: T.func.isRequired,
  onStatusChange: T.func.isRequired,
  openModal: T.func.isRequired,
  isLoading: T.bool.isRequired,
  reqParams: T.object.isRequired,
  meta: T.object,
};

Users.contextTypes = {
  isTablet: T.bool.isRequired,
};

const enhance = compose(
  connect(
    (state) => ({
      resources: state.dataTable.adminUsers.resources,
      reqParams: state.dataTable.adminUsers.params,
      meta: state.dataTable.adminUsers.meta,
      isLoading: state.dataTable.adminUsers.isLoading,
    }), {
      fetchData: (query) => fetchData('/admin/users', 'adminUsers', query),
      setDataItem: (resource) => setDataItem(resource, 'adminUsers'),
      openModal,
    }
  ),
  withDataTableController('fetchData', 'reqParams'),
);

export default enhance(Users);
