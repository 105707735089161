import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import TreatmentScheduleForm from './TreatmentForm';
import Collapse from 'components/Collapse/Collapse';
import PlusButton from 'components/PlusButton';
import TrashButton from 'components/TrashButton';
import { FieldArray } from 'redux-form';
// utils
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
// constants
import { treatmentProtocolFormSelector } from './helper';
// styles
import styles from './TreatmentForm.module.scss';

const cn = classnames.bind(styles);

const getCollapseTitle = (step) => {
  const { step_type } = step;
  const { step_unit, first_period, last_period } = step[step_type] || {};
  const values = { first_period, last_period };
  const isCurrentValueValid = step_unit && !!(step_type === 'between' ? first_period && last_period : first_period);
  return isCurrentValueValid ? (
    <FormattedMessage
      id={`general.treatPigs.${step_type}.${step_unit}`}
      values={{ b: (msg) => <b>{msg}</b>, ...values }}
    />
  ) :
    <FormattedMessage id="general.treatPigs" />;
};

const multiStepRenderer = (data) => (
  <Fragment>
    {data.fields.map((field, fieldIndex) => (
      data.fields.length === 1 ?
        <TreatmentScheduleForm key={fieldIndex} fieldName={field} /> : (
          <div key={fieldIndex} className={cn('treatment-step')}>
            <div className={cn('treatment-counter')}>{fieldIndex + 1}</div>
            <Collapse isOpenOnMount className={cn('collapse')} title={getCollapseTitle(data.values[fieldIndex])}>
              <div className={cn('collapse-content')}>
                <TreatmentScheduleForm fieldName={field} />
              </div>
            </Collapse>
            <div className={cn('action-btn-group')}>
              {data.fields.length > 2 && (
                <TrashButton className={cn('ml-15')} onClick={() => data.fields.remove(fieldIndex)} />
              )}
              {data.fields.length - 1 === fieldIndex && (
                <PlusButton onClick={() => data.fields.push({})} className="mt-2 ml-5" />
              )}
            </div>
          </div>
        )
    ))}
  </Fragment>
);

const TreatmentSteps = ({ fieldName, values }) => (
  <Fragment>
    <div className="semibold mb-10">
      <FormattedMessage id="general.treatmentSteps" />
    </div>
    <FieldArray name={fieldName} values={values} component={multiStepRenderer} />
  </Fragment>
);

TreatmentSteps.propTypes = {
  fieldName: T.string.isRequired,
  values: T.array.isRequired,
};

export default connect((state, { fieldName }) => ({
  values: treatmentProtocolFormSelector(state, fieldName),
}))(TreatmentSteps);
