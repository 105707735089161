import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// endpoints
import {
  getArrivalProtocols,
  deleteArrivalProtocol,
  updateArrivalProtocol,
} from 'endpoints/admin/farms';
// components
import { FormattedMessage } from 'react-intl';
import CommentBoxTransparent from 'components/CommentBoxTransparent';
import Resumable from 'resumablejs';
import { toastr } from 'react-redux-toastr';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import Button from 'components/Button';
// utils
import classnames from 'classnames/bind';
import { bytesToSize } from 'utils';
import { getAuthData } from 'utils/authData';
import findIndex from 'lodash.findindex';
// styles
import styles from './ArrivalProtocolUploader.module.scss';

const cn = classnames.bind(styles);

let resumable = null;
let rootElement = null;
let dropzoneElement = null;
let browseButton = null;

let dragInsCount = 0;

const types = ['doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'gif', 'png', 'pdf'];
const fileTypes = types.concat(types.map((ext) => ext.toUpperCase()));

const getAssetPreviewUrl = (asset) => {
  const { url } = asset;
  return url.thumb || url.small || url.medium || url.original || url.origin;
};

const ArrivalProtocolUploader = ({ farmId }) => {
  const [arrivalProtocols, setArrivalProtocols] = useState([]);
  const [filesInQueue, setFilesInQueue] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const fetchArrivalsProtocols = () => {
    getArrivalProtocols(farmId)
      .then(setArrivalProtocols);
  };

  const onDragEnter = () => {
    dragInsCount++;
    if (dragInsCount === 1) setIsDragOver(true);
  };

  const onDragLeave = () => {
    dragInsCount--;
    if (dragInsCount === 0) setIsDragOver(false);
  };

  const removeFileFromQueue = (file) => {
    setFilesInQueue((prevFilesInQueue) => {
      const fileIndex = findIndex(prevFilesInQueue, { uniqueIdentifier: file.uniqueIdentifier });
      return [
        ...prevFilesInQueue.slice(0, fileIndex),
        ...prevFilesInQueue.slice(fileIndex + 1),
      ];
    });
  };

  const onDrop = (resumableFile) => {
    const R = resumableFile.resumableObj;
    setFilesInQueue((prevFilesInQueue) => [...prevFilesInQueue, resumableFile]);
    R.upload();
    setIsDragOver(false);
    dragInsCount = 0;
  };

  const onFileSuccess = (resumableFile) => {
    setTimeout(() => {
      removeFileFromQueue(resumableFile);
      fetchArrivalsProtocols();
    }, 500);
  };

  const onFileError = (resumableFile, error) => {
    toastResponseErrors(error);
    setIsDragOver(false);
  };

  const onFileProgress = (file) => {
    setFilesInQueue((prevFilesInQueue) => {
      const index = findIndex(prevFilesInQueue, { uniqueIdentifier: file.uniqueIdentifier });
      const updatedFile = {
        ...prevFilesInQueue[index],
        uploadProgress: file.progress(),
        cancelable: file.progress() < 0.7
      };
      return [
        ...prevFilesInQueue.slice(0, index),
        updatedFile,
        ...prevFilesInQueue.slice(index + 1),
      ];
    });
    if (file.progress() === 1) {
      file.resumableObj.removeFile(file);
    }
  };

  const initializeFileUploader = () => {
    const uploadPath = `/api/admin/farms/${farmId}/arrival_protocol_chunk`;
    resumable = new Resumable({
      target: uploadPath,
      testTarget: uploadPath,
      headers: { 'Accept': '*/*', ...getAuthData() },
      simultaneousUploads: 3,
      testChunks: true,
      chunkRetryInterval: 500,
      maxFileSize: 314572800,
      fileType: fileTypes,
      fileTypeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className={cn('fa fa-ban')} />,
          className: 'file-type-error',
          component: (
            <FormattedMessage
              id="component.toastr.availFileTypes.text"
              values={{ filename: file.name, types: types.join(', ') }}
            >
              {(text) => <div className={cn('rrt-text')}>{text}</div>}
            </FormattedMessage>
          ),
        });
      },
      maxFileSizeErrorCallback: (file) => {
        toastr.error('', '', {
          icon: <i className={cn('fa fa-ban')} />,
          component: (
            <FormattedMessage id="general.fileSizeExceeded" values={{ size: '300mb', filename: file.name }}>
              {(text) => <div className={cn('rrt-text')}>{text}</div>}
            </FormattedMessage>
          ),
        });
      },
    });

    if (resumable.support) {
      resumable.assignDrop(dropzoneElement);
      resumable.on('fileAdded', onDrop);
      resumable.on('fileProgress', onFileProgress);
      resumable.on('fileSuccess', onFileSuccess);
      resumable.on('fileError', onFileError);
    } else {
      console.warn('ResumableJS not supported!');
    }
  };

  useEffect(() => {
    fetchArrivalsProtocols();
  }, [farmId]);

  useEffect(() => {
    rootElement = document.getElementById('root');
    dropzoneElement = document.getElementById('protocols-dropzone');
    browseButton = document.getElementById('upload-button');
    if (rootElement && dropzoneElement) {
      initializeFileUploader();
      rootElement.addEventListener('dragenter', onDragEnter);
      rootElement.addEventListener('dragleave', onDragLeave);
    }

    return () => {
      if (rootElement) {
        rootElement.removeEventListener('dragenter', onDragEnter);
        rootElement.removeEventListener('dragleave', onDragLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (!dropzoneElement) {
      dropzoneElement = document.getElementById('protocols-dropzone');
      browseButton = document.getElementById('upload-button');
      initializeFileUploader();
    }
  }, [dropzoneElement]);

  useEffect(() => {
    if (!isDragOver && resumable && resumable.support) {
      browseButton = document.getElementById('upload-button');
      resumable.assignBrowse(browseButton);
    }
  }, [isDragOver]);

  const cancelUpload = (file) => {
    file.cancel();
    removeFileFromQueue(file);
  };

  const deleteAsset = (assetId) => {
    deleteArrivalProtocol(farmId, assetId)
      .then(() => {
        setArrivalProtocols((prevAssets) => {
          const index = findIndex(prevAssets, { id: assetId });
          return [
            ...prevAssets.slice(0, index),
            ...prevAssets.slice(index + 1),
          ];
        });
      });
  };

  const onNoteSave = (assetId) => (note) => {
    updateArrivalProtocol(farmId, assetId, { description: note })
      .then((protocol) => setArrivalProtocols((protocols) => {
        const index = findIndex(protocols, { id: assetId });
        return [
          ...protocols.slice(0, index),
          protocol,
          ...protocols.slice(index + 1),
        ];
      }));
  };

  return (
    <>
      <div className={cn('assets-list')}>

        {/* FILES IN QUEUE */}
        { !!filesInQueue.length &&
                filesInQueue.map((file, index) => {
                  const progress = Math.ceil((file.uploadProgress || 0) * 99);
                  const progressBarStyle = { width: `${progress}%`, };

                  return (
                    <div className={cn('asset-wrapper')} key={`${index}-${file.uniqueIdentifier}`}>
                      <div className={cn('assets-item')}>
                        <div className={cn('asset-info')} title={file.fileName}>
                          <div className={cn('uploading-asset-info')}>
                            <div className={cn('asset-name')}>{file.fileName}</div>
                            <div className={cn('asset-size')}>{bytesToSize(file.size)}</div>
                          </div>
                          <div className={cn('upload-progress')}>
                            <div className={cn('progress-value')} style={progressBarStyle} />
                          </div>
                          <FormattedMessage id="general.uploadingProgress" values={{ progress }}>
                            {(text) => (
                              <div className={cn('asset-description')}>{text}</div>
                            )}
                          </FormattedMessage>
                        </div>
                        {file.cancelable && (
                          <div className={cn('asset-actions')}>
                            <i className={cn('fa fa-times')} onClick={() => cancelUpload(file)} />
                          </div>
                        )}
                      </div>
                    </div>

                  );
                })
              }

        {/* UPLOADED ASSETS */}
        {!!arrivalProtocols.length &&
            arrivalProtocols.map((asset, index) => {
              const isImage = asset.media_type.includes('image/');
              return (
                <div className={cn('asset-wrapper')} key={`${index}-${asset.id}`}>
                  <div className={cn('assets-item')}>
                    <div className={cn('asset-preview')}>
                      <div
                        className={cn('image')}
                        style={{ backgroundImage: `url(${getAssetPreviewUrl(asset)})`, }}
                      >
                        {!isImage && <i className="fa fa-2x fa-ep-doc" />}
                      </div>
                    </div>
                    <div className={cn('asset-info')} title={asset.filename}>
                      <div className={cn('asset-name')}>
                        <a>
                          {asset.filename}
                        </a>
                      </div>
                      <div className={cn('asset-description')}>
                        <FormattedMessage id={`general.${isImage ? 'image' : 'file'}`} />
                        {' | '}
                        {bytesToSize(asset.media_size)}
                      </div>
                    </div>
                    <div className={cn('asset-actions')}>
                      <i className={cn('fa fa-trash-o')} onClick={() => deleteAsset(asset.id)} />
                    </div>
                  </div>
                  <CommentBoxTransparent
                    onSave={onNoteSave(asset.id)}
                    initialValue={asset.description}
                  />
                </div>
              );
            })
        }
      </div>
      <div id="protocols-dropzone" className={cn('dropzone', { isDragOver })}>
        <i className={cn('fa fa-5x fa-ep-doc', 'upload-icon')} />
        {!isDragOver && (
          <>
            <div className={cn('mb-15')}>
              <FormattedMessage id="component.arrivalProtocolUploader.dragOr" />
            </div>
            <Button hollow id="upload-button">
              <FormattedMessage id="general.uploadFile" />
            </Button>
          </>
        )}

        {isDragOver && <FormattedMessage id="component.resourcesUploader.dropFilesHere" />}
      </div>
    </>
  );
};

ArrivalProtocolUploader.propTypes = {
  farmId: T.string.isRequired,
};

export default ArrivalProtocolUploader;
