import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatTreatmentProtocolPayload } from './helper';
// endpoints
import { getTreatmentProtocolsFarm, createFarmTreatmentProtocol } from 'endpoints/treatmentProtocols';
// components
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import TreatmentProtocolForm from './components/TreatmentProtocolForm';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
// styles
import styles from './TreatmentProtocols.module.scss';

const initialTreatment = { product: '', steps: [{}], };
const defaultInitialValues = { treatments: [initialTreatment] };

const CreateFarmTreatmentProtocol = ({ setBreadcrumbs, setPageOptions, router: { params: { farmId }, push } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [farm, setFarm] = useState(undefined);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      setFarm(await getTreatmentProtocolsFarm(farmId));
      setIsLoading(false);
    } catch (error) {
      toastResponseErrors(error);
      push(`/treatment-protocol-farms/${farmId}/treatment-protocols`);
    }
  };

  useEffect(() => {
    fetchData();
    setPageOptions({ backLink: `/treatment-protocol-farms/${farmId}/treatment-protocols` });
  }, []);

  useEffect(() => {
    if (farm) {
      setBreadcrumbs([
        {
          label: <FormattedMessage id="general.treatmentProtocolFarms" />,
          path: '/treatment-protocol-farms',
        },
        {
          label: farm.name,
          path: `/treatment-protocol-farms/${farmId}/treatment-protocols`,
        },
        {
          label: <FormattedMessage id="general.createTreatmentProtocol" />,
          path: `/treatment-protocol-farms/${farmId}/treatment-protocols/create`,
          useLabelAsMobileTitle: true,
        },
      ]);
    }
  }, [farm]);

  const onSubmit = async (newProtocolData) => {
    try {
      setIsLoading(true);
      const treatmentProtocolPayload = formatTreatmentProtocolPayload(newProtocolData);
      await createFarmTreatmentProtocol(farmId, treatmentProtocolPayload);
      push(`/treatment-protocol-farms/${farmId}/treatment-protocols`);
    } catch (error) {
      toastResponseErrors(error);
      setIsLoading(false);
    }
  };

  if (!farm) return <Preloader isActive />;

  return (
    <div>
      <Preloader isActive={isLoading} />
      <FarmProfileHeader
        className="show-for-large"
        farm={farm}
        actualFarmId={farm.id}
        farmInfo={(
          <div className={styles['farm-details']}>
            <span>ID&nbsp;&bull;&nbsp;{farm.federal_premise_id || 'N/A'}</span>
          </div>
        )}
      />
      <TreatmentProtocolForm
        onSubmit={onSubmit}
        initialValues={defaultInitialValues}
        title={<FormattedMessage id="general.createTreatmentProtocol" />}
        submitLabel={<FormattedMessage id="general.createTreatmentProtocol" />}
      />
    </div>
  );
};

CreateFarmTreatmentProtocol.propTypes = {
  setBreadcrumbs: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  router: T.object.isRequired,
};

export default connect(null, { setPageOptions, setBreadcrumbs })(CreateFarmTreatmentProtocol);
