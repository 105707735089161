import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
// components
import AsyncSelect from 'components/AsyncSelect/AsyncSelect';
import Panel from 'components/Panel';
import { FormattedMessage } from 'react-intl';
import GeneticsPanel from './components/GeneticsPanel';
import TruckingTermsPanel from './components/TruckingTermsPanel';
import SyncNote from 'components/SyncNote/SyncNote';
import Input from 'components/Input';
// reducers
import { updateFarm } from 'reducers/admin/farms';
// utils
import classnames from 'classnames/bind';
import { querifyArrayParams, getQueryString } from 'utils';
import isEmpty from 'lodash.isempty';
// requests
import { getFarmTerms, updateFarmTerm } from 'endpoints/admin/farms';
// styles
import styles from './FarmScheduling.module.scss';

const cn = classnames.bind(styles);

const noteTextareaProps = { rows: 4 };
const noteInputProps = { type: 'number', min: 1, step: 1 };

const farmRoles = ['owner', 'caretaker', 'manager'];
const farmTypesForTerms = ['wf', 'f', 'm', 'ff'];

function getUserSearchOptionsPath(farmId) {
  const rolesParams = querifyArrayParams('having_roles', farmRoles);
  const otherParams = getQueryString({ without_pagination: true, with_current_user: true, farm_id: farmId });
  return `/users/search?${rolesParams}&${otherParams}`;
}

const FarmScheduling = ({ params: { id: farmId }, farm, updateFarm }) => {
  const {
    farm_type,
    farm_genetics,
    logistics_trucking_comment,
    caretaker_availability,
    minutes_for_load,
    default_trucking_company,
    logistics_contact,
  } = farm;
  const isTermsVisible = farmTypesForTerms.includes(farm_type);
  const handleUpdateFarm = (resource) => updateFarm(resource, farmId);

  return (
    <Fragment>
      {isTermsVisible && (
        <TruckingTermsPanel
          getFarmTerms={(params) => getFarmTerms(farmId, params)}
          truckingComment={logistics_trucking_comment || ''}
          updateFarm={handleUpdateFarm}
          updateFarmTerm={(termId, resource) => updateFarmTerm(farmId, termId, resource)}
        />
      )}
      {farm_type === 'su' && (
        <GeneticsPanel genetics={farm_genetics} updateFarm={handleUpdateFarm} />
      )}

      <Panel className="medium-12 xlarge-8 mb-15">
        <Panel.Heading
          title={<FormattedMessage id="general.truckingCompanyDefault" />}
        />
        <Panel.Body className="ph-20">
          <AsyncSelect
            className="medium-6 xlarge-4"
            selected={!isEmpty(default_trucking_company)
              ? { value: default_trucking_company.id, label: default_trucking_company.name }
              : null
            }
            onChange={(option) => handleUpdateFarm({ default_trucking_company_id: option?.value || null })}
            optionsPath="/admin/trucking_companies"
            optionsKeys={{ value: 'id', label: 'name' }}
            placeholder={<FormattedMessage id="general.selectTrucking" />}
            clearable
          />
        </Panel.Body>
      </Panel>

      {/* responsible for scheduling */}
      <Panel className="medium-12 xlarge-8 mb-15">
        <Panel.Heading
          title={<FormattedMessage id="general.responsibleForScheduling" />}
        />
        <Panel.Body className="ph-20">
          <AsyncSelect
            className="medium-6 xlarge-4"
            optionsPath={getUserSearchOptionsPath(farmId)}
            placeholder={<FormattedMessage id="general.selectUser" />}
            clearable
            selected={!isEmpty(logistics_contact)
              ? { value: logistics_contact.id, label: logistics_contact.full_name }
              : null
            }
            optionsKeys={{ value: 'id', label: 'full_name' }}
            onChange={(option) => handleUpdateFarm({ logistics_contact_id: option?.value || null })}
          />
        </Panel.Body>
      </Panel>

      {/* caregiver availability */}
      <Panel className="medium-12 xlarge-8 mb-15">
        <Panel.Heading
          title={<FormattedMessage id="general.caregiverAvailability" />}
        />
        <Panel.Body className="ph-20">
          <SyncNote
            componentProps={noteTextareaProps}
            addLabel={
              <FormattedMessage id="general.notes.addCaregiverAvailability" />
            }
            comment={caretaker_availability || ''}
            onSave={(restriction) => handleUpdateFarm({ caretaker_availability: restriction })}
          />
        </Panel.Body>
      </Panel>

      {/* time to load */}
      <Panel className="medium-12 xlarge-8">
        <Panel.Heading
          title={<FormattedMessage id="general.timeToLoad" />}
        />
        <Panel.Body className="ph-20">
          <SyncNote
            className={cn('load-time-note')}
            addLabel={
              <FormattedMessage id="general.notes.addTimeToLoad" />
            }
            component={Input}
            componentProps={noteInputProps}
            comment={minutes_for_load ? minutes_for_load.toString() : ''}
            onSave={(minutes_for_load) => handleUpdateFarm({ minutes_for_load })}
          />
        </Panel.Body>
      </Panel>

    </Fragment>
  );
};

FarmScheduling.propTypes = {
  params: T.object.isRequired,
  farm: T.object,
  updateFarm: T.func.isRequired,
};

export default connect(
  (state) => ({
    farm: state.farms.resource.data
  }),
  { updateFarm }
)(FarmScheduling);
