import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import TreatmentStepCard from 'components/TreatmentStepCard';
import PaginationWrapper from 'components/PaginationWrapper';
import NothingBox from 'components/NothingBox';
import DateLine from 'components/DateLine/DateLine';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// utils
import moment from 'moment';
// endpoints
import { getPigGroupTreatmentProtocolProductSteps } from 'endpoints/treatmentProtocols';
// constants
import { tableNames } from 'utils/constants';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

const BarnSheetsTreatmentProtocols = ({ setTableParams, tableParams, pigGroup, params: { id } }) => {
  const {
    data: { resources, isLoading, meta: { total } },
    fetchData,
    onPageChange,
    onPerPageChange,
  } = useDataTable((params) => getPigGroupTreatmentProtocolProductSteps(id, params), {
    setTableParams: (params) => setTableParams(tableNames.pigGroupTreatments, params),
    tableParams,
  });

  useEffect(() => {
    fetchData(tableParams);
  }, []);

  return (
    <div className="row centered pv-20">
      <div className="small-12 column">
        <Panel>
          <Panel.Heading title={<FormattedMessage id="general.treatmentProtocols" />} />
          <Panel.Body className="ph-20">
            <Preloader isActive={isLoading} />
            {resources.map((treatmentStepData, idx) => {
              const startDate = formatDate(treatmentStepData.start_date);
              const previousStartDate = formatDate(resources[idx - 1]?.start_date);

              return (
                <Fragment key={treatmentStepData.id}>
                  {(idx === 0 || startDate !== previousStartDate) && <DateLine date={startDate} />}
                  <TreatmentStepCard
                    treatmentStepData={treatmentStepData}
                    pigGroup={pigGroup}
                    className="mv-10"
                  />
                </Fragment>
              );
            })}

            <NothingBox display={!resources.length}>
              <h2 className="lighter semibold">
                <FormattedMessage id="component.nothingBox.noTreatmentProtocols" />
              </h2>
            </NothingBox>

            {!!resources.length && (
              <PaginationWrapper
                onlyBottom
                totalItems={total}
                currentPage={tableParams.page}
                onPageChange={onPageChange}
                perPage={tableParams.per_page}
                onPerPageChange={onPerPageChange}
              />
            )}
          </Panel.Body>
        </Panel>
      </div>
    </div>
  );
};

BarnSheetsTreatmentProtocols.propTypes = {
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  pigGroup: T.object.isRequired,
  params: T.object.isRequired,
};


export default connect((state) => ({
  tableParams: tableParamsSelector(state, tableNames.pigGroupTreatments),
}), { setTableParams })(BarnSheetsTreatmentProtocols);
