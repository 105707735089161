import React, { useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Translation from 'components/Translation';
import Collapse from 'components/Collapse/Collapse';
import Line from 'components/Line';
import CollapseTitle from './CollapseTitle';
// utils
import { calculateMgPerHead, renderMentionsText, formatWater } from 'utils';
import classnames from 'classnames/bind';
// styles
import styles from './SectionCollapse.module.scss';

const cn = classnames.bind(styles);

const WaterUsageCollapse = ({
  className,
  comment,
  dailyCheckup,
  isEdited,
  measurement_system,
  showCheckup,
  titleClassName,
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const hasComment = Boolean(comment);

  if (!hasComment && (dailyCheckup.water_usage_value === null)) return null;

  const measure = measurement_system === 'imperial' ? 'gallonage' : 'liters';
  const { edge_water_usage, dicam_water_usage, barn_tools_water_usage, water_usage_value, initial_pigs } = dailyCheckup;
  const waterUsageLines = [
    {
      valueKey: 'gallonageConsumed',
      label: <FormattedMessage id={`general.${measure}Consumed`} />,
      value: formatWater(water_usage_value, measurement_system),
    },
    {
      valueKey: 'gallonagePerHead',
      label: <FormattedMessage id={`general.${measure}PerHead`} />,
      value: calculateMgPerHead(formatWater(water_usage_value, measurement_system), initial_pigs)
    },
  ];

  return ((comment || showCheckup) && (
    <Collapse
      className={cn(className, 'section-collapse', { 'highlighted': comment && !isOpened })}
      titleClassName={titleClassName}
      title={(
        <CollapseTitle
          hasEditedBadge={isEdited}
          hasNotesBadge={hasComment}
          hasEdgeBadge={edge_water_usage}
          hasDicamBadge={dicam_water_usage}
          hasBarnToolBadge={barn_tools_water_usage}
          hasTriggerIcon={hasComment}
        >
          <FormattedMessage tagName="b" id="general.waterUsage" />
        </CollapseTitle>
      )}
      onToggle={setIsOpened}
    >
      {waterUsageLines.map(({ valueKey, ...other }, index) => (
        <Line key={valueKey} boldedValue hasTopBorder={!index} {...other} />
      ))}
      {comment && (
        <>
          <Line almostWhite bolded>
            <FormattedMessage id="general.notes" />
          </Line>
          <Line lightYellow>
            <Translation text={comment}>{renderMentionsText(comment)}</Translation>
          </Line>
        </>
      )}
    </Collapse>
  ));
};

WaterUsageCollapse.propTypes = {
  className: T.string,
  titleClassName: T.string,
  dailyCheckup: T.object.isRequired,
  comment: T.string,
  isEdited: T.bool,
  showCheckup: T.bool,
  measurement_system: T.string.isRequired,
};

export default WaterUsageCollapse;
