/* eslint-disable react/prop-types */
import React from 'react';
// components
import { FormattedMessage } from 'react-intl';
import { CustomColumn } from 'components/DataTable/Columns/index';
// utils
import cn from 'classnames';
import moment from 'moment';

const renderFarmNameColumn = ({ farm_name, error }) => (
  <CustomColumn
    label={<FormattedMessage id="component.dataTable.headers.farmName" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {farm_name || '-'}
  </CustomColumn>
);

const renderFarmAddressColumn = ({ address, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.address" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {address || '-'}
  </CustomColumn>
);

const renderPremiseIdColumn = ({ premise, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.premiseId" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {premise || '-'}
  </CustomColumn>
);

const renderFarmTypeColumn = ({ farm_type, error }) => (
  <CustomColumn
    label={<FormattedMessage id="general.farmType" />}
    className={cn({ 'highlighted': error === 'farm' })}
  >
    {farm_type || '-'}
  </CustomColumn>
);

const renderStartDateColumn = (status) => ({ start_date, created_on, error }) => {
  const dateValue = status === 'create' ? created_on : start_date;
  return (
    <CustomColumn
      label={<FormattedMessage id="general.creationDate" />}
      className={cn({ 'highlighted': error === 'start_date' })}
    >
      {dateValue ? moment(dateValue).format('MM/DD/YYYY') : '-'}
    </CustomColumn>
  );
};

const farmColumns = (status) => ([
  { label: <FormattedMessage id="component.dataTable.headers.farmName" />, flex: '1 1 140px',
    renderer: renderFarmNameColumn, sortKey: 'farm_name' },
  { label: <FormattedMessage id="general.address" />, flex: '1 1 140px',
    renderer: renderFarmAddressColumn, sortKey: 'address' },
  { label: <FormattedMessage id="general.premiseId" />, flex: '1 1 140px',
    renderer: renderPremiseIdColumn, sortKey: 'premise' },
  { label: <FormattedMessage id="general.farmType" />, flex: '1 1 140px',
    renderer: renderFarmTypeColumn, sortKey: 'farm_type' },
  { label: <FormattedMessage id="general.creationDate" />, flex: '1 1 90px',
    renderer: renderStartDateColumn(status) }
]);

export default farmColumns;
