import React, { useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { reportSymptoms } from 'reducers/dailyCheckup/checkupEdit';
// components
import CheckupSegment from './CheckupSegment/CheckupSegment';
import SegmentComment from './CheckupSegmentComment/SegmentComment';
import AudioPreview from 'components/AudioPreview';
// utils
import { isAudioAsset } from 'utils';
import classnames from 'classnames/bind';
import { getCommentByRole } from 'helpers.js';
import { isSomeSectionReporting } from 'utils/checkupHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './ReportSymptomsSegment.module.scss';
// constants
import { comment_roles } from 'constants.js';

const { SYMPTOMS_ROLE } = comment_roles;
const cn = classnames.bind(styles);

const ReportSymptomsSegment = ({
  checkup,
  paramsCheckupId,
  isDisabled,
  isSubmitting,
  reportSymptoms,
  deleteAsset,
  validateCheckup,
}) => {
  const [isAllGoodReported, setIsAllGoodReported] = useState(false);
  const symptoms = (checkup.dc_symptoms || []).filter(({ _destroy }) => !_destroy);
  const symptom_count = symptoms.length;
  const { comments } = checkup;

  const isReported = checkup.symptom_count !== null;
  const symptomAudios = checkup.dc_assets.filter((asset) => isAudioAsset(asset) && asset.category === 'symptoms');

  const handleReportNoSymptoms = () => {
    if (!validateCheckup(checkup)) return;
    setIsAllGoodReported(true);
    reportSymptoms(paramsCheckupId, { all_good: true }).catch(toastResponseErrors);
  };

  return (
    <CheckupSegment
      isSegmentConditionChanged={isAllGoodReported}
      isDisabled={isDisabled}
      isReported={isReported}
      isSubmitting={isSubmitting}
      onEmptyDataSubmit={handleReportNoSymptoms}
      itemsCount={symptom_count}
      titleKey="component.checkupSegment.doYouHaveSymptoms"
      titleKeyWhenReported="general.symptoms"
      reportPageUrl={`/daily-checkup/${paramsCheckupId}/report-symptoms`}
      noData={isReported && !symptom_count}
    >
      <div className={cn('reported-symptoms')}>
        {symptoms.map((symptom, index) => (
          <div className={cn('symptom-row', { 'highlighted': symptom.critical })} key={`symptom-${index}`}>
            <div className={cn('name')}>{symptom.name}</div>
            <div className={cn('value')}>{symptom.frequency}%</div>
          </div>
        ))}
        <SegmentComment comment={getCommentByRole(comments, SYMPTOMS_ROLE)} />
        {!!symptomAudios.length && (
          <div className={cn('symptoms-audios')}>
            {symptomAudios.map((asset) => (
              <div className={cn('asset-wrapper')} key={asset.id}>
                <AudioPreview
                  className={cn('assets-item')}
                  asset={asset}
                  controlSize="40"
                  onDelete={() => deleteAsset(asset.id)}
                />
                <SegmentComment comment={{ comment: asset.description }} />
              </div>
            ))}
          </div>
        )}
      </div>
    </CheckupSegment>
  );
};

ReportSymptomsSegment.propTypes = {
  checkup: T.object.isRequired,
  paramsCheckupId: T.oneOfType([T.string, T.number]).isRequired,
  isSubmitting: T.bool.isRequired,
  isDisabled: T.bool.isRequired,
  validateCheckup: T.func.isRequired,
  reportSymptoms: T.func.isRequired,
  deleteAsset: T.func.isRequired,
};

export default connect(
  ({ dailyCheckup }) => ({
    isSubmitting: dailyCheckup.checkupEdit.isReportingSymptoms,
    isDisabled: isSomeSectionReporting(dailyCheckup.checkupEdit) && !dailyCheckup.checkupEdit.isReportingSymptoms,
  }), {
    reportSymptoms,
  }
)(ReportSymptomsSegment);
