import React, { useState, useEffect, useRef } from 'react';
import T from 'prop-types';
// components
import { Link } from 'react-router';
import useOnclickOutside from 'react-cool-onclickoutside';
import DownloadLink from 'components/DownloadLink';
// hooks
import { useDropdown, useDropdownActions } from 'hooks/useDropdownContext';
import useDidMountEffect from 'hooks/useDidMountEffect';
// utils
import { isTablet, isMobile } from 'react-device-detect';
import classnames from 'classnames/bind';
// assets
import styles from './ContextDropdown.scss';

const cn = classnames.bind(styles);

function getLink(item, closeDropdown, isMobile) {
  if (item.url) {
    return !item.downloadBtn
      ? (
        <Link to={item.url} className={item.listItemClass}>
          {item.icon && (isMobile || item.desktopIcon) &&
            <i className={cn(`fa fa-${item.icon}`, 'item-icon')} />}
          {item.label}
        </Link>
      )
      : <DownloadLink className={item.listItemClass} endpoint={item.url}>{item.label}</DownloadLink>;
  }
  return (
    <a
      className={cn(item.listItemClass, { 'description-item': item.isDescription })}
      onClick={(data) => {
        if (item.onClick) {
          item.onClick(data);
        }
        closeDropdown();
      }}
    >
      {item.icon && (isMobile || item.desktopIcon) &&
        <i className={cn(`fa fa-${item.icon}`, 'item-icon')} />}
      {item.label}
    </a>
  );
}

const ContextDropdown = ({ layoutRef, pathname, setIsOpened }) => {
  const { options, isOpened, targetCoords, idKey, isScrollIgnored } = useDropdown();
  const { closeDropdown } = useDropdownActions();
  const [scrollTop, setScrollTop] = useState(0);
  const [{ dropdownHeight, dropdownWidth }, setDropdownCoords] = useState({ dropdownHeight: 2, dropdownWidth: 2 });
  const dropdownRef = useRef(null);
  const isMobileOnly = isMobile && !isTablet;

  const listRef = useOnclickOutside((e) => {
    if (isOpened && (idKey !== e.target.dataset.name && idKey !== e.target.parentNode?.dataset.name)) closeDropdown();
  });

  useEffect(() => {
    const handleScrollEvent = () => setScrollTop(layoutRef.scrollTop);
    if (isScrollIgnored) {
      layoutRef.addEventListener('scroll', handleScrollEvent);
    }
    return () => {
      if (isScrollIgnored) {
        layoutRef.removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, [isScrollIgnored]);

  useEffect(() => {
    const newScrollTop = layoutRef.scrollTop;
    if (newScrollTop !== scrollTop) setScrollTop(newScrollTop);
    setIsOpened(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (options.length) {
      const { height = 2, width = 2 } = dropdownRef.current?.getBoundingClientRect();
      if (height !== dropdownHeight || width !== dropdownWidth) {
        setDropdownCoords({ dropdownHeight: height, dropdownWidth: width });
      }
    }
  }, [options.length]);

  useDidMountEffect(() => {
    if (isOpened) closeDropdown();
  }, [pathname]);

  const getStyles = () => {
    if (!isOpened) return {};

    const { right, height, y } = layoutRef.getBoundingClientRect();
    const isOpenAbove = ((targetCoords.bottom - y) + dropdownHeight) > height;

    // targetCoords.top - y: bodybag top offset - mainHeader height (y)
    // width + x: bodybag width + sidebar width (x)

    if (isMobileOnly) {
      const { marginRight } = window.getComputedStyle(dropdownRef.current || {});
      const triangleOffset = (right - targetCoords.right - parseInt(marginRight, 10)) + 'px';
      dropdownRef.current.style.setProperty('--triangleOffset', triangleOffset);

      return {
        isOpenAbove,
        top: ((targetCoords[`${isOpenAbove ? 'top' : 'bottom'}`] - y) + scrollTop) + 'px',
        transform: isOpenAbove ? 'translateY(-100%)' : null,
      };
    }

    const rightOffset = right - targetCoords.right;

    return {
      isOpenAbove,
      top: ((targetCoords[`${isOpenAbove ? 'top' : 'bottom'}`] - y) + scrollTop) + 'px',
      right: rightOffset < 0 ? (rightOffset + dropdownWidth) : rightOffset + 'px',
      transform: isOpenAbove ? 'translateY(-100%)' : null,
    };
  };

  const { isOpenAbove, ...styles } = getStyles();

  return (
    <div
      ref={dropdownRef}
      style={styles}
      className={cn('context-dropdown', {
        'stack-order': isScrollIgnored && isOpened,
        'is-opened': isOpened,
        'is-opened-above': isOpenAbove,
        'is-mobile': isMobileOnly,
      })}
    >
      <ul className={cn('list-ul')} ref={listRef}>
        {options.map((item, index) => !item.hide && (
          <li key={index} className={cn('list-item-li')}>
            {getLink(item, closeDropdown, isMobileOnly)}
          </li>
        ))}
      </ul>
    </div>
  );
};

ContextDropdown.propTypes = {
  layoutRef: T.object.isRequired,
  pathname: T.string.isRequired,
  setIsOpened: T.func.isRequired,
};

export default ContextDropdown;
