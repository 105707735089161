import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// components
import GroupField from './GroupField';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker/SingleDatePicker';
// utils
import moment from 'moment';
import classnames from 'classnames/bind';
// styles
import styles from './Section.module.scss';

const cn = classnames.bind(styles);

const CreationDateSection = ({
  className,
  data,
  onClose,
  onSave,
  ...others
}) => {
  const [stateDate, setStateDate] = useState(data || moment());
  const isSameDay = moment(data).isSame(moment(stateDate), 'day');

  useEffect(() => {
    if (!isSameDay && data) setStateDate(data);
  }, [data]);

  const renderFieldLabel = () => {
    const isToday = moment(data).isSame(moment(), 'day');
    const date = isToday
      ? <FormattedMessage id="general.time.todayAt" values={{ date: moment(data).format('MM/DD/YY') }} />
      : moment(data).format('MM/DD/YY');
    return (
      <FormattedMessage id="component.groupCreate.creationDateLabel" values={{ date: <strong>{date}</strong> }} />
    );
  };

  return (
    <GroupField
      isRequired
      className={className}
      label={renderFieldLabel()}
      title={<FormattedMessage id="component.groupCreate.creationDate" />}
      btnLabel={<FormattedMessage id="component.groupCreate.setDate" />}
      hint={<FormattedMessage id="component.groupCreate.creationDateHint" />}
      isSaved={Boolean(data)}
      {...others}
    >
      <div className={cn('field-body')}>
        <div className={cn('field-body-title')}>
          <FormattedMessage id="component.groupCreate.groupCreationDate" />
        </div>
        <div className="pv-10">
          <SingleDatePicker
            date={stateDate || data}
            onDateChange={setStateDate}
            inputLabel="MM/DD/YYYY"
            isOutsideRange={(day) => (day.isAfter(moment(), 'day'))}
            enableOutsideDays
            placeholder="Today"
          />
        </div>
        <div className="pv-10">
          <Button
            primary
            disabled={!stateDate || isSameDay}
            onClick={() => onSave({ created_on: stateDate.format('YYYY-MM-DD') })}
          >
            <FormattedMessage id="general.button.save" />
          </Button>
          <Button className="btn-link ml-5" onClick={onClose}>
            <FormattedMessage id="general.button.cancel" />
          </Button>
        </div>
      </div>
    </GroupField>
  );
};

CreationDateSection.propTypes = {
  className: T.string,
  data: T.object,
  onSave: T.func.isRequired,
  onClose: T.func.isRequired,
};

export default CreationDateSection;
