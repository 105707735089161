import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import ActivitySwitcher from 'components/Activities/ActivitySwitcher';
import Preloader from 'components/Preloader';
import Waypoint from 'react-waypoint';
// utils
import moment from 'moment';
// styles
import './FarmFeedActivities.scss';
// translate
import { FormattedMessage } from 'react-intl';

const TimeLineSeparator = ({ activities, index }) => {
  const currentDate = activities[index].updated_at;
  const prevDate = index && activities[index - 1].updated_at;
  const showTimeLine = !moment(currentDate).isSame(prevDate, 'day');
  const isToday = moment(currentDate).isSame(moment(), 'day');

  return showTimeLine && !isToday
    ? (
      <div className="TimeLineSeparator">
        {(moment(currentDate).isSame(moment().subtract(1, 'days'), 'day'))
          ? <FormattedMessage id="container.farmfeed.timeLine.yesterday" />
          : moment(currentDate).format('MMM DD, YYYY')}
      </div>
    )
    : null;
};

TimeLineSeparator.propTypes = {
  activities: T.array,
  index: T.number,
};

const FarmFeedActivities = ({
  activities,
  currentUser,
  loadMoreActivities,
  markAsSeen,
  isLoading,
  meta,
}) => {
  const total = meta?.total || 0;
  const hasMoreItems = activities.length < total;

  return (
    <div>
      {!!activities.length &&
        activities.map((activity, index) => (
          <Fragment key={activity.id + '-' + index}>
            <TimeLineSeparator activities={activities} index={index} />
            <ActivitySwitcher
              activity={activity}
              currentUser={currentUser}
              markAsSeen={() => markAsSeen(activity.id)}
            />
          </Fragment>
        ))}
      {!isLoading && hasMoreItems &&
        <Waypoint onEnter={loadMoreActivities} />}
      {!!activities.length && hasMoreItems &&
        <Preloader isActive={isLoading} className="load-more" />}
    </div>
  );
};

FarmFeedActivities.propTypes = {
  activities: T.array.isRequired,
  loadMoreActivities: T.func.isRequired,
  markAsSeen: T.func.isRequired,
  isLoading: T.bool,
  currentUser: T.object.isRequired,
  meta: T.object,
};

export default FarmFeedActivities;
