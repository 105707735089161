import React, { useState } from 'react';
import T from 'prop-types';
// components
import TreatmentProductInfo from './TreatmentProductInfo';
import { FormattedMessage } from 'react-intl';
import Waypoint from 'components/Waypoint/Waypoint';
import InfoRow from 'components/InfoRow/InfoRow';
import DotSeparator from 'components/DotSeparator/DotSeparator';
import Collapse from 'react-collapse';
import Button from 'components/Button';
// utils
import cn from 'classnames';
import moment from 'moment';
// styles
import styles from './TreatmentStepCard.module.scss';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

const TreatmentStepCard = ({ className, pigGroup, treatmentStepData, onSubmit }) => {
  const {
    treatment_protocol_product: treatmentProtocolProduct,
    start_date: startDate, // date when treatment starts
    end_date: endDate, // date when treatment ends
    step_unit: timeUnit, // possible values: [day, week]
    step_type: stepCondition, // possible values: [before, between, after, on]
    used_on: treatmentUsageDate,
  } = treatmentStepData;

  const [isOpened, setIsOpened] = useState(false);
  const toggleCollapse = () => setIsOpened(!isOpened);
  const { treatment_product, treatment_protocol } = treatmentProtocolProduct;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const pigGroupStartDate = moment(pigGroup.started_on).format('MM/DD/YYYY');
  const runCount = moment(endDate).diff(moment(startDate), 'days') + 1; // taking current day into account
  const isTreatmentUsed = !!treatmentUsageDate;

  const getFooterLabelKey = () => {
    if (['before', 'between'].includes(stepCondition) || (stepCondition === 'on' && startDate !== endDate)) {
      return 'general.runTreatmentForPigs.between';
    }

    return `general.runTreatmentForPigs.${stepCondition}`;
  };

  const getFooterLabelValues = () => (stepCondition === 'after' || (stepCondition === 'on' && timeUnit === 'day') ?
    ({ date: formattedStartDate }) :
    ({ start_date: formattedStartDate, end_date: formattedEndDate }));

  const renderTreatmentTimeLine = () => (
    <div className={styles['treatment-timeline']}>
      <Waypoint type="source">
        <div className="semibold">
          <InfoRow
            label={<FormattedMessage id="container.checkupIndex.row.start" />}
            value={formatDate(startDate)}
          />
        </div>
        <div className={styles['duration-wrapper']}>
          {stepCondition !== 'after' && (
            <div className={styles.duration}>
              <FormattedMessage
                id="general.runDaysCount"
                values={{ days_count: <FormattedMessage id="general.daysCount" values={{ count: runCount }} /> }}
              />
            </div>
          )}
        </div>
      </Waypoint>
      <Waypoint icon={<i className={cn('fa fa-times', styles['end-icon'])} />} isEnd>
        <div className="semibold">
          <InfoRow
            label={<FormattedMessage id="general.treatmentWindowEnds" />}
            value={endDate ? formattedEndDate : <FormattedMessage id="general.notAvail" />}
          />
        </div>
      </Waypoint>
    </div>
  );

  return (
    <div className={cn(styles['treatment-step-card'], className)}>
      <div className={cn(styles['status-line'], { [styles.completed]: isTreatmentUsed })}>
        <InfoRow
          label={<FormattedMessage id="general.treatment" />}
          value={(<FormattedMessage id="general.arrivingPigsOnDate" values={{ date: pigGroupStartDate }} />)}
        />
      </div>

      {/* HEADER */}
      <div className={styles['card-header']}>
        <i
          className={cn({
            [styles['complete-icon']]: isTreatmentUsed,
            [styles['active-icon']]: !isTreatmentUsed,
            'fa fa-check-circle-bts': isTreatmentUsed,
          })}
        />
        <span className={cn('semibold', styles.ellipsis, 'pl-20')}>
          {treatmentProtocolProduct.treatment_product.name}
          <DotSeparator />
          {treatmentProtocolProduct.treatment_protocol.name}
        </span>

        <div className={styles['btn-group']}>

          {!onSubmit && isTreatmentUsed && (
            <span className={styles['complete-label']}>
              <FormattedMessage id="general.completedDate" values={{ date: formatDate(treatmentUsageDate) }} />
            </span>
          )}

          {onSubmit && (
            <Button primary onClick={onSubmit} className={styles['submit-button']}>
              <FormattedMessage id="general.report" />
            </Button>
          )}

          <i
            onClick={toggleCollapse}
            className={cn('fa fa-angle-right', styles['collapse-icon'], { [styles.opened]: isOpened })}
          />
        </div>

      </div>

      {/* CONTENT */}
      <Collapse isOpened={isOpened}>
        <div className={styles['collapse-content']}>
          <div className={styles['general-info']}>
            {renderTreatmentTimeLine()}
            <div className={styles['product-info']}>
              <div className="semibold">{treatment_product.name}</div>
              <InfoRow
                label={<span className="semibold"><FormattedMessage id="general.headTreated" /></span>}
                value={treatmentProtocolProduct.head_treated}
              />
              <InfoRow
                label={<span className="semibold"><FormattedMessage id="general.sourceFarm" /></span>}
                value={
                  treatment_protocol?.farm_treatment_protocol?.farm?.name ||
                  <FormattedMessage id="general.notAvail" />
                }
              />
            </div>
          </div>
          <TreatmentProductInfo treatmentProtocolProduct={treatmentProtocolProduct} />
        </div>
      </Collapse>

      <div className={styles['mobile-content']}>
        {renderTreatmentTimeLine()}
        {onSubmit && (
          <Button primary onClick={onSubmit} className={cn(styles['submit-button'])}>
            <FormattedMessage id="general.report" />
          </Button>
        )}
      </div>

      {/* FOOTER */}
      <div className={cn(styles['card-footer'], { [styles.hidden]: isOpened })}>
        <InfoRow
          className={styles.ellipsis}
          label={<FormattedMessage id="general.treatmentDirections" />}
          value={(
            <FormattedMessage
              id={getFooterLabelKey()}
              values={{ ...getFooterLabelValues(), arrive_date: pigGroupStartDate, b: (msg) => <b>{msg}</b> }}
            />
          )}
        />
      </div>
    </div>
  );
};

TreatmentStepCard.propTypes = {
  className: T.string,
  pigGroup: T.object.isRequired,
  treatmentStepData: T.object.isRequired,
  onSubmit: T.func,
};

export default TreatmentStepCard;
