import React, { Component } from 'react';
import T from 'prop-types';
// recompose
import { compose } from 'recompose';
import withCorrection from './utils/withCorrectionHOC';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { reportPigs } from 'reducers/dailyCheckup/checkupEdit';
import { openPortalRight, closePortalRight } from 'reducers/rightToLeftPortal';
import { openModal, closeModal } from 'reducers/modals';
import {
  setDestroyedData,
  setSelectedData,
  selectItem as selectBarn,
  unselectItem as unselectBarn,
  resetData as resetBarnsData,
  setData as setBarnsList,
} from 'reducers/mobileListPicker';
// components
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import MessageBox from 'components/MessageBox';
import Button from 'components/Button';
import Input from 'components/Input';
import PigsUnderCareLine from 'components/PigsUnderCareLine/PigsUnderCareLine';
import CenterBox from 'components/CenterBox';
import StickyHeader from 'components/StickyHeader/StickyHeader';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ReactSelect from 'components/Select/ReactSelect';
import MobilePortalSelector from 'components/MobilePortalSelector/MobilePortalSelector';
import CloseGroupModal from './modals/CloseGroupModal';
import ReportPigGroupMarketingModal from './modals/ReportPigGroupMarketingModal';
import LabeledInput from 'components/LabeledInput/LabeledInput';
import ConditionRadioButtons from 'components/ConditionRadioButtons/ConditionRadioButtons';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import CollapseWithdrawalWarning from './components/CollapseWithdrawalWarning';
import InventoryConflictModal from './components/InventoryConflictModal';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileBarnRow from 'components/DailyCheckups/MobileBarnRow';
import FormattedWeight from 'components/FormattedWeight';
import TruckingRouteCard from 'components/TruckingRouteCard/TruckingRouteCard';
// utils
import cn from 'classnames';
import update from 'react-addons-update';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { sendNotify } from 'utils/airbrakeHelper';
import { isMigrationTypeNegative, getMigrationTypeLabel } from 'utils/checkupHelper';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import {
  getAvgWeightBasedOnCommitment,
  getCommitmentDirection,
  getMigrationTypeBasedOnCommitment,
} from 'utils/commitmentsHelper';
// endpoints
import { getFarmBarns } from 'endpoints/farms';
import { getCommitment } from 'endpoints/commitments';
// assets
import './DailyCheckupReportMovements.scss';

class DailyCheckupLoadMovement extends Component {

  constructor(props, { formatMessage }) {
    super(props);
    this.state = {
      migrations: [],
      commitmentMigration: {},
      multiSelectMode: false,
      barns: null,
    };

    this.movementTypes = [
      { label: formatMessage({ id: 'general.shipmentReceived' }), value: 'placement', icon: 'arrow-right',
        description: <FormattedMessage id="general.addPigs" /> },
      { label: formatMessage({ id: 'general.transferOut' }), value: 'transfer', icon: 'arrow-left',
        description: <FormattedMessage id="general.removePigs" /> },
      { label: formatMessage({ id: 'general.sale' }), value: 'sale', icon: 'arrow-left',
        description: <FormattedMessage id="general.removePigs" /> },
    ];
  }

  componentDidMount() {
    const { setPageOptions, params, checkup, isEditMode, currentUser, backLink, resetBarnsData } = this.props;
    const { migrations } = this.state;
    const isLogisticsEnabled = currentUser.current_company.logistics;
    resetBarnsData();
    if (!isLogisticsEnabled) {
      this.context.router.push(`daily-checkup/${params.id}`);
    }
    this.fetchData();

    if (checkup.id === parseInt(params.id, 10)) {
      if (migrations.length === 1 && !migrations[0].migration_type) {
        this.handleFocusOnMobile();
      }
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'pigMovements',
        inEditMode: isEditMode,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      setPageOptions, checkup, backLink, isEditMode, params: { commitmentId } } = this.props;
    if (prevProps.checkup.id !== checkup.id) {
      this.fetchFarmBarns(checkup.pig_group.farm_id);
      setPageOptions({
        backLink: { icon: 'fa fa-times fs-18', link: backLink },
        mobileTitle: 'pigMovements',
        inEditMode: isEditMode,
      });
    }

    if (prevProps.params.commitmentId !== commitmentId) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    const { resetBarnsData, setPageOptions } = this.props;
    resetBarnsData();
    setPageOptions({ mobileTitle: '' });
  }

  fetchData = () => {
    const { checkup } = this.props;
    this.fetchCommitment();
    if (checkup.pig_group) this.fetchFarmBarns(checkup.pig_group.farm.id);
  };

  fetchCommitment = () => {
    const { params: { commitmentId }, params, currentUser: { current_company } } = this.props;
    getCommitment(commitmentId)
      .then((commitment) => {
        if (!commitment || commitment.status !== 'accepted') {
          this.context.router.push(`daily-checkup/${params.id}`);
          return;
        }
        const avgWeight = getAvgWeightBasedOnCommitment(commitment);
        this.setState({
          commitmentMigration: {
            amount: commitment.head_count,
            migration_type: getMigrationTypeBasedOnCommitment(commitment),
            barn_ids: [commitment.barn_id ? commitment.barn_id : null],
            estimated_weight: formatBackEndWeightToSystem(avgWeight, current_company.measurement_system),
            condition: null,
            loading_commitment_id: commitment.id,
          },
          commitment,
        });
      })
      .catch((err) => {
        toastResponseErrors(err);
      });
  };

  fetchFarmBarns = (farmId) => {
    const { isOnline, setBarnsList } = this.props;
    if (!isOnline) return;
    getFarmBarns(farmId)
      .then((barns) => {
        setBarnsList(barns);
        this.setState((prevState) => ({ ...prevState, barns }));
      });
  };

  getBarnOptions = () => {
    const { commitmentMigration, barns } = this.state;
    const { barn_ids } = commitmentMigration;
    return barn_ids
      ? barns.map((barn) => ({ ...barn, disabled: barn_ids.includes(barn.id) }))
      : barns;
  };

  setComment = (e, newValue, plainText, mentions = []) => {
    this.setState({ comment: e.target.value, mentions: mentions.map((i) => i.id) });
  };

  onMigrationTypeChange = (option) => {
    this.setState({
      commitmentMigration: { migration_type: option.value, amount: 0 },
    });
  };

  updateCommitmentMigration = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      commitmentMigration: {
        ...prevState.commitmentMigration,
        ...value
      }
    }));
  };

  onConditionChange = (condition) => {
    this.updateCommitmentMigration({ condition });
  };

  addLocation = () => {
    const { commitmentMigration } = this.state;
    const { openPortalRight, setSelectedData } = this.props;

    if (isMobile) {
      const selectedIds = (commitmentMigration.barn_ids || []);
      const selected = this.state.barns.filter(({ id }) => selectedIds.includes(id));
      setSelectedData(selected);
      openPortalRight(
        <MobileListPicker
          title={<FormattedMessage id="general.pageTitle.addLocation" />}
          mode="add"
          idKey="id"
          rightButtonOptions={{ label: 'next' }}
          rowRenderer={this.renderMobileListRow()}
        />
      );
    } else {
      const { barn_ids } = this.state.commitmentMigration;
      this.updateCommitmentMigration({
        barn_ids: [
          ...(barn_ids || []),
          null
        ]
      });
    }
  };

  handleBarnsFocusOnMobile = (barnIndex) => {
    const { commitmentMigration } = this.state;
    const { openPortalRight, setSelectedData } = this.props;

    if (isMobile) {
      const selectedIds = (commitmentMigration.barn_ids || []);
      const selected = this.state.barns.filter(({ id }) => selectedIds.includes(id));
      setSelectedData(selected);
      openPortalRight(
        <MobileListPicker
          title={<FormattedMessage id="general.pageTitle.changeLocation" />}
          mode="change"
          idKey="id"
          rightButtonOptions={{ label: 'next' }}
          rowRenderer={this.renderMobileListRow(barnIndex)}
        />
      );
    }
  };

  renderMobileListRow = (barnIndex) => (barn, mode, { isSelected, isDisabled }) => {
    const onRowClick = {
      change: () => this.onMobileBarnChange(barn, barnIndex),
      add: () => this.onMobileBarnAdd(barn),
    }[mode];
    const selectedRow = isSelected(barn);
    const disabledRow = isDisabled(barn);
    const actionBtnType = mode === 'change' ? 'button' : 'checkbox';
    return (
      <MobileBarnRow
        key={barn.id}
        barn={barn}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={onRowClick}
        selectedRow={selectedRow}
      />
    );
  };

  onMobileBarnAdd = (barn) => {
    const { commitmentMigration } = this.state;
    const { selectedTreatments, setDestroyedData, selectBarn, unselectBarn } = this.props;
    const selectedBarns = selectedTreatments.find(({ id }) => id === barn.id);
    const ids = commitmentMigration.barn_ids || [];

    if (selectedBarns) {
      unselectBarn(selectedBarns);
      const barn_ids = ids.filter((id) => id !== selectedBarns.id);
      this.updateCommitmentMigration({ barn_ids });
    } else {
      selectBarn(barn);
      const barn_ids = [...ids, barn.id];
      this.updateCommitmentMigration({ barn_ids });
    }
    setDestroyedData([]);
  };

  onMobileBarnChange = (barn, barnIndex) => {
    const { commitmentMigration } = this.state;
    const { setDestroyedData, closePortalRight } = this.props;

    const ids = commitmentMigration.barn_ids;
    const barn_ids = [...ids.slice(0, barnIndex), barn.id, ...ids.slice(barnIndex + 1)];
    this.updateCommitmentMigration({ barn_ids });
    setDestroyedData([]);
    closePortalRight();
  };

  onLocationsChange = (barnIndex) => (barnOption) => {
    const { commitmentMigration } = this.state;
    const barn_ids = update(commitmentMigration.barn_ids || [], { [barnIndex]: { $set: barnOption?.id || null } });
    this.updateCommitmentMigration({ barn_ids });
  };

  removeLocation = (index, barnIndex) => () => {
    const { commitmentMigration } = this.state;
    const barn_ids = update(commitmentMigration.barn_ids, { $splice: [[barnIndex, 1]] });
    this.updateCommitmentMigration({ barn_ids });
  };

  onPigsAmountChange = (e) => {
    const { value, validity } = e.target;
    const amount = this.formatAmount(value, validity.valid);

    this.setState((prevState) => ({
      ...prevState,
      commitmentMigration: {
        ...prevState.commitmentMigration,
        amount
      }
    }));
  };

  onEstimatedWeightChange = (e) => {
    const { value: estimated_weight } = e.target;
    this.updateCommitmentMigration({ estimated_weight });
  };

  formatAmount = (value, isValid) => {
    const { commitmentMigration } = this.state;
    if (!isValid) return commitmentMigration.amount;
    if (String(value)[0] === '0') return parseInt(value, 10);
    return (value || '');
  };

  selectInputText = (e) => {
    e.target.select();
  };

  optionRenderer = ({ icon, label, description }) => (
    <div className="ReactSelect--option">
      <i className={`fa fa-${icon} mr-10`} />
      <span className="mr-5">{label}</span>
      <span className="description">({description})</span>
    </div>
  );

  valueRenderer = ({ label, description }) => (
    <div>{label} ({description})</div>
  );

  handleFocusOnMobile = () => {
    if (isMobile) {
      const { openPortalRight, closePortalRight } = this.props;
      const { commitmentMigration, multiSelectMode } = this.state;
      const currentValue = this.state.commitmentMigration.migration_type;

      if (!commitmentMigration.migration_type) {
        this.setState({ multiSelectMode: true });
      }

      openPortalRight(
        <MobilePortalSelector
          title={<FormattedMessage id="container.dailyCheckup.reportMovements.selectMovementType" />}
          multiSelectMode={multiSelectMode}
          onMultiSelectionClose={this.onMultiSelectionClose}
          options={this.movementTypes}
          currentValue={currentValue}
          setValue={this.onMigrationTypeChange}
          closePortal={closePortalRight}
        />
      );
    }
  };

  onMultiSelectionClose = (selectedObjects) => {
    this.setState({
      migrations: selectedObjects.map((option) => ({ migration_type: option.value, amount: 0 })),
      multiSelectMode: false,
    });
  };

  afterSubmitMigrationsModals = ({ pig_group, pigs, pig_migrations }, onCloseModal) => {
    const modals = [];

    if (!pig_group.marketing_reported_at && pig_migrations.some((pm) => pm.migration_type === 'sale')) {
      modals.push(
        <ReportPigGroupMarketingModal pigGroup={pig_group} closeModal={onCloseModal} />
      );
    }
    if (pigs === 0) {
      modals.push(
        <CloseGroupModal pigGroup={pig_group} closeModal={onCloseModal} />
      );
    }

    return modals;
  };

  afterSubmitMigrations = (resource) => {
    const { openModal, closeModal } = this.props;
    let modals = [];

    const openQueueModal = () => {
      const component = modals.shift();
      if (component) {
        openModal(
          component,
          {
            position: isMobile ? 'bottom' : 'center',
            backdropStyles: { opacity: 0.9 },
            onClose: () => { setTimeout(openQueueModal, 500); },
          }
        );
      }
    };

    const closeQueueModal = () => {
      closeModal();
      setTimeout(openQueueModal, 500);
    };

    modals = this.afterSubmitMigrationsModals(resource, closeQueueModal);

    openQueueModal();
  };

  calculateCurrentPigsCount = (checkup, commitmentMigration) => {
    const checkupPigs = checkup.pigs || 0;
    const migrationPigs = commitmentMigration.amount || 0;
    return isMigrationTypeNegative(commitmentMigration.migration_type)
      ? checkupPigs - migrationPigs
      : checkupPigs + migrationPigs;
  }

  submitData = () => {
    const { router } = this.context;
    const { checkup, reportPigs, validateCheckup, backLink, isEditMode, openModal, currentUser,
      currentUser: { current_company: { measurement_system } }, isOnline } = this.props;
    const { commitmentMigration } = this.state;
    if (!validateCheckup(checkup)) return;

    const pigsAmount = this.calculateCurrentPigsCount(checkup, commitmentMigration);

    if (!isEditMode && !isEmpty(checkup) && (pigsAmount < checkup.total_pigs_treated)) {
      openModal(
        <InventoryConflictModal handleConfirm={() => router.push(`/daily-checkup/${checkup.id}/report-treatments`)} />
      );
      return;
    }

    const data = {
      pig_migrations: [
        {
          ...commitmentMigration,
          estimated_weight: formatWeightToBackEndFormat(commitmentMigration.estimated_weight, measurement_system),
          amount: isMigrationTypeNegative(commitmentMigration.migration_type) ?
            -commitmentMigration.amount :
            +commitmentMigration.amount
        },
      ],
    };

    reportPigs(checkup.id, { resource: data })
      .then((resource) => {
        router.push(backLink);
        if (resource && isOnline) this.afterSubmitMigrations(resource);
      })
      .catch((response) => {
        sendNotify(response, currentUser);
        return toastResponseErrors(response);
      });
  };


  renderWarningTitle = () => {
    const { checkup } = this.props;
    const warnings = checkup.withdrawal_warnings || [];

    const latest = warnings.reduce((m, v, i) => ((v.withdrawn_on > m.withdrawn_on) && i ? v : m));

    return (
      <div className={cn('warning-title')}>
        <i className={cn('fa fa-warning', 'warning-icon')} />
        <FormattedMessage
          id="container.checkupEdit.reportMovement.withdrawalWarnText"
          tagName="div"
          values={{
            attentionText: (
              <FormattedMessage id="container.checkupEdit.reportMovement.withdrawalWarn">
                {(text) => <span className={cn('bold')}>{text}</span>}
              </FormattedMessage>
            ),
            date: <b>{moment(latest.withdrawn_on).format('MMM DD, YYYY')}</b>,
          }}
        />
      </div>
    );
  };

  render() {
    const { isReportingMovements, checkup, backLink, isEditMode, currentUser: { current_company } } = this.props;
    const { barns, commitmentMigration, commitment } = this.state;
    const isContinueDisabled = Boolean(!commitmentMigration.amount);
    const warnings = (checkup.withdrawal_warnings || []).sort((curr, next) => (
      Number(moment(curr.withdrawn_on) < moment(next.withdrawn_on))
    ));
    const amount = this.calculateCurrentPigsCount(checkup, commitmentMigration);

    return (
      <CenterBox isLoading={isReportingMovements} renderIf={Boolean(checkup)} className="DailyCheckupReportMovements">
        <StickyHeader className="hide-for-large">
          <PigsUnderCareLine mobile pigsCount={amount || 0} />
        </StickyHeader>

        <section className="center-box-header show-for-large">
          <div>
            <div className="subtitle">{checkup.pig_group_name}</div>
            <h1 className="title">
              {!isEmpty(commitmentMigration) && (
                <FormattedMessage
                  id={`general.loadMovement.${commitmentMigration.migration_type === 'placement' ? 'receive' : 'ship'}`}
                />
              )}
            </h1>
          </div>
          <Link to={backLink}>
            <i className="fa fa-times close-center-box" />
          </Link>
        </section>

        <PigsUnderCareLine mobile pigsCount={amount || 0} className="show-for-large" />

        {/* TODO: implement new trucking card */}
        {!isEmpty(commitment) && (
          <TruckingRouteCard
            direction={getCommitmentDirection(commitment)}
            className="mt-25 mh-20 mb-10"
            loadType={commitment.loading.load_type}
            commitments={commitment.loading.commitments}
          />
        )}

        <section className="center-box-body">
          {!isEditMode && !isEmpty(checkup) && (amount < checkup.total_pigs_treated) && (
            <MessageBox type="alert" isBorderHidden>
              <span className="head-treated-warning">
                <FormattedMessage id="component.alertBox.warning" tagName="strong" />&nbsp;
                <FormattedMessage id="container.checkupEdit.headTreatedWarning" />
              </span>
            </MessageBox>
          )}
          {isEditMode && (
            <MessageBox type="warning">
              <strong>
                <FormattedMessage id="component.alertBox.warning" />&nbsp;
              </strong>
              <FormattedMessage id="container.checkupEdit.editWarning" />
            </MessageBox>
          )}

          {!!warnings.length && current_company.withdrawal_tracking_on && (
            <CollapsesBox className="mb-20">
              <CollapseWithdrawalWarning data={warnings} title={this.renderWarningTitle()} />
            </CollapsesBox>
          )}

          <div className="migrations-form">
            <div className="migration-block">
              <div className="migration-input-row">
                <ReactSelect
                  className="movement-type-select"
                  placeholder={<FormattedMessage id="container.dailyCheckup.reportMovements.selectMovementType" />}
                  options={this.movementTypes}
                  value={commitmentMigration.migration_type}
                  onChange={this.onMigrationTypeChange}
                  optionRenderer={this.optionRenderer}
                  valueRenderer={this.valueRenderer}
                  onFocus={this.handleFocusOnMobile}
                  openOnClick={!isMobile}
                  searchable
                  disabled
                  noInputMargin
                />
              </div>
              {!!commitmentMigration.migration_type && (
                <div className="migration-inputs">

                  {/* PIGS AMOUNT */}
                  <div className="migration-input-row">
                    <label className="input-wrapper normalized">
                      <span className="input-label">{getMigrationTypeLabel(commitmentMigration.migration_type)}</span>
                      <Input
                        type="number"
                        min="0"
                        max="99999"
                        placeholder="0"
                        step="1"
                        pattern="[0-9]*"
                        value={commitmentMigration.amount || ''}
                        onChange={this.onPigsAmountChange}
                        className="migration-input"
                      />
                    </label>
                  </div>

                  {commitmentMigration.migration_type === 'placement' && (
                    <div>
                      {/* ESTIMATED WEIGHT */}
                      <div className="migration-input-row">
                        <label className="input-wrapper normalized">
                          <FormattedMessage id="general.estAvgWeight">
                            {(text) => <span className="input-label">{text}</span>}
                          </FormattedMessage>
                          <LabeledInput
                            label={<FormattedWeight hasOnlyUnit />}
                            placeholder="0"
                            type="number"
                            min="0"
                            max="99999"
                            step="0.01"
                            pattern="[0-9]*"
                            value={commitmentMigration.estimated_weight || ''}
                            onChange={this.onEstimatedWeightChange}
                            onFocus={this.selectInputText}
                            className="migration-input"
                          />
                        </label>
                      </div>

                      {/* CONDITION */}
                      <div className="migration-input-row">
                        <label className="input-wrapper">
                          <FormattedMessage id="general.condition">
                            {(text) => <span className="input-label">{text}</span>}
                          </FormattedMessage>
                          <ConditionRadioButtons
                            value={commitmentMigration.condition}
                            onChange={this.onConditionChange}
                            className="migration-input wide"
                          />
                        </label>
                      </div>

                      {/* LOCATION / BARNS */}
                      {barns && !!barns.length && (
                        <div>
                          {commitmentMigration.barn_ids?.map((barnId, barnIndex) => (
                            <div className="migration-input-row" key={`${barnId}-${barnIndex}`}>
                              <label className="input-wrapper">
                                <span className={cn('input-label', { 'show-for-large': barnIndex !== 0 })}>
                                  {barnIndex === 0 && (
                                    <FormattedMessage id="general.locationOnFarm" />
                                  )}
                                </span>
                                <div className="location-row">
                                  <ReactSelect
                                    key={barnId}
                                    options={this.getBarnOptions()}
                                    value={barnId}
                                    onChange={this.onLocationsChange(barnIndex, barnIndex)}
                                    onFocus={() => this.handleBarnsFocusOnMobile(barnIndex, barnIndex)}
                                    openOnClick={!isMobile}
                                    labelKey="name"
                                    valueKey="id"
                                    className="migration-input wide"
                                    placeholder={(
                                      <FormattedMessage id="container.dailyCheckup.reportMovements.selectLocation" />
                                    )}
                                    searchable
                                    noInputMargin
                                  />
                                  {(commitmentMigration.barn_ids?.length || 0) > 0 && (
                                    <div className="trash-cell" onClick={this.removeLocation(0, barnIndex)}>
                                      <i className="fa fa-trash-o trash-icon" />
                                    </div>
                                  )}
                                </div>
                              </label>
                            </div>
                          ))}
                          {(commitmentMigration.barn_ids?.length || 0) < (barns?.length || 0) && (
                            <div className="add-location" onClick={this.addLocation}>
                              <FormattedMessage id="general.addLocationWithPlus" />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="center-box-footer show-for-large">
          <div className="buttons text-right">
            <Link className="button margin-right-1" to={backLink}>
              <FormattedMessage id="general.button.cancel" />
            </Link>
            <Button
              primary
              disabled={isContinueDisabled || isReportingMovements}
              className="submit wider"
              onClick={this.submitData}
            >
              <FormattedMessage id="general.button.continue" />
            </Button>
          </div>
        </section>

        <StickyFooter className="hide-for-large" hidden={isContinueDisabled}>
          <Button
            stickyFooter
            primary
            disabled={isContinueDisabled || isReportingMovements}
            onClick={this.submitData}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </CenterBox>
    );
  }
}

DailyCheckupLoadMovement.contextTypes = {
  router: T.object.isRequired,
  formatMessage: T.func.isRequired,
};

DailyCheckupLoadMovement.propTypes = {
  checkup: T.object,
  currentUser: T.object,
  params: T.object,
  isReportingMovements: T.bool.isRequired,
  setPageOptions: T.func.isRequired,
  reportPigs: T.func.isRequired,
  openPortalRight: T.func.isRequired,
  closePortalRight: T.func.isRequired,
  validateCheckup: T.func.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  isOnline: T.bool.isRequired,
  backLink: T.string,
  selectedTreatments: T.array,
  setDestroyedData: T.func,
  selectBarn: T.func,
  unselectBarn: T.func,
  resetBarnsData: T.func,
  setBarnsList: T.func,
  setSelectedData: T.func,
  isEditMode: T.bool,
};

const enhance = compose(
  connect((state, props) => ({
    currentUser: state.auth.user,
    isOnline: state.network.isOnline,
    treatmentsList: state.mobileListPicker.dataList.resources,
    selectedTreatments: state.mobileListPicker.selected,
    destroyedTreatments: state.mobileListPicker.destroyed,
    isReportingMovements: props.isLoading || state.dailyCheckup.checkupEdit.isReportingMovements,
  }), {
    reportPigs,
    setPageOptions,
    openPortalRight,
    closePortalRight,
    openModal,
    closeModal,
    setDestroyedData,
    setSelectedData,
    selectBarn,
    unselectBarn,
    resetBarnsData,
    setBarnsList,
  }),
  withCorrection,
);

export default enhance(DailyCheckupLoadMovement);
