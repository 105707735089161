import React, { useState } from 'react';
import T from 'prop-types';
// components
import { UnmountClosed } from 'react-collapse';
import CategoryCounterStatus from 'containers/SiteVisitReport/components/CategoryCounterStatus';
import SVRActivityQuestionLine from './SVRActivityQuestionLine';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './SVRActivityCategoryCollapse.module.scss';

const cn = classnames.bind(styles);

const SVRActivityCategoryCollapse = ({ categoryName, categoryStats, questions, className }) => {
  const [isOpened, setIsOpened] = useState(!!questions.some((question) => !!question.answer.comments.length));
  const isCategoryFailed = !!categoryStats.failedAnswersCounter;

  return (
    <div className={cn('svr-category-collapse', { [className]: !!className, failed: isCategoryFailed })}>
      <div
        className={cn('collapse-header', { 'is-opened': isOpened })}
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className={cn('category-name')}>
          {!!categoryStats.failedAnswersCounter && <i className={cn('fa fa-times-circle', 'fail-icon')} />}
          {categoryName}
        </div>
        <div className={cn('counter-badges')}>
          <CategoryCounterStatus small count={categoryStats.passedAnswersCounter} type="pass" />
          <CategoryCounterStatus small count={categoryStats.failedAnswersCounter} type="fail" />
          <CategoryCounterStatus small count={categoryStats.notAvailableAnswersCounter} type="not_available" />
        </div>
        <i className={cn('fa fa-angle-right', 'collapse-icon', { 'is-opened': isOpened })} />
      </div>
      <div className={cn('collapse-content')}>
        <UnmountClosed isOpened={isOpened}>
          <div className={cn('svr-body')}>
            {questions.map((question) => <SVRActivityQuestionLine question={question} key={question.id} />)}
          </div>
        </UnmountClosed>
      </div>
    </div>
  );
};

SVRActivityCategoryCollapse.propTypes = {
  className: T.string,
  categoryName: T.node.isRequired,
  questions: T.array.isRequired,
  categoryStats: T.object.isRequired,
};

export default SVRActivityCategoryCollapse;
