import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { submit, formValueSelector } from 'redux-form';
import { fetchPigGroupMigration, clearPigGroupMigration } from 'reducers/pigGroups/pigGroupMigrationShow';
import { setPageOptions } from 'reducers/layout';
import { openModal } from 'reducers/modals';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Link from 'react-router/lib/Link';
import CenterBox from 'components/CenterBox';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import TransferForm from './components/TransferForm';
import PigsUnderCareLine from 'components/PigsUnderCareLine';
import InventoryConflictModal from '../GroupProfile/modals/InventoryConflictModal';
import Preloader from 'components/Preloader';
// utils
import moment from 'moment';
import pick from 'lodash.pick';
import isEmpty from 'lodash.isempty';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatBackEndWeightToSystem, formatWeightToBackEndFormat } from 'utils/weightHelper';
import { showToastrMessage } from 'utils';
// groups api
import { createPigGroupMigration, makePigGroupCorrections } from 'endpoints/pigGroups';

const TransferEdit = ({
  clearPigGroupMigration,
  destination,
  fetchPigGroupMigration,
  isLoading,
  location: { query },
  measurementSystem,
  migration,
  migrationType,
  openModal,
  params: { id: pigGroupId, migrationId },
  router,
  setPageOptions,
  submit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    fetchPigGroupMigration(migrationId);
    setPageOptions({ mobileTitle: 'editSaleTransfer' });
    return () => {
      setPageOptions({ mobileTitle: '' });
      clearPigGroupMigration();
    };
  }, []);

  const getInitialDestinations = (pigMigration) => {
    const { external_destinations, internal_destinations } = pigMigration;
    if (external_destinations.length) {
      return {
        type: 'external',
        data: { value: external_destinations[0].id, label: external_destinations[0].name },
      };
    }
    if (internal_destinations.length) {
      return {
        type: 'internal',
        data: { value: internal_destinations[0].id, label: internal_destinations[0].name },
      };
    }
    return {};
  };

  const getInitialValues = () => {
    if (isEmpty(migration) || migration.id !== Number(migrationId)) return undefined;
    const { amount, created_at, delivery_date, estimated_weight, migration_type } = migration;
    return {
      destination: getInitialDestinations(migration),
      delivery_date: moment(delivery_date || created_at),
      amount: Math.abs(amount),
      migration_type,
      estimated_weight: formatBackEndWeightToSystem(estimated_weight, measurementSystem),
    };
  };

  const prepareResource = (data) => {
    const resource = pick(data, ['delivery_date', 'migration_type', 'estimated_weight']);
    if (data?.destination?.type) {
      resource[`${data.destination.type}_destination_ids`] = [data.destination.data.value];
    }

    return {
      ...resource,
      amount: -Math.abs(data.amount),
      estimated_weight: formatWeightToBackEndFormat(resource.estimated_weight, measurementSystem),
    };
  };

  const handleMakeCorrections = (bodyParams) => {
    makePigGroupCorrections(pigGroupId, bodyParams)
      .then(() => {
        // TO PREVIEW CREATED CORRECTIONS
        router.push(`/barnsheets/groups/${pigGroupId}?editMode=true`);
      })
      .catch(toastResponseErrors);
  };

  const handleSubmitForm = (data) => {
    setIsSubmitting(true);
    const bodyParams = {
      resource: prepareResource(data),
      pig_migration_id: migrationId,
    };

    createPigGroupMigration(pigGroupId, bodyParams)
      .then(() => {
        showToastrMessage('component.toastr.transfer.successUpdated');
        router.goBack();
      })
      .catch((response) => {
        setIsSubmitting(false);
        if (response?.errors?.migration_conflict) {
          // INFORM ABOUT MIGRATIONS ERROR
          openModal(
            <InventoryConflictModal onSubmit={() => handleMakeCorrections(bodyParams)} />
          );
        } else {
          toastResponseErrors(response);
        }
      });
  };

  const initialValues = getInitialValues();
  const pigsCount = migration?.pig_group?.pigs || 0;
  const isCreateDisabled = !migration.loading_commitment_id
    ? !migrationType || !destination?.type || isEmpty(destination?.data)
    : false;
  const pathname = query.redirect_url || `/groups/${pigGroupId}/transfers`;

  const handleRemoteSubmit = () => {
    submit('group-movement');
  };

  return (
    <CenterBox>
      <section className="center-box-header show-for-large">
        <div className="title">
          <FormattedMessage
            id="general.editMovementWithName"
            values={{ name: migration?.pig_group?.name }}
          />
        </div>
        <Link to={pathname}><i className="fa fa-times close-center-box" /></Link>
      </section>

      <PigsUnderCareLine pigsCount={pigsCount} mobile />

      <section className="center-box-body">
        <Preloader isActive={isLoading || isSubmitting} />
        <TransferForm
          initialValues={initialValues}
          onSubmit={handleSubmitForm}
          minDeliveryDate={migration?.pig_group?.created_on ? moment(migration.pig_group.created_on) : undefined}
          isCommitmentBasedForm={!!migration.loading_commitment_id}
        />
      </section>

      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={pathname}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button
            primary
            className="submit wider"
            onClick={handleRemoteSubmit}
            disabled={isSubmitting || isLoading || isCreateDisabled}
          >
            <FormattedMessage id="general.button.save" />
          </Button>
        </div>
      </section>

      <StickyFooter className="hide-for-large">
        <Button
          primary
          className="submit"
          onClick={handleRemoteSubmit}
          disabled={isSubmitting || isLoading || isCreateDisabled}
        >
          <FormattedMessage id="general.button.save" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

TransferEdit.propTypes = {
  params: T.object.isRequired,
  router: T.object.isRequired,
  migration: T.object,
  destination: T.object,
  migrationType: T.string,
  submit: T.func.isRequired,
  fetchPigGroupMigration: T.func.isRequired,
  clearPigGroupMigration: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  isLoading: T.bool,
  openModal: T.func.isRequired,
  location: T.object,
  measurementSystem: T.string.isRequired,
};

const selector = formValueSelector('group-movement');

export default connect(
  (state) => ({
    migrationType: selector(state, 'migration_type'),
    destination: selector(state, 'destination'),
    migration: state.pigGroups.groupMigrationShow.data,
    isLoading: state.pigGroups.groupMigrationShow.isLoading,
    measurementSystem: state.auth.user.current_company.measurement_system,
  }), {
    submit,
    fetchPigGroupMigration,
    clearPigGroupMigration,
    setPageOptions,
    openModal,
  },
)(TransferEdit);
