import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal } from 'reducers/modals';
import { fetchDraftSiteVisitReports, deleteDraftSiteVisitReport } from 'reducers/svr/svrs';
import { createDraftSVR as createNewDraftSVR } from 'reducers/siteVisitReport';
// components
import { FormattedMessage } from 'react-intl';
import DraftReportCard from '../DraftReportCard';
import Preloader from 'components/Preloader';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Waypoint from 'react-waypoint';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
import { showToastrMessage } from 'utils';
import { isSomeReportQuestionFlagged } from 'utils/SVRHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
// styles
import styles from './DraftReportsList.module.scss';

const cn = classnames.bind(styles);

const DraftReportsList = ({
  resources,
  isLoading,
  isOnline,
  fetchDraftSiteVisitReports,
  params,
  meta,
  farmId,
  deleteDraftSiteVisitReport,
  openModal,
  createNewDraftSVR,
}, { router }) => {
  useEffect(() => {
    fetchDraftSiteVisitReports(farmId, { ...params, page: 1 });
  }, [isOnline, farmId]);

  const [isReportCreating, setReportCreating] = useState(false);

  const loadMore = () => {
    fetchDraftSiteVisitReports(farmId, { ...params, page: params.page + 1 });
  };

  const handleDeleteDraft = (report) => {
    const deleteReport = () => {
      return deleteDraftSiteVisitReport(farmId, report.id)
        .then(() => {
          showToastrMessage('component.toastr.svr.draftDeleted');
        })
        .catch(toastResponseErrors);
    };

    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="component.modal.deleteSVRDraft.title" />}
        actionBtnLabel={<FormattedMessage id="component.modal.deleteSVRDraft.confirm" />}
        actionBtnProps={{ red: true }}
        handleConfirm={deleteReport}
      >
        <FormattedMessage
          id="component.modal.deleteSVRDraft.text"
          tagName="p"
          values={{ date: moment(report.created_at).format('MM/DD/YYYY') }}
        />
      </ConfirmationModal>
    );
  };

  const onDraftReportCreate = () => {
    setReportCreating(true);
    createNewDraftSVR(+farmId)
      .then((createdReportId) => {
        router.push(`/svr-farms/${farmId}/svrs/${createdReportId}`);
      })
      .catch((error) => {
        toastResponseErrors(error);
        setReportCreating(false);
      });
  };

  return (
    <div className={cn('draft-reports-row')}>
      <Preloader isActive={isLoading || isReportCreating} />
      <div className={cn('add-report-card')}>
        <div onClick={onDraftReportCreate} className={cn('circle')}>
          <i className={cn('fa fa-plus', 'plus-icon')} />
        </div>
      </div>
      {resources.map((report) => (
        <DraftReportCard
          key={report.id}
          isFlagged={isSomeReportQuestionFlagged(report.svr_categories)}
          onDelete={() => handleDeleteDraft(report)}
          onContinue={() => router.push(`/svr-farms/${farmId}/svrs/${report.id}`)}
          reportUser={report.user}
        />
      ))}
      {meta.total > resources.length && !isLoading && (
        <Waypoint horizontal onEnter={loadMore} />
      )}
    </div>
  );
};

DraftReportsList.contextTypes = {
  router: T.object.isRequired,
};

DraftReportsList.propTypes = {
  resources: T.array.isRequired,
  fetchDraftSiteVisitReports: T.func.isRequired,
  farmId: T.string.isRequired,
  params: T.object.isRequired,
  meta: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isOnline: T.bool.isRequired,
  deleteDraftSiteVisitReport: T.func.isRequired,
  openModal: T.func.isRequired,
  createNewDraftSVR: T.func.isRequired,
};

export default connect(
  (state) => ({
    resources: state.svr.siteVisitReports.draft.resources,
    params: state.svr.siteVisitReports.draft.params,
    meta: state.svr.siteVisitReports.draft.meta,
    isLoading: state.svr.siteVisitReports.draft.isLoading,
  }), {
    deleteDraftSiteVisitReport,
    fetchDraftSiteVisitReports,
    openModal,
    createNewDraftSVR,
  }
)(DraftReportsList);
