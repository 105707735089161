import { lifecycle } from 'recompose';

const withSoundOnMount = (soundSrc) => lifecycle({
  componentDidMount() {
    const audio = new Audio(soundSrc);
    const promise = audio.play();
    // eslint-disable-next-line no-console
    if (promise !== undefined) promise.catch((error) => console.warn(error));
  },
});

export default withSoundOnMount;
