import React from 'react';
import T from 'prop-types';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// components
import StatusBadge from 'components/StatusBadge/StatusBadge';
import { ThreeDotsBtn } from 'components/Button';
import Avatar from 'components/Avatar/Avatar';
// styles
import styles from './TreatmentProtocolProfile.module.scss';

const TreatmentProtocolProfileHeader = ({ treatmentProtocol, options }) => {
  const { openDropdown } = useDropdownActions();

  return (
    <div className={styles['treatment-protocol-header']}>
      <div className={styles['treatment-protocol-information']}>
        <Avatar type="treatment-protocol" avatarSize={50} iconSize={24} />
        <h1 className="lighter inline-block mr-5 ml-10">{treatmentProtocol.name}</h1>
        <StatusBadge className="ml-10" status={treatmentProtocol.active ? 'active' : 'disabled'} />
      </div>
      <div className={styles['header-dropdown']}>
        <ThreeDotsBtn onClick={(e) => openDropdown(e, options)} />
      </div>
    </div>
  );
};

TreatmentProtocolProfileHeader.propTypes = {
  treatmentProtocol: T.object.isRequired,
  options: T.array.isRequired,
};

export default TreatmentProtocolProfileHeader;
