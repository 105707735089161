import React, { useState, Fragment } from 'react';
import T from 'prop-types';
// components
import Link from 'react-router/lib/Link';
import CustomColumn from 'components/DataTable/Columns/CustomColumn';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import DataTable from 'components/DataTable/DataTable';
import Preloader from 'components/Preloader';
// utils
import download from 'downloadjs';
import classnames from 'classnames/bind';
import moment from 'moment';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getAnswersCountByAnswerType, getSVRAssetName, isSomeReportQuestionFlagged } from 'utils/SVRHelper';
// api
import { getSiteVisitReportPDF } from 'endpoints/svr';
// hooks
import { useDropdownActions } from 'hooks/useDropdownContext';
// styles
import styles from './TableView.module.scss';

const cn = classnames.bind(styles);

const TableView = ({ backLocationQuery, resources, paginationProps, isReportEditable, isLoading }) => {
  const { openDropdown } = useDropdownActions();
  const [isDownloading, setDownloading] = useState(false);

  const buildSVRPath = (farmId, svrId, isReview) => {
    const pathname = isReview
      ? `/svr-farms/${farmId}/svrs/${svrId}/review`
      : `/svr-farms/${farmId}/svrs/${svrId}`;
    return { pathname, query: backLocationQuery };
  };

  const renderDateColumn = (rowData) => {
    const { id, farm_id, svr_categories, created_at } = rowData;

    return (
      <CustomColumn
        className={cn('date-column', { flagged: isSomeReportQuestionFlagged(svr_categories) })}
        label={<FormattedMessage id="general.date" />}
      >
        <Link className="color-primary" to={buildSVRPath(farm_id, id, true)}>
          {created_at ? moment(created_at, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}
        </Link>
      </CustomColumn>
    );
  };

  const renderReporterColumn = (rowData) => (
    <CustomColumn label={<FormattedMessage id="general.reporter" />}>
      {rowData.user.full_name}
    </CustomColumn>
  );

  const renderPassesColumn = (rowData) => {
    const passesCount = getAnswersCountByAnswerType(rowData, 'pass');
    return (
      <CustomColumn label={<FormattedMessage id="general.passes" />}>
        {passesCount}
      </CustomColumn>
    );
  };

  const renderFailsColumn = (rowData) => {
    const failsCount = getAnswersCountByAnswerType(rowData, 'fail');
    return (
      <CustomColumn label={<FormattedMessage id="general.fails" />}>
        {failsCount}
      </CustomColumn>
    );
  };

  const renderNAColumn = (rowData) => {
    const NACount = getAnswersCountByAnswerType(rowData, 'not_available');
    return (
      <CustomColumn noBottomBorder label={<FormattedMessage id="general.notAvail" />}>
        {NACount}
      </CustomColumn>
    );
  };

  const onSVRDownload = (svr) => () => {
    setDownloading(true);
    getSiteVisitReportPDF(svr.farm_id, svr.id)
      .then((blob) => download(blob, getSVRAssetName(svr), 'application/pdf'))
      .catch(toastResponseErrors)
      .finally(() => setDownloading(false));
  };

  const handleDropdownOpen = (e, svr) => {
    const options = [
      {
        label: <FormattedMessage id="general.button.edit" />,
        url: buildSVRPath(svr.farm_id, svr.id),
        hide: !isReportEditable,
      },
      {
        label: <FormattedMessage id="general.button.download" />,
        onClick: onSVRDownload(svr),
      },
    ];

    openDropdown(e, options);
  };

  const renderActionsColumn = (svr, { rowIndex }) => {
    return (
      <div className={cn('collapsible-value button-column', 'actions-column')}>
        <i
          data-name={`${svr.name}-${svr.id}-${rowIndex}`}
          className={cn('fa fa-dots-three-horizontal show-for-large', 'dropdown-icon')}
          onClick={(e) => handleDropdownOpen(e, svr)}
        />
        {isReportEditable && (
          <Link to={buildSVRPath(svr.farm_id, svr.id)} className="button light hide-for-large">
            <FormattedMessage id="general.button.edit" />
          </Link>
        )}
        <Button
          darkGrey
          onClick={onSVRDownload(svr)}
          className="hide-for-large"
        >
          <FormattedMessage id="general.button.download" />
        </Button>
      </div>
    );
  };

  const columns = [
    { label: <FormattedMessage id="general.date" />, flex: '1 1 140px', renderer: renderDateColumn },
    { label: <FormattedMessage id="general.reporter" />, flex: '1 1 140px', renderer: renderReporterColumn },
    { label: <FormattedMessage id="general.passes" />, flex: '1 1 140px', renderer: renderPassesColumn },
    { label: <FormattedMessage id="general.fails" />, flex: '1 1 140px', renderer: renderFailsColumn },
    { label: <FormattedMessage id="general.notAvail" />, flex: '1 1 140px', renderer: renderNAColumn },
    { label: '', flex: '0 0 45px', fixed: true, renderer: renderActionsColumn },
  ];

  return (
    <Fragment>
      <Preloader isActive={isDownloading} />
      <DataTable
        data={resources}
        columns={columns}
        paginationProps={paginationProps}
        isLoading={isLoading}
        scrollable
      />
    </Fragment>

  );
};

TableView.propTypes = {
  backLocationQuery: T.object,
  paginationProps: T.object.isRequired,
  resources: T.array.isRequired,
  isReportEditable: T.bool.isRequired,
  isLoading: T.bool.isRequired,
};

export default TableView;
