import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { openModal, closeModal } from 'reducers/modals';
import { updateCurrentUser } from 'reducers/auth';
import { addNewActivity } from 'reducers/farmfeed';
import { setPigsUnderCareData } from 'reducers/pigsUnderCare';
import { showTenantNotification, setIsSchedulePageOutdated } from 'reducers/layout';
import { setUnreadNotificationsCount, increaseUnviewedAssetsCount } from 'reducers/sidebar';
import { mergeNewNotification } from 'reducers/notifications';
// components
import TimeToReconcileModal from 'components/Inventory/TimeToReconcileModal/TimeToReconcileModal';
import NotificationMessage from 'components/Notifications/NotificationMessage';
import AISuggestionModal from './Modals/AISuggestionModal';
import BirthdayModal from 'components/BirthdayModal/BirthdayModal';
// services
import { trackHotJar } from 'utils/trackServices';
// utils
import { isMobile } from 'react-device-detect';
import { clearOfflineDB } from 'db/offline-db';
import { getLSDeviceToken } from 'utils/localStorageHelper';
import { initFirebaseMessaging } from 'utils/pushNotificationsHelper';
import debounce from 'lodash.debounce';
import isEmpty from 'lodash.isempty';
import { toastr } from 'react-redux-toastr';

const updateScheduleNotificationTypes = [
  'loading_canceled_production',
  'loading_created_production',
  'loading_edited_confirmation_production',
  'loading_edited_production',
  'loading_force_accepted_production',
];

class CurrentUserManager extends Component {

  constructor(props) {
    super(props);
    this.checkUserEventsDebounced = debounce(this.checkUserEvents, 300);
  }

  componentDidMount() {
    const { user } = this.props;
    trackHotJar();
    this.checkUserEventsDebounced(user);
    this.setupSocketChannel();
  }

  componentDidUpdate({ user: prevUser }) {
    const { user } = this.props;
    const { router } = this.context;
    if (isEmpty(user)) {
      router.push('/login');
      return;
    }
    if (user !== prevUser) {
      this.checkUserEventsDebounced(user, prevUser);
    }
  }

  componentWillUnmount() {
    this.channel.unsubscribe();
    clearOfflineDB();
  }

  checkTenantStatus = (user) => {
    const { showTenantNotification } = this.props;
    const isAdmin = user?.roles_map?.admin;
    const isTrialTenant = !!user?.current_company?.expires_at;
    const isTenantDeactivated = user?.current_company?.tenant_status === 'deactivated';
    showTenantNotification(isAdmin && (isTrialTenant || isTenantDeactivated));
  };

  setupSocketChannel = () => {
    const { user } = this.props;
    this.channel = window.actionCable.subscriptions
      .create({ channel: 'UserChannel', user_id: user.id }, {
        received: this.handleNewSocketMessage,
      });
  };

  handleNewSocketMessage = (response) => {
    const { addNewActivity, setPigsUnderCareData, increaseUnviewedAssetsCount, user, setUnreadNotificationsCount,
      unreadNotifications, mergeNewNotification, setIsSchedulePageOutdated } = this.props;
    const { tenant_id, company_id, type, resource, action_type, action_author_id } = response;
    const currentCompanyId = user?.current_company?.id;
    const theSameUser = action_author_id === user.id;

    if (currentCompanyId !== tenant_id) return;

    if ((action_type === 'comment') && theSameUser) return;

    // direct post activity has merged already on action author feed
    if ((type === 'activity') && theSameUser && resource.variety.includes('direct')) return;

    switch (type) {
      case 'activity':
        addNewActivity(resource);
        return;
      case 'pig_analytics':
        setPigsUnderCareData(resource, company_id);
        return;
      case 'notification':
        if (resource.group_name !== 'production') return;
        if (updateScheduleNotificationTypes.includes(resource.notification_type)) {
          const { pathname } = this.context.router.location;
          if (pathname === '/my-schedule') setIsSchedulePageOutdated(true);
        }
        mergeNewNotification(resource);
        setUnreadNotificationsCount(unreadNotifications + 1);
        toastr.light('', '', {
          timeOut: 6000,
          position: isMobile ? 'bottom-center' : 'top-right',
          className: 'web-notification',
          component: <NotificationMessage data={resource} user={user} />,
        });
        return;
      case 'tenant_resource':
        increaseUnviewedAssetsCount(1);
        return;
      default:
        console.warn("Websocket message type didn't recognized", { type }); // eslint-disable-line
    }
  };

  onMarkAISuggestionModalAsSeen = (user) => {
    const { closeModal, updateCurrentUser } = this.props;
    updateCurrentUser({
      static_store: Object.assign(user.static_store, { AI_SUGGESTION_POPUP_SEEN: true })
    }, user.id);
    closeModal();
  };

  checkUserEvents = (user, prevUser) => {
    const { openModal } = this.props;
    const { router: { location } } = this.context;
    const isIncompleteUser = user.registration_state !== 'complete';

    if (user?.current_company?.id !== prevUser?.current_company?.id) {
      this.checkTenantStatus(user);
    }

    // skip modals conditions
    if (['/login', '/set-password'].includes(location.pathname)) return;
    if (isIncompleteUser) return;

    // init web push notifications (FCM)
    if (user?.id !== prevUser?.id || !getLSDeviceToken()) {
      try {
        initFirebaseMessaging();
      } catch (err) {
        console.warn(err.message);
      }
    }

    const tenantCanSuggest = user?.current_company?.ai_img_prediction || user?.current_company?.ai_tm_prediction;
    const birthdayModalSeen = user?.static_store?.BIRTHDAY_MODAL_SEEN;
    const suggestionModalSeen = user?.static_store?.AI_SUGGESTION_POPUP_SEEN;
    const showAISuggestionModal = !suggestionModalSeen && tenantCanSuggest;
    const showBirthdayModal = !showAISuggestionModal && !birthdayModalSeen && user.registration_state === 'complete';

    // reconcile inventory modal
    if (user.pig_groups_locked) {
      openModal(
        <TimeToReconcileModal />
      );
    }

    if (user && !user.pig_groups_locked && showAISuggestionModal) {
      openModal(
        <AISuggestionModal onMarkAsSeen={() => this.onMarkAISuggestionModalAsSeen(user)} />,
        { closeable: false }
      );
    }

    if (user && !user.pig_groups_locked && showBirthdayModal) {
      openModal(<BirthdayModal user={user} />, { closeable: false });
    }
  };

  render() {
    return null;
  }
}

CurrentUserManager.contextTypes = {
  router: T.object.isRequired,
};

CurrentUserManager.propTypes = {
  user: T.object.isRequired,
  addNewActivity: T.func.isRequired,
  setPigsUnderCareData: T.func.isRequired,
  showTenantNotification: T.func.isRequired,
  openModal: T.func.isRequired,
  closeModal: T.func.isRequired,
  updateCurrentUser: T.func.isRequired,
  setUnreadNotificationsCount: T.func.isRequired,
  setIsSchedulePageOutdated: T.func.isRequired,
  increaseUnviewedAssetsCount: T.func.isRequired,
  mergeNewNotification: T.func.isRequired,
  unreadNotifications: T.number.isRequired,
};

export default connect(
  (state) => ({
    unreadNotifications: state.sidebar.counters?.unreadNotifications || 0,
  }),
  {
    openModal,
    closeModal,
    updateCurrentUser,
    addNewActivity,
    setPigsUnderCareData,
    setIsSchedulePageOutdated,
    showTenantNotification,
    setUnreadNotificationsCount,
    increaseUnviewedAssetsCount,
    mergeNewNotification,
  }
)(CurrentUserManager);
