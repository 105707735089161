import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Preloader from 'components/Preloader';
import ExtendedHeader from 'components/DailyCheckups/ExtendedHeader/ExtendedHeader';
// utils
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import isEmpty from 'lodash.isempty';
// styles
import './CheckupSegment.scss';

const CheckupSegment = ({
  className,
  isReported,
  itemsCount,
  titleKey,
  titleKeyWhenReported,
  isItemsCountHidden,
  reportPageUrl = null,
  reportBtnClassName,
  isButtonsHidden,
  onEmptyDataSubmit,
  children,
  withoutBottomBorder,
  itemsCountPlaceholder = 0,
  noData,
  isSegmentConditionChanged,
  isDisabled = false,
  isSubmitting = false,
  extendedHeaderData = {},
  title,
  warningText,
  onEdit,
  onDataSubmit,
}, { router }) => {
  const isMobileEmptySegment = isMobile && isReported && !itemsCount;
  const messageId = isReported && titleKeyWhenReported ? titleKeyWhenReported : titleKey;

  return (
    <div
      className={cn(className, 'checkup-segment', {
        'reported': isReported,
        'is-disabled': isDisabled,
        'without-bottom-border': withoutBottomBorder,
        'no-data': noData,
        'has-warning': !!warningText
      })}
    >
      <Preloader className="segment-preloader" isActive={isSubmitting} />
      {!isEmpty(extendedHeaderData) && <ExtendedHeader headerData={extendedHeaderData} />}
      <div className={cn('checkup-segment-content', { 'mobile-empty-segment': isMobileEmptySegment })}>
        {isReported && (
          <div
            className={cn('segment-icon-column', { 'animated fadeInLeft': isReported && isSegmentConditionChanged })}
          >
            <i className={cn('fa fa-check-circle-bts', 'status-icon')} />
          </div>
        )}

        <div className={cn('segment-info-column', { isReported })}>
          <div className={cn('segment-title', { isMobile })}>
            {title || <FormattedMessage id={messageId} />}
            {!isItemsCountHidden && isReported &&
              <span className="value"> • {itemsCount || itemsCountPlaceholder}</span>
            }
          </div>
          {warningText && (
            <div className="warning-text hide-for-large">
              <i className="fa fa-exclamation-triangle-bts warning-icon" />
              {warningText}
            </div>
          )}
        </div>
        {!isButtonsHidden && (
          <div className="segment-buttons-column">
            {!isReported && (
              <Button light className="action-button" onClick={onEmptyDataSubmit} disabled={isSubmitting}>
                <FormattedMessage id="general.button.no" />
              </Button>
            )}
            {!isReported &&  (
              <Button
                light
                disabled={isSubmitting}
                onClick={onDataSubmit || (() => router.push(reportPageUrl))}
                className={cn('action-button', reportBtnClassName)}
              >
                <FormattedMessage id="general.button.yes" />
              </Button>
            )}
            {isReported && (
              <Button
                light
                onClick={onEdit || (() => router.push(reportPageUrl))}
                to={reportPageUrl}
                className={cn({
                  'mobile-edit-btn': isMobile,
                  'animated fadeInLeft': isSegmentConditionChanged
                })}
              >
                <FormattedMessage id="general.button.edit" />
              </Button>
            )}
          </div>
        )}
      </div>
      {warningText && (
        <div className="warning-segment">
          <i className="fa fa-exclamation-triangle-bts warning-icon" />
          {warningText}
        </div>
      )}
      {isReported && children}
    </div>
  );
};

CheckupSegment.contextTypes = {
  router: T.object,
};

CheckupSegment.propTypes = {
  className: T.string,
  itemsCount: T.number,
  titleKey: T.string,
  title: T.string,
  warningText: T.oneOfType([T.string, T.node]),
  titleKeyWhenReported: T.string,
  isItemsCountHidden: T.bool,
  reportPageUrl: T.string,
  reportBtnClassName: T.string,
  isButtonsHidden: T.bool,
  isReported: T.bool.isRequired,
  children: T.node,
  onEmptyDataSubmit: T.func,
  withoutBottomBorder: T.bool,
  itemsCountPlaceholder: T.oneOfType([T.string, T.number, T.node]),
  noData: T.bool,
  onDataSubmit: T.func,
  onEdit: T.func,
  isSegmentConditionChanged: T.bool,
  isSubmitting: T.bool,
  isDisabled: T.bool,
  extendedHeaderData: T.shape({
    isError: T.bool,
    text: T.oneOfType([T.string, T.object]),
  }),
};

export default CheckupSegment;
