import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import ProgressBar from './components/ProgressBar';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import MobileCategoryQuestions from './components/MobileCategoryQuestions';
import CategoryStepper from './components/CategoryStepper';
import SVRHeader from './components/SVRHeader';
import Preloader from 'components/Preloader';
import AdditionalSection from './components/AdditionalSection';
// utils
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { animateScroll } from 'react-scroll';
import { ADDITIONAL_CATEGORY_KEY, isSVRCategoriesEmpty, isAdditionalCategoryEmpty } from 'utils/SVRHelper';
// styles
import styles from './SiteVisitReport.module.scss';

const cn = classnames.bind(styles);

const SiteVisitReportMobileEdit = ({ report, farm, isLoading, location, router, onSave, onClose, onPreview }) => {
  const { push } = router;
  const { query, pathname } = location;
  const { isDraft, createdAt, categories, additionalCategory } = report;
  const isSVREmpty = isSVRCategoriesEmpty(categories) && isAdditionalCategoryEmpty(additionalCategory);

  const onStepChange = (step) => push({ pathname, query: { ...query, currentStep: step } });

  const toggleStepChange = (idx) => () => {
    onStepChange(Math.max(0, idx));
    animateScroll.scrollToTop({ containerId: 'svr-content' });
  };

  const onSubmit = () => {
    if (isDraft) return onPreview();
    return onSave();
  };

  const allCategories = additionalCategory ? [...categories, additionalCategory] : categories;
  const currentStep = query?.currentStep;
  const categoryStep = +(currentStep || 0);
  const currentCategory = allCategories[categoryStep];
  const allQuestionsCount = currentCategory.svr_questions.length;
  const answeredQuestionsCount = currentCategory.svr_questions.filter((question) => !!question.answer?.value).length;

  const isFinalStep = categoryStep === allCategories.length - 1;
  const isNextStepAvailable = allQuestionsCount === answeredQuestionsCount || isFinalStep;

  return (
    <div className={cn('wrapper')}>
      <Preloader isCentered isActive={isLoading} />
      <SVRHeader farmId={farm.id} createdAt={createdAt} onClose={isSVREmpty ? onClose : onSave} farmName={farm.name}>
        <ProgressBar questionsData={categories} />
        <Button onClick={isSVREmpty ? onClose : onSave} className={cn('save-close-btn')}>
          <FormattedMessage id="general.button.saveAndClose" />
        </Button>
      </SVRHeader>
      <div className={cn('svr-content')} id="svr-content">
        <div className={cn('content-wrapper')}>
          <div className={cn('content')}>
            {categoryStep <= categories.length - 1 && (
              <MobileCategoryQuestions categoryIndex={categoryStep} currentCategory={categories[categoryStep]} />
            )}
            {isFinalStep && <AdditionalSection />}
          </div>
        </div>
      </div>
      <div className={cn('svr-footer')}>
        <div className={cn('category-info')}>
          {currentCategory.item_key === ADDITIONAL_CATEGORY_KEY ? (
            <FormattedMessage id="general.notes" tagName="b" />
          ) : (
            <Fragment>
              <span className="semibold mr-5">{currentCategory.name}</span>
              <span>{answeredQuestionsCount}/{allQuestionsCount}</span>
            </Fragment>
          )}
        </div>
        <div className={cn('actions')}>
          <Button
            onClick={toggleStepChange(categoryStep - 1)}
            disabled={categoryStep === 0}
            className={cn('btn', 'back')}
          >
            <i className="mr-10 fa fa-angle-left-btb" />
            <FormattedMessage id="general.button.back" />
          </Button>
          <CategoryStepper categories={categories} />
          <Button
            className={cn('btn')}
            primary
            disabled={!isNextStepAvailable}
            onClick={isFinalStep ? onSubmit : toggleStepChange(categoryStep + 1)}
          >
            {isFinalStep && !isDraft ? (
              <FormattedMessage id="general.button.save" />
            ) : (
              <Fragment>
                <FormattedMessage id="general.button.next" />
                <i className="ml-10 fa fa-angle-right-btb" />
              </Fragment>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

SiteVisitReportMobileEdit.propTypes = {
  report: T.object.isRequired,
  isLoading: T.bool.isRequired,
  farm: T.object.isRequired,
  location: T.object.isRequired,
  router: T.object.isRequired,
  onSave: T.func.isRequired,
  onPreview: T.func.isRequired,
  onClose: T.func.isRequired,
};

export default connect((state) => ({
  farm: state.siteVisitReport.farm,
  report: state.siteVisitReport.report,
  isLoading: state.siteVisitReport.isLoading,
}))(SiteVisitReportMobileEdit);
