import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { updateUserSetting, fetchUserSetting } from 'reducers/auth';
// components
import { FormattedMessage } from 'react-intl';
import ToggleSwitcher from 'components/ToggleSwitcher';
import ReactSelect from 'components/Select/ReactSelect';
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import PreferenceItem from './components/PreferenceItem';
import Link from 'react-router/lib/Link';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { langOptions } from 'utils/localizationHelper';
import { setLSLanguage } from 'utils/localStorageHelper';
// styles
import './MyProfilePreferences.scss';

const MyProfilePreferences = ({
  fetchUserSetting,
  isLoaded,
  isLoading,
  user,
  userSetting,
  updateUserSetting,
}) => {
  const { id: currentUserId, language: currentUserLanguage, birthday_date } = user;
  const { enable_ff_check_ins = false, language: settingLanguage, birthday_congrats = false } = userSetting;

  useEffect(() => {
    if (!isLoaded) {
      fetchUserSetting(user.id).catch(toastResponseErrors);
    }
  }, []);

  const handleChangeLanguage = ({ value }) => {
    if (currentUserLanguage === value) return;
    updateUserSetting({ language: value }, currentUserId)
      .then((response) => {
        setLSLanguage(response.value.language);
      })
      .catch(toastResponseErrors);
  };

  const handleEnableSetting = (e, settingName) => {
    const { checked } = e.target;
    if (userSetting[settingName] === checked) return;
    updateUserSetting({ [settingName]: checked }, currentUserId).catch(toastResponseErrors);
  };

  return (
    <Panel>
      <Panel.Heading title={<FormattedMessage id="general.preferences" />} />
      <Panel.Body noPadding>
        <div className="preferences-body">
          <Preloader isActive={isLoading} />
          <PreferenceItem title={<FormattedMessage id="container.myProfile.preferences.lang" tagName="strong" />}>
            <ReactSelect
              className="lang-select"
              options={langOptions}
              value={settingLanguage}
              labelKey="label"
              onChange={handleChangeLanguage}
              matchProp="value"
              searchable={false}
            />
          </PreferenceItem>
          <PreferenceItem title={<FormattedMessage id="container.myProfile.personal" tagName="strong" />}>
            {birthday_date
              ? (
                <ToggleSwitcher
                  checked={birthday_congrats}
                  onChange={(e) => handleEnableSetting(e, 'birthday_congrats')}
                  text={<FormattedMessage id="container.myProfile.birthdayReminders" />}
                  description={<FormattedMessage id="container.myProfile.birthdayRemindersDesc" />}
                />
              )
              : (
                <div className="unavailable-reminder">
                  <div>
                    <FormattedMessage tagName="b" id="container.myProfile.birthdayReminders" />:&nbsp;
                    <span className="unavailable-caption"><FormattedMessage id="general.unavailable" /></span>
                  </div>
                  <div className="info-link">
                    <FormattedMessage id="container.myProfile.mustHaveBirthday" />&nbsp;
                    <Link to="/profile/edit">
                      <FormattedMessage id="component.userProfile.manageBirthdate" />
                    </Link>
                  </div>
                </div>
              )}
          </PreferenceItem>
          <PreferenceItem title={<FormattedMessage id="general.pageTitle.farmfeed" tagName="strong" />}>
            <ToggleSwitcher
              checked={enable_ff_check_ins}
              onChange={(e) => handleEnableSetting(e, 'enable_ff_check_ins')}
              text={<FormattedMessage id="container.myProfile.farmfeed.checkins" />}
              description={<FormattedMessage id="container.myProfile.farmfeed.checkins.description" />}
            />
          </PreferenceItem>
        </div>
      </Panel.Body>
    </Panel>
  );
};

MyProfilePreferences.propTypes = {
  updateUserSetting: T.func.isRequired,
  fetchUserSetting: T.func.isRequired,
  user: T.object.isRequired,
  userSetting: T.object.isRequired,
  isLoading: T.bool.isRequired,
  isLoaded: T.bool.isRequired,
};

export default connect(
  (state) => ({
    user: state.auth.user,
    userSetting: state.auth.user_setting,
    isLoading: state.auth.isSettingLoading,
    isLoaded: state.auth.isSettingLoaded,
  }),
  { updateUserSetting, fetchUserSetting }
)(MyProfilePreferences);
