import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
// styles
import './InventoryConflictModal.scss';

const InventoryConflictModal = ({ handleConfirm }) => (
  <ConfirmationModal
    className="treatment-conflict-modal"
    title={<FormattedMessage id="component.modal.inventoryConflict.title" />}
    warningMessage={<FormattedMessage id="component.alertBox.inventoryConflict" />}
    actionBtnLabel={<FormattedMessage id="general.button.continue" />}
    actionBtnProps={{ primary: true }}
    handleConfirm={handleConfirm}
  >
    <div className="treatment-modal-body">
      <FormattedMessage id="component.alertBox.inventoryConflict.text" />
      <FormattedMessage id="component.alertBox.inventoryConflict.additionalText" tagName="strong" />
    </div>
  </ConfirmationModal>
);

InventoryConflictModal.propTypes = {
  handleConfirm: T.func.isRequired,
};

export default InventoryConflictModal;
