import React, { useState, useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { resetData, fetchActiveSiteVisitReports } from 'reducers/svr/svrs';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import DraftReportsList from './components/DraftReportsList';
import SiteVisitReportsList from 'components/SiteVisitReports/SiteVisitReportsList';
// hooks
import useDataTableController from 'hooks/useDataTableController';
// endpoints
import { fetchFarm } from 'endpoints/farms';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { getIDBSVRFarm } from 'utils/offlineSVRHelper';
import { getUserAccessToFarmSVR } from 'utils/SVRHelper';

const FarmSiteVisitReports = ({
  activeMeta,
  activeParams,
  activeReports,
  currentUser,
  fetchActiveSiteVisitReports,
  isActiveReportsLoading,
  isOnline,
  params: { farmId },
  reqParams,
  resetData,
  router,
  setBreadcrumbs,
}) => {
  const [canEdit, setCanEdit] = useState(false);
  const { onPageChange, onPerPageChange } = useDataTableController(
    (params) => fetchActiveSiteVisitReports(farmId, params),
    reqParams
  );

  useEffect(() => {
    const errorCallback = (errors) => {
      toastResponseErrors(errors);
      router.push('/svr-farms');
    };

    (isOnline ? fetchFarm : getIDBSVRFarm)(+farmId)
      .then((farm) => {
        setBreadcrumbs([
          { label: <FormattedMessage id="general.pageTitle.siteVisitReports" />, path: '/svr-farms' },
          { label: farm.name, path: `/svr-farms/${farm.id}`, useLabelAsMobileTitle: true },
        ]);
        const { canEdit } = getUserAccessToFarmSVR(currentUser, farmId, farm.manager_id);
        if (canEdit) setCanEdit(canEdit);
      })
      .catch(errorCallback);
    fetchActiveSiteVisitReports(farmId, { ...reqParams, page: 1 }).catch(errorCallback);
  }, [isOnline, farmId]);

  useEffect(() => {
    return resetData;
  }, []);

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: activeMeta.total,
    currentPage: activeParams.page,
    perPage: activeParams.per_page,
  };

  return (
    <div className="small-12 column pb-25">
      {canEdit && <DraftReportsList farmId={farmId} isOnline={isOnline} />}
      <SiteVisitReportsList
        className={canEdit ? 'mt-30' : 'mt-20'}
        isLoading={isActiveReportsLoading}
        isReportsEditable={canEdit}
        resources={activeReports}
        paginationProps={paginationProps}
      />
    </div>
  );
};

FarmSiteVisitReports.propTypes = {
  currentUser: T.object.isRequired,
  setBreadcrumbs: T.func.isRequired,
  router: T.object.isRequired,
  params: T.object.isRequired,
  reqParams: T.object.isRequired,
  activeParams: T.object.isRequired,
  activeMeta: T.object.isRequired,
  isOnline: T.bool.isRequired,
  resetData: T.func.isRequired,
  fetchActiveSiteVisitReports: T.func.isRequired,
  activeReports: T.array.isRequired,
  isActiveReportsLoading: T.bool.isRequired,
};

export default connect(
  (state) => ({
    activeReports: state.svr.siteVisitReports.active.resources,
    currentUser: state.auth.user,
    activeResources: state.svr.siteVisitReports.active.resources,
    activeParams: state.svr.siteVisitReports.active.params,
    activeMeta: state.svr.siteVisitReports.active.meta,
    isActiveReportsLoading: state.svr.siteVisitReports.active.isLoading,
    isOnline: state.network.isOnline,
    resources: state.svr.siteVisitReports.active.resources,
    reqParams: state.svr.siteVisitReports.active.params,
    meta: state.svr.siteVisitReports.active.meta,
  }), {
    resetData,
    setBreadcrumbs,
    fetchActiveSiteVisitReports,
  },
)(FarmSiteVisitReports);
