import React, { useEffect } from 'react';
import T from 'prop-types';
// components
import Panel from 'components/Panel';
import { FormattedMessage } from 'react-intl';
import Divider from 'components/Divider';
// sections
import FarmfeedEventsSection from './sections/FarmfeedEventsSection';
import GeneralSection from './sections/GeneralSection';
import CategoriesSection from './sections/CategoriesSection';
import AdditionalSection from './sections/AdditionalSection';
// utils
import { connect } from 'react-redux';

const SiteVisitReports = ({ isSVRFeatureEnabled, isSVREnabled, router }) => {

  useEffect(() => {
    if (!isSVRFeatureEnabled) {
      router.push('/admin/preferences');
    }
  }, []);

  return (
    <Panel className="site-visit-reports">
      <Panel.Heading title={<FormattedMessage id="general.siteVisitReports" />} />
      <Panel.Body className="ph-15">
        <GeneralSection />
        <Divider className="mt-20 mb-20" />
        <FarmfeedEventsSection />
        {isSVREnabled && (
          <>
            <Divider className="mt-20 mb-20" />
            <CategoriesSection />
            <AdditionalSection />
          </>
        )}
      </Panel.Body>
    </Panel>
  );
};

SiteVisitReports.propTypes = {
  isSVRFeatureEnabled: T.bool.isRequired,
  isSVREnabled: T.bool.isRequired,
  router: T.object.isRequired,
};

export default connect((state) => ({
  isSVREnabled: state.auth.user.current_company.svrs,
  isSVRFeatureEnabled: state.auth.user.current_company.admin_svrs,
}))(SiteVisitReports);
