import { mapProps } from 'recompose';
// utils
import { getDeathsCount } from 'utils';
import omit from 'lodash.omit';
import get from 'lodash.get';
// constants
import { comment_roles } from 'constants.js';
// helpers
import { getCommentByRole } from 'helpers.js';

const roles_arr = Object.values(comment_roles);
const comment_keys = roles_arr.map((i) => (`comments_${i}`));

function getCorrectedCheckup(checkup) {
  const { correction } = checkup;
  const clean_corrections = omit(correction, comment_keys);
  const corrected = { ...checkup, ...clean_corrections };
  const {
    dc_symptoms,
    dc_treatments,
    acute_death_count,
    chronic_death_count,
    euthanasia_death_count,
    comments
  } = corrected;

  // corrected comments
  const new_comments = roles_arr.map((c_role) => {
    const comment_key = `comments_${c_role}`;
    const { text, comment: old_comment, ...rest } = get(correction, comment_key)
      || getCommentByRole(comments, c_role);
    const comment = text || old_comment;

    if (!comment) return undefined;

    return ({
      ...rest,
      comment,
      role: c_role,
    });
  });

  corrected.comments = new_comments.filter((i) => !!i);

  // deaths sum
  corrected.deaths_sum = getDeathsCount({ chronic_death_count, acute_death_count, euthanasia_death_count });

  // remove deleted symptoms
  if (dc_symptoms) {
    corrected.dc_symptoms = dc_symptoms.filter((symptom) => !symptom._destroy);
  }

  // remove deleted treatments
  if (dc_treatments) {
    corrected.dc_treatments = dc_treatments.filter((treatment) => !treatment._destroy);
  }

  return corrected;
}

// withCorrection - handle checkup corrections in edit mode if it has them
export default mapProps(
  ({ checkup, checkup: { correction }, isEditMode, ...otherProps }) => ({
    checkup: isEditMode && correction ? getCorrectedCheckup(checkup) : checkup, isEditMode, ...otherProps,
  })
);
