import { useState, useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';
// api
import { createUserFilter, updateUserFilter } from 'endpoints/userFilters';
// utils
import { toastResponseErrors } from 'utils/responseErrorsHelper';

const defaultParams = {
  page: 1,
  per_page: 25,
  search: '',
  sort: '',
  status: '',
};

const initialState = {
  resources: [],
  isLoading: false,
  meta: {
    total: 0,
    stats: {},
  },
};

export const tableParamsSelector = createSelector(
  (state, tableName) => state.tableParams[tableName],
  (params) => params || defaultParams,
);

export default function useDataTable(fetcher, options = {}) {
  const [state, setState] = useState(initialState);
  const { callback, tableParams, setTableParams, filterFetcher, userFilterGroupName } = options;

  const handleFetchData = (params) => {
    return (params.filters && filterFetcher ? filterFetcher : fetcher)(params);
  };

  const fetchData = useCallback(
    (reqParams) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      return handleFetchData({ ...reqParams, search: encodeURIComponent(reqParams?.search || '') })
        .then((response) => {
          setState((prevState) => ({ ...prevState, ...response, isLoading: false }));
          setTableParams(reqParams);
          callback?.(response);
        })
        .catch((errors) => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
          toastResponseErrors(errors);
        });
    },
    []
  );

  const setDataByIndex = useCallback(
    (updatedResourceIndex, updatedResource) => {
      setState((prevState) => ({
        ...prevState,
        resources: prevState.resources.map((resource, index) => (updatedResourceIndex === index ?
          updatedResource :
          resource))
      }));
    },
    [],
  );

  const tableActions = useMemo(() => ({
    onSearchChange: (search) => fetchData({ ...tableParams, page: 1, search }),
    onSortChange: (sort) => fetchData({ ...tableParams, page: 1, sort }),
    onPageChange: (page) => fetchData({ ...tableParams, page }),
    onPerPageChange: (per_page) => fetchData({ ...tableParams, page: 1, per_page }),
    onStatusChange: (status) => fetchData({ ...tableParams, page: 1, status }),
    setDataByIndex,
  }), [fetchData, tableParams]);

  const onUserFilterSectionsChange = useCallback(
    (filter) => {
      fetchData({ ...tableParams, page: 1, filters: filter.sections?.length ? [filter] : undefined });
    },
    [tableParams]
  );

  const onUserFilterSegmentSave = useCallback(
    (filter, isUpdating) => {
      if (isUpdating) {
        return updateUserFilter(tableParams.filters?.[0].id, filter)
          .catch((errors) => {
            toastResponseErrors(errors);
            throw errors;
          });
      }

      return createUserFilter({ ...filter, group_name: userFilterGroupName })
        .catch((errors) => {
          toastResponseErrors(errors);
          throw errors;
        });
    },
    [userFilterGroupName, tableParams]
  );

  return { data: state, fetchData, onUserFilterSectionsChange, onUserFilterSegmentSave, ...tableActions };
}
