import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import SubNavigation from 'components/Subnavigation';
import OfflineContainer from 'components/OfflineScreen/OfflineContainer';
// styles
import './Admin.scss';
import './FlexDataTable.scss';

const Admin = ({ children, company, location, setBreadcrumbs, setPageOptions }) => {
  const links = [
    { link: '/admin/users', label: <FormattedMessage id="general.users" /> },
    { link: '/admin/farms', label: <FormattedMessage id="general.farms" /> },
    { link: '/admin/companies', label: <FormattedMessage id="general.companies" />,
      activePaths: ['/admin/companies', '/admin/trucking-companies', '/admin/packing-companies'] },
    { link: '/admin/shareholder-groups', label: <FormattedMessage id="general.shareholderGroups" />,
      isHidden: !company.shareholder_group_on },
    { link: '/admin/my-company', label: <FormattedMessage id="general.myCompany" /> },
    { link: '/admin/health-variables', label: <FormattedMessage id="general.healthVariables" /> },
    { link: '/admin/preferences', label: <FormattedMessage id="general.preferences" /> },
  ];

  useEffect(() => {
    setPageOptions({
      links,
      backLink: '',
      pinHeader: false,
    });
    setBreadcrumbs();
    return () => {
      setPageOptions({ links: null });
    };
  }, []);

  return (
    <div className="AdminPanel">
      <SubNavigation pathname={location.pathname} links={links} className="small-12 column hide-for-large" />
      <OfflineContainer>
        {children}
      </OfflineContainer>
    </div>
  );
};

Admin.propTypes = {
  children: T.node.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  company: T.object.isRequired,
  location: T.object.isRequired,
};

export default connect(
  (state) => ({
    company: state.auth.user.current_company,
  }),
  { setPageOptions, setBreadcrumbs }
)(Admin);
