import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCurrentCheckup } from 'reducers/barnsheets/barnsheetsCheckup';
import { setCurrentGroup, enableEditMode } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
import { setCheckup as setEditModeCheckup } from 'reducers/dailyCheckup/checkupEdit';
import { openModal, TYPE_PANEL } from 'reducers/modals';
import { openPortal } from 'reducers/portal';
import { updateCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import StickyFooter from 'components/StickyFooter';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import BarnsheetsDailyCheckupComponent from 'components/Barnsheets/BarnsheetsDailyCheckup';
import Preloader from 'components/Preloader';
import MobileDiseasesSelector from 'components/MobileDiseasesSelector/MobileDiseasesSelector';
import ModalSidebar from 'components/Modal/ModalSidebar';
import DiagnosisSidebar from 'components/DiagnosisSidebar/DiagnosisSidebar';
import FormattedWeight from 'components/FormattedWeight';
// endpoints
import { downloadDailyCheckupCSV } from 'endpoints/dailyCheckups';
// utils
import download from 'downloadjs';
import { isMobile } from 'react-device-detect';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { formatMortality, isUserCanDiagnose, showToastrMessage } from 'utils';
// styles
import './BarnsheetsDailyCheckup.scss';

class BarnsheetsDailyCheckup extends Component {

  componentDidMount() {
    const { params: { id }, location: { query }, fetchCurrentCheckup, setCurrentGroup,
      setEditModeCheckup } = this.props;
    const isEditMode = query.editMode === 'true';
    fetchCurrentCheckup(id, isEditMode)
      .then(({ value: { resource } }) => {
        this.setupPage(resource);
        setCurrentGroup(resource.pig_group);
        if (isEditMode) {
          setEditModeCheckup(resource);
        }
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pigGroup, setPageOptions, location: { pathname }, params: { id }, fetchCurrentCheckup } = this.props;
    const { router } = this.context;
    // update header's editMode on edit mode toggle
    const currInEditMode = pigGroup.in_edit_mode || false;
    const nextInEditMode = nextProps.pigGroup.in_edit_mode || false;
    if (currInEditMode !== nextInEditMode) {
      setPageOptions({ inEditMode: nextProps.pigGroup.in_edit_mode });
      router.push({ pathname, query: { editMode: nextProps.pigGroup.in_edit_mode || undefined } });
    }
    // refetch checkup on param ID change (checkup navigation buttons)
    if (nextProps.params.id !== id) {
      fetchCurrentCheckup(nextProps.params.id);
    }
  }

  componentWillUnmount() {
    const { setPageOptions } = this.props;
    setPageOptions({ backLink: '' });
  }

  setupPage = (resource) => {
    const { setPageOptions, setBreadcrumbs } = this.props;
    const { pig_group: { farm, name, id, in_edit_mode } } = resource;
    setPageOptions({
      backLink: this.getBackLink(resource),
      inEditMode: in_edit_mode,
    });
    setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.barnsheets" />, path: '/barnsheets' },
      { label: farm.name, path: `/barnsheets/farms/${farm.id}` },
      { label: name, path: `/barnsheets/groups/${id}`, useLabelAsMobileTitle: true },
    ]);
  };

  getBackLink = (checkup) => {
    const { location: { query }, pigGroup } = this.props;
    const isEditMode = pigGroup.in_edit_mode && query.editMode === 'true';
    return `/barnsheets/groups/${checkup.pig_group_id}` + (isEditMode ? '?editMode=true' : '');
  };

  downloadCSV = () => {
    const { params: { id }, checkup } = this.props;
    const filename = `daily-checkup-${checkup.updated_at}.csv`;
    return downloadDailyCheckupCSV(id)
      .then((blob) => download(blob, filename, 'text/csv'))
      .catch((response) => toastResponseErrors(response));
  };

  turnOnEditMode = () => {
    const { enableEditMode, checkup } = this.props;
    const { router } = this.context;
    enableEditMode(checkup.pig_group_id)
      .then(() => router.push(`/barnsheets/daily-checkup/${checkup.id}?editMode=true`));
  };

  saveAndReview = () => {
    const { checkup } = this.props;
    const { router } = this.context;
    router.push(`/barnsheets/groups/${checkup.pig_group_id}?editMode=true`);
  };

  onSidebarDataSubmit = (checkupId, data) => {
    const { updateCheckupDiagnoses } = this.props;
    return updateCheckupDiagnoses(checkupId, data)
      .then(() => {
        showToastrMessage('component.toastr.diagnoses.successUpdated');
      })
      .catch(toastResponseErrors);
  };

  openSidebar = ({ id }) => () => {
    const { openModal, openPortal, pigGroup } = this.props;

    if (isMobile) {
      openPortal(
        <MobileDiseasesSelector
          checkupId={id}
          pigGroup={pigGroup}
          onSubmitData={(data) => this.onSidebarDataSubmit(id, data)}
        />
      );
    } else {
      openModal(
        <ModalSidebar>
          <DiagnosisSidebar
            checkupId={id}
            pigGroup={pigGroup}
            onSubmitData={(data) => this.onSidebarDataSubmit(id, data)}
          />
        </ModalSidebar>,
        {},
        TYPE_PANEL
      );
    }
  };

  render() {
    const { checkup, enableEditMode, isLoading, location: { query }, pigGroup, user } = this.props;
    const isAdmin = user.roles_map.admin;
    const isEditMode = pigGroup.in_edit_mode && query.editMode === 'true';
    const groupStats = [
      { label: <FormattedMessage id="general.puc" />, value: pigGroup.pigs || 0 },
      { label: <FormattedMessage id="general.estAvgWeight" />,
        value: <FormattedWeight value={pigGroup.estimated_weight} unitFontSize="13px" /> },
      { label: <FormattedMessage id="general.mortalityRate" />, value: formatMortality(pigGroup.mortality_rate) },
    ];
    const dropdownOptions = [
      { label: <FormattedMessage id="general.button.downloadCSV" />, onClick: this.downloadCSV },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${pigGroup.id}`, hide: !isAdmin },
      { label: <FormattedMessage id="general.button.saveAndReview" />, onClick: this.saveAndReview, hide: !isEditMode },
      { label: <FormattedMessage id="general.button.edit" />, onClick: this.turnOnEditMode, hide: isEditMode },
      { label: <FormattedMessage id="general.editDiagnosis" />, onClick: this.openSidebar(checkup),
        hide: !isUserCanDiagnose(user, pigGroup.farm, pigGroup.farm?.manager_id) },
    ];

    return (
      <div className="BarnsheetsDailyCheckup">
        <GroupProfileHeader group={pigGroup} dropdownOptions={isEditMode ? [] : dropdownOptions}>
          {!isEditMode &&
            <ProfileHeaderStats stats={groupStats} />}
          {isEditMode && (
            <Button primary onClick={this.saveAndReview} className="show-for-large">
              <FormattedMessage id="general.button.saveAndReview" />
            </Button>
          )}
        </GroupProfileHeader>

        <div className="row centered">
          <div className="small-12 column">
            <Preloader isActive={isLoading} />
            <section>
              {checkup.id && (
                <BarnsheetsDailyCheckupComponent
                  fromPageUrl={query.from}
                  checkup={checkup}
                  isEditMode={isEditMode}
                  enableEditMode={() => enableEditMode(checkup.pig_group_id)}
                />
              )}
            </section>
          </div>
        </div>

        <StickyFooter className="hide-for-large" hidden={!isEditMode}>
          <Button primary onClick={this.saveAndReview} className="submit-btn">
            <FormattedMessage id="general.button.saveAndReview" />
          </Button>
        </StickyFooter>
      </div>
    );
  }
}

BarnsheetsDailyCheckup.contextTypes = {
  router: T.object,
};

BarnsheetsDailyCheckup.propTypes = {
  checkup: T.object,
  isLoading: T.bool,
  pigGroup: T.object,
  location: T.object.isRequired,
  fetchCurrentCheckup: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  setCurrentGroup: T.func.isRequired,
  setEditModeCheckup: T.func.isRequired,
  user: T.object.isRequired,
  enableEditMode: T.func,
  params: T.object,
  updateCheckupDiagnoses: T.func.isRequired,
  openModal: T.func.isRequired,
  openPortal: T.func.isRequired,
};

export default connect(
  (state) => ({
    checkup: state.barnsheets.checkup.resource,
    isLoading: state.barnsheets.checkup.isLoading,
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
    user: state.auth.user,
  }), {
    fetchCurrentCheckup,
    setCurrentGroup,
    setPageOptions,
    setBreadcrumbs,
    enableEditMode,
    setEditModeCheckup,
    openModal,
    openPortal,
    updateCheckupDiagnoses,
  }
)(BarnsheetsDailyCheckup);
