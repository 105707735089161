import React from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import ResponsiveSelect from 'components/ResponsiveSelect/ResponsiveSelect';
import Button from 'components/Button';
import Input from 'components/Input';
import AttentionBox from 'components/AttentionBox/AttentionBox';
import Panel from 'components/Panel';
import Link from 'react-router/lib/Link';
import { FormattedMessage } from 'react-intl';
import TenantSettingTogglePanel from 'components/TenantSettingTogglePanel/TenantSettingTogglePanel';
import ToggleSwitcher from 'components/ToggleSwitcher';
import FormattedWeight from 'components/FormattedWeight';
import DefaultMapCenterSection from './DefaultMapCenterSection';
import AsyncMultiSelect from 'components/AsyncMultiSelect';
// settings API
import {
  setTreatmentProtocolsResponsibleContact,
  removeTreatmentProtocolsResponsibleContact,
} from 'endpoints/tenantSettings';
// utils, constants
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { formatWeightToBackEndFormat } from 'utils/weightHelper';
import { inventoryReconciliationOptions, withdrawalWeightOptions, autoReportPeriodOptions } from 'constants.js';
// styles
import './Settings.scss';

const getOptLabel = (options, value) => (options.find((opt) => opt.value === value) || {}).label;

const filterOption = (selectedEntities) => () => (option) => {
  const isAlreadySelected = !!selectedEntities.find((entity) => entity.user.id === option.value);
  if (isAlreadySelected) return null;
  return option;
};

const labels = {
  panelTitle: <FormattedMessage id="general.settings" />,
  updateSuccess: <FormattedMessage id="general.settings.updateSuccess" />,
  save: <FormattedMessage id="general.button.save" />,
  sms: {
    text: <FormattedMessage id="component.settings.smsComplianceMessaging" />,
    description: <FormattedMessage id="component.settings.smsComplianceDescription" />,
  },
  inventory: {
    text: <FormattedMessage id="component.settings.invReconciliation" />,
    description: <FormattedMessage id="component.settings.invReconciliationDescription" />,
    reconciliationTimeFrame: <FormattedMessage id="component.settings.reconciliationTimeFrame" />,
    reconciliationInfo: (val) => (
      <FormattedMessage
        id="component.settings.invReconciliationInfoText"
        values={{
          weekDay: val,
          b: (msg) => <b>{msg}</b>
        }}
      />
    ),
  },
  treatmentProtocols: {
    text: <FormattedMessage id="general.treatmentProtocols" />,
    description: <FormattedMessage id="component.settings.treatmentProtocolsDescription" />,
    responsibleUsers: {
      text: <FormattedMessage id="general.responsibleUsers" />,
      description: <FormattedMessage id="component.settings.treatmentProtocolsResponsibleUsers.desc" />,
    }
  },
  emails: {
    text: <FormattedMessage id="general.pageTitle.autoReport" />,
    description: <FormattedMessage id="component.settings.autoReportDescription" />,
    autoReportTimeFrame: <FormattedMessage id="component.settings.autoReportTimeFrame" />
  },
  withdrawal: {
    text: <FormattedMessage id="component.settings.trackWithdrawalPeriods" />,
    description: <FormattedMessage id="component.settings.trackWithdrawalDescription" />,
    withdrawalWeightThreshold: <FormattedMessage id="component.settings.withdrawalWeightThreshold" />,
    info: (val) => (
      <FormattedMessage
        id="component.settings.trackWithdrawalInfoText"
        values={{ weight: <strong>{val}</strong>, measure: <FormattedWeight hasOnlyUnit /> }}
      />
    ),
  },
  customSurvey: {
    text: <FormattedMessage id="component.settings.customSurvey" />,
    description: <FormattedMessage id="component.settings.customSurveyDesc" />,
  },
  mortality: {
    text: <FormattedMessage id="component.settings.trackMortalityReasons" />,
    description: <FormattedMessage id="component.settings.mortalityReasonsDescription" />,
    manage: <FormattedMessage id="general.manageMortalityReasons" />,
  },
  comments: {
    text: <FormattedMessage id="component.settings.commentCompanyDirectPost" />,
    description: <FormattedMessage id="component.settings.commentCompanyDirectPostDescription" />,
  },
};

const optionsMeasures = [
  { label: <FormattedMessage id="container.setPoints.system.usStandard" />, value: 'imperial' },
  { label: <FormattedMessage id="container.setPoints.system.metric" />, value: 'metric' },
];

const optionsTemps = [
  { label: <FormattedMessage id="general.fahrenheit" />, value: 'fahrenheit' },
  { label: <FormattedMessage id="general.celsius" />, value: 'celsius' },
];


const Settings = ({ tenant_settings, updateSettings, setSettings, isTreatmentProtocolsFeatureEnabled }) => {
  const setWeight = (e) => {
    e.persist();
    const reg = /^[0-9]{1,5}$/g;
    if (e.target.value === '' || reg.test(e.target.value)) {
      setSettings({
        new_withdrawal_weight_limit: Number(e.target.value)
      });
    }
  };

  const handleSelectChange = (key) => (value) => {
    updateSettings({ [key]: value });
  };

  const getSubmitResource = (key, value) => {
    if (key === 'inventory_reconciliation_active') {
      return { inventory_reconciliation: value ? tenant_settings.inventory_reconciliation : 'disabled' };
    }
    return { [key]: value };
  };

  const handleSettingChange = ({ target: { name, checked } }) => {
    const resource = getSubmitResource(name, checked);
    updateSettings(resource);
  };

  const renderToggleTitle = (labelKey, valueLabel = '') => (
    <div className="toggleTitle">
      <FormattedMessage
        tagName="b"
        id={`general.settings.${labelKey}`}
        values={{ label: <b className="primary">{valueLabel}</b> }}
      />
      <p className="toggleSub">
        <FormattedMessage
          id={`general.settings.${labelKey}Info`}
          values={{ label: valueLabel }}
        />
      </p>
    </div>
  );

  const {
    sms_compliance,
    inventory_reconciliation_active,
    inventory_reconciliation,
    auto_report_emails,
    auto_report_period,
    withdrawal_tracking_on,
    withdrawal_weight_limit,
    new_withdrawal_weight_limit,
    track_mortality_reasons,
    comment_company_direct_post,
    measurement_system,
    temperature_system,
    treatment_protocols,
    treatment_protocol_contacts,
  } = tenant_settings;

  const updateTreatmentProtocolsResponsibleContact = ({ value }) => {
    const { treatment_protocol_contacts } = tenant_settings;
    setTreatmentProtocolsResponsibleContact({ user_id: value }).then((resource) => {
      setSettings({ treatment_protocol_contacts: [...treatment_protocol_contacts, resource] });
    }).catch(toastResponseErrors);
  };

  const deleteTreatmentProtocolsResponsibleContact = ({ id }) => {
    const { treatment_protocol_contacts } = tenant_settings;
    const newArray = treatment_protocol_contacts.filter((user) => user.id !== id);
    removeTreatmentProtocolsResponsibleContact(id).then(() => {
      setSettings({ treatment_protocol_contacts: newArray });
    }).catch(toastResponseErrors);
  };

  return (
    <Panel>
      <Panel.Heading title={labels.panelTitle} />
      <Panel.Body>
        {/* <Preloader isActive={isLoading} /> */}
        <div>
          <section className="setting-section">
            <TenantSettingTogglePanel
              className="small-12 medium-8 pl-0"
              options={optionsMeasures}
              value={measurement_system}
              onChange={updateSettings}
              settingKey="measurement_system"
            >
              {renderToggleTitle('weights', getOptLabel(optionsMeasures, measurement_system))}
            </TenantSettingTogglePanel>
          </section>
          <section className="setting-section">
            <TenantSettingTogglePanel
              className="small-12 medium-8 pl-0"
              options={optionsTemps}
              value={temperature_system}
              onChange={updateSettings}
              settingKey="temperature_system"
            >
              {renderToggleTitle('temperatureScale', getOptLabel(optionsTemps, temperature_system))}
            </TenantSettingTogglePanel>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="sms_compliance"
              className="small-12 medium-8"
              text={labels.sms.text}
              description={labels.sms.description}
              checked={sms_compliance}
              onChange={handleSettingChange}
            />
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="inventory_reconciliation_active"
              className="small-12 medium-8"
              text={labels.inventory.text}
              description={labels.inventory.description}
              checked={inventory_reconciliation_active}
              onChange={handleSettingChange}
            >
              <h3 className="body-title">
                {labels.inventory.reconciliationTimeFrame}
              </h3>
              <ResponsiveSelect
                className="reconciliation-select"
                options={inventoryReconciliationOptions}
                onChange={handleSelectChange('inventory_reconciliation')}
                value={inventory_reconciliation}
                disabled={!inventory_reconciliation_active}
              />
              {inventory_reconciliation_active && (
                <AttentionBox className="info-box">
                  <span className="info-text">
                    {labels.inventory.reconciliationInfo(inventory_reconciliation)}
                  </span>
                </AttentionBox>
              )}
            </ToggleSwitcher>
          </section>

          {isTreatmentProtocolsFeatureEnabled && (
            <section className="setting-section">
              <ToggleSwitcher
                name="treatment_protocols"
                className="small-12 medium-8"
                text={labels.treatmentProtocols.text}
                description={labels.treatmentProtocols.description}
                checked={treatment_protocols}
                onChange={handleSettingChange}
              >
                <div>
                  <h3 className={cn('body-title', 'mb-0')}>{labels.treatmentProtocols.responsibleUsers.text}</h3>
                  <span className="description">{labels.treatmentProtocols.responsibleUsers.description}</span>
                </div>
                <AsyncMultiSelect
                  values={treatment_protocol_contacts}
                  scrollable
                  filterOption={filterOption(treatment_protocol_contacts)}
                  className={cn('settings-multi-select', { disabled: !treatment_protocols })}
                  selectClassName={cn('mt-10')}
                  onSelect={updateTreatmentProtocolsResponsibleContact}
                  optionsPath="/users/search?with_current_user=true"
                  onRemove={deleteTreatmentProtocolsResponsibleContact}
                  optionKeys={{ value: 'id', label: 'full_name' }}
                  placeholderKey="general.searchBy.users"
                  extractLabel={(value) => value.user.full_name}
                />
              </ToggleSwitcher>
            </section>
          )}

          <section className="setting-section">
            <ToggleSwitcher
              name="auto_report_emails"
              className="small-12 medium-8"
              text={labels.emails.text}
              description={labels.emails.description}
              checked={auto_report_emails}
              onChange={handleSettingChange}
            >
              <h3 className="body-title">
                {labels.emails.autoReportTimeFrame}
              </h3>
              <ResponsiveSelect
                className="report-select mr-15"
                options={autoReportPeriodOptions}
                onChange={handleSelectChange('auto_report_period')}
                value={auto_report_period}
                disabled={!auto_report_emails}
              />
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="withdrawal_tracking_on"
              className="small-12 medium-8"
              text={labels.withdrawal.text}
              description={labels.withdrawal.description}
              checked={withdrawal_tracking_on}
              onChange={handleSettingChange}
            >
              <h3 className="body-title">
                {labels.withdrawal.withdrawalWeightThreshold}
              </h3>
              <div className="withdrawal">
                <Input
                  type="text"
                  value={new_withdrawal_weight_limit}
                  onChange={setWeight}
                  disabled={!withdrawal_tracking_on}
                />

                <ResponsiveSelect
                  className="reconciliation-select"
                  options={withdrawalWeightOptions}
                  onChange={handleSelectChange('withdrawal_weight')}
                  value={measurement_system === 'imperial' ? 'lb' : 'kg'}
                  disabled
                />

                {withdrawal_tracking_on && (
                  <Button
                    primary
                    className="save-weight-btn"
                    onClick={() => updateSettings({
                      withdrawal_weight_limit: formatWeightToBackEndFormat(
                        new_withdrawal_weight_limit,
                        measurement_system
                      )
                    })}
                    disabled={(withdrawal_weight_limit === new_withdrawal_weight_limit) || false}
                  >
                    {labels.save}
                  </Button>
                )}
              </div>
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="track_mortality_reasons"
              className="small-12 medium-8"
              text={labels.mortality.text}
              description={labels.mortality.description}
              checked={track_mortality_reasons}
              onChange={handleSettingChange}
            >
              <Link to="/admin/health-variables/mortality-reasons" className="mt-15 mr-15 button light wider">
                {labels.mortality.manage}
              </Link>
            </ToggleSwitcher>
          </section>

          <section className="setting-section">
            <ToggleSwitcher
              name="comment_company_direct_post"
              className="small-12 medium-8"
              text={labels.comments.text}
              description={labels.comments.description}
              checked={comment_company_direct_post}
              onChange={handleSettingChange}
            />
          </section>

          <DefaultMapCenterSection settings={tenant_settings} onUpdate={updateSettings} />
        </div>
      </Panel.Body>
    </Panel>
  );
};

Settings.propTypes = {
  tenant_settings: T.object.isRequired,
  updateSettings: T.func.isRequired,
  setSettings: T.func.isRequired,
  isTreatmentProtocolsFeatureEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    isTreatmentProtocolsFeatureEnabled: state.auth.user.current_company.admin_treatment_protocols
  })
)(Settings);
