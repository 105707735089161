import React, { Component } from 'react';
import T from 'prop-types';
import Pagination from '../Pagination/Pagination';
import PaginationSelect from '../Pagination/PaginationSelect';
import './PaginationWrapper.scss';

class PaginationWrapper extends Component {

  onPrev = () => {
    const { currentPage, onPageChange } = this.props;
    onPageChange(currentPage - 1);
  };

  onNext = () => {
    const { currentPage, onPageChange } = this.props;
    onPageChange(currentPage + 1);
  };

  renderPagintaion = () => {
    const { totalItems, currentPage, perPage } = this.props;
    const pagesCount = Math.ceil(totalItems / perPage);

    return (
      <Pagination
        count={pagesCount}
        current={currentPage}
        onPrev={this.onPrev}
        onNext={this.onNext}
        totalItems={totalItems}
        perPage={perPage}
      />
    );
  };

  withPagination = () => {
    const { onlyBottom, onPerPageChange, perPage, children } = this.props;

    return (
      <div className="table-pagination-wrapper margin-bottom-1 clearfix">
        {!onlyBottom && (
          <div className="float-right pagination-container mb-10">
            {this.renderPagintaion()}
          </div>
        )}

        <div className="pagination-data">
          {children}
        </div>

        <div className="float-left">
          <div className="dataTables_length">
            <PaginationSelect current={perPage} onChange={onPerPageChange} />
          </div>
        </div>
        <div className="float-right">
          {this.renderPagintaion()}
        </div>
      </div>
    );
  };

  withoutPagination = () => (
    <div className="table-pagination-wrapper margin-bottom-1 clearfix">{this.props.children}</div>
  );

  render() {
    return this.props.totalItems
      ? this.withPagination()
      : this.withoutPagination();
  }
}

PaginationWrapper.propTypes = {
  totalItems: T.number,
  currentPage: T.number,
  onPageChange: T.func.isRequired,
  perPage: T.number,
  onPerPageChange: T.func.isRequired,
  onlyBottom: T.bool,
  children: T.oneOfType([T.arrayOf(T.node), T.node]),
};

PaginationWrapper.defaultProps = {
  currentPage: 1,
  perPage: 10,
  totalItems: 0,
};

export default PaginationWrapper;
