import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { openViewer } from 'reducers/mediaViewer';
import { uploadAssetComplete, deleteAsset } from 'reducers/dailyCheckup/checkupEdit';
import withCorrection from './utils/withCorrectionHOC';
import { setPageOptions } from 'reducers/layout';
// components
import { FormattedMessage } from 'react-intl';
import Link from 'react-router/lib/Link';
import Button from 'components/Button';
import CenterBox from 'components/CenterBox';
import MediaUploader from 'components/MediaUploader';
import StickyFooter from 'components/StickyFooter/StickyFooter';

const DailyCheckupMedia = ({
  backLink,
  checkup,
  deleteAsset,
  isEditMode,
  isLoading,
  openViewer,
  params: { id },
  setPageOptions,
  uploadAssetComplete,
}, {
  router,
}) => {
  useEffect(() => {
    setPageOptions({
      backLink: {
        link: backLink,
        icon: 'fa fa-times fs-18',
      },
      inEditMode: isEditMode,
    });
  }, [isEditMode]);

  return (
    <CenterBox isLoading={isLoading} renderIf={Boolean(checkup?.id)}>
      <section className="center-box-header show-for-large">
        <div>
          <div className="subtitle">{checkup.pig_group_name}</div>
          <h1 className="title"><FormattedMessage id="general.media" /></h1>
        </div>
        <Link to={backLink}><i className="fa fa-times close-center-box" /></Link>
      </section>
      <section className="center-box-body">
        {+id === checkup.id && (
          <MediaUploader
            checkup={checkup}
            uploadAssetComplete={uploadAssetComplete}
            deleteAsset={(assetId) => deleteAsset(checkup.id, assetId)}
            openViewer={openViewer}
            validateCheckup={() => isEditMode}
            headerLess
          />
        )}
      </section>
      <section className="center-box-footer show-for-large">
        <div className="buttons text-right">
          <Link className="button margin-right-1" to={backLink}>
            <FormattedMessage id="general.button.cancel" />
          </Link>
          <Button
            primary
            disabled={isLoading}
            className="submit wider"
            onClick={() => router.push(backLink)}
          >
            <FormattedMessage id="general.button.continue" />
          </Button>
        </div>
      </section>
      <StickyFooter className="hide-for-large">
        <Button stickyFooter primary disabled={isLoading} className="submit" onClick={() => router.push(backLink)}>
          <FormattedMessage id="general.button.continue" />
        </Button>
      </StickyFooter>
    </CenterBox>
  );
};

DailyCheckupMedia.contextTypes = {
  router: T.object.isRequired,
};

DailyCheckupMedia.propTypes = {
  checkup: T.object,
  isLoading: T.bool.isRequired,
  backLink: T.oneOfType([T.string, T.object]),
  openViewer: T.func.isRequired,
  uploadAssetComplete: T.func.isRequired,
  deleteAsset: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  isEditMode: T.bool,
  params: T.object.isRequired,
};

export default compose(
  connect(null, { openViewer, uploadAssetComplete, deleteAsset, setPageOptions }),
  withCorrection,
)(DailyCheckupMedia);
