import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { getFarm, updateFarm } from 'reducers/admin/farms';
import { openModal } from 'reducers/modals';
import { stopSubmit, setSubmitFailed } from 'redux-form';
// components
import { FormattedMessage } from 'react-intl';
import FarmProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmProfileHeader';
import FarmMiniProfileHeader from 'components/ProfileHeader/FarmProfileHeader/FarmMiniProfileHeader';
import DropdownButton from 'components/DropdownButton';
import SubNavigation from 'components/Subnavigation';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import FarmTypeLabel from 'components/FarmTypeLabel';
import VerticalAligner from 'components/VerticalAligner';
import Panel from 'components/Panel';
import ProgressBox from 'components/Admin/ProgressBox';
import ProgressChecklist from 'components/ProgressChecklist/ProgressChecklist';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import MessageBox from 'components/MessageBox';
// utils
import isEmpty from 'lodash.isempty';
import { showToastrMessage } from 'utils';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import isObject from 'lodash.isobject';
import classnames from 'classnames/bind';
// styles
import styles from './FarmProfile.module.scss';

const cn = classnames.bind(styles);

const FarmProfile = ({
  children,
  farm,
  getFarm,
  hasLogisticsEnabled,
  isLoading,
  openModal,
  params: { id: farmId },
  router,
  stopSubmit,
  setSubmitFailed,
  updateFarm,
}) => {
  const isEmptyFarm = isEmpty(farm);
  const { active, caregiver, farm_type, manager_id, name, owner, status } = farm;
  const isFarmDisabled = status === 'disabled';

  useEffect(() => {
    getFarm(farmId)
      .catch((errors) => {
        toastResponseErrors(errors);
        router.push('/farmfeed');
      });
  }, []);

  const handleUpdateFarm = (data) => {
    updateFarm(data, farmId)
      .then((updatedFarm) => {
        return updatedFarm.active
          ? showToastrMessage('component.toastr.farm.enabled')
          : showToastrMessage('component.toastr.farm.disabled', 'error', 'fa fa-ban');
      })
      .catch(({ errors }) => {
        if (isObject(errors)) {
          router.push(`/admin/farms/${farmId}/edit`);
          stopSubmit('edit-farm', errors);
          setSubmitFailed.apply(null, ['edit-farm', ...Object.keys(errors)]);
        } else {
          toastResponseErrors({ errors });
        }
      });
  };

  const handleConfirmDisable = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.button.disableFarm" />}
        actionBtnLabel={<FormattedMessage id="component.modal.disableFarm.confirm" />}
        actionBtnProps={{ red: true }}
        warningMessage={<FormattedMessage id="component.modal.disableFarm.warning" />}
        handleConfirm={() => handleUpdateFarm({ active: false })}
      />
    );
  };

  const handleConfirmEnable = () => {
    openModal(
      <ConfirmationModal
        title={<FormattedMessage id="general.button.enableFarm" />}
        actionBtnLabel={<FormattedMessage id="component.modal.enableFarm.confirm" />}
        actionBtnProps={{ blue: true }}
        warningMessage={<FormattedMessage id="component.modal.enableFarm.warning" />}
        handleConfirm={() => handleUpdateFarm({ active: true })}
      />
    );
  };

  const progressData = {
    closeable: !!owner && !!caregiver && !!manager_id,
    headerText: <FormattedMessage id="container.farmProfile.setupProgress" />,
    steps: [
      {
        text: <FormattedMessage id="container.farmProfile.basicInfoSaved" />,
        done: true,
      }, {
        text: <FormattedMessage id="general.assignFarmOwner" />,
        done: !!owner,
        url: `/admin/farms/${farmId}/assign-user-roles`,
      }, {
        text: <FormattedMessage id="general.assignManagementCompany" />,
        done: !!manager_id,
        url: `/admin/farms/${farmId}/assign-company-roles`,
      }, {
        text: <FormattedMessage id="general.assignCaregiver" />,
        done: !!caregiver,
        url: `/admin/farms/${farmId}/assign-user-roles`,
      }],
  };

  const navLinks = [
    { link: `/admin/farms/${farmId}/user-roles`, label: <FormattedMessage id="general.userRoles" /> },
    { link: `/admin/farms/${farmId}/health-variables`, label: <FormattedMessage id="general.healthVariables" /> },
    { link: `/admin/farms/${farmId}/scheduling`, label: <FormattedMessage id="general.farmLogistics" />,
      isHidden: !hasLogisticsEnabled },
    { link: `/admin/farms/${farmId}/edit`, label: <FormattedMessage id="general.farmInformation" /> },
    { link: `/admin/farms/${farmId}/barns`, label: <FormattedMessage id="general.barns" /> },
  ];

  const steps = [
    { label: <FormattedMessage id="general.farmSetup" />, icon: 'fa fa-check', completed: true },
    { label: <FormattedMessage id="general.role.managementCo" />, icon: 'fa fa-business-socks',
      completed: !!manager_id },
    { label: <FormattedMessage id="general.assignCaregiver" />, icon: 'fa fa-user-male', completed: !!caregiver },
    { label: <FormattedMessage id="general.setupComplete" />, icon: 'fa fa-trophy',
      completed: active && status === 'active' },
  ];

  const dropdownOptions = [
    { label: <FormattedMessage id="general.button.disableFarm" />, onClick: handleConfirmDisable,
      hide: isEmptyFarm || !active },
    { label: <FormattedMessage id="general.button.enableFarm" />, onClick: handleConfirmEnable,
      hide: isEmptyFarm || active },
    { label: <FormattedMessage id="general.viewFarmCheckInHistory" />, url: `/checkins/farms/${farmId}` },
  ];

  return (
    <div className={cn('farm-profile', 'clearfix')}>
      <FarmProfileHeader
        farm={farm}
        isLoading={isLoading}
        actualFarmId={Number(farmId)}
        className="show-for-large"
        dropdownOptions={dropdownOptions}
      >
        <ProgressChecklist steps={steps} />
      </FarmProfileHeader>

      <SubNavigation
        className="small-12 column show-for-large"
        links={navLinks}
        stickyContent={<FarmMiniProfileHeader farm={farm} />}
        isSticky
      />

      <section className="small-12 column">

        <Panel>
          <Panel.Heading className="hide-for-large">
            <h1 className="lighter">
              <VerticalAligner>
                <span className="mr-10">{name}</span>
                <FarmTypeLabel farmType={farm_type} />
              </VerticalAligner>
            </h1>
            <StatusBadge status={active ? 'active' : 'disabled'} />
            {!isEmptyFarm && (
              <DropdownButton
                idKey={`${farmId}-${name}`}
                wide
                label={<FormattedMessage id={active ? 'general.button.disableFarm' : 'general.button.enableFarm'} />}
                onClick={active ? handleConfirmDisable : handleConfirmEnable}
                dropDownData={[
                  { label: <FormattedMessage id="general.viewFarmCheckInHistory" />, url: `/checkins/farms/${farmId}` },
                ]}
              />
            )}
          </Panel.Heading>

          <Panel.Body noPadding className={cn('mobile-body')}>
            <SubNavigation links={navLinks} className="hide-for-large" inner />

            <div className={cn('mobile-body-box')}>
              <ProgressBox className={cn('hide-for-large', 'progress-box')} {...progressData} />

              {isFarmDisabled && (
                <MessageBox type="info" className={cn('user-alert')}>
                  <FormattedMessage
                    id="container.farmProfile.farmDisabled"
                    values={{
                      title: <FormattedMessage id="container.farmProfile.farmDisabled.title" tagName="b" />
                    }}
                  />
                  <button className={cn('enable-btn')} onClick={handleConfirmEnable}>
                    <FormattedMessage id="general.button.enableFarm" />
                  </button>
                </MessageBox>
              )}

              {children}
            </div>
          </Panel.Body>
        </Panel>
      </section>
    </div>
  );
};

FarmProfile.propTypes = {
  getFarm: T.func.isRequired,
  params: T.object.isRequired,
  farm: T.object.isRequired,
  isLoading: T.bool.isRequired,
  children: T.object.isRequired,
  openModal: T.func.isRequired,
  updateFarm: T.func.isRequired,
  router: T.object.isRequired,
  stopSubmit: T.func.isRequired,
  setSubmitFailed: T.func.isRequired,
  hasLogisticsEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    farm: state.farms.resource.data,
    isLoading: state.farms.resource.loading,
    hasLogisticsEnabled: state.auth.user.current_company.logistics,
  }), {
    getFarm,
    updateFarm,
    openModal,
    stopSubmit,
    setSubmitFailed,
  }
)(FarmProfile);
