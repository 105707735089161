export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const BEAMER_APP_ID = process.env.REACT_APP_BEAMER_APP_ID;

export const ESC_KEY = 27;
export const LEFT_KEY = 37;
export const RIGHT_KEY = 39;

export const tableNames = {
  adminGroupShipments: 'adminGroupShipments',
  adminGroupTransfers: 'adminGroupTransfers',
  adminGroupScheduledLoads: 'adminGroupScheduledLoads',
  allCommitments: 'allCommitments',
  autoReports: 'autoReports',
  barnSheetsCompanies: 'barnSheetsCompanies',
  barnSheetsFarms: 'barnSheetsFarms',
  barnSheetsFarmGroups: 'barnSheetsFarmGroups',
  barnSheetsFarmSVRs: 'barnSheetsFarmSVRs',
  barnSheetsGroups: 'barnSheetsGroups',
  barnSheetsGroupSalesResults: 'barnSheetsGroupSalesResults',
  pigGroupCommitments: 'pigGroupCommitments',
  startPigGroupCommitments: 'startPigGroupCommitments',
  truckingCompanies: 'truckingCompanies',
  virtualVisits: 'virtualVisits',
  treatmentProtocolFarms: 'treatmentProtocolFarms',
  farmTreatmentProtocols: 'farmTreatmentProtocols',
  pigGroupTreatments: 'pigGroupTreatments',
  pigGroupTreatmentProtocols: 'pigGroupTreatmentProtocols',
  siteVisitReportsFarms: 'siteVisitReportsFarms'
};
