import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import T from 'prop-types';
// endpoints
import {
  getFarmDiagnoses,
  setDiagnosisData,
  getFarmVaccines,
  addFarmVaccine,
  updateFarmVaccine,
  deleteFarmVaccine,
} from 'endpoints/admin/farms';
// utils
import classnames from 'classnames/bind';
import get from 'lodash.get';
import omit from 'lodash.omit';
// components
import Panel from 'components/Panel';
import Toggle from 'components/Toggle';
import { FormattedMessage } from 'react-intl';
import Preloader from 'components/Preloader';
import CommentBoxTransparent from 'components/CommentBoxTransparent';
import VaccineSelect from 'components/Admin/Farms/VaccineSelect';
import ArrivalProtocolUploader from './components/ArrivalProtocolUploader';
// constants
import { farmTypes } from 'constants.js';
// styles
import styles from './FarmHealthVariables.module.scss';

const cn = classnames.bind(styles);
const TMP_VACCINE = 'new-vaccine';

const toObject = (array, key) => {
  let result = {};
  array.forEach((item) => {
    result = { ...result, [item[key]]: item };
  });
  return result;
};

const FarmHealthVariables = (props) => {
  const {
    farmType, params: { id: farmId } } = props;
  const [farmDiagnoses, setFarmDiagnoses] = useState({});
  const [farmVaccines, setFarmVaccines] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVaccinesLoading, setVaccinesLoading] = useState(false);
  const [newVaccineId, setNewVaccineid] = useState(0);

  const selectedVaccines = Object.values(farmVaccines).map(({ treatment_product: { id } }) => id);
  const farmTypeLabel = get(farmTypes.find(({ value }) => value === farmType), 'label');

  useEffect(() => {
    setIsLoading(true);
    getFarmDiagnoses(farmId)
      .then(((resources) => toObject(resources, 'id')))
      .then((resources) => setFarmDiagnoses(resources))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setVaccinesLoading(true);
    getFarmVaccines(farmId)
      .then(((resources) => toObject(resources, 'id')))
      .then((resources) => setFarmVaccines(resources))
      .finally(() => setVaccinesLoading(false));
  }, []);

  const changeDiagnosis = (id, data) => {
    setIsLoading(true);
    setDiagnosisData(farmId, id, { ...data })
      .then((diagnosis) => setFarmDiagnoses((prevState) => ({
        ...prevState,
        [diagnosis.id]: diagnosis
      })))
      .then(() => setIsLoading(false));
  };

  const setIsEdit = (id, value) => {
    setFarmVaccines((prevState) => ({
      ...prevState,
      [id]: { ...prevState[id], isEdit: value },
    }));
  };

  const addVacine = () => {
    setFarmVaccines((prev) => ({
      ...prev,
      [TMP_VACCINE]: {
        isEdit: true,
        id: TMP_VACCINE,
        treatment_product: {}
      },
    }));
    setNewVaccineid(newVaccineId + 1);
  };

  const removeTmpVaccine = () => {
    setFarmVaccines(omit(farmVaccines, TMP_VACCINE));
  };

  const onCancel = (id) => () => {
    if (id === TMP_VACCINE) removeTmpVaccine();
  };

  const onDelete = (id) => () => {
    setVaccinesLoading(true);
    deleteFarmVaccine(farmId, id)
      .then(() => setFarmVaccines({ ...omit(farmVaccines, id) }))
      .finally(() => setVaccinesLoading(false));
  };

  const saveVaccine = (farmVaccineId) => ({ notes, vaccineId }) => {
    const resourse = { notes, treatment_product_id: vaccineId };
    setVaccinesLoading(true);
    if (farmVaccineId !== TMP_VACCINE) return updateFarmVaccine(farmId, farmVaccineId, resourse)
      .then(({ id }) => setIsEdit(id, false))
      .finally(() => setVaccinesLoading(false));

    return addFarmVaccine(farmId, resourse)
      .then((vaccine) => setFarmVaccines({ ...omit(farmVaccines, TMP_VACCINE), [vaccine.id]: vaccine }))
      .finally(() => setVaccinesLoading(false));
  };


  const handleSwitch = (id, active) => changeDiagnosis(id, { active });
  const handleCommentSave = (id, comment) => changeDiagnosis(id, { comment });

  return (
    <div className={cn('FarmHealthVariables')}>
      <div className={cn('medium-12 xlarge-8', 'farm-health-wrapper')}>
        <Panel className="pb-15">
          <Panel.Heading
            title={(
              <FormattedMessage
                id="general.farmTypeHealth"
                values={{ farmType: farmTypeLabel }}
              />
            )}
          />
          <div className={cn('panel-body')}>
            <Preloader isActive={isLoading} />
            {Object.values(farmDiagnoses).map(({
              id,
              name,
              description,
              active: isActive,
              comment,
            }) => {
              return (
                <div className={cn('diagnosis-line')} key={`diagnosis-line-${id}`}>
                  <div className={cn('diagnosis-label')}>
                    <span className={cn('bold')}>{name}</span>
                    {' '}
                    ({description})
                  </div>
                  <div className={cn('toggle-wrapper', { isActive })}>
                    {isActive ? (
                      <FormattedMessage id="general.positive" />
                    ) : (
                      <FormattedMessage id="general.negative" />
                    )}
                    <Toggle
                      className={cn('switcher')}
                      isChecked={isActive}
                      handleChange={() => handleSwitch(id, !isActive)}
                    />
                  </div>
                  {isActive && (
                    <CommentBoxTransparent initialValue={comment} onSave={(text) => handleCommentSave(id, text)} />
                  )}
                </div>
              );
            })}
          </div>
        </Panel>
        <Panel className="pb-15">
          <Panel.Heading title={<FormattedMessage id="general.vaccinationHistory" />} />
          <div className={cn('panel-body')}>
            <Preloader isActive={isVaccinesLoading} />
            {Object.values(farmVaccines).map(({
              id,
              notes,
              treatment_product,
              isEdit
            }) => (
              <VaccineSelect
                key={`vaccine-${id}`}
                note={notes}
                treatment_product={treatment_product}
                isEdit={isEdit}
                setIsEdit={(value) => setIsEdit(id, value)}
                onSave={saveVaccine(id)}
                onCancel={onCancel(id)}
                onDelete={onDelete(id)}
                selectedVaccines={selectedVaccines}
              />
            ))}
            {!farmVaccines[TMP_VACCINE] && (
              <div className={cn('add-vaccine-btn')} onClick={addVacine}>
                <FormattedMessage id="general.addVaccination" />
              </div>
            )}
          </div>
        </Panel>
      </div>

      <Panel className={cn('xlarge-4')}>
        <Panel.Heading title={<FormattedMessage id="general.arrivalProtocol" />} />
        <div className={cn('panel-body')}>
          <ArrivalProtocolUploader farmId={farmId} />
        </div>
      </Panel>

    </div>
  );
};

FarmHealthVariables.propTypes = {
  farmType: T.string,
  params: T.object,
};

export default connect(
  (state) => ({
    farmType: state.farms.resource.data.farm_type,
  })
)(FarmHealthVariables);
