import React, { Fragment } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
// components
import { FormattedMessage } from 'react-intl';
import TruckingRouteCard from 'components/TruckingRouteCard/TruckingRouteCard';
import Panel from 'components/Panel';
import cn from 'classnames';
// constants
import { getSalesResultInfo, getFormattedSalesResults } from './utils';
// styles
import styles from './SalesResults.module.scss';

const SalesResults = ({ commitment, router: { push } }) => {
  if (!commitment.sales_results.length) {
    push('/my-schedule');
    return null;
  }

  const formattedSalesResults = getFormattedSalesResults(commitment);
  return (
    <Panel>
      <Panel.Heading title={<FormattedMessage id="general.salesResults" />} />
      <Panel.Body noPadding>
        <div className="ph-30 pb-20">
          <div className={styles.body}>
            {formattedSalesResults.map((salesResult, idx) => (
              <Fragment key={idx}>
                <div className="pt-20">
                  <TruckingRouteCard
                    direction="out"
                    loadType="sale"
                    commitments={[salesResult.source_commitment, salesResult.destination_commitment]}
                  />
                </div>
                <div className={styles['info-block']}>
                  {getSalesResultInfo(salesResult).map(({ label, value, targetValue }, idx) => (
                    <Fragment key={idx}>
                      <div className={styles['info-row']}>
                        {/* TARGET */}
                        <div className={styles['row-item']}>
                          <div className={styles.label}>{label}</div>
                          <div className={styles.target}>
                            <FormattedMessage id="general.target" />
                          </div>
                        </div>
                        {/* VALUES */}
                        <div>
                          <div className={cn(styles['row-item'], styles.values)}>
                            <div className={styles.value}>{value}</div>
                            <div className={styles.target}>{targetValue}</div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                  {/* NOTES */}
                  <div className="pt-10">
                    <div className={styles.notes}>
                      <div className="semibold fs-14">
                        <FormattedMessage id="general.notes" />
                      </div>
                    </div>
                    <div className="fs-14">{salesResult?.comment || 'N/A'}</div>
                  </div>
                </div>
                {idx !== formattedSalesResults.length - 1 && <div className={styles.divider} />}
              </Fragment>
            ))}
          </div>
        </div>
      </Panel.Body>
    </Panel>
  );
};

SalesResults.propTypes = {
  commitment: T.object.isRequired,
  router: T.object.isRequired,
};

export default connect((state) => ({
  commitment: state.commitment.data,
}))(SalesResults);
