import React, { Fragment, memo } from 'react';
import T from 'prop-types';
// reducers
import { connect } from 'react-redux';
import { updateActivity } from 'reducers/farmfeed';
// components
import { FormattedMessage } from 'react-intl';
import ActivityActionsBar from './ActivityActionsBar/ActivityActionsBar';
import ActivityCheckupBox from './ActivityCheckupBox/ActivityCheckupBox';
import ActivityBox from './ActivityBox';
import ActivityBodyCollapse from './ActivityBodyCollapse';
import ActivityHeader from './ActivityHeader';
import ActivityHeaderBadge from './ActivityHeader/ActivityHeaderBadge';
import AISuggestionFeedback from './AISuggestionFeedback';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import FarmFeedRowComments from 'components/FarmFeed/FarmFeedRowComments';
import FarmFeedRowAddComment from 'components/FarmFeed/FarmFeedRowAddComment';
import GroupInfoCollapse from 'components/Groups/GroupInfoCollapse';
import LastCommentInfo from './LastCommentInfo/LastCommentInfo';
import TreatmentPopoverList from './TreatmentPopoverList';
import Translation from 'components/Translation';
// hooks
import useActivityController from 'hooks/useActivityController';
// utils
import classnames from 'classnames/bind';
import moment from 'moment';
import { isEqualKeys, isUserCanDiagnose } from 'utils';
import { getSuggestionBadgeTitle } from 'utils/activityHelper';
// endpoints
import { createSuggestionFeedback, updateSuggestionFeedback } from 'endpoints/farmfeed';
// styles
import styles from './AISuggestionActivity.module.scss';
// assets
import magicLoopSvg from '../../../public/images/magic_2_looped.svg';

const cn = classnames.bind(styles);

const affectRerenderKeys = ['id', 'updated_at', 'is_viewed', 'flagged', 'comments', 'ai_suggestion_feedbacks'];

const AISuggestionActivity = (props) => {
  const {
    defaultDropdownOptions,
    isMinimized,
    isTruncated,
    onToggleMinimized,
    onViewFullActivity,
  } = useActivityController(props);
  const { className, activity, currentUser, updateActivity, markAsSeen, isChildActivity = false, openDropdown,
    setActivityFlag } = props;
  const { id, farm, pig_group, additional_data, created_at, ai_suggestion_feedbacks, flagged, is_viewed } = activity;
  const isAdmin = currentUser.roles_map.admin;
  const isUserVetOrAdmin = isUserCanDiagnose(currentUser, farm.id, farm.manager?.id);
  const mortality = additional_data?.mortality;
  const date = moment(created_at).format('YYYY-MM-DD');

  const treatmentFeedback = ai_suggestion_feedbacks.find((feedback) => feedback.variety === 'treatment');
  const mortalityFeedback = ai_suggestion_feedbacks.find((feedback) => feedback.variety === 'mortality');
  const diagnosisFeedback = ai_suggestion_feedbacks.find((feedback) => feedback.variety === 'diagnosis');

  const suggestionBadgeTitle = getSuggestionBadgeTitle(additional_data);

  const createFeedback = ({ answer, variety }) => {
    return createSuggestionFeedback(id, { answer, variety })
      .then((newFeedback) => {
        const newActivity = {
          ...activity,
          ai_suggestion_feedbacks: [...ai_suggestion_feedbacks, newFeedback],
        };
        updateActivity(newActivity);
      });
  };

  const updateFeedback = ({ answer, variety, id }) => {
    return updateSuggestionFeedback(activity.id, id, { answer, variety })
      .then((updatedFeedback) => {
        const newActivity = {
          ...activity,
          ai_suggestion_feedbacks: ai_suggestion_feedbacks.map((fb) => (
            fb.variety === variety ? updatedFeedback : fb))
        };
        updateActivity(newActivity);
      });
  };

  const handleOpenDropdown = (e) => {
    openDropdown(e, defaultDropdownOptions);
  };

  const renderIcon = () => <img loading="lazy" className={styles['magic-loop-img']} src={magicLoopSvg} alt="" />;

  return (
    <ActivityBox
      className={className}
      isFlagged={flagged}
      isUnseen={!is_viewed}
      isTruncated={isTruncated}
      hasMarker={!isChildActivity}
      markAsSeen={markAsSeen}
      onViewFullActivity={onViewFullActivity}
    >
      {!isMinimized &&
        <LastCommentInfo activity={activity} currentUser={currentUser} />}
      <ActivityHeader activityId={id} onOpenDropdown={handleOpenDropdown} onDoubleClick={onToggleMinimized}>
        <ActivityHeaderBadge renderIcon={renderIcon} isCentered>
          {suggestionBadgeTitle}
        </ActivityHeaderBadge>
      </ActivityHeader>
      <ActivityBodyCollapse isClosed={isMinimized}>
        <div className={cn('mortality-suggestion-text')}>
          {mortality && (
            <>
              <FormattedMessage
                tagName="div"
                id="component.AISuggestionActivity.mortalityText"
                values={{
                  percent: mortality.chance,
                  deathAmount: mortality.death_amount,
                  daysCount: mortality.duration_days,
                  red: (msg) => <span className={cn('brand-red')}>{msg}</span>,
                  orange: (msg) => <span className={cn('brand-orange')}>{msg}</span>
                }}
              />
              {mortality.explanation && (
                <div className={cn('explanation')}>
                  <Translation text={mortality.explanation}>
                    <FormattedMessage id="general.explanation" tagName="strong" />
                    :&nbsp;
                    {mortality.explanation}
                  </Translation>
                </div>
              )}
              <div className={cn('suggestion-timestamp')}>
                {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
              </div>
              {!isChildActivity && isUserVetOrAdmin && (
                <AISuggestionFeedback
                  feedback={mortalityFeedback}
                  sendFeedback={mortalityFeedback ? updateFeedback : createFeedback}
                  type="mortality"
                />
              )}
            </>
          )}
          {!!additional_data?.treatments?.length && (
            <>
              <FormattedMessage
                tagName="div"
                id="component.AISuggestionActivity.treatmentsText"
                values={{
                  blue: (msg) => <span className={cn('brand-blue')}>{msg}</span>,
                  treatmentsList: (
                    <TreatmentPopoverList
                      treatments={additional_data?.treatments || []}
                      isCurrentUserAdmin={isAdmin}
                    />
                  ),
                }}
              />
              <div className={cn('suggestion-timestamp')}>
                {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
              </div>
              {!isChildActivity && isUserVetOrAdmin && (
                <AISuggestionFeedback
                  feedback={treatmentFeedback}
                  sendFeedback={treatmentFeedback ? updateFeedback : createFeedback}
                  type="treatment"
                />
              )}
            </>
          )}
          {additional_data?.diagnosis?.map((item, index) => (
            <Fragment key={index}>
              <FormattedMessage
                tagName="div"
                id="component.AISuggestionActivity.diseaseText"
                values={{
                  chance: item.chance,
                  disease_name: item.name,
                  duration_days: item.duration_days,
                  yellow: (msg) => <span className={cn('bran-yellow')}>{msg}</span>,
                  red: (msg) => <span className={cn('brand-red')}>{msg}</span>,
                  blue: (msg) => <span className={cn('brand-blue')}>{msg}</span>,
                }}
              />
              <div className={cn('suggestion-timestamp')}>
                {moment(created_at).format('MMM DD, YYYY, hh:mm a')}
              </div>
              {!isChildActivity && isUserVetOrAdmin && (
                <AISuggestionFeedback
                  feedback={diagnosisFeedback}
                  sendFeedback={diagnosisFeedback ? updateFeedback : createFeedback}
                  type="diagnosis"
                />
              )}
            </Fragment>
          ))}
        </div>

        <ActivityCheckupBox isAdmin={isAdmin} activity={activity} className={cn({ 'mb-15': isChildActivity })}>
          {pig_group && (
            <CollapsesBox className={cn('ai-suggestion-collapse-box')}>
              <GroupInfoCollapse
                isAdmin={isAdmin}
                titleClassName={cn('ai-suggestion-group-info')}
                group={pig_group}
                customEndpoint={`/pig_groups/${pig_group.id}/history_dc_info?history_date=${date}`}
              />
            </CollapsesBox>
          )}
        </ActivityCheckupBox>

        {!isChildActivity && (
          <>
            <ActivityActionsBar
              activity={activity}
              currentUser={currentUser}
              openDropdown={openDropdown}
              setActivityFlag={setActivityFlag}
            />
            <FarmFeedRowComments activity={activity} />
            <FarmFeedRowAddComment activity={activity} />
          </>
        )}
      </ActivityBodyCollapse>
    </ActivityBox>
  );
};

AISuggestionActivity.propTypes = {
  className: T.string,
  activity: T.object.isRequired,
  currentUser: T.object.isRequired,
  updateActivity: T.func.isRequired,
  markAsSeen: T.func,
  isChildActivity: T.bool,
  openDropdown: T.func.isRequired,
  setActivityFlag: T.func.isRequired,
};

export default memo(
  connect(null, { updateActivity })(AISuggestionActivity), (prevProps, nextProps) => {
    return isEqualKeys(affectRerenderKeys, prevProps.activity, nextProps.activity);
  }
);
