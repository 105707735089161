import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import FarmInformationBar from './components/FarmInformationBar';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import CategoryViewRow from './components/CategoryViewRow';
import SubmitStepper from './components/SubmitStepper';
import SVRStatBlocks from './components/SVRStatBlocks';
import SVRHeader from './components/SVRHeader';
import Preloader from 'components/Preloader';
import SVRComment from 'components/SVRComment';
import SVRMediaRow from './components/SVRMediaRow';
// store
import { publishSVR } from 'reducers/siteVisitReport';
// utils
import { connect } from 'react-redux';
import classnames from 'classnames/bind';
import { getSVRBackLink, additionalSectionTitleKeys, getSVRNotesComment } from 'utils/SVRHelper';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { isMobile } from 'react-device-detect';
// styles
import styles from './SiteVisitReport.module.scss';

const cn = classnames.bind(styles);

const SiteVisitReportConfirmation = ({ isLoading, farm, report, currentUser, publishSVR }, { router }) => {
  const { location: {  query, pathname } } = router;
  const { createdAt, categories, additionalCategory } = report;
  const backLink = getSVRBackLink(farm.id, query);

  const onSave = () => router.push(backLink);

  const onBack = () => {
    const allCategories = additionalCategory ? [...categories, additionalCategory] : categories;
    const query = isMobile ? { currentStep: allCategories.length - 1 } : {};
    router.push({ pathname, query });
  };

  const onSubmit = async () => {
    try {
      await publishSVR();
      router.push(backLink);
    } catch (error) {
      toastResponseErrors(error);
    }
  };

  const categoryViewRenderer = (categoryData, idx) => (
    <CategoryViewRow categoryIndex={idx} category={categoryData} key={idx} />
  );

  return (
    <div className={cn('wrapper', { loading: isLoading })}>
      <Preloader isCentered isActive={isLoading} />
      <SVRHeader farmId={farm.id} farmName={farm.name} onClose={onSave} createdAt={createdAt}>
        <Button onClick={onSave} className={cn('save-close-btn')}>
          <FormattedMessage id="general.button.saveAndClose" />
        </Button>
      </SVRHeader>
      <SubmitStepper className={cn('stepper')} />
      <div className={cn('svr-content')}>
        <div className={cn('farm-bar')}>
          <FarmInformationBar farm={farm} currentCompany={currentUser.current_company} />
        </div>
        <div className={cn('content-wrapper')}>
          <div className={cn('content')}>
            <SVRStatBlocks categories={categories} />
            <div className="mt-30">
              {categories.map(categoryViewRenderer)}
            </div>

            {!!additionalCategory?.svr_questions?.length && (
              <div className={cn('general-section')}>
                {additionalCategory.svr_questions.map(({ answer, question_type, item_key }, idx) => (
                  <Fragment key={idx}>
                    {question_type === 'additional_section' && !!answer?.value && (
                      <>
                        <div className={cn('section-title')}>
                          <FormattedMessage id={additionalSectionTitleKeys[item_key]} />
                        </div>
                        <div>
                          <SVRComment comment={getSVRNotesComment(answer, currentUser)} />
                        </div>
                      </>
                    )}
                    {question_type === 'media' && !!answer?.value?.length && (
                      <>
                        <div className={cn('section-title', 'mb-15')}>
                          <FormattedMessage id={additionalSectionTitleKeys[item_key]} />
                        </div>
                        <SVRMediaRow media={answer.value} />
                      </>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={cn('svr-footer')}>
        <div className={cn('category-info', 'semibold')}>
          <FormattedMessage id="general.button.review" />
        </div>
        <div className={cn('actions')}>
          <Button onClick={onBack} className={cn('btn', 'back')}>
            <i className="mr-10 fa fa-angle-left-btb" />
            <FormattedMessage id="general.button.back" />
          </Button>
          <SubmitStepper className={cn('stepper')} />
          <Button onClick={onSubmit} className={cn('btn')} primary>
            <FormattedMessage id="general.button.submit" />
            <i className="ml-10 fa fa-angle-right-btb" />
          </Button>
        </div>
      </div>
    </div>
  );
};

SiteVisitReportConfirmation.contextTypes = {
  router: T.object.isRequired,
};

SiteVisitReportConfirmation.propTypes = {
  farm: T.object,
  report: T.object.isRequired,
  currentUser: T.object.isRequired,
  isLoading: T.bool.isRequired,
  publishSVR: T.func.isRequired,
};

export default connect((state) => ({
  currentUser: state.auth.user,
  isLoading: state.siteVisitReport.isLoading,
  farm: state.siteVisitReport.farm,
  report: state.siteVisitReport.report,
}), { publishSVR })(SiteVisitReportConfirmation);
