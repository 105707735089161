import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import VideoPreviewOverlay from 'components/VideoPreviewOverlay';
// utils
import { isVideoAsset } from 'utils';
import classnames from 'classnames/bind';
// assets
import magicLoopSvg from '../../../../public/images/magic_2_looped_white.svg';
// styles
import styles from './ActivityAssets.module.scss';

const cn = classnames.bind(styles);

const getAssetPreviewUrl = (assets, index) => {
  const asset = assets[index];
  const { url } = asset;
  if (isVideoAsset(asset)) return url.thumb || url.medium;
  if (index === 0) return url.medium || url.small || url.large || url.origin || url.original;
  if (index === 1 && !assets[2]) return url.medium || url.small || url.large || url.origin || url.original;
  return url.small || url.medium || url.origin || url.original;
};

const ActivityAssets = ({ assets, activity, onAssetPreview, suggestionsCount, className }) => (
  <div className={cn(className, 'activity-assets', 'animated fadeIn')}>
    <div className={cn('farmfeed-assets')}>
      {!!suggestionsCount && (
        <div className={cn('smart-suggestions')}>
          <img className={cn('magic-loop-img')} src={magicLoopSvg} loading="lazy" alt="" />
          <FormattedMessage id="general.smartSuggestions" values={{ count: suggestionsCount }}>
            {(text) => <div className={cn('suggestions-text')}>{text}</div>}
          </FormattedMessage>
        </div>
      )}
      <div className={cn('column-left')}>
        {assets[0] && (
          <div className={cn('asset-preview')}>
            <div
              className={cn('image')}
              style={{ backgroundImage: 'url(' + getAssetPreviewUrl(assets, 0) + ')' }}
              title={assets[0].filename}
              onClick={() => onAssetPreview(assets, 0, activity)}
            />
            {isVideoAsset(assets[0]) && (
              <VideoPreviewOverlay
                showText={assets.length === 1}
                asset={assets[0]}
                activity={activity}
                size="40"
              />
            )}
          </div>
        )}
      </div>
      {assets[1] && (
        <div className={cn('column-right')}>
          <div className={cn('asset-preview')}>
            <div
              className={cn('image')}
              style={{ backgroundImage: 'url(' + getAssetPreviewUrl(assets, 1) + ')' }}
              title={assets[1].filename}
              onClick={() => onAssetPreview(assets, 1, activity)}
            />
            {isVideoAsset(assets[1]) &&
              <VideoPreviewOverlay asset={assets[1]} activity={activity} size={assets[2] ? 30 : 40} />}
          </div>

          {assets[2] && (
            <div className={cn('asset-preview', 'mt-5')}>
              <div
                className={cn('image')}
                style={{ backgroundImage: 'url(' + getAssetPreviewUrl(assets, 2) + ')' }}
                title={assets[2].filename}
                onClick={() => onAssetPreview(assets, 2, activity)}
              />
              {isVideoAsset(assets[2]) &&
                <VideoPreviewOverlay asset={assets[2]} activity={activity} size="30" small />}

              {(assets.length > 3) && (
                <div className={cn('more-assets-overlay')}>
                  <span>+{assets.length - 3}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);

ActivityAssets.propTypes = {
  onAssetPreview: T.func.isRequired,
  activity: T.object,
  assets: T.array.isRequired,
  suggestionsCount: T.number,
  className: T.string,
};

ActivityAssets.defaultProps = {
  activity: {},
};

export default ActivityAssets;
