import React, { Fragment, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import SVRMediaUploader from '../SVRMediaUploader';
import Button from 'components/Button';
import MentionsInput from 'components/MentionsInput';
// redux
import { connect } from 'react-redux';
import { setAdditionalAnswer } from 'reducers/siteVisitReport';
// utils
import classnames from 'classnames/bind';
// styles
import styles from './AdditionalSection.module.scss';

const cn = classnames.bind(styles);

const svrQuestionMentionRoles = ['owner', 'caretaker', 'manager', 'vet'];

export const additionalSectionTitleKeys = {
  general_notes_section: 'general.generalNotes',
  action_plan_section: 'general.actionPlan',
  photo_video_uploads: 'general.media'
};

const placeholderKeys = {
  general_notes_section: 'general.enterNotesHere',
  action_plan_section: 'general.enterActionPlanHere',
};

const AdditionalSection = ({
  setAdditionalAnswer,
  additionalCategory: { svr_questions } = { svr_questions: [] }
}, { router: { params } }) => {
  const [notes, setNotes] = useState(svr_questions.map(({ answer }) => ({
    value: answer?.value || '',
    comment_mentions_attributes: answer?.mentions || [],
  })));

  const onNoteValueChange = (index) => (value) => {
    setNotes((prevData) => [
      ...prevData.slice(0, index),
      { ...prevData[index], value },
      ...prevData.slice(index + 1)
    ]);
  };

  const onNoteMentionsChange = (index) => (mentions) => {
    setNotes((prevData) => [
      ...prevData.slice(0, index),
      { ...prevData[index], comment_mentions_attributes: mentions.map(({ id: user_id }) => ({ user_id })) },
      ...prevData.slice(index + 1)
    ]);
  };

  const onSave = (index) => () => {
    setAdditionalAnswer(index, notes[index]);
  };

  if (!svr_questions.length) {
    return null;
  }

  return (
    <div className={cn('general-section')}>
      {svr_questions.map((question, index) => (
        <Fragment key={index}>
          {question.question_type === 'additional_section' && (
            <div className={cn('notes-section')}>
              <div className={cn('title')}><FormattedMessage id={additionalSectionTitleKeys[question.item_key]} /></div>
              <FormattedMessage id={placeholderKeys[question.item_key]}>
                {(placeholder) => (
                  <MentionsInput
                    comment={notes[index].value}
                    disabledIds={notes[index].comment_mentions_attributes.map((mention) => mention.user_id)}
                    entityType="farm"
                    entityId={params.farmId}
                    inputType="square"
                    mentionRoles={svrQuestionMentionRoles}
                    onCommentChange={onNoteValueChange(index)}
                    onMentionsChange={onNoteMentionsChange(index)}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
              <Button
                className={cn('save-btn')}
                onClick={onSave(index)}
                disabled={(question?.answer?.value || '') === notes[index].value}
                primary
              >
                <FormattedMessage id="general.button.save" />
              </Button>
            </div>
          )}

          {question.question_type === 'media' && (
            <div className="mb-30">
              <div className={cn('title')}>
                <FormattedMessage id={additionalSectionTitleKeys[question.item_key]} />
              </div>
              <SVRMediaUploader attachedAssets={question?.answer?.value} questionIndex={index} />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

AdditionalSection.propTypes = {
  additionalCategory: T.object,
  setAdditionalAnswer: T.func.isRequired,
};

AdditionalSection.contextTypes = {
  router: T.object.isRequired,
};

export default connect((state) => ({
  additionalCategory: state.siteVisitReport.report.additionalCategory,
}), { setAdditionalAnswer })(AdditionalSection);
