import React, { useEffect } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { setTableParams } from 'reducers/tableParams';
// components
import SiteVisitReportsList from 'components/SiteVisitReports/SiteVisitReportsList';
// hooks
import useDataTable, { tableParamsSelector } from 'hooks/useDataTable';
// api
import { getFarmSiteVisitReports } from 'endpoints/farms';
// utils, constants
import { tableNames } from 'utils/constants';

const BarnSheetsSiteVisitReports = ({
  isSVREnabled,
  params: { farmId },
  router,
  setTableParams,
  tableParams,
}) => {
  const {
    data: { resources, isLoading, meta },
    fetchData,
    onPageChange,
    onPerPageChange,
  } = useDataTable((params) => getFarmSiteVisitReports(farmId, params), {
    setTableParams: (params) => setTableParams(tableNames.barnSheetsFarmSVRs, params),
    tableParams,
  });
  const { page, per_page } = tableParams;
  const { total } = meta;

  useEffect(() => {
    fetchData({ ...tableParams, draft: false, page: 1 });
  }, []);

  useEffect(() => {
    if (!isSVREnabled) {
      router.push('/barnsheets/farms');
    }
  }, [isSVREnabled]);

  const paginationProps = {
    onPageChange,
    onPerPageChange,
    totalItems: total,
    currentPage: page,
    perPage: per_page,
  };
  const backLocationQuery = { from: 'barnsheets' };

  return (
    <SiteVisitReportsList
      backLocationQuery={backLocationQuery}
      className="mb-20"
      resources={resources}
      isLoading={isLoading}
      paginationProps={paginationProps}
    />
  );
};

BarnSheetsSiteVisitReports.propTypes = {
  setTableParams: T.func.isRequired,
  tableParams: T.object.isRequired,
  isSVREnabled: T.bool.isRequired,
  router: T.object.isRequired,
  params: T.object.isRequired,
};

export default connect(
  (state) => ({
    tableParams: tableParamsSelector(state, tableNames.barnSheetsFarmSVRs),
    isSVREnabled: state.auth.user.current_company.svrs && state.auth.user.current_company.admin_svrs,
  }), { setTableParams }
)(BarnSheetsSiteVisitReports);
