import React, { Component } from 'react';
import T from 'prop-types';
// components
import Input from 'components/Input';
// translate
import { FormattedMessage } from 'react-intl';
// utils
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import debounce from 'lodash.debounce';
// styles
import './SearchBox.scss';

class SearchBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: props.initialValue,
    };
  }

  onSearchRequestDebounced = debounce(
    (search) => this.props.onChange(search),
    300,
  );

  onSearchChange = (e) => {
    const search = e.target.value;
    const searchWithSpaces = search ? search.trim() : search;
    this.setState({ search });
    this.onSearchRequestDebounced(searchWithSpaces);
  };

  onClearSearch = () => {
    this.setState({ search: '' });
    this.onSearchRequestDebounced('');
  };

  resetSearch = () => this.setState({ search: '' });

  componentDidMount() {
    if (!this.props.disableFocus && !isMobile) {
      this.input.focus();
    }
  }

  getReference = (ref) => {
    this.input = ref;
  }

  render() {
    const { className, autoFocus, placeholder, isSearchIconHidden, isClearIconHidden, disabled,
      placeholderKey, wide } = this.props;
    const { search } = this.state;

    return (
      <div className={cn(`SearchBox ${className}`, { wide })}>
        {!isSearchIconHidden &&
          <i className="search-icon fa fa-search" />}
        <FormattedMessage id={placeholderKey}>
          {(text) => (
            <Input
              type="search"
              placeholder={placeholder || text}
              value={search || ''}
              onChange={this.onSearchChange}
              autoFocus={autoFocus}
              disabled={disabled}
              getReference={this.getReference}
            />
          )}
        </FormattedMessage>
        {search && !isClearIconHidden && (
          <i onClick={this.onClearSearch} className="clear-icon fa fa-times" />
        )}
      </div>
    );
  }
}

SearchBox.propTypes = {
  onChange: T.func.isRequired,
  className: T.string,
  initialValue: T.string,
  disableFocus: T.bool,
  placeholder: T.string,
  placeholderKey: T.string,
  isSearchIconHidden: T.bool,
  isClearIconHidden: T.bool,
  disabled: T.bool,
  autoFocus: T.bool,
  wide: T.bool,
};

SearchBox.defaultProps = {
  className: '',
  disabled: false,
  isSearchIconHidden: false,
  isClearIconHidden: false,
  disableFocus: false,
  autoFocus: false,
  placeholderKey: 'component.searchbox.placeholder',
};

export default SearchBox;
