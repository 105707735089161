import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCheckupDiagnoses } from 'reducers/dailyCheckup/checkupDiagnoses';
import {
  fetchList as fetchDiagnosesList,
  setDestroyedData,
  setSelectedData,
  selectItem as selectDisease,
  unselectItem as unselectDisease,
  resetData as resetDiseasesData,
  updateSelectedItem,
} from 'reducers/mobileListPicker';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import StickyFooter from 'components/StickyFooter';
import Subnavigation from '../Subnavigation/Subnavigation';
import CollapsesBox from 'components/CollapsesBox/CollapsesBox';
import GroupInfoCollapse from 'components/Groups/GroupInfoCollapse';
import DiagnosesCollapse from 'components/FarmFeed/GroupAnalyticsBlock/DiagnosesCollapse';
import Preloader from 'components/Preloader';
import MobileListPicker from 'components/MobileListPicker/MobileListPicker';
import MobileDiseasesRow from 'components/FarmFeed/MobileDiagnosisRow/MobileDiseasesRow';
import DiseaseField from 'components/MobileDiseasesSelector/DiseaseField';
// styles
import './MobileDiseasesSelector.scss';
// utils
import { IntercomAPI } from 'react-intercom';
import cn from 'classnames';
import update from 'react-addons-update';
import isEqual from 'lodash.isequal';
import differenceWith from 'lodash.differencewith';
import { INTERCOM_APP_ID } from 'utils/constants';

const DIAGNOSE = 'diagnose';
const GROUP_INFO = 'group-info';

class MobileDiseasesSelector extends Component {

  state = {
    activeTab: DIAGNOSE,
    mobileSelectMode: null,
    isLoaded: false,
    index: null,
    diseasesCount: 0,
    initialData: [],
  };

  componentDidMount() {
    const { fetchCheckupDiagnoses, checkupId, fetchDiagnosesList, diseasesList, setSelectedData,
      diagnosisId } = this.props;
    fetchCheckupDiagnoses(checkupId).then(({ value: { resources } }) => {
      this.setState({
        mobileSelectMode: !resources.length ? 'select' : null,
        diseasesCount: resources.length,
        isLoaded: true,
      });
      if (resources.length) {
        const normalizedData = resources.map(({ id, diagnosis_id, diagnosis_type, diagnosis: { name }, note }) => (
          { id, diagnosis_id, diagnosis_type, name, note }
        ));

        const initialData = diagnosisId ? normalizedData.filter(({ id }) => id === diagnosisId) : normalizedData;

        this.setState({
          initialData,
        });
        setSelectedData(initialData);
      }
    });
    if (!diseasesList.length) fetchDiagnosesList('/diagnoses');
  }

  componentWillUnmount() {
    this.props.resetDiseasesData();
  }

  onSubnavSelect = (selectedItem) => {
    this.setState({ activeTab: selectedItem.tab });
  };

  onClose = () => {
    const { closePortal } = this.props;
    closePortal();
  };

  onCloseMobileSelect = () => {
    const { closePortal } = this.props;
    const { mobileSelectMode } = this.state;
    if (mobileSelectMode === 'select') return closePortal();
    return this.setState({ mobileSelectMode: null });
  };

  onMobileDiseaseAdd = (disease) => {
    const { selectedDiseases, destroyedDiseases, selectDisease, unselectDisease, setDestroyedData } = this.props;
    const selected = selectedDiseases.find(({ diagnosis_id }) => diagnosis_id === disease.id);
    const destroyed = destroyedDiseases.find(({ diagnosis_id }) => diagnosis_id === disease.id);
    const newDisease = destroyed
      ? { ...destroyed, _destroy: undefined }
      : { diagnosis_id: disease.id, name: disease.name, diagnosis_type: 'clinical', note: '', notify_all: false };
    delete newDisease._destroy;
    if (selected) unselectDisease(selected);
    if (!selected) selectDisease(newDisease);

    if (destroyed) {
      const updatedDestroyedData = destroyedDiseases.filter(({ diagnosis_id }) => diagnosis_id !== disease.id);
      setDestroyedData(updatedDestroyedData);
    }
  };

  onMobileDiseaseChange = (disease) => {
    const { index } = this.state;
    const { updateSelectedItem, selectedDiseases, destroyedDiseases, setDestroyedData } = this.props;
    const destroyed = { ...selectedDiseases[index], _destroy: true };
    setDestroyedData([...destroyedDiseases, destroyed]);
    updateSelectedItem(index, {
      id: null,
      diagnosis_id: disease.id,
      name: disease.name,
      diagnosis_type: 'clinical',
      note: '',
      notify_all: false,
    });
    this.setState({ mobileSelectMode: null });
  };

  onDiseaseClick = (disease) => {
    const { mobileSelectMode } = this.state;
    if (mobileSelectMode === 'change') return this.onMobileDiseaseChange(disease);
    return this.onMobileDiseaseAdd(disease);
  };

  removeDisease = (index) => {
    const { selectedDiseases, destroyedDiseases, setDestroyedData, setSelectedData } = this.props;
    const destroyedData = selectedDiseases[index].id ? [{ ...selectedDiseases[index], _destroy: true }] : [];
    setSelectedData(update(selectedDiseases, { $splice: [[index, 1]] }));
    if (destroyedData.length) setDestroyedData([...destroyedDiseases, ...destroyedData]);
  };

  showInterCom = () => INTERCOM_APP_ID && IntercomAPI('show');

  submitData = () => {
    const { onSubmitData, selectedDiseases, destroyedDiseases, closePortal } = this.props;
    const { initialData } = this.state;
    const values = differenceWith(selectedDiseases, initialData, isEqual);
    onSubmitData(values.concat(destroyedDiseases)).then(closePortal);
  };

  renderMobileListRow = (disease, mode, { isSelected, isDisabled }) => {
    const { mobileSelectMode } = this.state;
    const selectedRow = isSelected(disease);
    const disabledRow = isDisabled(disease);
    const actionBtnType = mobileSelectMode === 'change' ? 'button' : 'checkbox';
    return (
      <MobileDiseasesRow
        key={disease.id}
        name={disease.name}
        actionBtnType={actionBtnType}
        disabledRow={disabledRow}
        onRowClick={() => this.onDiseaseClick(disease)}
        selectedRow={selectedRow}
      />
    );
  };

  renderSelectedDiseases = () => {
    const { selectedDiseases, updateSelectedItem, isDiagnosesLoading, diagnosisId: editMode } = this.props;
    return (
      <div className="diagnose-tab">
        <Preloader isActive={isDiagnosesLoading} />
        <div className="diseases-list">
          {selectedDiseases.map((disease, index) => (
            <DiseaseField
              key={index}
              onChange={() => this.setState({ mobileSelectMode: 'change', index })}
              onUpdate={(data) => updateSelectedItem(index, data)}
              onRemove={() => this.removeDisease(index)}
              isRemovable={selectedDiseases.length > 1}
              disease={disease}
              type={disease.diagnosis_type}
              note={disease.note}
              name={disease.name}
              notify_all={disease.notify_all}
            />
          ))}
          {!editMode && (
            <div onClick={() => this.setState({ mobileSelectMode: 'add' })} className="add-disease">
              <FormattedMessage id="component.mobileDiseasesSelector.addAnotherDisease" />
            </div>
          )}
        </div>
        <StickyFooter className="btn-block">
          <Button primary className="submit" onClick={this.submitData}>
            <FormattedMessage id="general.button.continue" />
          </Button>
        </StickyFooter>
      </div>
    );
  };

  renderMobileListTitle = (key) => (
    <div className="select-diseases-header">
      <FormattedMessage id={`general.pageTitle.${key}`} />
      <span className="select-diseases-title">{this.props.pigGroup.name}</span>
    </div>
  );

  renderRightButtonOptions = () => (
    this.state.mobileSelectMode !== 'change'
      ? { label: 'next', onClick: () => this.setState({ mobileSelectMode: null }) }
      : null
  );

  render() {
    const { checkupId, pigGroup, authUser } = this.props;
    const { activeTab, mobileSelectMode, diseasesCount, isLoaded } = this.state;

    return (
      <div className="MobileDiseasesSelector">
        {isLoaded && (
          <div className={cn('mobile-select-overlay', { 'isHidden': !mobileSelectMode })}>
            {mobileSelectMode && (
              <MobileListPicker
                idKey="diagnosis_id"
                title={this.renderMobileListTitle(`${mobileSelectMode}Disease`)}
                backLinkOptions={{ onClick: this.onCloseMobileSelect }}
                rightButtonOptions={this.renderRightButtonOptions()}
                rowRenderer={this.renderMobileListRow}
              />
            )}
          </div>
        )}
        <div className="disease-selector-header">
          <i className="fa fa-times back-link" onClick={this.onClose} />
          <div className="title">
            {this.renderMobileListTitle('diagnose')}
          </div>
          <div className="mobile-question">
            <div className="fa fa-ep-question-circle intercom-icon" id="Intercom" onClick={this.showInterCom} />
          </div>
        </div>

        <Subnavigation
          className="sub-navigation"
          isActive={(item) => item.tab === activeTab}
          items={[
            { tab: DIAGNOSE, name: <FormattedMessage id="general.diagnose" /> },
            { tab: GROUP_INFO, name: <FormattedMessage id="general.groupInfo" /> },
          ]}
          onSelect={this.onSubnavSelect}
        />

        {activeTab === DIAGNOSE
          ? this.renderSelectedDiseases()
          : (
            <div className="group-info-content">
              <CollapsesBox className="group-info-tab">
                <GroupInfoCollapse group={pigGroup} checkupId={checkupId} />
                <DiagnosesCollapse checkupId={checkupId} currentUser={authUser} diagnosisCount={diseasesCount} />
              </CollapsesBox>
            </div>
          )}
      </div>
    );
  }
}

MobileDiseasesSelector.propTypes = {
  checkupId: T.number,
  diagnosisId: T.number,
  pigGroup: T.object,
  authUser: T.object,
  closePortal: T.func,
  onSubmitData: T.func,
  fetchCheckupDiagnoses: T.func,
  fetchDiagnosesList: T.func,
  setDestroyedData: T.func.isRequired,
  setSelectedData: T.func.isRequired,
  selectDisease: T.func.isRequired,
  unselectDisease: T.func.isRequired,
  resetDiseasesData: T.func.isRequired,
  updateSelectedItem: T.func.isRequired,
  selectedDiseases: T.array,
  destroyedDiseases: T.array,
  isDiagnosesLoading: T.bool,
  diseasesList: T.array,
};

export default connect(
  (state) => ({
    isDiagnosesLoading: state.dailyCheckup.checkupDiagnoses.isLoading,
    authUser: state.auth.user,
    diseasesList: state.mobileListPicker.dataList.resources,
    selectedDiseases: state.mobileListPicker.selected,
    destroyedDiseases: state.mobileListPicker.destroyed,
  }),
  {
    fetchCheckupDiagnoses,
    fetchDiagnosesList,
    setDestroyedData,
    setSelectedData,
    selectDisease,
    unselectDisease,
    updateSelectedItem,
    resetDiseasesData,
  }
)(MobileDiseasesSelector);
