import React, { useEffect, useState } from 'react';
import T from 'prop-types';
// components
import Panel from 'components/Panel';
import Preloader from 'components/Preloader';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import Treatment from './Treatment';
import Field from 'components/Field';
import { reduxForm, change, FieldArray, getFormValues } from 'redux-form';
import CommentBox from 'components/CommentBox';
import Divider from 'components/Divider';
// constants
import { treatmentProtocolFormName } from './helper';
// endpoints
import { getTreatments as getMedications } from 'endpoints/treatmentProducts';
// utils
import { connect } from 'react-redux';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import cn from 'classnames';
import { validateTreatmentProtocol } from '../../helper';
import { prepareTreatmentsList } from 'utils';
// styles
import styles from './TreatmentProtocolForm.module.scss';

const initialTreatment = { steps: [{}] };

const renderTreatments = ({
  fields,
  options,
  treatments,
  isProductsChangeable,
} = {}) => fields.map((fieldName, fieldIndex) => (
  <Treatment
    isProductsChangeable={isProductsChangeable}
    product={treatments?.[fieldIndex]?.product}
    key={fieldName}
    options={options}
    fieldName={fieldName}
    onRemove={fields.length > 1 ? () => fields.remove(fieldIndex) : undefined}
  />
));

const TreatmentProtocolForm = ({
  title,
  formValues = {},
  change,
  onSubmit,
  submitLabel,
  isProductsChangeable = true,
  isNameChangeable = true,
  validate = validateTreatmentProtocol,
  initialValues,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [medications, setMedications] = useState([]);

  const addTreatment = () => change('treatments', [...formValues.treatments, initialTreatment]);
  const resetData = () => change('treatments', [initialTreatment]);

  useEffect(() => {
    getMedications()
      .then(prepareTreatmentsList)
      .then(setMedications)
      .catch(toastResponseErrors)
      .finally(() => setIsLoading(false));
  }, []);

  const getTreatmentProductSelectOptions = () => medications.map((medication) => ({
    ...medication,
    disabled: formValues.treatments?.some(({ product }) => product?.id === medication.id),
  }));

  const isFormValid = validate(formValues, initialValues);

  const onCommentChange = (comment) => change('comment', comment);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <form onSubmit={handleSubmit}>
      <section className="small-12 column">
        <Panel className="mb-20">
          <Panel.Heading title={title} />
          <Panel.Body className="ph-5">
            <Preloader isActive={isLoading} />
            <div className={styles['treatment-protocol-form']}>
              <div className="medium-4 ph-15">
                <FormattedMessage id="general.typeProtocolName">
                  {(placeholder) => (
                    <Field
                      disabled={!isNameChangeable}
                      name="name"
                      label={<FormattedMessage id="general.treatmentProtocolName" />}
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
              </div>
              <div className={styles['protocol-comment']}>
                <CommentBox
                  label={<FormattedMessage id="general.addTreatmentProtocolNote" />}
                  className="no-margin"
                  onValueChange={onCommentChange}
                  value={formValues.comment}
                />
              </div>
              <Divider className="mh-15 mv-15" />
              <FieldArray
                name="treatments"
                component={renderTreatments}
                options={getTreatmentProductSelectOptions()}
                treatments={formValues.treatments}
                isProductsChangeable={isProductsChangeable}
              />
              <div className={cn(styles['action-btn-group'], 'ph-15')}>
                {isProductsChangeable && (
                  <>
                    <Button type="button" onClick={addTreatment} blue>
                      <FormattedMessage id="general.addProduct" />
                    </Button>
                    <Button type="button" className="ml-20" onClick={resetData}>
                      <FormattedMessage id="general.button.clearAll" />
                    </Button>
                  </>
                )}
                <Button disabled={!isFormValid} primary className={styles['submit-btn']}>
                  {submitLabel}
                </Button>
              </div>
            </div>
          </Panel.Body>
        </Panel>
      </section>
    </form>
  );
};

TreatmentProtocolForm.propTypes = {
  initialValues: T.object.isRequired,
  title: T.node.isRequired,
  formValues: T.object,
  change: T.func.isRequired,
  onSubmit: T.func.isRequired,
  submitLabel: T.node.isRequired,
  validate: T.func,
  isProductsChangeable: T.bool,
  isNameChangeable: T.bool,
};

const formComponent = reduxForm({ form: treatmentProtocolFormName, enableReinitialize: true, })(TreatmentProtocolForm);

export default connect((state) => ({
  formValues: getFormValues(treatmentProtocolFormName)(state),
}), { change })(formComponent);
