import React from 'react';
import { FormattedMessage } from 'react-intl';
// assets
import usersImg from '../../public/images/csv-users.svg';
import farmsImg from '../../public/images/csv-farms.svg';
import groupsImg from '../../public/images/csv-groups.svg';
import barnsImg from '../../public/images/csv-barns.svg';
import csvFileImg from '../../public/images/csv-file.svg';
import csvMultiFilesImg from '../../public/images/csv-multi-files.svg';

export const usersCardName = 'users';

export const farmsCardName = 'farms';

export const groupsCardName = 'groups';

export const barnsCardName = 'barns';

export const oneObjectMode = 'oneObject';

export const multipleObjectsMode = 'multipleObjects';

export const csvImportModes = [
  {
    type: 'oneObject',
    title: 'general.csvImport.card.oneObject.title',
    infoText: 'general.csvImport.card.oneObject.infoText',
    svgIcon: csvFileImg,
  },
  {
    type: 'multipleObjects',
    title: 'general.csvImport.card.multi.title',
    infoText: 'general.csvImport.card.multi.infoText',
    svgIcon: csvMultiFilesImg,
  }
];

export const importObjectsTypes = [
  {
    type: usersCardName,
    title: 'general.users',
    infoText: 'general.csvImport.card.oneObject.users.infoText',
    svgIcon: usersImg,
  },
  {
    type: farmsCardName,
    title: 'general.farms',
    infoText: 'general.csvImport.card.oneObject.farms.infoText',
    svgIcon: farmsImg,
  },
  {
    type: groupsCardName,
    title: 'general.csvImport.card.oneObject.groups.title',
    infoText: 'general.csvImport.card.oneObject.groups.infoText',
    svgIcon: groupsImg,
  },
  {
    type: barnsCardName,
    title: 'general.barns',
    infoText: 'general.csvImport.card.oneObject.barns.infoText',
    svgIcon: barnsImg,
  }
];

export const availImportCSVTypes = importObjectsTypes.map((item) => item.type);

export const csvColumnsByType = {
  groups: {
    matchColumns: [
      'name',
      'farm_name',
      'start_date',
      'close_date',
      'current_group_name',
      'new_group_name',
    ],
    requiredColumns: [
      'name',
      'farm_name',
    ],
    mappingColumns: {
      'group id': 'name',
      'farm id': 'farm_id',
      'farm name': 'farm_name',
      'created at': 'created_at',
      'start date': 'start_date',
      'close date': 'close_date',
      'current group name': 'current_group_name',
      'new group name': 'new_group_name',
    },
    options: [
      { value: 'name', label: <FormattedMessage id="component.dataTable.headers.groupId" /> },
      { value: 'farm_name', label: <FormattedMessage id="component.dataTable.headers.farmName" /> },
      { value: 'start_date', label: <FormattedMessage id="general.startDate" /> },
      { value: 'close_date', label: <FormattedMessage id="general.closeDate" /> },
      { value: 'current_group_name', label: <FormattedMessage id="component.dataTable.headers.current_group_name" /> },
      { value: 'new_group_name', label: <FormattedMessage id="component.dataTable.headers.new_group_name" /> },
    ],
  },
  users: {
    matchColumns: [
      'email',
      'first_name',
      'last_name',
      'phone',
      'pqa_expire',
      'pqa_number',
      'roles',
      'site_id',
    ],
    requiredColumns: [
      'email',
      'first_name',
      'last_name',
    ],
    mappingColumns: {
      'email': 'email',
      'first name': 'first_name',
      'last name': 'last_name',
      'pqa expire': 'pqa_expire',
      'pqa number': 'pqa_number',
      'site id': 'site_id',
      'phone': 'phone',
      'roles': 'roles',
    },
    options: [
      { value: 'email', label: <FormattedMessage id="general.email" /> },
      { value: 'first_name', label: <FormattedMessage id="component.userProfile.firstName" /> },
      { value: 'last_name', label: <FormattedMessage id="component.userProfile.lastName" /> },
      { value: 'roles', label: <FormattedMessage id="general.role" /> },
      { value: 'site_id', label: <FormattedMessage id="general.siteId" /> },
      { value: 'phone', label: <FormattedMessage id="general.phone" /> },
      { value: 'pqa_expire', label: <FormattedMessage id="general.pqaExpiration" /> },
      { value: 'pqa_number', label: <FormattedMessage id="component.userProfile.pqaNumber.placeholder" /> },
    ],
  },
  farms: {
    matchColumns: [
      'name',
      'user_email',
      'status',
      'pigs_count',
      'estimated_weight',
      'address',
      'city',
      'farm_type',
      'federal_premise_id',
      'zipcode',
      'state',
    ],
    requiredColumns: [
      'name',
    ],
    mappingColumns: {
      'farm name': 'name',
      'user email': 'user_email',
      'status': 'status',
      'pigs count': 'pigs_count',
      'estimated weight': 'estimated_weight',
      'address': 'address',
      'city': 'city',
      'farm type': 'farm_type',
      'federal premise id': 'federal_premise_id',
      'zip code': 'zipcode',
      'state': 'state',
    },
    options: [
      { value: 'name', label: <FormattedMessage id="general.farmName" /> },
      { value: 'user_email', label: <FormattedMessage id="general.userEmail" /> },
      { value: 'status', label: <FormattedMessage id="component.dataTable.headers.status" /> },
      { value: 'pigs_count', label: <FormattedMessage id="general.pigsCount" /> },
      { value: 'estimated_weight', label: <FormattedMessage id="general.estimatedWeight" /> },
      { value: 'address', label: <FormattedMessage id="general.address" /> },
      { value: 'city', label: <FormattedMessage id="general.city" /> },
      { value: 'farm_type', label: <FormattedMessage id="general.farmType" /> },
      { value: 'federal_premise_id', label: <FormattedMessage id="general.federalPremiseId" /> },
      { value: 'zipcode', label: <FormattedMessage id="general.ziplabel.zipcode" /> },
      { value: 'state', label: <FormattedMessage id="general.stateLabel.state" /> },
    ],
  },
  barns: {
    matchColumns: [
      'name',
      'farm_name',
      'active',
      'capacity',
    ],
    requiredColumns: [
      'name',
      'farm_name',
    ],
    mappingColumns: {
      'name': 'name',
      'active': 'active',
      'farm name': 'farm_name',
      'capacity': 'capacity',
    },
    options: [
      { value: 'name', label: <FormattedMessage id="general.placeholder.barnName" /> },
      { value: 'farm_name', label: <FormattedMessage id="general.farmName" /> },
      { value: 'active', label: <FormattedMessage id="general.status.active" /> },
      { value: 'capacity', label: <FormattedMessage id="component.dataTable.headers.capacity" /> },
    ],
  },
};

export const labelsByType = {
  [usersCardName]: <FormattedMessage id="general.users" />,
  [farmsCardName]: <FormattedMessage id="general.farms" />,
  [barnsCardName]: <FormattedMessage id="general.barns" />,
  [groupsCardName]: <FormattedMessage id="general.groups" />,
};

export const uploadUrls = {
  [groupsCardName]: 'pig_groups_csv_imports',
  [farmsCardName]: 'farms_csv_imports',
  [usersCardName]: 'users_csv_imports',
  [barnsCardName]: 'barns_csv_imports',
};
