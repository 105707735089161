import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';
// components
import { FormattedMessage, useIntl } from 'react-intl';
import ChartPlaceholder from 'components/Amchart/ChartPlaceholder';
import Preloader from 'components/Preloader';
import Panel from 'components/Panel';
// endpoints
import { getGroupFlow } from 'endpoints/pigGroups';
// utils
import classnames from 'classnames/bind';
// amchart
import createGroupFlowChart from 'components/Amchart/charts/groupFlowSankeyChart';
import {
  defaultCardHeight,
  getHeight,
  getGroupFlowWidth,
  prepareData,
  minChartWidth,
} from 'components/Amchart/helpers/groupFlowChartHelper';
// styles
import styles from './GroupFlow.scss';

const cn = classnames.bind(styles);

const GroupFlow = ({ params, isBarnSheets }, { router }) => {
  const [{ isLoaded, isEmptyData, ...styleData }, setState] = useState({
    height: defaultCardHeight,
    width: minChartWidth,
    isLoaded: false,
    isEmptyData: false,
  });
  const chartRef = useRef({});
  const { formatMessage } = useIntl();

  useEffect(() => {
    getGroupFlow(params.id)
      .then(({ resources, meta }) => {
        const { data, cardIdsByType } = prepareData(resources, formatMessage);
        chartRef.current = createGroupFlowChart('group-flow', data, router);
        const availHeightArr = Object.keys(cardIdsByType)
          .map((item) => getHeight(cardIdsByType[item].size));
        setState({
          width: Math.max(getGroupFlowWidth(meta.depth), minChartWidth),
          height: Math.max(...availHeightArr, defaultCardHeight),
          isLoaded: true,
          isEmptyData: !data.length,
        });
      })
      .catch(() => {
        setState((prevState) => ({ ...prevState, isEmptyData: true, isLoaded: true }));
      });

    return () => {
      if (chartRef.current) chartRef.current.dispose();
    };
  }, []);

  return (
    <section className={cn('small-12 column', { 'mt-0': isBarnSheets })}>
      <Panel className={cn('group-sales-transfers')}>
        <Panel.Heading title={<FormattedMessage id="general.groupFlow" />} />
        <Panel.Body>
          <Preloader isActive={!isLoaded} />
          <div className="group-flow-diagram">
            {isEmptyData
              ? (
                <ChartPlaceholder
                  chartStyle={{ height: styleData.height }}
                  placeholder={<FormattedMessage id="component.nothingBox.noData" />}
                />
              )
              : <div id="group-flow" style={styleData} />
            }
          </div>
        </Panel.Body>
      </Panel>
    </section>
  );
};

GroupFlow.contextTypes = {
  router: T.object.isRequired,
};

GroupFlow.propTypes = {
  params: T.object.isRequired,
  isBarnSheets: T.bool,
};

export default GroupFlow;
