import isEqual from 'lodash.isequal';

export const formatTreatmentProtocolResponse = (fetchedTreatmentProtocol) => ({
  id: fetchedTreatmentProtocol.id,
  active: fetchedTreatmentProtocol.active,
  name: fetchedTreatmentProtocol.name,
  comment: fetchedTreatmentProtocol.comment,
  farm_treatment_protocol: fetchedTreatmentProtocol.farm_treatment_protocol,
  treatments: fetchedTreatmentProtocol.treatment_protocol_products.map((product) => ({
    id: product.id,
    product: product.treatment_product,
    comment: product.comment,
    dosage_comment: product.dosage_comment,
    steps: product.treatment_protocol_product_steps.map((step) => {
      const { step_type, step_unit, first_period, last_period, id } = step;
      return { [step_type]: { step_unit, first_period, last_period }, step_type, id };
    }),
  }))
});

export const formatTreatmentProtocolPayload = (treatmentProtocolPayload) => ({
  id: treatmentProtocolPayload.id,
  active: treatmentProtocolPayload.active,
  name: treatmentProtocolPayload.name,
  comment: treatmentProtocolPayload.comment,
  treatment_protocol_products_attributes: treatmentProtocolPayload.treatments.map((treatment) => ({
    id: treatment.id,
    treatment_product_id: treatment.product.id,
    comment: treatment.comment,
    dosage_comment: treatment.dosage_comment,
    _destroy: treatment._destroy,
    treatment_protocol_product_steps_attributes: treatment.steps.map((step) => {
      const { step_type, id, _destroy } = step;
      const { step_unit, first_period, last_period } = step[step_type];
      return { step_unit, first_period, last_period, step_type, id, _destroy };
    }),
  }))
});

// TODO: replace it with adding _destroy key dynamically, on every remove call
export const markDestroyedItems = (updatedTreatmentProtocol, initialTreatmentProtocol) => {
  const formattedTreatmentProtocol = { ...updatedTreatmentProtocol };
  initialTreatmentProtocol.treatments.forEach((treatment) => {
    const treatmentItem = formattedTreatmentProtocol.treatments.find(({ id }) => id === treatment.id);
    if (treatmentItem) {
      treatment.steps.forEach((step) => {
        const stepItem = treatmentItem.steps.find(({ id }) => id === step.id);
        if (!stepItem) {
          treatmentItem.steps.push({ ...step, _destroy: true });
        }
      });
    } else {
      formattedTreatmentProtocol.treatments.push({ ...treatment, _destroy: true });
    }
  });

  return formattedTreatmentProtocol;
};

const isTreatmentStepFilled = (treatmentStepData = {}) => {
  const { step_type } = treatmentStepData;
  const { step_unit, first_period, last_period } = treatmentStepData[step_type] || {};
  return step_type && step_unit && Boolean(step_type === 'between' ?  first_period && last_period : first_period);
};

export const validateTreatmentProtocol = (treatmentProtocol = {}) => {
  const isProtocolNamed = !!treatmentProtocol.name;
  if (!isProtocolNamed) return false;
  return treatmentProtocol.treatments?.every((treatment) => {
    const isProductSelected = !!treatment.product;
    if (!isProductSelected) return false;
    return treatment.steps?.every(isTreatmentStepFilled);
  });
};

export const validateUpdatedTreatmentProtocol = (updatedTreatmentProtocol, initialTreatmentProtocol) => {
  return !isEqual(updatedTreatmentProtocol, initialTreatmentProtocol) &&
    validateTreatmentProtocol(updatedTreatmentProtocol);
};
