import React from 'react';
import T from 'prop-types';
// components
import { FormattedMessage } from 'react-intl';
import FormattedWeight from 'components/FormattedWeight';
import SelectedFilterSectionRow from './SelectedFilterSectionRow';
// utils
import { capitalize } from 'utils';
import {
  formatOptionsValues,
  getConditionTranslationsBySection,
  getSectionOptionValueKey,
} from 'utils/userFiltersHelper';
import { conditionsBySectionType, sectionEntityKeys, userFilterSectionTypes } from 'utils/constants/userFilters';

const SwitchUserFilterSection = ({ section, onEdit, onRemove }) => {
  const defaultProps = {
    onEdit: () => onEdit(section),
    onRemove: () => onRemove(section),
    sectionType: section.type,
  };
  switch (section.type) {
    case userFilterSectionTypes.aiImagePrediction:
    case userFilterSectionTypes.aiTreatmentPrediction:
    case userFilterSectionTypes.aiMortalityPrediction:
    case userFilterSectionTypes.dcAsset:
    case userFilterSectionTypes.svr:
    case userFilterSectionTypes.pigGroupStatus:
    case userFilterSectionTypes.flagged:
    case userFilterSectionTypes.pigMovements: {
      const text = conditionsBySectionType[section.type].find(({ value }) => value === section.condition)?.label || '';
      return (
        <SelectedFilterSectionRow
          {...defaultProps}
          conditionText={text}
        />
      );
    }
    case userFilterSectionTypes.farm:
    case userFilterSectionTypes.pigGroup:
    case userFilterSectionTypes.user:
    case userFilterSectionTypes.company:
    case userFilterSectionTypes.load:
    case userFilterSectionTypes.managementCompany:
    case userFilterSectionTypes.latestSvrCreatedBy: {
      const messageId = getConditionTranslationsBySection(section.type)[section.condition];
      const target = section[sectionEntityKeys[section.type]];
      const values = {
        ...formatOptionsValues(section.options),
        name: target?.name || target?.full_name,
      };
      return (
        <SelectedFilterSectionRow
          {...defaultProps}
          conditionText={<FormattedMessage id={messageId} values={values} />}
        />
      );
    }
    case userFilterSectionTypes.latestSvrCreatedAt:
    case userFilterSectionTypes.date:
    case userFilterSectionTypes.pigGroupCloseDate:
    case userFilterSectionTypes.closeDate:
    case userFilterSectionTypes.pigGroupStartDate:
    case userFilterSectionTypes.startDate:
    case userFilterSectionTypes.arriveAt:
    case userFilterSectionTypes.farmExternalLinkId:
    case userFilterSectionTypes.farmFederalPremiseId:
    case userFilterSectionTypes.farmLocation:
    case userFilterSectionTypes.compliance:
    case userFilterSectionTypes.currentWaterUsage:
    case userFilterSectionTypes.avgMortalityRate:
    case userFilterSectionTypes.avgTreated:
    case userFilterSectionTypes.dcMortalityRate:
    case userFilterSectionTypes.dcInventory:
    case userFilterSectionTypes.mortalityRate:
    case userFilterSectionTypes.openPigGroupsCount:
    case userFilterSectionTypes.totalDeaths:
    case userFilterSectionTypes.pigsCount: {
      const messageId = getConditionTranslationsBySection(section.type)[section.condition];
      return (
        <SelectedFilterSectionRow
          {...defaultProps}
          conditionText={<FormattedMessage id={messageId} values={formatOptionsValues(section.options)} />}
        />
      );
    }
    case userFilterSectionTypes.dcEstimatedWeight:
    case userFilterSectionTypes.weight: {
      const messageId = getConditionTranslationsBySection(section.type)[section.condition];
      const options = formatOptionsValues(section.options);
      return (
        <SelectedFilterSectionRow
          {...defaultProps}
          conditionText={(
            <FormattedMessage
              id={messageId}
              values={{
                unit: <FormattedWeight hasOnlyUnit />,
                value: options.count,
                value_start: options.count_from,
                value_end: options.count_to,
                ...options,
              }}
            />
          )}
        />
      );
    }
    case userFilterSectionTypes.farmType:
    case userFilterSectionTypes.loadType:
    case userFilterSectionTypes.commitmentStatus:
    case userFilterSectionTypes.loadingStatus: {
      const messageId = getConditionTranslationsBySection(section.type)[section.condition];
      const optionKey = getSectionOptionValueKey(section.type);
      const text = formatOptionsValues(section.options)[optionKey];
      const values = {
        text: section.type === userFilterSectionTypes.farmType ? (text?.toUpperCase() || '') : capitalize(text),
      };
      return (
        <SelectedFilterSectionRow
          {...defaultProps}
          conditionText={<FormattedMessage id={messageId} values={values} />}
        />
      );
    }
    case userFilterSectionTypes.dcDiagnoses: {
      const messageId = getConditionTranslationsBySection(section.type)[section.condition];
      const optionKey = getSectionOptionValueKey(section.type);
      const entityIds = formatOptionsValues(section.options)[optionKey];
      return (
        <SelectedFilterSectionRow
          {...defaultProps}
          conditionText={<FormattedMessage id={messageId} values={{ count: entityIds?.length }} />}
        />
      );
    }
    default:
      return <div className="text-center p-15">The section view is under construction</div>;
  }
};

SwitchUserFilterSection.propTypes = {
  section: T.object.isRequired,
  onEdit: T.func.isRequired,
  onRemove: T.func.isRequired,
};

export default SwitchUserFilterSection;
