import intersection from 'lodash.intersection';

function isUserHasRole(roleMap) {
  return roleMap
    ? Boolean(roleMap.company_ids.length || roleMap.farm_ids.length)
    : false;
}

export const userRoleKeys = {
  admin: 'admin',
  caretaker: 'caregiver',
  vet: 'veterinarian',
  manager: 'manager',
  tenant_owner: 'tenant_owner',
  owner: 'primary_contact',
  super_admin: 'super_admin',
  farm_owner: 'farm_owner',
};

export const userRoleLabels = {
  admin: 'Tenant Admin',
  caregiver: 'Caregiver',
  veterinarian: 'Veterinarian',
  manager: 'Manager',
  tenant_owner: 'Tenant Owner',
  owner: 'Primary Contact',
  super_admin: 'Super Admin',
  farm_owner: 'Farm Owner',
};

export function getUserRoles(user) {
  if (!user.roles_map) return [];
  const rolesList = [];
  const {
    admin,
    caretaker,
    manager,
    owner,
    super_admin,
    tenant_owner,
    vet,
  } = user.roles_map;

  if (super_admin) rolesList.push(userRoleKeys.super_admin);
  if (admin) rolesList.push(userRoleKeys.admin);
  if (tenant_owner) rolesList.push(userRoleKeys.tenant_owner);
  if (owner && !!owner.company_ids.length) {
    rolesList.push(userRoleKeys.owner);
  }
  if (owner && !!owner.farm_ids.length) {
    rolesList.push(userRoleKeys.farm_owner);
  }
  if (isUserHasRole(manager)) rolesList.push(userRoleKeys.manager);
  if (isUserHasRole(caretaker)) rolesList.push(userRoleKeys.caretaker);
  if (isUserHasRole(vet)) rolesList.push(userRoleKeys.vet);
  return rolesList;
}

export function getUserRolesList(user) {
  return getUserRoles(user).map((key) => userRoleLabels[key]).join(', ');
}

export function isUserHasExactRoles(user, roles = []) {
  const userRoles = getUserRoles(user);
  if (userRoles.length !== roles.length) return false;
  return userRoles.every((role) => roles.includes(role));
}

export function isUserHasAnyRole(user, roles = []) {
  const userRoles = getUserRoles(user);
  return !!intersection(userRoles, roles).length;
}
