import React, { Component } from 'react';
import T from 'prop-types';
// redux
import { connect } from 'react-redux';
import { fetchCurrentGroup, enableEditMode, submitCorrections } from 'reducers/barnsheets/barnsheetsGroupCheckups';
import { setPageOptions } from 'reducers/layout';
import { setBreadcrumbs } from 'reducers/breadcrumbs';
// components
import { FormattedMessage } from 'react-intl';
import Button from 'components/Button';
import GroupProfileHeader from 'components/ProfileHeader/GroupProfileHeader/GroupProfileHeader';
import ProfileHeaderStats from 'components/ProfileHeader/ProfileHeaderStats/ProfileHeaderStats';
import SubNavigation from 'components/Subnavigation';
import StickyFooter from 'components/StickyFooter/StickyFooter';
import ProgressPercent from 'components/ProgressBar/ProgressPercent';
import FormattedWeight from 'components/FormattedWeight';
// utils
import download from 'downloadjs';
import { toastResponseErrors } from 'utils/responseErrorsHelper';
import { fetchFromAPI } from 'utils/api';
import { editModeBackUrl } from '../utils/editMode';
import { formatMortality } from 'utils';
// styles
import './BarnSheetsGroupProfile.scss';

class BarnSheetsGroupProfile extends Component {

  state = {
    invalidCorrections: false,
  };

  componentDidMount() {
    const { fetchCurrentGroup, route, location: { pathname } } = this.props;
    const { router } = this.context;

    router.setRouteLeaveHook(route, this.routerWillLeave);
    fetchCurrentGroup().then(({ value: { resource, resource: { in_edit_mode } } }) => {
      this.setBreadcrumbs(resource);
      this.setPageOptions(resource);
      router.push({ pathname, query: { editMode: in_edit_mode || undefined } });
    });
  }

  componentDidUpdate(prevProps) {
    const { params, pigGroup, pigGroup: { in_edit_mode }, location: { pathname } } = prevProps;
    const { router } = this.context;
    // edit mode toggle
    // refetch checkups on edit mode change
    if (pigGroup.id === this.props.pigGroup.id) {
      if (in_edit_mode !== this.props.pigGroup?.in_edit_mode) {
        this.setPageOptions(this.props.pigGroup);
        router.push({ pathname, query: { editMode: this.props.pigGroup?.in_edit_mode } });
      }
    }

    if (params.id !== this.props.params.id) {
      this.props.fetchCurrentGroup().then(({ value: { resource } }) => {
        this.setBreadcrumbs(resource);
        this.setPageOptions(resource);
      });
    }
  }

  componentWillUnmount() {
    this.props.setPageOptions({ backLink: '' });
  }

  getNavLinks = () => {
    const {
      isLogisticsEnabled,
      isTreatmentProtocolsEnabled,
      pigGroup,
      params: { id },
      location: { query },
      user: {
        current_company: {
          track_water_usage,
        }
      }
    } = this.props;
    const path = `/barnsheets/groups/${id}`;

    return [
      {
        link: { pathname: path, query }, label: <FormattedMessage id="general.dailyCheckups" />,
        onlyActiveOnIndex: true
      },
      {
        link: { pathname: `${path}/treatments`, query },
        label: <FormattedMessage id="general.pageTitle.treatments" />
      },
      {
        link: { pathname: `${path}/water-usage`, query },
        label: <FormattedMessage id="general.waterUsage" />,
        isHidden: !track_water_usage,
      },
      { link: { pathname: `${path}/diagnoses`, query }, label: <FormattedMessage id="general.diagnosisHistory" /> },
      { link: { pathname: `${path}/pig-movements`, query }, label: <FormattedMessage id="general.pigMovements" /> },
      {
        link: { pathname: `${path}/sales-results`, query },
        label: <FormattedMessage id="general.salesResults" />,
        isHidden: !isLogisticsEnabled || !pigGroup.marketing_reported_at,
      },
      { link: { pathname: `${path}/media`, query }, label: <FormattedMessage id="general.media" /> },
      { link: { pathname: `${path}/flow`, query }, label: <FormattedMessage id="general.groupFlow" /> },
      {
        link: { pathname: `${path}/treatment-protocols`, query },
        label: <FormattedMessage id="general.treatmentProtocols" />,
        isHidden: !isTreatmentProtocolsEnabled,
      },
    ];
  };

  setBreadcrumbs = (group) => {
    this.props.setBreadcrumbs([
      { label: <FormattedMessage id="general.pageTitle.barnsheets" />, path: '/barnsheets' },
      { label: group.farm.name, path: `/barnsheets/farms/${group.farm.id}/groups` },
      { label: group.name, path: `/barnsheets/groups/${group.id}`, useLabelAsMobileTitle: true, },
    ]);
  };

  setPageOptions = (pigGroup) => {
    if (!pigGroup) return;
    const { setPageOptions } = this.props;
    setPageOptions({
      backLink: '/barnsheets',
      inEditMode: pigGroup?.in_edit_mode,
    });
  };

  routerWillLeave = (nextLocation) => {
    const isEditModeNow = this.context.router.location?.query?.editMode;
    const isEditModeNext = nextLocation.query?.editMode;

    if (isEditModeNow && !isEditModeNext) {
      this.props.setPageOptions({ inEditMode: false });
    }

    return true;
  };

  validateCorrections = ({ resources }) => {
    this.setState({
      invalidCorrections: resources.some(({ correction }) => (correction ? correction.pigs < 0 : false)),
    });
  };

  downloadCSV = (path, fileName) => (e) => {
    e.stopPropagation();
    return fetchFromAPI(path, { blob: true })
      .then((blob) => download(blob, fileName, 'text/csv'))
      .catch(toastResponseErrors);
  };

  submitCorrections = () => {
    const { pigGroup, submitCorrections, user } = this.props;
    const { router } = this.context;

    submitCorrections()
      .then(() => {
        const url = editModeBackUrl(pigGroup, user);
        if (url) router.push(url);
      })
      .catch(toastResponseErrors);
  };

  renderDropdownOptions = () => {
    const { pigGroup: { in_edit_mode: isEditMode, name, external_source }, enableEditMode, params: { id },
      location: { pathname }, user: { roles_map: { admin } } } = this.props;

    return [
      {
        label: <FormattedMessage id="general.downloadBarnSheet" />,
        onClick: this.downloadCSV(`/csv/barnsheets/pig_groups/${id}`, `barnsheets-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadMortalityData" />,
        onClick: this.downloadCSV(`/csv/barnsheets/pig_groups/${id}/mortalities`, `mortality-report-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadTreatmentData" />,
        onClick: this.downloadCSV(`/csv/barnsheets/pig_groups/${id}/treatment_products`, `treatments-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadSymptomData" />,
        onClick: this.downloadCSV(`/csv/barnsheets/pig_groups/${id}/symptoms`, `symptoms-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadDiagnosesData" />,
        onClick: this.downloadCSV(`/csv/barnsheets/pig_groups/${id}/diagnoses`, `diagnoses-${name}.csv`)
      },
      {
        label: <FormattedMessage id="general.downloadMovementData" />, onClick: this.downloadCSV(
          `/csv/barnsheets/pig_groups/${id}/pig_migrations`,
          `pig-migrations-${name}.csv`
        )
      },
      { label: <FormattedMessage id="general.manageGroup" />, url: `/groups/${id}`, hide: !admin },
      {
        label: <FormattedMessage id="general.viewSource" />, url: `/sources/${external_source?.id}`,
        hide: !admin
      },
      {
        label: <FormattedMessage id="general.editData" />, onClick: enableEditMode,
        hide: isEditMode || pathname !== `/barnsheets/groups/${id}`
      },
    ];
  };

  render() {
    const { invalidCorrections } = this.state;
    const { pigGroup, pigGroup: { in_edit_mode: isEditMode }, isGroupLoading, children } = this.props;

    const groupStats = [
      { label: <FormattedMessage id="general.puc" />, value: pigGroup.pigs || 0 },
      {
        label: <FormattedMessage id="general.estAvgWeight" />,
        value: <FormattedWeight value={pigGroup.estimated_weight} unitFontSize="13px" />
      },
      {
        label: <FormattedMessage id="general.mortalityToDate" />,
        value: formatMortality(pigGroup.mortality_rate)
      },
      {
        label: <FormattedMessage id="general.groupCompliance" />,
        value: <ProgressPercent progress={pigGroup.progress} />
      },
    ];

    const childrenWithProps = React.Children.map(
      children,
      (child) => React.cloneElement(
        child,
        { validateCorrections: this.validateCorrections, pigGroup }
      )
    );

    const submitButtonText = (
      <FormattedMessage id={`general.button.${pigGroup.edit_inventory_id ? 'submit' : 'saveChanges'}`} />
    );

    return (
      <div className="BarnSheetsGroupProfile">

        <GroupProfileHeader
          isLoading={isGroupLoading}
          group={pigGroup}
          dropdownOptions={this.renderDropdownOptions()}
        >
          <ProfileHeaderStats stats={groupStats} />
          {isEditMode && (
            <Button
              primary
              onClick={this.submitCorrections}
              className="show-for-large mr-10"
              disabled={invalidCorrections}
            >
              {submitButtonText}
            </Button>
          )}
        </GroupProfileHeader>

        {isEditMode && invalidCorrections && (
          <div className="page-warn animated fadeInDown">
            <i className="fa fa-exclamation-triangle mr-10" />
            <FormattedMessage id="container.barnsheetsGroupProfile.editModeWarning" />
          </div>
        )}

        {!isEditMode &&
          <SubNavigation className="small-12 column" links={this.getNavLinks()} />}

        <section className="small-12 column">
          {childrenWithProps}
        </section>

        {isEditMode && (
          <StickyFooter className="hide-for-large">
            <Button className="submit-btn" primary onClick={this.submitCorrections} disabled={invalidCorrections}>
              {submitButtonText}
            </Button>
          </StickyFooter>
        )}
      </div>
    );
  }
}

BarnSheetsGroupProfile.contextTypes = {
  router: T.object.isRequired,
};

BarnSheetsGroupProfile.propTypes = {
  pigGroup: T.object,
  location: T.object,
  route: T.object,
  isGroupLoading: T.bool.isRequired,
  params: T.object,
  user: T.object,
  children: T.node,
  fetchCurrentGroup: T.func.isRequired,
  enableEditMode: T.func.isRequired,
  setBreadcrumbs: T.func.isRequired,
  submitCorrections: T.func.isRequired,
  setPageOptions: T.func.isRequired,
  isLogisticsEnabled: T.bool.isRequired,
  isTreatmentProtocolsEnabled: T.bool.isRequired,
};

export default connect(
  (state) => ({
    pigGroup: state.barnsheets.groupCheckups.pigGroup,
    isGroupLoading: state.barnsheets.groupCheckups.isGroupLoading,
    user: state.auth.user,
    isLogisticsEnabled: state.auth.user.current_company.logistics,
    isTreatmentProtocolsEnabled: state.auth.user.current_company.treatment_protocols,
  }), (dispatch, { params: { id } }) => ({
    fetchCurrentGroup: () => dispatch(fetchCurrentGroup(id)),
    setPageOptions: (params) => dispatch(setPageOptions(params)),
    enableEditMode: () => dispatch(enableEditMode(id)),
    submitCorrections: () => dispatch(submitCorrections(id)),
    setBreadcrumbs: (levels) => dispatch(setBreadcrumbs(levels)),
  })
)(BarnSheetsGroupProfile);
