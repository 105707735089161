import React from 'react';
import T from 'prop-types';
// components
import Pagination from 'components/Pagination/Pagination';
import PaginationSelect from 'components/Pagination/PaginationSelect';
// styles
import './DataTablePagination.scss';

const DataTablePagination = ({
  currentPage,
  onPageChange,
  onPerPageChange,
  perPage,
  totalItems,
}) => {
  const pagesCount = Math.ceil(totalItems / perPage);
  return (
    <div className="DataTablePagination">
      <PaginationSelect current={perPage} onChange={onPerPageChange} />
      <Pagination
        count={pagesCount}
        current={currentPage}
        onPrev={() => onPageChange(currentPage - 1)}
        onNext={() => onPageChange(currentPage + 1)}
        totalItems={totalItems}
        perPage={perPage}
      />
    </div>
  );
};

DataTablePagination.propTypes = {
  totalItems: T.number,
  currentPage: T.number,
  perPage: T.number,
  onPageChange: T.func.isRequired,
  onPerPageChange: T.func.isRequired,
};

DataTablePagination.defaultProps = {
  currentPage: 1,
  perPage: 25,
  totalItems: 0,
};

export default DataTablePagination;
