import { handleActions, createAction, combineActions } from 'redux-actions';
import { fetchFromAPI } from 'utils/api';

// ------------------------------------
// Constants
// ------------------------------------
const initialState = {
  user: {
    data: {},
    isLoading: false,
  },
  farm: {
    data: {},
    isLoading: false,
  },
  company: {
    data: {},
    isLoading: false,
  },
  plant: {
    data: {},
    isLoading: false,
  }
};

const FETCH_USER_DATA = 'profiles/FETCH_USER_DATA';
const FETCH_COMPANY_DATA = 'profiles/FETCH_COMPANY_DATA';
const FETCH_FARM_DATA = 'profiles/FETCH_FARM_DATA';
const SET_COMPANY_DATA = 'profiles/SET_COMPANY_DATA';
const UPDATE_USER_DATA = 'profiles/UPDATE_USER_DATA';
const UPDATE_COMPANY_DATA = 'profiles/UPDATE_COMPANY_DATA';
const FETCH_USER_DATA_PENDING = 'profiles/FETCH_USER_DATA_PENDING';
const FETCH_USER_DATA_FULFILLED = 'profiles/FETCH_USER_DATA_FULFILLED';
const FETCH_USER_DATA_REJECTED = 'profiles/FETCH_USER_DATA_REJECTED';
const UPDATE_USER_DATA_PENDING = 'profiles/UPDATE_USER_DATA_PENDING';
const UPDATE_USER_DATA_FULFILLED = 'profiles/UPDATE_USER_DATA_FULFILLED';
const UPDATE_USER_DATA_REJECTED = 'profiles/UPDATE_USER_DATA_REJECTED';
const FETCH_COMPANY_DATA_PENDING = 'profiles/FETCH_COMPANY_DATA_PENDING';
const FETCH_COMPANY_DATA_FULFILLED = 'profiles/FETCH_COMPANY_DATA_FULFILLED';
const FETCH_COMPANY_DATA_REJECTED = 'profiles/FETCH_COMPANY_DATA_REJECTED';
const UPDATE_COMPANY_DATA_PENDING = 'profiles/UPDATE_COMPANY_DATA_PENDING';
const UPDATE_COMPANY_DATA_FULFILLED = 'profiles/UPDATE_COMPANY_DATA_FULFILLED';
const UPDATE_COMPANY_DATA_REJECTED = 'profiles/UPDATE_COMPANY_DATA_REJECTED';
const FETCH_FARM_DATA_PENDING = 'profiles/FETCH_FARM_DATA_PENDING';
const FETCH_FARM_DATA_FULFILLED = 'profiles/FETCH_FARM_DATA_FULFILLED';
const FETCH_FARM_DATA_REJECTED = 'profiles/FETCH_FARM_DATA_REJECTED';
const UPDATE_FARM_DATA_PENDING = 'profiles/UPDATE_FARM_DATA_PENDING';
const UPDATE_FARM_DATA_FULFILLED = 'profiles/UPDATE_FARM_DATA_FULFILLED';
const UPDATE_FARM_DATA_REJECTED = 'profiles/UPDATE_FARM_DATA_REJECTED';
const FETCH_PLANT_DATA = 'profiles/FETCH_PLANT_DATA';
const FETCH_PLANT_DATA_PENDING = 'profiles/FETCH_PLANT_DATA_PENDING';
const FETCH_PLANT_DATA_FULFILLED = 'profiles/FETCH_PLANT_DATA_FULFILLED';
const FETCH_PLANT_DATA_REJECTED = 'profiles/FETCH_PLANT_DATA_REJECTED';
const UPDATE_PLANT_DATA_PENDING = 'profiles/UPDATE_PLANT_DATA_PENDING';
const UPDATE_PLANT_DATA_FULFILLED = 'profiles/UPDATE_PLANT_DATA_FULFILLED';
const UPDATE_PLANT_DATA_REJECTED = 'profiles/UPDATE_PLANT_DATA_REJECTED';
const UPDATE_PLANT_DATA = 'profiles/UPDATE_PLANT_DATA';
// ------------------------------------
// Actions
// ------------------------------------

export const fetchUserProfile = (path) => createAction(FETCH_USER_DATA)(fetchFromAPI(path));

export const fetchCompanyProfile = (path) => createAction(FETCH_COMPANY_DATA)(fetchFromAPI(path));

export const fetchFarmProfile = (path) => createAction(FETCH_FARM_DATA)(fetchFromAPI(path));

export const fetchPlantProfile = (path) => createAction(FETCH_PLANT_DATA)(fetchFromAPI(path));

export const updatePlantProfile = (path, reqParams) => createAction(UPDATE_PLANT_DATA)(
  fetchFromAPI(path, reqParams || { method: 'put' })
);

export const updateUserProfile = (path, reqParams) => createAction(UPDATE_USER_DATA)(
  fetchFromAPI(path, reqParams || { method: 'put' })
);

export const updateCompanyProfile = (path, reqParams) => createAction(UPDATE_COMPANY_DATA)(
  fetchFromAPI(path, reqParams || { method: 'put' })
);

export const setCompanyProfile = (data) => createAction(SET_COMPANY_DATA)(data);

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions({
  [combineActions(FETCH_USER_DATA_PENDING, UPDATE_USER_DATA_PENDING)]: (state) => ({
    ...state,
    user: {
      ...state.user,
      isLoading: true,
    },
  }),
  [combineActions(FETCH_USER_DATA_FULFILLED, UPDATE_USER_DATA_FULFILLED)]: (state, action) => ({
    ...state,
    user: {
      data: action.payload.resource,
      isLoading: false,
    },
  }),
  [combineActions(FETCH_USER_DATA_REJECTED, UPDATE_USER_DATA_REJECTED)]: (state) => ({
    ...state,
    user: {
      ...state.user,
      isLoading: false,
    },
  }),
  [combineActions(FETCH_COMPANY_DATA_PENDING, UPDATE_COMPANY_DATA_PENDING)]: (state) => ({
    ...state,
    company: {
      ...state.company,
      isLoading: true,
    },
  }),
  [combineActions(FETCH_COMPANY_DATA_FULFILLED, UPDATE_COMPANY_DATA_FULFILLED)]: (state, { payload }) => ({
    ...state,
    company: {
      data: {
        ...payload.resource,
        status: payload.resource.status || (payload.resource.active ? 'active' : 'disabled'),
      },
      isLoading: false,
    },
  }),
  [combineActions(FETCH_COMPANY_DATA_REJECTED, UPDATE_COMPANY_DATA_REJECTED)]: (state) => ({
    ...state,
    company: {
      ...state.company,
      isLoading: false,
    },
  }),
  [combineActions(FETCH_FARM_DATA_PENDING, UPDATE_FARM_DATA_PENDING)]: (state) => ({
    ...state,
    farm: {
      ...state.farm,
      isLoading: true,
    },
  }),
  [combineActions(FETCH_FARM_DATA_FULFILLED, UPDATE_FARM_DATA_FULFILLED)]: (state, action) => ({
    ...state,
    farm: {
      data: action.payload.resource,
      isLoading: false,
    },
  }),
  [combineActions(FETCH_FARM_DATA_REJECTED, UPDATE_FARM_DATA_REJECTED)]: (state) => ({
    ...state,
    farm: {
      ...state.farm,
      isLoading: false,
    },
  }),
  [SET_COMPANY_DATA]: (state, { payload }) => ({
    ...state,
    company: {
      ...state.company,
      data: {
        ...state.company.data,
        ...payload,
        status: payload.status || (payload.active ? 'active' : 'disabled'),
      },
    },
  }),
  [combineActions(FETCH_PLANT_DATA_PENDING, UPDATE_PLANT_DATA_PENDING)]: (state) => ({
    ...state,
    plant: {
      ...state.plant,
      isLoading: true,
    },
  }),
  [combineActions(FETCH_PLANT_DATA_FULFILLED, UPDATE_PLANT_DATA_FULFILLED)]: (state, { payload }) => ({
    ...state,
    plant: {
      data: {
        ...payload.resource,
        status: payload.resource.status || (payload.resource.active ? 'active' : 'disabled'),
      },
      isLoading: false,
    },
  }),
  [combineActions(FETCH_PLANT_DATA_REJECTED, UPDATE_PLANT_DATA_REJECTED)]: (state) => ({
    ...state,
    plant: {
      ...state.plant,
      isLoading: false,
    },
  }),
  'global/RESET_STORE': () => ({
    ...initialState,
  }),
}, initialState);
