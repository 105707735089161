import React, { Fragment } from 'react';
import T from 'prop-types';
// components
import Field from 'components/Field';
import { FormattedMessage } from 'react-intl';
import Input from 'components/Input';
import ReactSelect from 'components/Select/ReactSelect';
import CircleCheckbox from 'components/CircleCheckbox';
// utils
import classnames from 'classnames/bind';
import { connect } from 'react-redux';
import { capitalize } from 'utils';
import getValue from 'lodash.get';
// constants
import { treatmentPeriodUnits, treatmentPeriods, treatmentProtocolFormSelector } from './helper';
// styles
import styles from './TreatmentForm.module.scss';

const cn = classnames.bind(styles);

const ValueInput = (props) => (
  <Input
    {...props}
    {...props.input}
    placeholder="0"
    className={cn('days-input', 'ml-10')}
    hasNoMargin
    type="number"
    min={1}
    max={99}
    onChange={({ target: { value, validity } }) => (validity.valid && props.input.onChange(value ? +value : ''))}
  />
);

const UnitSelectField = (props) => (
  <Field
    {...props}
    component={({ input }) => (
      <ReactSelect
        className={cn('unit-select')}
        noInputMargin
        options={treatmentPeriodUnits}
        labelKey="label"
        valueKey="value"
        arrowRenderer={() => <i className="fa fa-angle-down" />}
        value={input.value || ''}
        onBlur={() => input.onBlur(getValue(input, 'value'))}
        onChange={(option) => (input.onChange(getValue(option, 'value')))}
        clearable={false}
        {...props}
      />
    )}
  />
);

const TreatmentForm = ({ fieldName, values }) => {
  const renderTreatmentLabel = (step_type) => {
    const { step_unit } = values?.[step_type] || {};
    const fieldKey = `${fieldName}.${step_type}`;

    return step_type === 'between' ? (
      <Fragment>
        <UnitSelectField name={`${fieldKey}.step_unit`} />
        <Field component={ValueInput} name={`${fieldKey}.first_period`} />
        <div className={cn('label-text')}>
          <FormattedMessage id={`general.and${capitalize(step_unit || 'day')}`} />
        </div>
        <Field component={ValueInput} name={`${fieldKey}.last_period`} />
        <div className={cn('label-text')}><FormattedMessage id="general.onFeed" /></div>
      </Fragment>
    ) : (
      <Fragment>
        <UnitSelectField name={`${fieldKey}.step_unit`} />
        <Field min={1} component={ValueInput} name={`${fieldKey}.first_period`} />
        <div className={cn('label-text', 'ml-10')}><FormattedMessage id="general.onFeed" /></div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {treatmentPeriods.map((step_type) => (
        <div key={step_type} className={cn('radio-item')}>
          <Field
            name={`${fieldName}.step_type`}
            component={({ input }) => (
              <CircleCheckbox
                {...input}
                type="radio"
                label={(
                  <div className={cn('label-text', 'semibold')}>
                    <FormattedMessage id={`general.${step_type}`} />
                  </div>
                )}
                checked={input.value === step_type}
                onChange={() => input.onChange(step_type)}
              />
            )}
          />
          <div className={cn('radio-label', 'semibold', { disabled: step_type !== values.step_type })}>
            {renderTreatmentLabel(step_type)}
          </div>
        </div>
      ))}
    </Fragment>
  );
};

TreatmentForm.propTypes = {
  fieldName: T.string.isRequired,
  values: T.shape({
    step_type: T.string,
    step_unit: T.string,
    first_period: T.number,
    last_period: T.number,
  }),
};

export default connect((state, { fieldName }) => ({
  values: treatmentProtocolFormSelector(state, fieldName),
}))(TreatmentForm);
